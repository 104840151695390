import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { fetch_get, PROJECTS_LIST, Context } from '../common';

class LeftNav extends Component {
  static contextType = Context
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: window.location.pathname.split('/').pop(),
      projectDetails: {},
      project: false,
      projectId: null,
      isLoading: false,
    };
  }
  
  componentDidMount() {
    this.collapseActions()
    if(this.state.project === false && (window.location.pathname.split('/')[4])) {
      this.setState({ selectedMenu: window.location.pathname.split('/')[4] === 'tasks-kanban' ? 'tasks' :  window.location.pathname.split('/')[4] }, () => this.isActiveSubMenu(this.state.selectedMenu))
      this.fetchProjectDetails(window.location.pathname.split('/')[3]);
      this.getRooms(window.location.pathname.split('/')[3])
    }
  }

  collapseActions() {
    let MenuBlock = document.getElementsByClassName("menu_block");
    let  Sidebar = document.getElementsByClassName("sidebar");
    let Stage = document.getElementsByClassName("stage");
    let SideMenu = document.getElementsByClassName("side_menu");
    if([`${this.context.company}/pm/dashboard`, `${this.context.company}/pm/dashboard/`, `${this.context.company}/pm/`, `${this.context.company}/pm`, `${this.context.company}/pm/${this.props.location.pathname.split("/")[3]}/document/${this.props.location.pathname.split("/")[5]}/details/`, `${this.context.company}/pm/create-project`].includes(window.location.pathname)) {
      MenuBlock[0].style.display = "none";
      Sidebar[0].style.width = "3.5%";
      Stage[0].style.marginLeft = "3.5%";
      SideMenu[0].style.width = "100%";
    } 
    else{
      MenuBlock[0].style.display = "inline-block";
      Sidebar[0].style.background = "#3F51B5";
      Sidebar[0].style.width = "15%";
      Stage[0].style.marginLeft = "15%";
      SideMenu[0].style.width = "25%";
    }
    // console.log(window.location.pathname, `${this.context.company}/pm/${window.location.pathname.split('/')[3]}/chat`)
    if(window.location.pathname === `${this.context.company}/pm/${window.location.pathname.split('/')[3]}/chat`) {
      Stage[0].style.paddingTop = "0px";
    } else {
      Stage[0].style.paddingTop = "15px";
    }
  }

  static getDerivedStateFromProps() {
    return (window.location.pathname.split('/')[4]) ? { selectedMenu: window.location.pathname.split('/')[4] === 'tasks-kanban' ? 'tasks' :  window.location.pathname.split('/')[4] } : { project: false, projectDetails: {}}
  }

  componentDidUpdate() {
    if(!this.state.project) {
      let SideMenu = document.getElementsByClassName("side_menu");
      if(SideMenu[0].style.width !== "100%") {
        this.collapseActions()
      }
    }
    if(window.location.pathname.split('/')[4] && !Object.keys(this.state.projectDetails).length > 0 && !this.state.isLoading) {
      this.fetchProjectDetails(window.location.pathname.split('/')[3]);
      this.getRooms(window.location.pathname.split('/')[3])
    }
    if(this.context.updateTasksMilestonesCount === 'true') {
      this.fetchProjectDetails(window.location.pathname.split('/')[3])
      this.getRooms(window.location.pathname.split('/')[3])
      this.context.updateCount('false')
    }
  }

  fetchProjectDetails(id) {
    var idIsNum = /^\d+$/.test(id);
    if(idIsNum) {
      this.setState({isLoading: true})
      fetch_get(PROJECTS_LIST + id + '/')
      .then((response) => {
        if(response.error) {
          // this.props.history.push(APP_NAME + '/pm/page-not-found')
        } 
        else {

          if(response.project) {
            this.setState({ 
              projectDetails: response.project,
              project: true,
              isLoading: false
            })
          } 
        }
      })
    }
  }

  getRooms(id) {
    fetch_get(PROJECTS_LIST + id + '/get_rooms/')
    .then((response) => { 
      // console.log(response, 'response')
      this.context.chatRoomDetails(response.chat_rooms[0].id)
    })
  }

  onClickMenu(value) {
    this.setState({ selectedMenu: value });
  }

  isActiveSubMenu(value) {
    return (value === this.state.selectedMenu) ? 'active' : ''
  }

  goBackDashBoard() {
    this.setState({ project: false, projectDetails: { } });
    this.props.history.push('/pm/')
  }

  render() {
    const { projectDetails } = this.state;
    return (
      <span>
        {this.state.project &&
          <Fragment> 
            {this.collapseActions()}
            <ul className="sub_menu inside_dashboard">
              <li>
                <Link to={`${this.context.company}/pm/dashboard`}> 
                  <span className="fa_icon"><i className="fas fa-arrow-left"></i></span> 
                  <span className="menu_text">Projects</span>
                </Link>
              </li>
              <li>
                <div className="company_menu_logo">
                  <h4 className="project_line text-center w-100">{projectDetails.data.name}
                  <small>{projectDetails.data.dead_line ? 'Dead line: '+ projectDetails.data.dead_line : ''}</small></h4>
                </div>
              </li>
              <li>
                <Link 
                  className={this.isActiveSubMenu('dashboard')} 
                  onClick={() => this.onClickMenu('dashboard')}
                  to={ `${this.context.company}/pm/${projectDetails.data.id}/dashboard`}
                > 
                  <span className="fa_icon"><i className="fa fa-tachometer-alt"></i></span>
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Dashboard  
                  </span>
                </Link>
              </li>
              <li>
                <Link 
                  className={this.isActiveSubMenu('tasks')} 
                  onClick={() => this.onClickMenu('tasks')}
                  to={ `${this.context.company}/pm/${projectDetails.data.id}/tasks` }
                > 
                  <span className="fa_icon"><i className="fa fa-tasks"></i></span>
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Tasks  
                    <small className="count">  {projectDetails.issues_count}</small>
                  </span>
                </Link>
              </li>
                
              <li>
                <Link 
                  className={this.isActiveSubMenu('milestones')} 
                  onClick={() => this.onClickMenu('milestones')}
                  to={{ 
                    pathname:`${this.context.company}/pm/${projectDetails.data.id}/milestones`,
                    state:{
                      collapseValue: this.props.collapse
                    }
                  }}
                >
                  <span className="fa_icon"><i className="fa fa-flag"></i></span>
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Milestones  
                    <small className="count">  {projectDetails.data.open_milestones_count}</small>
                  </span>
                </Link>
              </li>
              <li>
                <Link 
                  className={this.isActiveSubMenu('activities')} 
                  onClick={() => this.onClickMenu('activities')}
                  to={{
                    pathname:`${this.context.company}/pm/${projectDetails.data.id}/activities`, 
                    state:{
                      projectId: projectDetails.data.id, 
                      projectName: projectDetails.data.name,
                    }
                  }}
                >
                  <span className="fa_icon"><i className="fa fa-signal"></i></span>
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Activity</span>
                </Link>
              </li>
              <li>
                <Link 
                  className={this.isActiveSubMenu('worklog')} 
                  onClick={() => this.onClickMenu('worklog')}
                  to={{
                    pathname:`${this.context.company}/pm/${projectDetails.data.id}/worklog`, 
                    state:{
                      projectId: projectDetails.data.id, 
                      projectName: projectDetails.data.name,
                    }
                  }}
                >
                  <span className="fa_icon"><i className="fa fa-history"></i></span> 
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Worklog</span>
                </Link>
              </li>
              <li>
                <Link 
                  className={this.isActiveSubMenu('screenshots')} 
                  onClick={() => this.onClickMenu('screenshots')}
                  to={{
                    pathname:`${this.context.company}/pm/${projectDetails.data.id}/screenshots`, 
                    state:{
                      projectId: projectDetails.data.id, 
                      projectName: projectDetails.data.name,
                    }
                  }}
                >
                  <span className="fa_icon"><i className="fa fa-camera"></i></span>
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Screenshots</span>
                </Link>
              </li>
              <li>
                <Link 
                  className={this.isActiveSubMenu('documents')} 
                  onClick={() => this.onClickMenu('documents')}
                  to={{
                    pathname:`${this.context.company}/pm/${projectDetails.data.id}/documents/list`, 
                    state:{
                      projectId: projectDetails.data.id, 
                      projectName: projectDetails.data.name,
                    }
                  }}
                >
                  <span className="fa_icon"><i className="fa fa-folder"></i></span>
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Documents</span>
                </Link>
              </li>
              <li>
                <Link 
                  className={this.isActiveSubMenu('meetings')}  
                  onClick={() => this.onClickMenu('meetings')}
                  to={ `${this.context.company}/pm/${projectDetails.data.id}/meetings` }
                >
                  <span className="fa_icon"><i className="fa fa-users"></i></span> 
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Meetings</span>
                </Link>
              </li>
              <li>
                <Link 
                  className={this.isActiveSubMenu('testing')}  
                  onClick={() => this.onClickMenu('testing')}
                  to={ `${this.context.company}/pm/${projectDetails.data.id}/testing` }
                >
                  <span className="fa_icon"><i className="fa fa-users"></i></span> 
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Testing</span>
                </Link>
              </li>
              <li>
                <Link 
                  className={this.isActiveSubMenu('online')}  
                  onClick={() => this.onClickMenu('online')}
                  to={ `${this.context.company}/pm/${projectDetails.data.id}/online` }
                >
                  <span className="fa_icon"><i className="fa fa-users"></i></span> 
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Online</span>
                </Link>
              </li>
              <li>
                <Link 
                  className={this.isActiveSubMenu('project-settings')}  
                  onClick={() => this.onClickMenu('project-settings')}
                  to={ `${this.context.company}/pm/${projectDetails.data.id}/project-settings` }
                >
                  <span className="fa_icon"><i className="fa fa-cogs"></i></span> 
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Settings</span>
                </Link>
              </li>
              <li>
                <a 
                  className={this.isActiveSubMenu('client-installation')} 
                  href="https://download.pietrack.com/" 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fa_icon"><i className="fas fa-code"></i></span> 
                  <span className="menu_text">Download</span>
                </a>
              </li>
              <li>
                <Link 
                  className={this.isActiveSubMenu('chat')}  
                  onClick={() => this.onClickMenu('chat')}
                  to={ `${this.context.company}/pm/${projectDetails.data.id}/chat` }
                >
                  <span className="fa_icon"><i className="fa fa-comment"></i></span> 
                  <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Chat</span>
                </Link>
              </li>
            </ul>
          </Fragment>
        // :
        //   <Fragment> 
        //     {permissions.includes('REPORTS') ?
        //       <ul>
        //         <li>
        //           <Link 
        //             className={this.isActiveSubMenu('projects')}  
        //             onClick={() => this.onClickMenu('projects')}
        //             to={ `/pm/projects` }
        //           >
        //             <span className="fa_icon"><i className="fa fa-users"></i></span> 
        //             <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Projects</span>
        //           </Link>
        //         </li>
        //         <li>
        //           <Link 
        //             className={this.isActiveSubMenu('reports')}  
        //             onClick={() => this.onClickMenu('reports')}
        //             to={ `/pm/reports` }
        //           >
        //             <span className="fa_icon"><i className="fa fa-signal"></i></span> 
        //             <span className={this.props.collapse ? "menu_text span_hover" : "menu_text"} style={{display: this.props.collapse ? "none" : ""}}>Reports</span>
        //           </Link>
        //         </li> 
        //       </ul>
        //     : ''}
        //   </Fragment>
        }
      </span>
    );
  }
}

export default withRouter(LeftNav)
