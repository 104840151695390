import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  fetch_get,
  Context,
  PROJECTS_LIST,
  Spinner,
  TimeSince
} from '../../common';

export default class ProjectActivities extends Component {
  static contextType = Context;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      timelineList: [],
      loading: false,
      lazyLoading: false,
      totalActivities: '',
      nextPageNumber: '',
      loadnextPage: false

    };
    this.fetchTimelineList = this.fetchTimelineList.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

  }

  componentDidMount() {
    this._isMounted = true;
    this.initialState = this.state;
    document.getElementById('menu_left').addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScroll);
    this.setState({ loading: true }) 
    this.fetchTimelineList(1, true);
   
  }

  activeTab(value) {
    return 'tab' + (this.state.currentTab === value ? ' active' : '');
  }   

  fetchTimelineList(page=1, isLoadMore=false) {
    this.setState({ lazyLoading: true, loading: true });
    let timelineResponse = this.state.timelineList;
    if(page){
      fetch_get(PROJECTS_LIST +this.props.match.params.id + '/timeline/?page=' + page)
      .then((response) => {
        if (response.data && this._isMounted) {
          timelineResponse = (isLoadMore ? timelineResponse : []).concat(response.data.data)
          this.setState({ 
            timelineList: timelineResponse, loading: false, totalActivities: response.data.count,
            nextPageNumber: response.data.next_page_number ? response.data.next_page_number : '', lazyLoading: false, loadnextPage: isLoadMore
         });
        }
      })
      .catch(() => {
        this.setState({ error: 'Something went wrong, Please try again' });
      });
    }else{
      this.setState({loading: false, lazyLoading: false})
    }
  }

  handleScroll() {
    var menuLeft = document.getElementById('menu_left') ? document.getElementById('menu_left') : '';
    var offset = Math.max(menuLeft.scrollTop + document.getElementById('menu_left').clientHeight);
    const windowHeight = Math.max(window.innerHeight);
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
    var windowBottom = Math.max(windowHeight + window.pageYOffset);
    windowBottom = windowBottom + 1;
    if (windowBottom >= docHeight && offset >= menuLeft.scrollHeight) {
      if(!this.state.lazyLoading) {
        this.fetchTimelineList(this.state.nextPageNumber, this.state.loadnextPage);
      }
    }
  }

  activityStatus(value) {
    let status = 'timeline-item dark pb-0';
    switch (value) {
      case 'updated-task':
        status = 'timeline-item ';
        break;
      case 'task-create':
        status = 'timeline-item green';
        break;
      case 'label-create':
        status = 'timeline-item green';
        break;
      case 'user-remove':
        status = 'timeline-item danger';
        break;
      case 'commented':
        status = 'timeline-item warning';
        break;

      default :
    }
    return status;
  }

  activityTitle(obj) {
    let title = obj.event_type;
    switch (obj.event_type) {
      case 'updated task':
        title = 'Updated a task';
        break;
      case 'task-create':
        title = 'Created a task';
        break;
      case 'task-status-create':
        title = 'Created a task status';
        break;
      case 'task-status-edit':
        title = 'Updated a task status';
        break;
      case 'priority-create':
        title = 'Created a priority';
        break;
      case 'priority-update':
        title = 'Updated a priority';
        break;

      case 'label-create':
        title = 'Created a new label';
        break;
      case 'label-update':
        title = 'Updated a label';
        break;
      case 'user-remove':
        title = 'Removed user from task';
        break;
      case 'commented':
        title = 'commented for a task';
        break;

      default :
    }
    return title;
  }

  activityLink(obj) {
    let link = `${this.context.company}/pm/${obj.project_details.data.id}/dashboard/`;
    switch (obj.get_content_object_url_response.model) {
      case 'task':
        link = `${this.context.company}/pm/${obj.project_details.data.id}/tasks/${obj.get_content_object_url_response.id}`;
        break;
      case 'labels':
        link = `${this.context.company}/pm/${obj.project_details.data.id}/project-settings/`;
        break;
      case 'priority':
        link = `${this.context.company}/pm/${obj.project_details.data.id}/project-settings/`;
        break;
      case 'taskstatus':
        link = `${this.context.company}/pm/${obj.project_details.data.id}/project-settings/`;
        break;
      case 'user-remove':
        link = `${this.context.company}/pm/${obj.project_details.data.id}/tasks/${obj.get_content_object_url_response.id}`;
        break;
      case 'commented':
        link = `${this.context.company}/pm/${obj.project_details.data.id}/tasks/${obj.get_content_object_url_response.id}`;
        break;

      default :
    }
    return link
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(document.getElementById('menu_left')) {
      document.getElementById('menu_left').removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  render() {
    const { timelineList, totalActivities } = this.state;
    return (
      <div className="secondary_block">
        <div className="row mar_lr_0 comments project_detail_activity">
          <div className="col-lg-12">
            <h3 className="heading">Activity</h3>
          </div>
          <div className="activity_block">
            <div className="card">
              <div className="body" id="menu_left">
                {
                  totalActivities > 0 ?
                    timelineList.map((obj) => 
                      <div className={this.activityStatus(obj.event_type)} key={obj.created_on}>
                        <span className="date"><TimeSince date={obj.created_on} displayDate /></span>
                        <h5>{this.activityTitle(obj)}</h5>
                        <p><Link to={this.activityLink(obj)}>{obj.event_type === 'commented' && obj.profile_details.data.employee_name}{obj.namespace}</Link></p>
                        <div className="avatars project_members">
                          {
                            obj.profile_details ? 

                            obj.profile_details.data.has_profile_pic ?
                          <span className="avatar tooltips">

                          <img src={obj.profile_details.data.profile_img} />
                          <span className="right">{obj.profile_details.data.employee_name}</span>
                          </span>
                           :
                           <span className="profile_name" title={obj.profile_details.data.employee_name}>{obj.profile_details.data.profile_text}</span>
                        : '' 
                         }
                        </div>
                      </div>
                    )
                  : 
                    ''
                }
                {this.state.loading ?
                  <Spinner />
                :
                  totalActivities > 10 &&
                    <div>*** No more activities to show ***</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}