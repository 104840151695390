import React from 'react'
import PropTypes from 'prop-types'
// import ReactDOM from 'react-dom'
import loadScript from 'load-script'
// const loadScript = require('load-script');

const defaultScriptUrl = 'https://cdn.ckeditor.com/4.10.1/standard-all/ckeditor.js'
/**
 * @author codeslayer1
 * @description CKEditor component to render a CKEditor textarea with defined configs and all CKEditor events handler
 */
let commentUsers = []
let isUser = false
class CKEditor extends React.Component {
  constructor (props) {
    super(props)
    // Bindings
    this.onLoad = this.onLoad.bind(this)
    this.updateChanges = this.updateChanges.bind(this)
    // State initialization
    this.state = {
      isScriptLoaded: this.props.isScriptLoaded,
      config: this.props.config,
      content: this.props.content,
      commentUsers: this.props.commentUsers ? this.props.commentUsers : []
    }
    isUser = this.props.isUser || false
  }

  // load ckeditor script as soon as component mounts if not already loaded
  componentDidMount () {
    // if(window.CKEDITOR) {
    //   Object.keys(window.CKEDITOR.instances).forEach((obj) => {
    //     window.CKEDITOR.instances[obj].destroy()
    //   })
    // }
    if (!this.state.isScriptLoaded) {
      loadScript(this.props.scriptUrl, this.onLoad)
      commentUsers = this.state.commentUsers
    } else {
      this.onLoad()
      this.updateChanges(this.state.content)
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if ((nextProps.commentUsers && nextProps.commentUsers.length) !== (prevState.commentUsers && prevState.commentUsers.length)) {
      return { commentUsers: nextProps.commentUsers }
    }
    return (nextProps.editCKEditor) ? {
      content: nextProps.content
    } : null
  }

  componentDidUpdate () {
    if (this.state.commentUsers) {
      if (this.state.commentUsers.length !== commentUsers.length) {
        this.updateCommentUsers()
      }
    }
    if (this.props.editCKEditor) {
      this.updateChanges(this.props.content)
    }
  }

  updateCommentUsers () {
    commentUsers = this.state.commentUsers
  }

  // componentWillReceiveProps(nextProps) {
  //   if(nextProps.editCKEditor && this.state.isScriptLoaded){
  //     this.setState({ content: nextProps.content })
  //     this.updateChanges(nextProps.content)
  //   }
  // }

  updateChanges (ckbody) {
    if (this.state.isScriptLoaded) {
      this.editorInstance.setData(ckbody)
    }
  }

  componentWillUnmount () {
    this.unmounting = true
  }

  onLoad () {
    if (this.unmounting) return
    commentUsers = this.state.commentUsers
    this.setState({
      isScriptLoaded: true
    })

    if (!window.CKEDITOR) {
      console.error('CKEditor not found')
      return
    }
    this.editorInstance = window.CKEDITOR.appendTo(
      // ReactDOM.findDOMNode(this),
      {
        plugins: 'mentions,basicstyles,undo,link,wysiwygarea,toolbar,clipboard',
        toolbar: [
          { name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', 'NumberedList', 'BulletedList'] },
          { name: 'maximized' }
        ],
        contentsCss: [
          'https://cdn.ckeditor.com/4.10.1/full-all/contents.css',
          'https://sdk.ckeditor.com/samples/assets/mentions/contents.css',
          '../../public/css/main.css'
        ],
        height: 150,
        removeDialogTabs: 'image:advanced;link:advanced;link:target',
        mentions: [
          {
            feed: dataFeed,
            marker: '@',
            itemTemplate: '<li data-id="{id}">' +
                  '<img className="photo" style=width:30px src={profile_img} />' +
                  '<strong className="username">  {nickname}  </strong>' +
                '</li>',
            outputTemplate: '<a href="mailto:{nickname}">@{nickname}</a><span>&nbsp;</span>',
            minChars: 0
          }
        ],
        // <li data-id="{id}" style=color:blue>
        removePlugins: 'elementspath',
        resize_enabled: true,
        resize_maxWidth: 5000,
        resize_maxHeight: 5000,
        resize_dir: 'both'
      },
      this.state.content
    )
    for (const event in this.props.events) {
      const eventHandler = this.props.events[event]

      this.editorInstance.on(event, eventHandler)
    }
  }

  render () {
    return <div className={this.props.activeClass} />
  }
}

function getMatchObject (item) {
  const matchProperty = 'nickname'
  if (isUser) {
    return item[matchProperty]
  }
  return item.user_details.data[matchProperty]
}

function dataFeed (opts, callback) {
  if (commentUsers && commentUsers.length > 0) {
    // var matchProperty = 'nickname',
    let data = commentUsers.filter((item) => {
      const name = getMatchObject(item)
      return name.indexOf(opts.query.toLowerCase()) === 0
    })
    data = data.sort((a, b) => {
      a.nickname = getMatchObject(a)
      b.nickname = getMatchObject(b)
      return getMatchObject(a).localeCompare(getMatchObject(b), undefined, { sensitivity: 'accent' })
    })
    callback(data)
  }
}

CKEditor.defaultProps = {
  content: '',
  config: {},
  isScriptLoaded: false,
  scriptUrl: defaultScriptUrl,
  activeClass: '',
  events: {}
}

CKEditor.propTypes = {
  content: PropTypes.any,
  config: PropTypes.object,
  isScriptLoaded: PropTypes.bool,
  scriptUrl: PropTypes.string,
  activeClass: PropTypes.string,
  events: PropTypes.object
}

export { CKEditor }
