import React, { Component, Fragment } from 'react'
import { fetch_post, fetch_get, PROJECTS_LIST, notify } from '../../common'

export default class LabelBoard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      board: this.props.labelBoard,
      taskName: '',
      dragStatusId: '',
      dropStatusId: '',
      editLabel: false,
      membersMenu: false,
      labelName: '',
      showLabelPicker: false,
      labelColor: '',
      lazyLoading: false,
      tasks: this.props.tasks.data,
      taskCreate: false,
      tasksNextPageNumber: this.props.tasks.data.length >= 15 ? 2 : null,
      tasksCount: this.props.labelBoard.tasks_count
      // labelsDetails: this.props.labelthis.state.tasks.
    }
    // this.updateBoard = this.updateBoard.bind(this);
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount () {
    document.getElementById(this.state.board.id).addEventListener('scroll', this.handleScroll)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    if (document.getElementById(this.state.board.id)) {
      document.getElementById(this.state.board.id).removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  componentDidUpdate () {
    if (this.props.updateBoardIds.includes(this.state.board.id)) {
      this.fetchBoardDetails(this.state.board.id)
    }
    if (this.props.updateOnlyBoardId.includes(this.state.board.id)) {
      this.updateStatus()
    }
  }

  CreateTask (status) {
    const data = new FormData()
    data.append('name', this.state.taskName)
    data.append('status', status)
    fetch_post(PROJECTS_LIST + this.props.props.props.match.params.id + '/tasks/create/', data)
      .then((response) => {
        if (!response.error) {
          notify(response.messages)
          this.setState({ taskName: '', errors: {}, taskCreate: false }, () => this.fetchBoardDetails(status))
        } else {
          this.setState({ errors: response.errors })
        }
      })
  }

  updateStatus () {
    fetch_get(PROJECTS_LIST + this.props.props.props.match.params.id + '/task-status/' + this.state.board.id + '/')
      .then((response) => {
        this.setState({ board: response.data })
        this.props.updateOnlyBoard([])
      })
  }

  fetchBoardDetails (statusId = this.state.board.id, page = 1) {
    fetch_get(PROJECTS_LIST + this.props.props.props.match.params.id + '/kanban-status-scroll/' + statusId + '/?page=' + page)
      .then((response) => {
        this.setState({ tasksCount: response.count })
        if (!this.state.lazyLoading) {
          this.props.updateBoards([])
          this.setState({ tasks: response.data, tasksNextPageNumber: response.next_page_number, lazyLoading: false })
        } else {
          const tasks = this.state.tasks
          this.setState({ tasks: tasks.concat(response.data), tasksNextPageNumber: response.next_page_number, lazyLoading: false })
        }
      })
  }

  onDragOver (ev) {
    ev.preventDefault()
  }

  onDragStart (ev, statusId, taskId, task) {
    const j = JSON.stringify(task)
    ev.dataTransfer.setData('task', j)
    ev.dataTransfer.setData('statusId', statusId)
    ev.dataTransfer.setData('taskId', taskId)
    this.setState({ dragStatusId: statusId })
  }

  onDrop (ev, DropStatus) {
    const DropTask = JSON.parse(ev.dataTransfer.getData('task'))
    const statusId = parseInt(ev.dataTransfer.getData('statusId'))
    // let taskId = ev.dataTransfer.getData("taskId")
    if (parseInt(DropStatus.id) !== parseInt(statusId)) {
      this.setState({ dropStatusId: DropStatus.id, onDropTask: DropTask, dragStatusId: statusId }, this.taskUpdate)
    }
    // else {
    // const data = new FormData();
    // data.append('order', index+1);
    // fetch_post(`${PROJECTS_LIST}${this.props.props.props.match.params.id}/kanban-change-order/${DropStatus.id}/`, data)
    // .then((response) => {
    //   if(!response.error) {
    //     let values =
    //     this.props.updateBoards([this.state.dragStatusId, this.state.dropStatusId]);
    //   }
    // })
    // }
  }

  taskUpdate () {
    const data = new FormData()
    data.append('status', this.state.dropStatusId)
    fetch_post(`${PROJECTS_LIST}${this.props.props.props.match.params.id}/kanban-change-status/${this.state.onDropTask.pt_id}/`, data)
      .then((response) => {
        if (!response.error) {
        // let values =
          this.props.updateBoards([this.state.dragStatusId, this.state.dropStatusId])
        }
      })
  }

  renderMembers (members) {
    let list2 = []
    list2 = members.slice(2, members.length)
    return (
      <div className='col-lg-6 text-left client_view'>
        <div className='avatars no-padding'>
          {list2.map((member) =>
            <span key={member.id} className='avatar'>
              {member.has_profile_pic
                ? <img src={member.profile_img} />
                : <span className='profile_name' data-toggle='tooltip' data-placement='bottom' title={member.employee_name}>{member.employee_name && member.employee_name[0] + (member.employee_name.split(' ')[1] && member.employee_name.split(' ')[1][0])}</span>}
            </span>
          )}
        </div>
        <span
          className='btn-group dropdown people task_people no-padding '
          tabIndex={0}
          onClick={() => this.setState({ membersMenu: !this.state.membersMenu })}
          onBlur={() => this.setState({ membersMenu: false })}
        >
          {list2.length > 0 &&
            <a className='btn toggle avatar'>
              {list2.length}
            </a>}
          {this.state.membersMenu &&
            <ul className='menu' style={{ display: 'block' }}>
              {list2.map((member) =>
                <li key={member.id} className='submenu'>
                  <a>
                    {member.has_profile_pic
                      ? <img src={member.profile_img} />
                      : <span className='profile_name' data-toggle='tooltip' data-placement='bottom' title={member.employee_name}>{member.employee_name && member.employee_name[0] + (member.employee_name.split(' ')[1] && member.employee_name.split(' ')[1][0])}</span>}
                    {member.employee_name}
                  </a>
                </li>
              )}
            </ul>}
        </span>
      </div>
    )
  }

  handleScroll () {
    const menuLeft = document.getElementById(this.state.board.id) ? document.getElementById(this.state.board.id) : ''
    const offset = Math.max(menuLeft.scrollTop + document.getElementById(this.state.board.id).clientHeight)
    const windowHeight = Math.max(window.innerHeight)
    const body = document.body
    const html = document.documentElement
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
    const windowBottom = Math.max(windowHeight + window.pageYOffset)
    if (windowBottom >= docHeight && offset >= menuLeft.scrollHeight) {
      if (!this.state.lazyLoading && this.state.tasksNextPageNumber) {
        this.setState({ lazyLoading: true }, this.fetchBoardDetails(this.state.board.id, this.state.tasksNextPageNumber, true))
      }
    }
  }

  onBlur () {
    if (this.state.taskName) {
      this.CreateTask(this.state.board.id)
      this.setState({ editLabel: false })
    } else {
      this.setState({ taskCreate: false })
    }
  }

  render () {
    const { board } = this.state
    return (
      <div className='list to_do'>
        <div className='task_detail_heading'>
          <div className='task_title list-title form-group'>
            {this.state.editLabel
              ? <textarea
                  autoFocus
                  className='task_detail_title form-control'
                  rows='1'
                  placeholder='Add to List'
                  value={this.state.labelName}
                  onChange={(e) => this.setState({ labelName: e.target.value })}
                  onBlur={() => { this.props.addEditStatus(board.id, this.state.labelName); this.setState({ editLabel: false, taskCreate: false }) }}
                >
                Tasks to Do
                </textarea>
              : <div className='ml-5 heading list-title' onClick={() => this.setState({ editLabel: true, labelName: board.name, labelColor: board.color })}>{board.name}</div>}
          </div>
          <div className='kanban_model'><span className='count' onClick={() => this.props.addTask(true, this.state.board.id)}><i className='fas fa-plus' /></span></div>
          <small className='w-100' style={{ marginLeft: '10px' }}>{this.state.tasksCount} task{this.state.tasksCount !== 1 && 's'}</small>
        </div>
        <ul
          className='list-items'
          id={board.id}
          // draggable
          onDragOver={(e) => this.onDragOver(e)}
          onDrop={(e) => { this.onDrop(e, board) }}
        >
          {this.state.tasks.length > 0 ? this.state.tasks.map((task, index) =>
            <li
              key={task.id}
              onDragOver={(e) => this.onDragOver(e)}
              onDrop={(e) => { this.onDrop(e, board, index) }}
              onDragStart={(e) => this.onDragStart(e, board.id, task.pt_id, task)}
              draggable
              onClick={() => this.props.showTaskDetail(task.pt_id, board.id)}
            >
              <div className='sub-tag'>
                <div className='row marl no-gutters'>
                  <div className='col-lg-12'>{task.name}</div>
                  {task.assigned_to_list.data.length > 3
                    ? <>
                      {this.renderMembers(task.assigned_to_list.data)}
                    </>
                    : <>
                      {task.assigned_to_list.data.map((member) =>
                        <div className='avatars no-padding' key={member.id}>
                          <span key={member.id} className='avatar'>
                            {member.has_profile_pic
                              ? <img src={member.profile_img} data-toggle='tooltip' data-placement='bottom' title={member.employee_name} alt='' />
                              : <span className='profile_name' data-toggle='tooltip' data-placement='bottom' title={member.employee_name}>{member.employee_name && member.employee_name[0] + (member.employee_name.split(' ')[1] && member.employee_name.split(' ')[1][0])}</span>}
                          </span>
                        </div>
                      )}
                    </>}
                  {(task.comment_count > 0 || task.attachment_count > 0) &&
                    <div className='col-lg-6 text-right'>
                      <div className='more_details'>
                        {task.comment_count > 0 && <span className='detail' title='Comments'><a><i className='far fa-comments' />  ({task.comment_count}) </a></span>}
                        {task.attachment_count > 0 && <span className='detail' title='Attachments'><a><i className='fas fa-paperclip' />  ({task.attachment_count}) </a></span>}
                      </div>
                    </div>}
                  {task.labels_text && task.labels_text.data.length > 0
                    ? <div className='col-lg-12 kanban-tags'>
                      <div>
                        {task.labels_text.data.map((label) =>
                          <a key={label.id} style={{ backgroundColor: label.color }}>{label.name}</a>
                        )}
                      </div>
                      </div>
                    : null}
                </div>
              </div>
            </li>
          )
            : <li style={{ padding: '10px' }}>No tasks found</li>}
        </ul>
      </div>
    )
  }
}
