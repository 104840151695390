import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { VALIDATE_SUBDOMAIN, APP_NAME, LOGIN_SERVER } from '../common';
import logo from '../../static/images/logo.png'; 
export default class LoginDomain extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subDomain: '',
      error: '',
      nextRoute: ''
    };
    this.handleChangeSubdomain = this.handleChangeSubdomain.bind(this);
  }

  componentDidMount() {
    document.body.classList.add("authentication_body");
    if (localStorage.getItem('token')) {
      this.props.history.push(APP_NAME + '/intro')
    }
    else {
      if(this.props.match.url.split('/')[2] === 'change-domain') {
        const domain = localStorage.getItem('company');
        this.setState({ subDomain: domain })
      } else {
        let sub_domain = localStorage.getItem('company') ? localStorage.getItem('company') : window.location.host.split('.')[0]
        if(sub_domain !== 'microtrack') {
          let data = new FormData()
          data.append('sub_domain', sub_domain)
          fetch(VALIDATE_SUBDOMAIN, {
            method: 'POST',
            body: data
          })
          .then(response => response.json())
          .then((response) => {
            if (!response.error) {
              localStorage.setItem('company', sub_domain);
              const redirectUrl = `//${sub_domain + LOGIN_SERVER}/signin`;
              window.location.href = redirectUrl;
            }
          })
        }
      }
    }
  }

  handleChangeSubdomain(event) {
    this.setState({ subDomain: event.target.value });
    if (event.target.value === '') {
      this.setState({ error: '' });
    }
  }

  validateSubdomain(e) {
    e.preventDefault();
    const { subDomain } = this.state;
    let data = new FormData()
    data.append('sub_domain', subDomain)
    fetch(VALIDATE_SUBDOMAIN, {
      method: 'POST',
      body: data
    })
    .then(response => response.json())
    .then((response) => {
      if (!response.error) {
        localStorage.setItem('company', subDomain);
        const redirectUrl = `//${subDomain + LOGIN_SERVER}/signin`;
        window.location.href = redirectUrl;
      } else {
        this.setState({ error: response.errors.sub_domain[0] });
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  handleKeyPress = (event) => {
    event.preventDefault();
    if(event.key === 'Enter'){
      this.validateSubdomain()
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("authentication_body");
  }

  render() {
    return (
      <div className="authentication_wrapper">
        <div className="authentication_block">
          <div className="left">
            <div className="heading">Microtrack Login Page</div>
            <div className="domain_block">
              <div className="auth_form">
                <form>
                  <div className="form-group">
                    <input type="text" id="name" className="form-control1" onChange={this.handleChangeSubdomain} value={this.state.subDomain} required />
                    <label className="form-control-placeholder" htmlFor="name">Sub domain</label>
                    {this.state.error &&
                      <small className="error-text">{this.state.error}</small>
                    }
                  </div>
                  <div className="buttons">
                    <button type="submit" className="btn primary_btn" onClick={this.validateSubdomain.bind(this)}>Login</button>
                  </div>
                </form>
              </div>
              <br clear="all" />
            </div>
          </div>
          <div className="right">
            <img alt="" src={logo} width="100%"/>
            <strong>Don&apos;t have an account?</strong>
            <div className="buttons">
              <Link to={APP_NAME + '/register-domain'} className="primary_btn btn">Register</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}