import React, { Component } from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { fetch_get, fetch_post, PROJECTS_LIST, PROJECT_ACTIVITY, notify, Context } from '../../common';

export default class AddWorklog extends Component {
  static contextType = Context
  constructor(props) {
    super(props);
    this.state = {
      startTime: moment(),
      endTime: moment(),
      description: '',
      timezoneChoices: [],
      tasks: [],
      profile: '',
      timezone: '',
      taskId: '',
      errors: {},
      activityId: this.props.props.match.params.wid ? this.props.props.match.params.wid : null,
      updateActivity: true,
      isManualLog: false,
    };
  }

  componentDidMount() {
    this.setState({ timezone: this.context.timezone })
    this.getIntitalData()
    this.getProjectTasks();
    if(this.state.activityId) {
      this.editActivity();
    }
  }

  getIntitalData() {
    fetch_get(PROJECT_ACTIVITY + 'create/?project_id=' + this.props.props.match.params.id)
    .then((response) => {
      this.setState({ 
        timezoneChoices: response.timezone_choices, 
      })
    });
  }

  editActivity() {
    fetch_get(PROJECT_ACTIVITY + this.state.activityId + '/update/?project=' + this.props.props.match.params.id)
    .then((response) => {
      if(response.error) {
        notify(response.message)
        this.setState({ updateActivity: false })
      } else {
        this.setState({
          profile: response.activity.data.profile_details.data.id,
          taskId: response.activity.data.task_details.data.id,
          timezone: response.activity.data.timezone,
          description: response.activity.data.description,
          startTime: moment(response.activity.data.start_time),
          endTime: moment(response.activity.data.end_time),
          isManualLog: response.activity.data.is_manual_log
        })
      }
    })
  }

  getProjectTasks() {
    fetch_get(PROJECTS_LIST + this.props.props.match.params.id + '/get-tasks/')
    .then((response) => {
      this.setState({ tasks: response.tasks.data });
    });
  }

  createActivity() {
    const { startTime, endTime, description, taskId, timezone, profile } = this.state;
    this.setState({ errors: '' });
    const data = new FormData();
    data.append('profile', profile);
    data.append('project', this.props.props.match.params.id);
    data.append('task', taskId);
    timezone && data.append('timezone', timezone);
    data.append('description', description);
    data.append('start_time', startTime ? typeof(startTime) == 'object' ? startTime.format("YYYY-MM-DD HH:mm:ss") : startTime : '');
    data.append('end_time', endTime ? typeof(endTime) == 'object' ?  endTime.format("YYYY-MM-DD HH:mm:ss") : endTime : '');
    var url = this.state.activityId ? this.state.activityId + '/update/' : 'create/';
    fetch_post(PROJECT_ACTIVITY + url, data)
    .then((response) => {
      if(!response.error) {
        notify(response.response)
        this.props.overlayControl()
      } else {
        this.setState({ errors: response.errors })
      }
    });
  }

  isValid(date) {
    return typeof(date) === "object"
  }

  render() {
    const { errors, activityId } = this.state;
    let duration =  this.isValid(this.state.endTime) && this.isValid(this.state.startTime) ? moment.duration(this.state.endTime.diff(this.state.startTime)) : null;
    return (
      <div className="slide_section ">
        <div className="add_task slide_content">
          <div className="row justify-content-start slide_content_row add_milestone_modal">
            <div className="col-lg-12">
              <h2 className="heading">{activityId ? 'Edit' : 'Add' } Worklog</h2>
              {this.state.updateActivity ? 
                <form className="no-padding">
                  <div className="form-group row mar_lr_0">
                    <label>Task<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                    <select 
                      className="form-control"
                      value={this.state.taskId}
                      onChange={(e) => this.setState({ taskId: e.target.value })}
                      // disabled={(isManualLog && activityId) ? false : true}
                    >
                      <option>select task</option>
                      {this.state.tasks.length > 0 ? this.state.tasks.map((task) => 
                        <option key={task.id} value={task.id}>{task.name}</option>
                      ) : <option>No results found</option>}
                    </select>
                    <div className="error-text">
                      {errors.task ? errors.task[0] : null}
                    </div>
                  </div>
                  <div className="form-group row mar_lr_0">
                    <label>Timezone<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                    <select 
                      className="form-control"
                      value={this.state.timezone}
                      onChange={(e) => this.setState({ timezone: e.target.value })}
                      // disabled={(isManualLog && activityId) ? false : true}
                    >
                      <option value="">Select</option>
                      {this.state.timezoneChoices.length > 0 ? this.state.timezoneChoices.map((timezone) => 
                        <option key={timezone[0]} value={timezone[0]}>{timezone[0]}</option>
                        ) : <option>No results found</option>
                      }
                    </select>
                    <div className="error-text">
                      {errors.timezone ? errors.timezone[0] : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Start Time<sup className="error-text" style={{top: '0px' }}>*</sup>
                    </label>
                    <Datetime 
                      dateFormat="YYYY-MM-DD" 
                      timeFormat="hh:mm A" 
                      value={this.state.startTime} 
                      inputProps={{ placeholder: 'Select start time', className: 'form-control' }}
                      onChange={(e) => this.setState({ startTime: e })} 
                      closeOnSelect
                    />
                    {errors.start_time ? 
                      <div className="error-text">
                        {errors.start_time[0]} 
                      </div>
                    :
                      null
                    } 
                  </div>
                  <div className="form-group">
                    <label>
                      End Time<sup className="error-text" style={{top: '0px' }}>*</sup>
                    </label>
                    <Datetime
                      dateFormat="YYYY-MM-DD" 
                      timeFormat="hh:mm A" 
                      inputProps={{ placeholder: 'Select end time', className: 'form-control' }}
                      value={this.state.endTime} 
                      onChange={(e) => this.setState({ endTime: e })} 
                    />
                    {errors.end_time ? 
                      <div className="error-text">
                        {errors.end_time[0]}
                      </div>
                    :
                      null
                    }
                  </div>
                  <div className="form-group">
                    <label>
                      Duration : {
                        Math.floor(duration && duration.asHours()) + ' hrs ' + Math.floor(duration && duration.asMinutes()%60) + ' mins'
                      }
                    </label>
                  </div>
                  <div className="form-group row mar_lr_0">
                    <label>
                    Description<sup className="error-text" style={{top: '0px' }}>*</sup>
                    </label>
                    <textarea  
                      name="editor" 
                      rows="4"
                      className="form-control"
                      value={this.state.description} 
                      onChange={(e) => this.setState({ description: e.target.value })} 
                    > 
                    </textarea>
                    <div className="error-text">
                      {errors.description ? errors.description[0] : null}
                      {errors.__all__ ? errors.__all__[0] : null}
                    </div>
                  </div>
                  <div className="text-left buttons">
                    <button type="button" className="btn primary_btn" onClick={() => this.createActivity()}>Save Details</button>
                    <button type="button" className="btn secondary_btn" onClick={() => this.props.addWorklog ? this.props.overlayControl() : this.props.props.history.push(`${this.context.company}/pm/${this.props.props.match.params.id}/worklog`)}>Cancel</button>
                  </div>
                </form>
              :
                <form>You cannot update this Activity</form>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
