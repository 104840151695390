import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import MilestonesList from './milestones/MilestonesList';
// import MilestoneDetails from './milestones/MilestoneDetails';
import ProjectSettings from './settings/ProjectSettings';
import WorklogComment from './worklogSummary/WorklogComment';
import Worklog from './worklogSummary/Worklog';
import Screenshots from './worklogSummary/Screenshots';
import RedirectFromGitHub from './settings/integrations/RedirectFromGitHub';
import Documents from './documents';
import DocumentView from './documents/DocumentView';
import WriteDocument from './documents/WriteDocument';
import DocumentDetails from './documents/DocumentDetails';
import Meetings from './meetings/Meetings';
import Online from './Online';
import MeetingActions from './meetings/MeetingActions';
import MeetingDetails from './meetings/MeetingDetails';
import TasksList from './tasks/TasksList';
import ProjectActivities from './activities/ProjectActivities';
import ProjectDetails from './ProjectDetails';
import { fetch_get, PROJECTS_LIST, Context } from '../common';
// import TasksKanban from './tasks/TasksKanban';
import ChatScreenPage from './chat/ChatScreenPage';
import TestTypes from './testing/TestTypes'
import TestCases from './testing/TestCases'
import TestExecution from './testing/TestExecution'
import TestExecutionDetails from './testing/TestExecutionDetails'

export default class ProjectRoute extends Component {
  static contextType = Context;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isProjectFound: false,
      message: ''
    };
  }

  componentDidMount() {
    this._isMounted = true;
    var idIsNum = /^\d+$/.test(this.props.match.params.id);
    if(idIsNum) {
      fetch_get(PROJECTS_LIST + this.props.match.params.id + '/')
      .then((response) => {
        if(response && this._isMounted) {
          if(response.error) {
            this.setState({ isProjectFound: false, isLoading: false, message: response.message })
            // this.props.history.push(APP_NAME + '/pm/page-not-found')
          } else {
            this.setState({ 
              isProjectFound: true,
              isLoading: false
            })
          }
        }
      })
    } else {
      this.setState({ 
        isProjectFound: false,
        isLoading: false,
        message: 'Please enter valid Url, Project not found'
      }) 
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { company } = this.context;
    const ProjectRoutes = (
      <Switch>
        <Route sensitive path={company + "/pm/:id/tasks/:tid"} component={TasksList} />
        <Route sensitive path={company + "/pm/:id/tasks"} component={TasksList} />
        <Route sensitive path={company + "/pm/:id/tasks-kanban"} component={TasksList} />
        {/*<Route sensitive path={company + "/pm/:id/chatscreen"} component={JoinChat} exact={true} />*/}
        <Route sensitive path={company + "/pm/:id/chat"} component={ChatScreenPage} />
        <Route exact sensitive path={ company + "/pm/:id/dashboard"} component={ProjectDetails} />
        <Route exact sensitive path={ company + "/pm/:id/milestones"} component={MilestonesList} />
        <Route exact sensitive path={ company + "/pm/:id/milestones/:mid/edit"} component={MilestonesList} />
        <Route exact sensitive path={ company + "/pm/:id/worklog/"} component={Worklog} />
        <Route exact sensitive path={ company + "/pm/:id/activities/"} component={ProjectActivities} />
        <Route exact sensitive path={ company + "/pm/:id/screenshots/"} component={Screenshots} />
        <Route exact sensitive path={ company + "/pm/:id/documents/list/"} component={Documents} />
        <Route exact sensitive path={ company + "/pm/:id/document/:did/details"} component={WriteDocument} />
        <Route exact sensitive path={ company + "/pm/:id/document/:did/view"} component={DocumentView} />
        <Route exact sensitive path={ company + "/pm/:id/documents/:did/details"} component={DocumentDetails} />          
        <Route exact sensitive path={ company + "/pm/:id/meetings/"} component={Meetings} />
        <Route exact sensitive path={ company + "/pm/:id/online/"} component={Online} />
        <Route exact sensitive path={ company + "/pm/:id/meetings/add"} component={Meetings} />
        <Route exact sensitive path={ company + "/pm/:id/meetings/:mid/edit"} component={Meetings} />
        <Route exact sensitive path={ company + "/pm/:id/meetings/:mid/details"} component={MeetingDetails} />
        <Route exact sensitive path={ company + "/pm/:id/meetings/:mid"} component={Meetings} />
        <Route exact sensitive path={ company + "/pm/:id/meetings/:mid/:status"} component={MeetingActions} />
        <Route exact sensitive path={ company + "/pm/:id/worklog/add"} component={Worklog} />
        <Route exact sensitive path={ company + "/pm/:id/worklog/:wid/edit"} component={Worklog} />
        <Route exact sensitive path={ company + "/pm/:id/worklog/:aid/comment"} component={WorklogComment} />
        <Route exact sensitive path={ company + "/pm/:id/project-settings"} component={ProjectSettings} />
        <Route exact sensitive path={ company + "/pm/:id/project-settings/github/login-success/"} component={RedirectFromGitHub} />
        {/*<Route exact sensitive path={ company + "/pm/:pid/milestones/:mid"} component={MilestoneDetails} />*/}
        <Route exact sensitive path={ company + "/pm/:id/testing"} component={TestTypes} />
        <Route exact sensitive path={ company + "/pm/:id/testing/testcase/:id"} component={TestCases} />
        <Route exact sensitive path={ company + "/pm/:id/testing/testcase/:id/testexecutions"} component={TestExecution} />
        <Route exact sensitive path={ company + "/pm/:id/testing/testexecutiondetails"} component={TestExecutionDetails} />
      </Switch>
    )
    if(this.state.isProjectFound) {
      return ProjectRoutes;
    }
    return (
      <div>
        <center>
        <b>{this.state.isLoading ? 'Loading ...' : this.state.message}</b>
        </center>
      </div>
    )
  }
}

