import React, { Component } from 'react';
import Select from 'react-select';
import {
  fetch_get,
  fetch_post,
  PROJECTS_LIST,
  SERVER,
  notify,
  Modal,
  Context
} from '../../../common';

export default class Sentry extends Component {
  static contextType = Context
  constructor(props) {
    super(props);
    this.state = {
      domain: '//' + localStorage.getItem('company') + SERVER,
      apiUrl: '',
      errors: {},
      organizations: [],
      projects: [],
      labels: [],
      assignedList: [],
      label: [],
      member: [],
      hostScheme: 'http://',
      authToken: '',
      project: '',
      organization: '',
      moreOptions: false,
      issueLevel: '',
      visibleStatus: '',
      sentryDetails: false,
      sentryDetailsList: [],
      priority: '',
      issueStatus: '',
      projectId: this.props.projectId,
      edit: false,
      hostName: '',
      show: false,
      loading: true
    }
    this.editSentryDetails = this.editSentryDetails.bind(this);
  }

  componentDidMount() {
    this.intialSentryDetails();
    this.moreSettings();
    this.initialState = this.state;
  }

  componentDidUpdate(prevProps) {
    if(this.props.projectId !== prevProps.projectId) {
      this.setState(this.initialState)
      this.setState({ projectId: this.props.projectId }, () => { 
        this.intialSentryDetails();
        this.moreSettings();
      })
    }
  }

  intialSentryDetails() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/sentry/add/')
    .then(response => {
      this.setState({ 
        apiUrl: response.default_api_url,
        loading: false
      });
      if(response.sentry.data) {
        this.setState({ sentryDetailsList: response.sentry.data, sentryDetails: true });
      }
    })
  }

  getOrganizations(hostName=this.state.hostName, authToken=this.state.authToken) {
    this.setState({ errors: {} });
    const data = new FormData();
    data.append('host_name', hostName);
    data.append('scheme', this.state.hostScheme);
    data.append('auth_token', authToken);
    data.append('api_url', this.state.apiUrl);
    fetch_post(PROJECTS_LIST + this.state.projectId +'/integrations/sentry/get_sentry_ogranizations/', data)
    .then(response => {
      if(response.error) {
        this.setState({ errors: response.errors });
      } else {
        this.setState({ organizations: response.organizations_list })
      }
    })
  }

  getProjects(hostName=this.state.hostName, authToken=this.state.authToken, org=this.state.organization) {
    this.setState({ errors: {} });
    const data = new FormData();
    data.append('host_name', hostName)
    data.append('scheme', this.state.hostScheme);
    data.append('auth_token', authToken);
    data.append('api_url', this.state.apiUrl);
    data.append('organization', org)
    fetch_post(PROJECTS_LIST + this.state.projectId +'/integrations/sentry/get_sentry_ogranization_projects/', data)
    .then(response => {
      if(response.error) {
        this.setState({ errors: response.errors });
      } else {
        this.setState({ projects: response.projects_list })
      }
    })
  }

  moreSettings() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/sentry/default-options/')
    .then(response => {
      var members = [];
      var labelsList = [];
      response.labels.data.filter(function( obj ) {
        return (
          labelsList.push({label: obj.name, value: obj.id})
        )
      });
      response.profiles.data.filter(function( obj ) {
        return (
          members.push({
            label: 
            <div>
              <img className="select_img" src={obj.profile_img}  alt={obj.employee_name}/>
              {obj.employee_name.split(" ")[0]}
            </div>, 
            value: obj.id
          })
        )
      });
      this.setState({ 
        labels: labelsList,
        assignedMembersList: members,
        moreOptions: true,
        priorities: response.priorities,
        issueStatuses: response.issue_status_choices,
        visiblleStatuses: response.visible_status_choices,
        issueLevels: response.issue_level_choices
      })
    })
  }

  onLabelSelect(value) {
    this.setState({ label: value });
  }

  onMemberSelect(value) {
    this.setState({ member: value });
  }

  saveDefaultSettings() {
    this.setState({ errors: {} });
    const data = new FormData();
    if(this.state.label.length > 0) {
      this.state.label.filter(function( obj ) {
        return (
          data.append('labels', obj.value)
        )
      });
    }
    if(this.state.member) {
      this.state.member.filter(function( obj ) {
        return (
          data.append('assigned_to', obj.value)
        )
      });
    }
    data.append('issue_status', this.state.issueStatus)
    data.append('visible_status', this.state.visibleStatus)
    data.append('projects_list', JSON.stringify(this.state.projects));
    data.append('host_name', this.state.hostName);
    data.append('scheme', this.state.hostScheme);
    data.append('auth_token', this.state.authToken);
    data.append('api_url', this.state.apiUrl);
    data.append('org_slug', this.state.organization)
    data.append('project', this.state.project);
    data.append('priorities', this.state.priority)
    data.append('issue_level', this.state.issueLevel)
    var url = this.state.edit ? 'update/' + this.state.sentryDetailsList.id + '/' : 'default-options/';
    fetch_post(PROJECTS_LIST + this.state.projectId +'/integrations/sentry/'+ url, data)
    .then(response => {
      if(response.error) {
        this.setState({ errors: response.errors })
      } else {
        this.intialSentryDetails();
        notify(response.response);
        this.context.updateCount('true');
      }
    });
  }

  editSentryDetails() {
    const { sentryDetailsList } = this.state;
    var hostScheme = sentryDetailsList.host_name.split('//')[0] + '//';
    var labels = [];
    if(sentryDetailsList.default_values.labels.data) {
      sentryDetailsList.default_values.labels.data.filter(function( obj ) {
        return (
          labels.push({label: obj.name, value: obj.id})
        )
      });
    }
    if(sentryDetailsList.default_values.assigned_to.data.length > 0) {
      var member = [];
      sentryDetailsList.default_values.assigned_to.data.filter(function( obj ) {
        return (
          member.push({
            label: 
            <div>
              <img className="select_img" src={obj.profile_img} alt={obj.employee_name}/>
              {obj.employee_name.split(" ")[0]}
            </div>, 
            value: obj.id
          })
        )
      });
    }
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/sentry/update/' + sentryDetailsList.id + '/')
    .then(response => {
      this.setState({ 
        apiUrl: sentryDetailsList.api_url,
        authToken: sentryDetailsList.auth_token,
        hostScheme:  hostScheme,
        hostName: sentryDetailsList.host_name.split('//')[1],
        organization: sentryDetailsList.org_slug,
        project: sentryDetailsList.sentry_project_id,
        sentryDetails: false,
        label: labels,
        member: member,
        priority: sentryDetailsList.default_values.priorities.data ? sentryDetailsList.default_values.priorities.data.id : '',
        issueLevel: sentryDetailsList.default_values.issue_level,
        issueLevels: response.issue_level_choices,
        visiblleStatuses: response.visible_status_choices,
        issueStatuses: response.issue_status_choices,
        moreOptions: true,
        issueStatus: sentryDetailsList.default_values.issue_status,
        visibleStatus: sentryDetailsList.default_values.visible_status,
        edit: true
      });
    })
    this.getOrganizations(sentryDetailsList.host_name.split('//')[1], sentryDetailsList.auth_token);
    this.getProjects(sentryDetailsList.host_name.split('//')[1], sentryDetailsList.auth_token, sentryDetailsList.org_slug);
  }

  setValue(e) {
    this.setState({ visibleStatus: e.target.value });
  }

  setValueIssue(e) {
    this.setState({ issueStatus: e.target.value });
  }

  deleteSentrySettings() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/sentry/delete/' + this.state.sentryDetailsList.id + '/', true)
    .then(response => {
      if(response.status === 204) {
        this.setState({ displayDeleteModal: false })
        notify('Deleted Sentry Default settings');
        this.context.updateCount('true');
        this.setState(this.initialState);
        this.intialSentryDetails();
        this.moreSettings();
      }
    })
  }

  cancelDetails() {
    if(this.state.edit) {
      this.setState({ moreOptions: true, sentryDetails: true })
    }
  }

  LoadSentryIssues() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/sentry/load-issues/' + this.state.sentryDetailsList.id + '/')
    .then(() => { })
  }

  labelText(hex) {
    let rgb = (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).join(',').split(",")
    var colorRange = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000);
    var color = (colorRange > 200) ? '#000' : '#fff';
    return color;
  }

  render() {
    const { permissions, role } = this.context;
    const { visiblleStatuses, issueLevels, issueStatuses, sentryDetailsList, priorities } = this.state;
    return (
      <div className="row justify-content-center project_settings">
        <div className="col-lg-12 pl-0 sentry_conatiner">
          <h3 className="sml_heading"> Sentry</h3>
          {this.state.show &&
            <div className="integration_content" style={{ display: 'block' }}>
              <p>Sentry’s real-time error tracking gives you insight into production deployments and information to reproduce and fix crashes.
                Sentry provides open source error tracking that shows you every crash in your stack as it happens, with the details needed to prioritize, identify, reproduce, and fix each issue. 
                It also gives you information your support team can use to reach out to and help those affected and tools that let users send you feedback for peace of mind.
              </p>
              <strong> Instructions</strong>
              <h2>Host Name</h2>
              <p>Host name must be your registered organization Sentry host_url</p>
              <p>Example: https://abc.xyz.com <small>(or)</small> http://abc.xyz.com </p>
              <p>The auth token is available in your Account API Settings</p>
              <p>Click on User Icon which is at left bottom and click on API navigation button</p>
              <p>&gt;&gt; Sentry Web API</p>
              <p>&gt;&gt; Auth Tokens (Authentication tokens allow you to perform actions against the Sentry API on behalf of your account.
              They&apos;re the easiest way to get started using the API.)</p>
              <p>&gt;&gt; Here you can create a new token with possible options (You can choose the permissions on creating the token)</p>
            </div>
          }
          <div className="sliding_bar">
            {this.state.show &&
              <span className="arrow-up" onClick={() => this.setState({ show: false }) }>
                <i className="fa fa-times"></i>
              </span>
            }
            { !this.state.show &&
              <span className="arrow-down" onClick={() => this.setState({ show: true }) }>
                <i className="fa fa-question"></i>
              </span>
            }
          </div>
        </div>
        {!this.state.sentryDetails ?
          <div className="col-lg-12 pl-0 form">
            {this.state.loading ? 
              <div>Loading ...</div>
            :
              <form>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Host Name<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                      {this.state.edit ? 
                        <input 
                          type="text"
                          className="form-control" 
                          value={this.state.hostScheme + this.state.hostName}
                          onChange={() => {}}
                          disabled
                        />
                      :
                        <div className="input-group">
                          <span className="input-group-btn">
                            <select 
                              className="form-control select"
                              value={this.state.hostScheme} 
                              onChange={(e) => this.setState({ hostScheme: e.target.value})}
                            >
                              <option value="http://">Http://</option>
                              <option value="https://">Https://</option>
                            </select>
                          </span>
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Enter Host name" 
                            value={this.state.hostName}
                            onChange={(e) => this.setState({ hostName: e.target.value })}
                          /> 
                        </div>
                      }
                      <div className="error-text">
                        {this.state.errors.scheme ? this.state.errors.scheme : null }
                        {this.state.errors.host_name && this.state.errors.host_name }
                      </div>
                    </div>
                  </div>
                   <div className="col-lg-4">
                    <div className="form-group">
                      <label>API Url<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                      <input type="text" className="form-control " value={this.state.apiUrl} onChange={() => {}} placeholder=" url" disabled />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Auth Token<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                      <input 
                        type="text" 
                        className="form-control" 
                        value={this.state.authToken} 
                        onChange={(e) => this.setState({ authToken: e.target.value})} 
                        placeholder="Auth Token" 
                        disabled={this.state.edit}
                      />
                      <div className="error-text">
                        {this.state.errors.auth_token ? this.state.errors.auth_token : null }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 pl-0">
                    <div className="row">
                      <div className="col-lg-6">
                        {!this.state.edit && 
                          <a onClick={() => {this.getOrganizations()}}>Get Oragnizations</a>
                        }
                        {this.state.organizations.length > 0 &&
                          <div className="form-group ">
                            <label htmlFor="exampleInputEmail1">Organizations<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                            {this.state.edit ? 
                              <input 
                                type="text"
                                className="form-control" 
                                placeholder="Enter Host name" 
                                value={this.state.organization}
                                onChange={() => {}}
                                disabled
                              /> 
                              :
                              <select 
                                className="form-control "
                                value={this.state.organization}
                                onChange={(e) => this.setState({ organization: e.target.value})}
                              >
                                <option value=""> ----- </option>
                                {this.state.organizations.map((Organization) => 
                                  <option key={Organization.value} value={Organization.value}>{Organization.name}</option>
                                )}
                              </select> 
                            }
                            <div className="error-text">
                              {this.state.errors.organization ? this.state.errors.organization : null }
                            </div>
                          </div>
                        }
                      </div>
                      {this.state.organization && 
                        <div className="col-lg-6">  
                          {!this.state.edit ?
                            <a onClick={() => {this.getProjects()}}>Get Projects</a>
                          : null}
                          {this.state.projects.length > 0 ? 
                            <div className="form-group ">
                              <label htmlFor="exampleInputEmail1">Projects<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                              {this.state.edit ?
                                <input 
                                  type="text"
                                  className="form-control" 
                                  placeholder="Enter Host name" 
                                  value={sentryDetailsList.sentry_project_name}
                                  onChange={() => {}}
                                  disabled
                                /> 
                                : 
                                <select 
                                  className="form-control "
                                  value={this.state.project}
                                  onChange={(e) => {this.setState({ project: e.target.value}); this.moreSettings(e.target.value) }}
                                >
                                  <option value=""> ----- </option>
                                  {this.state.projects.map((projectObj) => 
                                    <option key={projectObj.project_id} value={projectObj.project_id}>{projectObj.name}</option>
                                  )}
                                </select>
                              }
                              <div className="error-text"></div>
                            </div>
                          : null }
                        </div>
                      }
                    </div>
                  </div>
                </div>
                {this.state.project && this.state.moreOptions && 
                  <div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label>Labels</label>
                              <Select
                                isMulti
                                onChange={(e) => {this.onLabelSelect(e)}}
                                options={this.state.labels}
                                value={this.state.label}
                                placeholder="select label(s)"
                              />
                              <div className="error-text">
                                {this.state.errors.labels ? this.state.errors.labels[0] : null }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label>Priority</label>
                              <select 
                                className="form-control "
                                value={this.state.priority}
                                onChange={(e) => this.setState({ priority: e.target.value })}
                              >
                                <option value=""> ----- </option>
                                {priorities ? priorities.data.map((priority) => 
                                  <option key={priority.id} value={priority.id}>{priority.name}</option>
                                ) : null}
                              </select>
                              <div className="error-text">
                                {this.state.errors.priorities ? this.state.errors.priorities[0] : null }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>Assign To{this.state.visibleStatus === 'private' && <sup className="error-text" style={{top: '0px'}}>*</sup>}</label>
                              <Select
                                isMulti
                                onChange={(e) => {this.onMemberSelect(e)}}
                                options={this.state.assignedMembersList}
                                value={this.state.member}
                                placeholder="select member(s)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label>Issue Status<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                              {issueStatuses ? issueStatuses.map((issue) => 
                                <div key={issue[0]} className="form-check">
                                  <input 
                                    type="radio" 
                                    className="form-check-input" 
                                    id={issue[0]}
                                    value={issue[0]}
                                    checked={this.state.issueStatus == issue[0]}
                                    onChange={this.setValueIssue.bind(this)}
                                  />
                                  <label htmlFor={issue[0]} className="form-check-label">
                                    {issue[1]}
                                  </label>
                                </div>
                              ): null}
                              <div className="error-text">
                                {this.state.errors.issue_status ? this.state.errors.issue_status[0] : null }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label>Visible Status<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                              {visiblleStatuses ? visiblleStatuses.map((visible) =>
                                <div key={visible[0]} className="form-check">
                                  <input 
                                    type="radio" 
                                    className="form-check-input"
                                    id={visible[0]}
                                    value={visible[0]}
                                    checked={this.state.visibleStatus == visible[0]}
                                    onChange={this.setValue.bind(this)}
                                  />
                                  <label htmlFor={visible[0]} className="form-check-label">
                                    {visible[1]}
                                  </label>
                                </div>
                              ) : null}
                              <div className="error-text">
                                {this.state.errors.visible_status ? this.state.errors.visible_status[0] : null }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>Issue Level<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                              <select 
                                className="form-control " 
                                value={this.state.issueLevel}
                                onChange={(e) => this.setState({ issueLevel: e.target.value }) }
                              >
                                <option value=""> ----- </option>
                                  {issueLevels ? issueLevels.map((issue_level) => 
                                    <option key={issue_level[0]} value={issue_level[0]}>{issue_level[1]}</option>
                                  ) : null}
                              </select>
                              <div className="error-text">
                                {this.state.errors.issue_level ? this.state.errors.issue_level[0] : null }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="error-text">
                          {this.state.errors.__all__ ? this.state.errors.__all__[0] : null }
                        </div>
                      </div>
                    </div>
                    <div className="row mar_lr_0">
                      <div className="col-lg-12 text-center buttons">
                        <button type="button" className="btn primary_btn" onClick={() => {this.saveDefaultSettings()}}>Proceed</button>
                        <button type="button" className="btn secondary_btn" onClick={() => this.cancelDetails()}>Cancel</button>
                      </div>
                    </div>
                  </div>
                }
              </form>
            }
          </div>
        : 
          <div className="col-lg-12 detailed_content">
            <div className="row">
              {(permissions.includes('PROJECT_ADMIN') || role == 'admin') &&
                <div className="col-lg-12 text-right">
                  <button type="button" className="btn primary_btn" onClick={() => this.editSentryDetails()}>Edit</button>
                  <button type="button" className="btn secondary_btn" onClick={() => this.setState({ displayDeleteModal: true })}>Delete</button>
                  <button type="button" className="btn secondary_btn" onClick={() => this.LoadSentryIssues()}>Refresh</button>
                </div>
              }
              <div className="col-lg-6">
                <table className="table table-hover table-user-information ">
                  <tbody>
                    <tr>
                      <td>Host Name:</td>
                      <td>{sentryDetailsList.host_name}</td>
                    </tr>
                    <tr>
                      <td>API Url:</td>
                      <td>{sentryDetailsList.api_url}</td>
                    </tr>
                    <tr>
                      <td>Auth Token:</td>
                      <td>{sentryDetailsList.auth_token}</td>
                    </tr>
                    <tr>
                      <td>Organization:</td>
                      <td>{sentryDetailsList.org_slug}</td>
                    </tr>
                    <tr>
                      <td>Project:</td>
                      <td>{sentryDetailsList.sentry_project_name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-6">
                <table className="table table-hover table-user-information ">
                  <tbody>
                    <tr>
                      <td>Labels:</td>
                      <td>
                        {sentryDetailsList.default_values.labels.data ? sentryDetailsList.default_values.labels.data.map((label) => 
                          <a className="tag" key={label.id} style={{ backgroundColor: label.color, color: this.labelText(label.color) }}>{label.name}</a>
                        ) : '--'}
                      </td>
                    </tr>
                    <tr>
                      <td>Priority:</td>
                      <td>{sentryDetailsList.default_values.priorities.data ? sentryDetailsList.default_values.priorities.data.name : '--'}</td>
                    </tr>
                    <tr>
                      <td>Assigned To:</td>
                      <td>
                        <div className="team_members">
                        {sentryDetailsList.default_values.assigned_to.data.length > 0 ? sentryDetailsList.default_values.assigned_to.data.map((member) => 
                          <a key={member.id} className="team_members">
                            <img 
                              src={member.profile_img} 
                              data-toggle="tooltip" 
                              data-placement="bottom" 
                              title={member.employee_name} 
                              data-original-title={member.employee_name}
                              alt={member.employee_name} 
                            />
                          </a>
                        ) : '--'}
                      </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Issue Status:</td>
                      <td>{sentryDetailsList.default_values.issue_status}</td>
                    </tr>
                    <tr>
                      <td valign="top">Visible Status:</td>
                      <td>{sentryDetailsList.default_values.visible_status}</td>
                    </tr>
                    <tr>
                      <td valign="top">Issue Level:</td>
                      <td>{sentryDetailsList.default_values.issue_level}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
        <Modal
          display={this.state.displayDeleteModal}
          bodyText='Are you sure? You want to delete?'
          modalAction={this.deleteSentrySettings.bind(this)}
          removeModal={() => this.setState({ displayDeleteModal: false })}
        />
      </div>
    )
  }
}
