import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import {
  fetch_post,
  fetch_get,
  PROJECTS_LIST,
  notify,
  Modal,
  Context
} from '../../common';
import { Link } from 'react-router-dom';

export default class ProjectSettingsPriorities extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      projectPriorities: [],
      errors: {},
      showPriorityPicker: false,
      editPriority: false,
      priorityColor: '#ddd',
      loading: true,
      displayDeleteModal: false
    };
    this.nameRef = React.createRef();
    this.idRef = React.createRef();
  }

  componentDidMount() {
    this.fetchProjectPriorities(this.props.projectId);
  }

  componentDidUpdate(prevProps) {
    if(this.props.projectId !== prevProps.projectId) {
      this.fetchProjectPriorities(this.props.projectId);
    }
  }

  fetchProjectPriorities(id) {
    fetch_get(PROJECTS_LIST + id + '/priorities/')
    .then((response) => {
      this.setState({ 
        projectPriorities: response.data,
        loading: false
      })
      this.props.updateCount('priority', response.data.length)
    })
  }

  EditPriority(priority) {
    this.setState({ 
      editPriority: true,
      priorityColor: priority.color,
      errors: {}
    })
    this.nameRef.current.value = priority.name
    this.idRef.current.value = priority.id
  }

  addEditPriority() {
    const data = new FormData();
    if(this.state.editPriority) {
      data.append('name', this.nameRef.current.value);
      data.append('color', this.state.priorityColor);
      fetch_post(PROJECTS_LIST + this.props.projectId + '/priorities/' + this.idRef.current.value + '/update/', data)
      .then((response) => {
        if(response.error) {
          this.setState({ errors: response.errors })
        } else {
          notify("Priority Edited successfully");
          this.clear();
          this.fetchProjectPriorities(this.props.projectId);
        }
      })
    } else {
      data.append('name', this.nameRef.current.value);
      data.append('color', this.state.priorityColor);
      fetch_post(PROJECTS_LIST + this.props.projectId + '/priorities/create/', data)
      .then((response) => {
        if(response.error) {
          this.setState({ errors: response.errors })
        } else {
          notify("Priority Added successfully");
          this.clear();
          this.fetchProjectPriorities(this.props.projectId)
        }
      })
    }
  }

  clear() {
    this.setState({
      priorityColor: '#ddd',
      editPriority: false,
      errors: {}
    })
    this.nameRef.current.value = "";
  }

  handleChangeComplete(color){
    this.setState({ priorityColor: color.hex })
  }

  onDeletePriority() {
    fetch_get(PROJECTS_LIST + this.props.projectId + '/priorities/' + this.state.priorityId + '/delete/', true)
    .then(response => {
      if(response.status === 204) {
        this.setState({ displayDeleteModal: false })
        this.clear()
        notify("Priority Deleted successfully");
        this.fetchProjectPriorities(this.props.projectId)
      }
    })
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.addEditPriority();
    }
  }

  render() {
    const { projectPriorities } = this.state;
    return (
      <div className="row priority_content tab-content">
        <div className="col-lg-8 pl-0">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Color</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {projectPriorities.length > 0 ? projectPriorities.map((priority,index) => 
              <tr key={priority.id}>
                <td>{index+1}</td>
                <td ref={this.idRef}>
                  <div className="task_title">
                    <Link to={{ pathname: `${this.context.company}/pm/${this.props.projectId}/tasks`, state: {priorityDetails: priority, priorityId: priority.id, priorityIndex: index }}}>
                      {priority.name}
                    </Link>
                  </div>
                </td>
                <td><div className="color_pick" style={{ color: priority.color}}><i className="fas fa-square"></i></div></td>
                <td className="actions" align="center">
                  <a className="action_edit" onClick={() => {this.EditPriority(priority)}}><i className="fa fa-edit"></i> </a>
                  <a className="action_delete" onClick={() => this.setState({ displayDeleteModal: true, priorityId: priority.id})}><i className="fa fa-trash"></i> </a>
                </td>
              </tr>
              )
            :
              <tr>
                <td>{this.state.loading ? 'Loading ...' : 'No Priorities found'}</td>
              </tr>
            }
            </tbody>
          </table>
        </div>
        <div className="col-lg-4">
          <h2 className="heading">{this.state.editPriority ? 'Edit Priority' : 'Add Priority'}</h2>
           <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Name<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="datetimepicker1"
                    ref={this.nameRef}
                    onKeyPress={this.handleKeyPress} 
                    autoComplete="off"
                  />
                  <div className="error-text">
                    {this.state.errors.name ? this.state.errors.name : null }
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Color</label>
                  <div className="color_pick">
                    <span
                      style={{ color: this.state.priorityColor }} 
                      onClick={() => this.setState({ showPriorityPicker: !this.state.showPriorityPicker })}
                      onBlur={() => this.setState({ showPriorityPicker: false })}
                    >
                      <i className="fas fa-square"></i>
                    </span>
                  </div>
                </div>
                {this.state.showPriorityPicker && 
                  <div style={{position: 'absolute', zIndex: '9999999999'}}>
                    <div
                      style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                      onClick={() => this.setState({ showPriorityPicker: false })}
                    />
                    <SketchPicker
                      color={ this.state.priorityColor }
                      onChangeComplete={ this.handleChangeComplete.bind(this) }
                    /> 
                  </div>
                }
              </div>
              <div className="col-lg-12 buttons text-left mt-5">
                <button type="button" className="primary_btn" onClick={() => {this.addEditPriority()}}>Save</button>
                <button type="button" className="secondary_btn" onClick={() => {this.clear()}}>Cancel</button>
              </div>
            </div>
          </form>
        </div>
        <Modal
          display={this.state.displayDeleteModal}
          bodyText='Are you sure? You want to delete?'
          modalAction={this.onDeletePriority.bind(this)}
          removeModal={() => this.setState({ displayDeleteModal: false })}
        />
      </div>
    );
  }
}