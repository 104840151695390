import React, { Component, Fragment } from 'react';
// import Select from 'react-select';
// import { Link } from 'react-router-dom';
import { QuillEditor, fetch_get, PROJECTS_LIST, fetch_post, Modal, Context } from '../../common';

export default class WriteDocument extends Component{
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      commentDescription: '',
      pageTitle: '',
      errors: {},
      selectedPage: null,
      pages: [],
      selectedPageModel: null,
      addPage: false,
      selectedPageTitle: '',
      deletePageId: {id: null, page: ''},
      enableSave: false,
      parentId: null,
      docName: '',
      docDetails: []
    };
  }

  componentDidMount() {
    this.fetchDocumentList()
  }

  fetchDocumentList() {
    fetch_get(`${PROJECTS_LIST}${this.props.match.params.id}/create-document/${this.props.match.params.did}/pages/list/`)
    .then((response) => {
      this.setState({ pages: response.data.data })
      let documentPages = response.data.data
      if(response.data.data.length > 0) {
        for(let i=0; i<documentPages.length; i++) {
          let childPages = []
          for(let j=0; j<documentPages.length; j++) {
            if(documentPages[i].id !== documentPages[j].id) {
              if(documentPages[j].Parent_page === documentPages[i].id) {
                childPages.push(j)
              }
            }
          }
          documentPages[i]['childPage'] = childPages
        }
        this.setState({ pages: documentPages, docName: response.document_details.data.title, docDetails: response.document_details.data })
      }
    })
    .catch((error) => console.log(error))
  }

  editDoc() {
    let formData = new FormData()
    formData.append("title", this.state.docName)
    formData.append("assigned_users", JSON.stringify(this.state.docDetails.assigned_users))
    formData.append("description", this.state.docDetails.description)
    formData.append("is_public", this.state.docDetails.is_public)
    fetch_post(`${PROJECTS_LIST}${this.props.match.params.id}/create-document/${this.props.match.params.did}/update/`, formData)
    .then((response) => {
      this.setState({ docName: response.document_data.data.title })
    })
    .catch((error) => console.log(error))
  }

  addPage(pageId=this.state.parentId) {
    let formData = new FormData()
    formData.append("title", this.state.pageTitle)
    formData.append("content", this.state.pageTitle)
    let url = ''
    if(pageId) {
      url = `/pm/project/document/${this.props.match.params.did}/page/${pageId}/create/`
    } else {
      url = `/pm/project/document/${this.props.match.params.did}/page/create/`
    }
    fetch_post(url, formData)
    .then((response) => { 
      if(response.errors) {
        this.setState({ errors: response.errors })
      } else {
        let close = document.getElementById("add_page_close")
        close.click()
        this.setState({ parentId: null, pageTitle: '' }, this.fetchDocumentList)
      }
    })
    .catch((error) => console.log(error))
  }

  deletePage() {
    fetch_get(`/pm/project/document/page/${this.state.deletePageId.id}/delete/`, true)
    .then((response) => {
      if(response.status === 204) {
        this.setState({ deletePageId: {id: null, page: ''} }, this.fetchDocumentList)
      }
    })
    .catch((error) => console.log(error))
  }

  getClassname(id) {
    if(id === this.state.selectedPageModel){
      return "list-group-item accordion_heading list-group-item-success strong"
    } else {
      return "list-group-item accordion_heading list-group-item strong"
    }
  }

  collapse(id) {
    if(id === this.state.selectedPageModel){
      return "collapse list-group-submenu show"
    } else {
      return "collapse list-group-submenu"
    }
  }

  updateContent() {
    let formData = new FormData()
    formData.append("title", this.state.selectedPageTitle)
    formData.append("content", this.state.commentDescription)
    fetch_post(`/pm/project/document/page/${this.state.selectedPage}/update/`, formData)
    .then((response) => { 
      if(response.errors) {
        this.setState({ errors: response.errors })
      } else {
        this.setState({ enableSave: false }, this.fetchDocumentList)
        alert('saved successfully')
        let close = document.getElementById("edit_page_close")
        close.click()
      }
    })
    .catch((error) => console.log(error))
  }

  renderChild(child, page) {
    let renderChild = child.map((child) => 
      <div className={this.collapse(page.id)} key={child} id={this.state.selectedPageModel}>
        <a 
          className={`list-group-item  ${this.state.selectedPage === this.state.pages[child].id ? "active" : ""}`} 
          onClick={() => this.setState({ 
            commentDescription: this.state.pages[child].content, 
            selectedPageTitle: this.state.pages[child].title,
            selectedPage: this.state.pages[child].id, 
          })}>
          {this.state.pages[child].title} <span className="actions float-right">
          <a 
            data-toggle="modal" 
            data-target="#exampleModal" 
            onClick={() => this.setState({ 
              selectedPage: child.id, 
              addPage: true,
              parentId: this.state.pages[child].id
            })}
          >
            <i className="fas fa-plus"></i> 
          </a>
          <a data-toggle="modal" data-target="#exampleModal2" onClick={() => this.setState({ pageTitle: this.state.pages[child].title })}>
            <i className="fas fa-pencil-alt"></i> 
          </a>
          <a onClick={() => this.setState({ deletePageId: { id: this.state.pages[child].id, page: this.state.pages[child].title } })}>
            <i className="far fa-trash-alt"></i> 
          </a>
          </span>
        </a>
        {this.state.pages[child].childPage && this.state.pages[child].childPage.length > 0 && this.renderChild(this.state.pages[child].childPage, page)}
      </div>
    )
    return renderChild
  }

  render(){
    return(
      <Fragment>
        <div className="row mar_lr_0 no-gutters documents_view justify-content-center">
          <div className="col-lg-2">
            <div className="editor_menu_container">
              <div className="editor_menu">
                <div className="document_nav">
                  <a onClick={()=>this.props.history.goBack()} className="back"><i className="fas fa-arrow-left"></i></a> 
                  <span className="title">
                    <input 
                      type="text" 
                      className="form-control" 
                      value={this.state.docName} 
                      onChange={(e) => this.setState({ docName: e.target.value })}
                      onBlur={() => this.editDoc()}
                    />
                  </span>
                </div>
                {this.state.pages.length > 0 && this.state.pages.map((page) =>
                  <div id="MainMenu" key={page.id}>
                    <div className="list-group panel">
                    {page.Parent_page === null &&
                      <a 
                        className={this.getClassname(page.id)} 
                        data-toggle="collapse" 
                        data-parent="#MainMenu"
                        onClick={() => this.setState({ 
                          selectedPageModel: page.id, 
                          commentDescription: page.content, 
                          selectedPageTitle: page.title, 
                          selectedPage: page.id, 
                        })}
                      >
                        {page.title} 
                        <span className="actions float-right">
                          <a 
                            data-toggle="modal" 
                            data-target="#exampleModal" 
                            onClick={() => this.setState({ 
                              selectedPage: page.id, 
                              addPage: true,
                              parentId: page.id 
                            })}
                          >
                            <i className="fas fa-plus"></i>
                          </a>
                          <a data-toggle="modal" data-target="#exampleModal2" onClick={() => this.setState({ pageTitle: page.title })}><i className="fas fa-pencil-alt"></i></a>
                          <a onClick={() => this.setState({ deletePageId: { id: page.id, page: page.title } })}>
                            <i className="far fa-trash-alt"></i> 
                          </a>
                        </span>
                      </a>
                    }
                    {page.childPage && page.childPage.length > 0 && this.renderChild(page.childPage, page)}
                    </div>
                  </div>
                )}
              </div>
              <a className="primary_btn btn add_page" data-toggle="modal" data-target="#exampleModal" onClick={() => this.setState({ addPage: true })}>
                <i className="fas fa-plus"></i> Add Page
              </a>
            </div>
            {this.state.addPage &&
              <div className="modal fade show" id="exampleModal" tabndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="heading" id="exampleModalLabel">Add Page</h5>
                      <button type="button" id="add_page_close" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="form-group">
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Enter Page Title" 
                            value={this.state.pageTitle}
                            onChange={(e) => this.setState({ pageTitle: e.target.value })}
                          />
                          <div className="error-text">{this.state.errors.title}</div>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn primary_btn" onClick={() => this.addPage()}>Add Page</button>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="modal fade " id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-label="close">
              <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="heading" id="exampleModalLabel">Edit Page</h5>
                    <button type="button" id="edit_page_close" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <input 
                          type="text" 
                          className="form-control" 
                          value={this.state.selectedPageTitle}
                          onChange={(e) => this.setState({ selectedPageTitle: e.target.value })}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn primary_btn" onClick={() => this.updateContent()}>Save Changes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-10">
            <div className="row justify-content-center marl document_editor_container">
              {this.state.pages.length > 0 &&
                <span
                  title={this.state.enableSave ? 'Save' : null}
                  className={`document_save primary_btn ${this.state.enableSave ? "" : "disable_btn"}`}
                  onClick={() => this.state.enableSave && this.updateContent()}
                >
                  <i className="fas fa-save"></i>
                </span>
              }
              <div className="col-lg-8">
                <div className="editor_content">
                  {this.state.pages.length > 0 &&
                    <QuillEditor 
                      value={this.state.commentDescription}
                      onChange={(e) => this.setState({ commentDescription: e, errors: {} })}
                      onKeyPress={() => this.setState({ enableSave: true })}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          display={this.state.deletePageId.id !== null}
          bodyText={`Are you sure? You want to Close "${this.state.deletePageId.page}" ?`}
          modalAction={this.deletePage.bind(this)}
          removeModal={() => this.setState({ deletePageId: {id: null, page: ''}})}
        />
      </Fragment>
    )
  }
}