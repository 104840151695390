import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import {
  fetch_get,
  fetch_post,
  notify,
  Context,
  DropBox,
  PROJECTS_LIST 
} from '../../common';

export default class AddDocument extends Component{
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      assigned_users: '',
      files: [],
      documentsList: [],
      assignedMembersList: [],
      errors: {},
      document_file_error: '',
      load:false
    };
    this.handleAssignedUsersSelectChange = this.handleAssignedUsersSelectChange.bind(this);
  }

  componentDidMount() {
    this.fetchDocumentsData();
    this.fetchDocumentsList();
  }

  fetchDocumentsList() {
    fetch_get(PROJECTS_LIST +`${this.props.projectId}/documents/list/`)
    .then((response) => {
      // response.documents.data.filter(( obj ) =>
      //   assignedMembersList.push({label: obj.title, value: obj.id})
      // );
      if (response.documents) {
        this.setState({ documentsList: response.documents });
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  fetchDocumentsData() {
    let assignedMembersList = []
    fetch_get(PROJECTS_LIST +`${this.props.projectId}/documents/create/`)
    .then((response) => {
      response.project_team.data.map((member) => 
        assignedMembersList.push({label: member.user_profile.data.employee_name,value: member.user_profile.data.id })
      )
      if(response.project_team){
        this.setState({ assignedMembersList })
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  saveDocument(e) {  
    e.preventDefault();
    const data = new FormData();
    this.state.files.map((file) => data.append('document_file', file))
    this.state.files.map((ob) => 
      data.append('file_name', ob.name )
    )
    data.append('title', this.state.title);
    let assigned_users_array = []
    if(this.state.assigned_users.length > 0)
    {
      this.state.assigned_users &&
      this.state.assigned_users.filter(( obj ) => 
        assigned_users_array.push(obj.value)
      );
    }
    data.append("assigned_users", JSON.stringify(assigned_users_array));
    data.append('description',this.state.description);
    fetch_post(PROJECTS_LIST+`${this.props.projectId}/documents/create/`, data)
    .then((response) => {
      if (!response.error) {
        notify('Document uploaded Successfully');
        this.props.overlayControl();
        this.props.fetchDocumentsList();
        this.setState({
          fileName: 'No file chosen',
          title: '',
          description: '',
          load:false,
        });
      }
      else {
        if(response.document_file_error){
          this.setState({ document_file_error: response.document_file_error, errors: '',load:false })
        }
        if(response.errors){
          this.setState({ errors: response.errors, load:false })
        }
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  handleAssignedUsersSelectChange(value){
    this.setState({ assigned_users: value})
  }

  render(){
    const { errors, assignedMembersList } = this.state;
    return(
      <div className="slide_section ">
        <div className="add_task slide_content">
          <div className="row justify-content-start slide-section slide_content_row no-gutters">
            <div className="col-lg-12">
              <h2 className="heading">Add Document</h2>

              <form className="no-padding">
                <div className="form-group">
                  <label>Title<div className='error-text'>*</div></label>
                  <input 
                    type="text" 
                    className="form-control" 
                    value={this.state.title}
                    onChange={(e) => this.setState({ title: e.target.value })}
                  />
                  <div className="error-text">
                    { errors.title ? errors.title : ''}
                  </div>
                </div>

                <div className="form-group">
                  <label>Description  </label>
                  <textarea 
                    className="form-control textarea" 
                    id="exampleFormControlTextarea1" 
                    value={this.state.description} 
                    rows="3"
                    onChange={(e) => this.setState({ description: e.target.value })}
                  >
                  </textarea>
                </div>

                <div className="form-group">
                  <label>Assigned Users <div className='error-text'>*</div></label>
                  {/*<select 
                    name="users_invited" 
                    className="select2 form-control" 
                    placeholder="Select Profile" 
                    onChange={(e) => this.setState({assigned_users: e.target.value, errors: {}})}
                    value={this.state.assigned_users}
                  >
                    <option key="0">---</option>
                   {
                      assignedMembersList.map(
                        (obj) => <option value={obj.user_profile.data.id} key={obj.id}>{obj.user_profile.data.employee_name}</option>
                      )
                    }
                  </select>*/}
                {/*  <div className="error-text">
                    { errors.assigned_users ? errors.profile[0] : null }
                  </div>*/}
                    <Select
                      isMulti
                      onChange={this.handleAssignedUsersSelectChange}
                      options={assignedMembersList}
                      placeholder="Select a User(s)"
                      value={this.state.assigned_users}
                    />
                    <div className="error-text">
                    { errors.assigned_users ? errors.assigned_users : null }
                    </div>

                </div>

                <div className="form-group">
                  <label>File<div className='error-text'>*</div> <small>[supported file formats: .jpg, .png, .jpeg, doc, docx, pdf, xlsx, csv]</small></label>
                  <DropBox
                    files={this.state.files}
                    onDrop={(files) => this.setState({ files })}
                  />
                  <div className="error-text">
                    { errors.document_file ? errors.document_file : '' }
                  </div>
                  <div className="error-text">
                    { this.state.document_file_error ? this.state.document_file_error : '' }
                  </div>
                </div>

                <div className="text-left buttons">
                  {this.state.load ? 
                    <Fragment>
                      <div className="spinner task">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                      </div>
                    </Fragment> :
                  <button type="button" className="btn primary_btn" onClick={(e)=> this.setState({load:true},this.saveDocument(e))}>Save Details </button>}
                  <button 
                    type="button" 
                    className="btn secondary_btn"  
                    onClick={this.props.cancelOverlay}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}