import React, { Component } from 'react';
import Datetime from 'react-datetime';
import {fetch_post, fetch_get, PROJECTS_LIST, notify, QuillEditor, DropBox, Context } from '../../common';

let labelsList =  [];
let assignedMembersList =  [];
let statusesList = [];
let milestones =  [];
let prioritiesList =  [];
let visibleToTeamMembers = []
export default class AddTasks extends Component {
  static contextType = Context
  constructor(props) {
    super(props);
    this.state = {
      datePickerOpen: false,
      errors: {},
      estimateTime: '',
      dueDate: '',
      description: '',
      labels: [],
      labelsIds: [],
      priority: '',
      status: '',
      isPublic: true,
      visibleToTeam: [],
      selectedAssignedMembers: [],
      milestone: '',
      taskSuccessStatus: '',
      modal: false,
      name: '',
      showAssingedMembers: false,
      showVisibleMembers: false,
      isDropdownVisible: false,
      editTask: {
        label: false,
        assignedTo: false,
        visibleToTeam: false
      },
      allLabels: [],
      integratedWithGitlab: false,
      syncWithGitlab: true,
      integratedWithGithub: false,
      syncWithGithub: true,
      files: [],
      labelSearchText: '',
      labelsMenu: false
    };
    this.ContentChange = this.ContentChange.bind(this);
  }

  componentDidMount() {
    this.initialState = this.state;
    this.createInitialData(this.props.id)
  }

  createInitialData(id) {
    labelsList =  [];
    assignedMembersList =  [];
    statusesList = [];
    milestones =  [];
    visibleToTeamMembers = [];
    prioritiesList =  [];
    fetch_get(PROJECTS_LIST + id + '/tasks/create/')
    .then((response) => {
      labelsList = response.labels.data;
      assignedMembersList = response.profiles.data;
      visibleToTeamMembers = response.visible_to_team.data;
      prioritiesList = response.priorities.data;
      statusesList = response.task_statuses.data;
      milestones = response.open_milestones_data;
      // milestones = response.milestones.data
      if(this.props.selectedMilestone.id) {
        this.setState({ 
          // milestone: this.props.selectedMilestone,
          milestone: this.props.selectedMilestone.id, 
        })
      }
      if(this.props.kanbanStatusId) {
        this.setState({ status: this.props.kanbanStatusId }) 
      } else {
        this.setState({ status: response.task_statuses.data.length > 0 ? response.task_statuses.data[0].id : null })
      }
      if(this.props.label) {
        this.setState({ labels: [...this.state.labels, this.props.label], labelsIds: [...this.state.labelsIds, this.props.label.id] })
      }
      this.setState({ integratedWithGitlab: response.integrated_with_gitlab ? true : false, 
        integratedWithGithub: response.integrated_with_github ? true : false,
        allLabels: response.labels.data })
    })
  }

  onCreate() {
    const {
      name, status, dueDate, priority, description, selectedAssignedMembers, 
      labels, isPublic, visibleToTeam, milestone, 
      estimateTime, syncWithGitlab, syncWithGithub,
    } = this.state;
    const data = new FormData();
    this.state.files.map((file) => data.append('files', file))
    labels.filter((obj) =>
      data.append('label',obj.id)
    );
    selectedAssignedMembers.filter((obj) =>
      data.append('assigned_to', obj.id)
    );
    visibleToTeam.filter((obj) =>
      data.append('visible_to_team', obj.id)
    );
    data.append('name', name);
    data.append('sync_with_gitlab', syncWithGitlab)
    data.append('sync_with_github', syncWithGithub)
    data.append('status', status);
    data.append('dead_line', dueDate ? dueDate.format("YYYY-MM-DD") : '');
    data.append('priority', priority);
    data.append('description', description);
    data.append('is_public', isPublic);
    data.append('milestone', milestone);
    data.append('estimated_time', estimateTime ? estimateTime.format("HH:mm:ss") : '')
    fetch_post(PROJECTS_LIST + this.props.id + '/tasks/create/', data)
    .then((response) => {
      if(response){
        if(!response.error) {
          notify(response.messages)
          this.props.milestoneTask ? this.props.addTaskOrMilestone() : this.onSubmit()
        } else {
            if(response.errors == 'File Size Should be less than 10MB'){
              alert('File Size Should be less than 10MB')
            }
            this.setState({ errors: response.errors })
        }
      }
    })
  }

  onSubmit() {
    if(this.props.label) {
      this.props.updateBoard(this.props.label.id)
    }
    this.props.overlayControl()
    this.props.updateTask(null, true, this.state.status)
    this.setState(this.initialState);
    this.setState({status: statusesList[0].id })
    this.context.updateCount('true');
  }

  ContentChange(e){
    // var newContent = e.editor.getData();
    this.setState({
      description: e,
    })
  }

  visibleToTeamUpdate(member, index, action) {
    if(action === 'add') {
      this.state.visibleToTeam.push(member)
      visibleToTeamMembers.splice(index, 1)
      this.setState({ visibleToTeam: this.state.visibleToTeam, showVisibleMembers: false })
    } else {
      visibleToTeamMembers.push(member)
      this.state.visibleToTeam.splice(index, 1)
      this.setState({ visibleToTeam: this.state.visibleToTeam })
    }
  }

  labelUpdate(label) {
    if(this.state.labelsIds.includes(label.id)) {
      this.state.labelsIds.splice(this.state.labelsIds.indexOf(label.id), 1 );
      this.state.labels.map((labelDetails, index) => {
        if(labelDetails.id === label.id) {
          this.state.labels.splice(index, 1)
        }
      })
      this.setState({ labelsIds: this.state.labelsIds, labels: this.state.labels, filter: true}, this.taskFilter)
    } else {
      this.setState({ labels: [...this.state.labels, label], filter: true, labelsIds: [...this.state.labelsIds, label.id] }, this.taskFilter)
    }
    this.setState({ labelSearchText: '', allLabels: labelsList });
  }

  profileUpdate(member, index, action) {
    if(action === 'add') {
      this.state.selectedAssignedMembers.push(member)
      assignedMembersList.splice(index, 1)
      this.setState({ selectedAssignedMembers: this.state.selectedAssignedMembers })
    } else {
      assignedMembersList.push(member)
      this.state.selectedAssignedMembers.splice(index, 1)
      this.setState({ selectedAssignedMembers: this.state.selectedAssignedMembers })
    }
  }

  onSearch(e) {
    let value = e.target.value
    this.setState({ labelSearchText: value });
    var filteredLabels = labelsList.filter(function (label) {
      return (label.name.toLowerCase().includes(value.toLowerCase()));
    });
    this.setState({ allLabels: filteredLabels, labelsMenu: true })
  }

  handleKeyPress(event) {
    if(event.key === 'Enter' && this.state.allLabels.length > 0 && this.state.labelSearchText.length > 0) {
      this.labelUpdate(this.state.allLabels[0], 0)
      this.setState({ labelsMenu: false })
    }
  }

  onCancel() {
    if(this.props.label) {
      this.props.updateBoard(false)
    } else {
      this.props.onCancel() 
    }
  }

  onChangeFiles(attachment){
    if(this.state.files.length > 0){
      let count = 0
      for(let i=0; i<this.state.files.length; i++) {
        if(attachment[0].name === this.state.files[i].name){
          count = 1;
          break;
        }
      }
      if(count === 1){
        alert('file already uploaded')
      } else {
        this.setState({ files: this.state.files.concat(attachment) })
      }
    } else {
      this.setState({ files: attachment })
    }
  }

  labelText(hex) {
    let rgb = (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).join(',').split(",")
    var colorRange = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000);
    var color = (colorRange > 200) ? '#000' : '#fff';
    return color;
  }

  render() {
    return (
      <div className="slide_section " onClick={() => this.state.labelsMenu && this.setState({ labelsMenu: false })}>
        <div className="add_task slide_content">
          <div className="row justify-content-start slide-section slide_content_row no-gutters">
            <div className="col-lg-12">
              <h2 className="heading">Add Task</h2>
              <form>
                <div className="form-group">
                  <label >Task Name<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                  <input 
                    type="text" 
                    className="form-control" 
                    value={this.state.name} 
                    placeholder="Task name"
                    onChange={(e) => {this.setState({ name: e.target.value, error: {} })}}
                  />
                  {this.state.errors.name ? 
                    <div className="error-text">
                      {this.state.errors.name[0]}
                    </div>
                  : 
                    null
                  }
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <QuillEditor 
                    value={this.state.description}
                    onChange={this.ContentChange}
                  />
                </div>
                <div className="form-group">
                  <label>Assign To</label>
                  <div className="avatars no-padding add_task_avatars">
                    {this.state.selectedAssignedMembers.length > 0 && 
                      this.state.selectedAssignedMembers.map((member, index) =>
                      <a key= {member.id} className="avatar">
                        {member.has_profile_pic ?
                          <img 
                            src={member.profile_img}
                            data-toggle="tooltip" 
                            data-placement="bottom" 
                            title={member.employee_name} 
                            data-original-title={member.employee_name}
                            alt=""  
                          />
                        :
                          <span className="profile_name" data-toggle="tooltip" data-placement="bottom" title={member.employee_name}>{member.employee_name && member.employee_name[0]+member.employee_name.split(" ")[1][0]}</span>
                        }
                        <small className="remove" onClick={() => { this.profileUpdate(member, index, 'remove') }}><i className="fas fa-times"></i></small>
                      </a>
                    )}
                  </div>
                  {assignedMembersList.length > 0 ? 
                    <span 
                      className="btn-group dropdown people task_people no-padding "
                      tabIndex={0}
                      onClick={() => this.setState({ assignedToMenu: !this.state.assignedToMenu})}
                      onBlur={() => this.setState({ assignedToMenu: false })}
                    >
                      <a className="btn toggle avatar">
                        +
                      </a>
                      {
                        <ul className="menu" style={{ display: this.state.assignedToMenu ? 'block' : 'none' }}>
                          {assignedMembersList.map((profile, index) =>
                          <li key={profile.id} className="submenu">
                            {profile.has_profile_pic ? 
                              <a onClick={() => this.profileUpdate(profile, index, 'add')}>
                                <img src={profile.profile_img} alt=""/>
                                {profile.employee_name.split(" ")[0]}
                              </a>
                            :
                              <a onClick={() => this.profileUpdate(profile, index, 'add')}>
                                <span className="profile_name" data-toggle="tooltip" data-placement="bottom">{profile.profile_text && profile.profile_text}</span>{profile.employee_name.split(" ")[0]}
                              </a>
                            }
                          </li>
                          )}
                        </ul>
                      }
                    </span>
                  : null}
                </div>
                <div className="row slide-section">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Milestone</label>
                      <select 
                        className="form-control"
                        value={this.state.milestone}
                        onChange={(e) => this.setState({ milestone: e.target.value })}
                      >
                        <option value=""> -select milestone- </option>
                        {milestones.length > 0 ? milestones.map((milestone) =>
                          <option key={milestone.data.id} value={milestone.data.id}>{milestone.data.name}</option>
                        ) : null }
                      </select>
                    </div>
                  </div>  
                  <div className="col-lg-6"> 
                    <div className="form-group">
                      <label>Status<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                      <select 
                        className="form-control"
                        value={this.state.status}
                        onChange={(e) => this.setState({ status: e.target.value })}
                      >
                        {statusesList.length > 0 ? statusesList.map((status) => 
                          <option key={status.id} value={status.id}>{status.name}</option>
                        ) : null}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row slide-section">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Priority</label>
                      <select 
                        className="form-control"
                        value={this.state.priority}
                        onChange={(e) => this.setState({ priority: e.target.value })}
                      >
                        <option value="">-select priority-</option>
                        {prioritiesList.length > 0 ? prioritiesList.map((priority) =>
                          <option value={priority.id} key={priority.id}>{priority.name}</option>
                        ) : null}
                      </select>
                    </div>
                  </div>  
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Due By  <i className="fas fa-calendar"></i></label>
                      <div className="input-group mb-3">
                        <Datetime
                          value={ this.state.dueDate ? this.state.dueDate : 'No Deadline'} 
                          dateFormat="YYYY-MM-DD"
                          timeFormat=''
                          closeOnSelect
                          onChange={(e) => this.setState({ dueDate: e })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                {this.state.integratedWithGitlab &&
                  <div className="col-lg-4 form-group">
                    <label>Sync With Gitlab</label>
                    <span className="switch_block">
                      <label className="switch">
                        <input 
                          type="checkbox" 
                          value={this.state.syncWithGitlab}
                          checked={this.state.syncWithGitlab === true } 
                          onChange={(e) => { 
                            this.setState({syncWithGitlab:  e.target.checked }) 
                          }}
                        />
                        <span className="slider round"></span>  
                      </label>
                    </span>
                  </div>
                }
                {this.state.integratedWithGithub &&
                  <div className="col-lg-4 form-group">
                    <label>Sync With Github</label>
                    <span className="switch_block">
                      <label className="switch">
                        <input 
                          type="checkbox" 
                          value={this.state.syncWithGithub}
                          checked={this.state.syncWithGithub === true } 
                          onChange={(e) => { 
                            this.setState({syncWithGithub:  e.target.checked }) 
                          }}
                        />
                        <span className="slider round"></span>  
                      </label>
                    </span>
                  </div>
                }

                <div className="col-lg-4 form-group">
                  <label>Public</label>
                  <span className="switch_block">
                    <label className="switch">
                      <input 
                        type="checkbox" 
                        value={this.state.isPublic}
                        checked={this.state.isPublic === true} 
                        onChange={() => { 
                          this.setState({isPublic:  !this.state.isPublic }) 
                        }}
                      />
                      <span className="slider round"></span>  
                    </label>
                    {this.state.isPublic &&
                      <span><i className="fas fa-unlock"></i></span>
                    }
                    {!this.state.isPublic &&
                      <span><i className="fas fa-lock"></i></span>
                    }
                  </span>
                </div>
                   </div>
                {!this.state.isPublic &&
                  <div className="form-group">
                    <label>Visible to Team</label>
                    <div className="avatars no-padding">
                    {this.state.visibleToTeam.length > 0 && 
                      this.state.visibleToTeam.map((member, index) =>
                      <span className="avatar tooltips" key={index}>
                        <img src={member.profile_img} alt=""/>
                        <span className="bottom">{member.employee_name}</span>
                        <small className="remove" onClick={() => { this.visibleToTeamUpdate(member, index, 'delete') }}><i className="fas fa-times"></i></small>
                      </span>
                    )}
                    </div>
                    {visibleToTeamMembers.length > 0 ? 
                      <span 
                        className="btn-group dropdown people task_people no-padding "
                        tabIndex={0}
                        onClick={() => this.setState({ visibilityMenu: !this.state.visibilityMenu})}
                        onBlur={() => this.setState({ visibilityMenu: false })}
                      >
                        <a className="btn toggle avatar">
                          +
                        </a>
                        {this.state.visibilityMenu &&
                          <ul className="menu" style={{ display: 'block' }}>
                            {visibleToTeamMembers.map((profile, index) => 
                              <li key={profile.id} className="submenu" onClick={() => this.visibleToTeamUpdate(profile, index, 'add')}>
                                <a>
                                  <img src={profile.profile_img} alt=""/>{profile.employee_name}</a>
                              </li>
                            )}
                          </ul>
                        }
                      </span>
                    : null }
                    <div className="error-text">
                      {this.state.errors.is_public ? this.state.errors.is_public[0] : '' }
                    </div>
                  </div>
                }
                <div className="row justify-content-start slide-section add_label_row no-gutters">
                  <div className="col-lg-6 mb-5">
                    <div className="form-group mar-bot-5">
                      <label>Add Label</label>
                      <span 
                        className="input-group mb-3 dropdown no-caret task_dropdown"
                        onClick={() => this.setState({ labelsMenu: !this.state.labelsMenu})}
                      >
                        <input 
                          type="text" 
                          className="form-control" 
                          placeholder="Search By Label" 
                          aria-label="Select Label" 
                          aria-describedby="button-addon2"
                          value={this.state.labelSearchText}
                          onKeyPress={this.handleKeyPress.bind(this)}
                          onChange={this.onSearch.bind(this)} 
                        />
                        <div className="input-group-append ">
                          <button className="toggle btn btn-outline-secondary" type="button" id="button-addon2"><i className="fas fa-angle-down"></i></button>
                        </div>
                        {this.state.labelsMenu &&
                          <ul className="menu task_detail_menu label_menu" style={{ display: 'block', zIndex: 999999 }}>
                            {this.state.allLabels.length > 0 ? this.state.allLabels.map((label, index) => 
                              <li className="submenu" key={label.id} onClick={() => this.labelUpdate(label, index, 'add')}>
                                <a style={{ backgroundColor: label.color, color: this.labelText(label.color) }}> {label.name} 
                                  {this.state.labelsIds.includes(label.id) && 
                                    <span className="float-right" style={{ display: 'block'}}><i className="fas fa-check"></i></span>
                                  }
                                  {!this.state.labelsIds.includes(label.id) && 
                                    <span className="float-right"><i className="fas fa-check"></i></span>
                                  }
                                </a>
                              </li>
                            )
                            : 
                              <li className="submenu" > <a>{this.state.loading ? 'loading ...' : 'No Labels'}</a></li>
                            }
                          </ul>
                        }
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="tags">
                      {(this.state.labels.length > 0 ) && this.state.labels.map((label) =>
                        <a key={label.id} className="tag" style={{ backgroundColor: label.color, color: this.labelText(label.color) }}>{label.name}</a>
                      )}
                    </div>
                  </div>  
                </div>
                <div className="row justify-content-start slide-section no-gutters">
                  <div className="col-lg-10">
                    <div className="form-group">
                      <label>Add Attachment</label>
                      <DropBox
                        files={this.state.files}
                        onDrop={(files) => this.onChangeFiles(files)}
                        multiple
                        isList={false}
                      />
                      <ul>
                        {this.state.files.length > 0 && this.state.files.map((file, index) => (
                          <li key={index} onClick={() => {
                            let filteredFiles = this.state.files
                            filteredFiles.splice(index, 1)
                            this.setState({ files: filteredFiles })
                          }}>
                            {file.name} <i className="fas fa-times" title="Remove"></i> 
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="buttons">
                  <button type="button" className="btn primary_btn" onClick={() => this.onCreate()}>Add Task</button>
                  <button type="button" className="btn secondary_btn" onClick={() => { this.setState(this.initialState, () => this.props.onCancel() ) }}>Cancel</button>
                </div>
                <br clear="all" />
              </form>
            </div>
          </div>      
        </div>
      </div>
    )
  }
}
