import React from 'react'
import * as Sentry from '@sentry/browser'
import App from './App'
import { Integrations } from "@sentry/tracing";
import ReactDOM from 'react-dom';


if(process.env.REACT_APP_DSN !== ' '){    
  Sentry.init({
    dsn: `${process.env.REACT_APP_DSN}`,
    integrations: [new Integrations.BrowserTracing()],  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
