import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class MicrotrackLogin extends Component {
  componentDidMount () {
    if (localStorage.getItem('token') || localStorage.getItem('company')) {
      this.props.history.push('/intro')
    }
  }

  render () {
    return (
      <div>
        <Link to='/'>Login</Link>
      </div>
    )
  }
}
