import React, { Component } from 'react';
import { Context, fetch_post } from '../../common'

export default class TestExecution extends Component {
  static contextType = Context;
  _isMounted = false
  constructor(props) {
    super(props)
    this.inputReference = React.createRef()
    this.state = {
      loading: true,
      modal: false,
      errors: '',
      
      testType: '',
      testCases: '',
      testSessionData: '',
      testSessionIds: [],
      isExecutionStarted: '',      
      sessionId: '',
      executionDetails: '', 

      testResult: '',
      actualResult: '',
      notes: '',
      screenshot: { display: '', data: ''},
      isAddNotes: false,
      isScreenshot: false
    
    }
  }

  componentDidMount() {
    this._isMounted = true    
    this.fetchTestDetails()
    this.fetchSessionState()
  }

  fetchTestDetails() {
    let data = new FormData();
    data.append('test_type_id', this.props.match.params.id)
    fetch_post('/test/type/details/', data)
      .then(response => {
        if(this._isMounted) {
          this.setState({ 
            testType: response.test_type_data.data, 
            testCases: response.test_cases_data,
            testSessionData: response.test_session_data.data[0],            
            testSessionIds: (response.test_session_data.data[0]) 
                  ? response.test_session_data.data[0].test_session_results.data.map(data => data.test_case_details.data.test_case_id): [],
            isExecutionStarted: (response.test_session_data.data.length > 0) ? true: false,            
            sessionId: (response.test_session_data.data.length > 0) ? response.test_session_data.data[0].id: ''
          })
        }
      })
  }

  fetchSessionState() {
    
  }

  toggleExecution(value) {       
    value ? this.startExecution() : this.stopExecution()
  }

  startExecution() {
    let data = new FormData()
    data.append('test_type_id', this.props.match.params.id)
    fetch_post('/test/session/start/', data)
      .then(response => {
        if(!response.error) {
          this.setState({
            sessionId: response.session_id,            
            isExecutionStarted: !this.state.isExecutionStarted
          })
        }
      })
  }

  stopExecution() {
    let data = new FormData()
    data.append('test_session_id', this.state.sessionId)
    fetch_post('/test/session/stop/', data)
      .then(response => {
        if(!response.error) {
          this.setState({
            isExecutionStarted: !this.state.isExecutionStarted
          })
        }
      })
  }
  getTestCaseDetails(id, testCaseId) {
    const data = new FormData()
    data.append('test_case_id', id)
    fetch_post('/test/case/details/', data)
      .then(response => {
        this.setState({ executionDetails: response.test_case_data.data })
      })
    this.setState({ modal: true })
    if(this.state.testSessionData) {
    this.state.testSessionData.test_session_results.data.map(data => {      
      if (data.test_case_details.data.test_case_id  == testCaseId) {
        this.setState({ 
          testResult: data.status,
          actualResult: data.actual_result,
          notes: data.notes,
          screenshot: {
            display: data.screenshot,
            data: data.screenshot
          }
        })
        if(data.notes.length > 0) {
          this.setState({ isAddNotes: true })
        }
        if(data.screenshot.length > 0) {
          this.setState({ isScreenshot: true })
        }
      } 
      else {
        this.setState({ 
          testResult: '',
          actualResult: '',
          notes: '',
          screenshot: ''
        })
      }
    })
  }    
  }

  updateTestResult(e) {
    this.setState({ testResult:  e.target.value })
  }

  handleChange(e) {
    this.setState({ [e.target.id]: e.target.value })
  }

  uploadImage() {    
    this.inputReference.current.click();    
    this.setState({ isScreenshot: true })
  }

  fileUploadInputChange(e) {              
    this.setState({ screenshot: {
      display: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0]
    } })
  }

    addTestResult(id) {
    const data = new FormData()
    data.append('test_case_id', id)
    data.append('test_session_id', this.state.sessionId)
    data.append('status', this.state.testResult)    
    data.append('actual_result', this.state.actualResult)    
    data.append('notes', this.state.notes)
    data.append('screenshot', this.state.screenshot.data)
    fetch_post('/test/session-results/add/', data)
      .then(response => {        
        if(response.error) {
          this.setState({ errors : response.errors })
        } else {
          document.querySelector('.modal-backdrop').remove()          
          document.querySelector('.modal-open').classList.remove('modal-open')
          this.setState({ modal: false })
          this.setState({ errors: '' })
          this.fetchTestDetails()
        }
      })    
  }

  render() {           
    return (
      <div className="secondary_block pb-0">
        <div className="row mar_lr_0 table_height ">
          <div className="col-lg-12 ">
            <h2 className="heading mt-3 pl-1">New Test Executions
            <a href="/pm/past_executions/" className="float-right primary_btn no_brdr_btn mr-2"><i className="fas fa-history"></i> Past Test Executions</a> </h2>
          </div>
        </div>              

        <div className="row mar_lr_0 mt-2">
          <div className="col-lg-8 pl-3">

            <div className="test_cases_container">

              <h5 className="secondary_heading mb-3 d-block text-decoration-none">Test Details - {this.state.testType.name}</h5>
              <h5 className="secondary_heading mb-3 d-block">Test Cases
                <span className="custom-control custom-switch float-right">                  
                  <div className="start-stop">
                    <div className="stop-btn">Stop</div>
                    <div className="start-stop-div">
                      <div className="start-stop-bg"></div>                      
                      <div  className={` start-stop-circle ${(this.state.isExecutionStarted) ? 'start-stop-circle-start' : 'start-stop-circle-stop'}`}
                            onClick={() => this.toggleExecution(!this.state.isExecutionStarted)}>
                      </div>
                    </div>
                    <div className="start-btn">Start</div>                    
                  </div>
                </span>
              </h5>

              <div id="accordionExample" className="accordion">
              {
                  this._isMounted && Object.keys(this.state.testCases).map(test => {                    
                      return(
                        <div className="card" key={test}>
                          <div id="headingOne" className="card-header border-0">
                          <h2 className="mb-0">
                            <button type="button" data-toggle="collapse" data-target={`#${test.replace(/ +/g, '')}`}
                              aria-expanded="true" aria-controls={`${test}`}
                              className="btn btn-link collapsible-link">{test} ({this.state.testCases[test].data.length})</button>
                          </h2>
                        </div>
                        <div id={`${test.replace(/ +/g, '')}`} aria-labelledby="headingOne" data-parent="#accordionExample" className="collapse show">
                          <div className="card-body">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col" width="100px">#ID</th>
                                  <th scope="col">Test Title</th>
                                  <th scope="col">Expected Result</th>
                                </tr>
                              </thead>                              
                              { 
                              this.state.testCases[test].data.map(test => {
                                return(
                                  <tbody key={test.test_case_id}>
                                    <tr>
                                      <th scope="row"><a href="#">{test.test_case_id}</a></th>
                                      <td>{test.name}</td>
                                      <td>{test.expected_result}</td>
                                      {
                                        this.state.isExecutionStarted ? 
                                        <td className="actions">
                                          <span className="play icon mr-2" data-toggle="modal" data-target="#myModal4"
                                                onClick={() => this.getTestCaseDetails(test.id, test.test_case_id)}>
                                            <i className="fas fa-play"></i>
                                          </span>
                                          {
                                            this.state.testSessionIds.includes(test.test_case_id) ?
                                            <span className="check icon"><i className="fas fa-check"></i></span>: ''
                                          }                                          
                                        </td> : ''
                                      }
                                    </tr>
                                  </tbody>
                                )})
                              }
                            </table>
                          </div>
                        </div>
                
                   
                    
                </div>
                
                      )    
                  })
                }
                
                {/* Modal */}
                {
                  this.state.modal 
                  ? <div className="modal right side_pop_modal fade " id="myModal4" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i className="fas fa-times"></i> Close</span></button>
                        <h4 className="modal-title" id="myModalLabel2">Test Execution Details</h4>
                      </div>
                      <div className="modal-body">
                        <div className="details_block">
                          <div className="item">
                            <p className="title">Test Case Title :</p>
                            <p>{this.state.executionDetails.name}</p>
                          </div>
                          <div className="item">
                            <p className="title">Steps :</p>
                            <ul className="mt-2">
                              <li>{this.state.executionDetails.steps}</li>                              
                            </ul>
                          </div>
                          <div className="item">
                            <p className="title">Expected Result :</p>
                            <p>{this.state.executionDetails.expected_result}</p>
                          </div>

                          <div className="test_results_block">
                            <h2 className="heading">Test Results </h2>
                            <div className="item" value={this.state.testResult} onChange={(e) => this.updateTestResult(e)}>
                              <p className="title">Test Result :</p>
                              <input  type="radio" id="Pass" name="fav_language" value="Pass" checked={this.state.testResult === 'Pass'}/>
                              <label className="d-inline-block mr-2" htmlFor="Pass">Pass</label>
                              <input type="radio" id="Fail" name="fav_language" value="Fail" checked ={this.state.testResult === 'Fail'}/>
                              <label className="d-inline-block mr-2" htmlFor="Fail">Fail</label>
                              <input type="radio" id="N/A" name="fav_language" value="N/A" checked ={this.state.testResult === 'N/A'}/>
                              <label className="d-inline-block mr-2" htmlFor="N/A">NA</label>
                              <input type="radio" id="Block" name="fav_language" value="Block" checked ={this.state.testResult === 'Block'}/>
                              <label className="d-inline-block mr-2" htmlFor="Block">Blocked</label>
                              <input type="radio" id="On Hold" name="fav_language" value="On Hold" checked ={this.state.testResult === 'On Hold'}/>
                              <label className="d-inline-block mr-2" htmlFor="On Hold">On Hold</label>
                            </div>
                            <div className="error-text">
                              {this.state.errors.status ? this.state.errors.status[0] : ''}
                            </div>
                            <div className="item">
                              <p className="title">Actual Result :</p>
                              <input  type="text" 
                                      className="form-control" 
                                      placeholder="Enter Actual Result"
                                      id="actualResult"
                                      value={this.state.actualResult} 
                                      onChange={(e) => this.handleChange(e)}/>
                            </div>
                            <div className="error-text">
                              {this.state.errors.actual_result ? this.state.errors.actual_result[0] : ''}
                            </div>
                            <button type="button" 
                                    className="border_btn add_btn mt-2"
                                    onClick={() => this.setState({ isAddNotes: !this.state.isAddNotes })}>
                              <i className="fas fa-plus"></i> Add Notes
                            </button>                            
                            <button type="button" 
                                    id="OpenImgUpload" 
                                    className="border_btn add_btn mt-2"
                                    onClick={() => this.uploadImage()} >
                              <i className="fas fa-plus"></i> Add Screenshot
                            </button>
                            <input type="file" id="imgupload" ref={this.inputReference} onChange={(e) => this.fileUploadInputChange(e)} hidden /> 


                            { (this.state.isAddNotes) ?
                              <div className="item">
                                <p className="title">Notes :</p>
                                <textarea className="form-control" 
                                          id="notes"
                                          rows="4"
                                          value={this.state.notes} 
                                          onChange={(e) => this.handleChange(e)}>
                                </textarea>
                              </div> : ''
                            }
                            { (this.state.isScreenshot) ?
                              <div className="item">
                                <p className="title mb-1">Screenshots :</p>
                                <span className="screenshot">                                
                                  <img src={this.state.screenshot.display} alt="" />                                  
                                </span>
                              </div> : ''
                            }
                            <br />
                            <button type="button" 
                                    className="new_btn mt-3 radius" 
                                    onClick={() => this.addTestResult(this.state.executionDetails.id)}>Add Test Result</button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div> {/* end of text modal dialog */}
                </div> 
                  : ''
                }

              </div> {/* end of text accordian */}

            </div> {/* end of text cases container */}

          </div> {/* end of col */}
        </div> {/* end of row */}


      </div> 
    )
  }
}