import React, { Component } from 'react';
import { fetch_get, fetch_post, DOCUMENTS_LIST, notify, Modal, Context, DropBox  } from '../../common';

export default class DocumentDetails extends Component {
  static contextType = Context;
  
  constructor(props) {
    super(props);
    this.state = {
      documentsList: [],
      profile: null,
      title: '',
      subject: '',
      description: '',
      labels: [],
      selectedUsers: [],
      errors: {},
      fileName: 'No file chosen',
      isEdit: false,
      document_id: null,
      files: []
    };
    this.fetchData = this.fetchData.bind(this);
    this.isActiveUpload = this.isActiveUpload.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    fetch_get(DOCUMENTS_LIST + this.props.match.params.pk + '/')
    .then((response) => {
      this.setState({
        profile: response.profile.data,
        documentsList: response.documents.data
      });
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  formData() {
    const data = new FormData();
    this.state.files.map((file) => data.append('document_file', file))
    data.append('title', this.state.title);
    data.append('description', this.state.description);
    data.append('subject', this.state.subject);
    data.append('profile', this.props.match.params.pk);
    return data
  }

  saveDocument() {
    fetch_post(DOCUMENTS_LIST + this.props.match.params.pk + '/create/', this.formData())
    .then(response => {
      if (!response.error) {
        notify('Document uploaded Successfully');
        this.fetchData();
        this.setState({
          title: '',
          subject: '',
          description: '',
          fileName: 'No file chosen',
          files: []
        });
      }
      else {
        this.setState({ errors: response.errors })
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  editDocument() {
    fetch_post(`${DOCUMENTS_LIST}${this.props.match.params.pk}/${this.state.document_id}/update/`, this.formData())
    .then(response => {
      if (!response.error) {
        notify('Document uploaded Successfully');
        this.fetchData();
        this.setState({
          isEdit: false,
          title: '',
          subject: '',
          description: '',
          fileName: 'No file chosen'
        });
      }
      else {
        this.setState({ errors: response.errors })
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  deleteDocument() {
    fetch_get(`${DOCUMENTS_LIST}${this.props.match.params.pk}/${this.state.document_id}/delete/`, true)
    .then((response) => {
      if (response.status === 204) {
        this.fetchData();
        this.setState({ displayDeleteModal: false })
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  isActiveUpload() {
    return ( this.state.fileName === 'No file chosen') ? "file-upload" : "file-upload active";
  }

  render() {    
    const { errors } = this.state;
    const { employee_id, employee_name, designation, user_experience, working_from, profile_pic_path } = this.state.profile ? this.state.profile : '';
    const firstLetter = employee_name ? employee_name[0] : '';
    return (
      <div>
        <div className="user_details_fixedtop row mar_lr_0">
          <div className="col-lg-1">
            { profile_pic_path ?
              <img src={profile_pic_path} alt=""  className="picture"/> :
              <div className="profile_name_big">{firstLetter}</div>
            }
          </div>
          <div className="col-lg-2 details">
            <div className="name">{employee_name}</div>
            {employee_id && <div className="name">{employee_id}</div>}
          </div>
          <div className="col-lg-3 details">
            {designation && <div><b>Designation :</b> {designation}</div>}
            {working_from && <div><b>Working From :</b> {working_from}</div>}
            {user_experience && <div><b>Experience :</b> {user_experience}</div>}
          </div>
        </div>
        <div className="docuemnts_container row mar_lr_0">
          <div className="col-lg-8 documents_list">
            <div className="row tabs_block">
            </div>
            <div className="row no-gutters documents">
              <div className="col-lg-12 tab-content">
                <div className="row">
                  {
                    this.state.documentsList.length > 0 ? 
                    this.state.documentsList.map((obj) =>
                      <div className="col-lg-4" key={obj.id}>
                        <div className="card document_block">
                          <div className="body text-center">
                            <div className="title">
                              <a href={obj.document_file_path} target='_blank' rel="noreferrer">{obj.title}</a>
                            </div>
                            <div className="icon">
                              <a href={obj.document_file_path} target='_blank' rel="noreferrer"><i className={obj.file_type_code[1]} /></a>
                            </div>
                            <div className="options text-center">
                              <a className="icon download" download href={obj.document_file_path}>
                                <i className="fa fa-download" aria-hidden="true" />
                              </a>
                              {
                                this.context.permissions.includes('DOCUMENTS') ?
                                [<a className="icon edit" key="edit" onClick={() => this.setState({
                                  isEdit: true,
                                  title: obj.title,
                                  subject: obj.subject,
                                  description: obj.description,
                                  document_id: obj.id
                                })}>
                                  <i className="fa fa-edit" aria-hidden="true" />
                                </a>,
                                <a key="delete" className="icon trash"
                                  onClick={() => this.setState({document_id:obj.id, displayDeleteModal: true})}>
                                  <i className="fa fa-trash" aria-hidden="true" />
                                </a>] : null
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    ) :
                    <div>No documents found</div>
                  }
                </div>
              </div>
                <Modal
                  display={this.state.displayDeleteModal}
                  bodyText='Are you sure? You want to delete?'
                  modalAction={() => this.deleteDocument()}
                  removeModal={() => this.setState({ displayDeleteModal: false })}
                />
            </div>
          </div>
          {
            this.context.permissions.includes('DOCUMENTS') &&
            <div className="col-lg-4 add_document">
              <h2 className="heading">{this.state.isEdit ? 'Edit' : 'Add'} Document</h2>
              <form className="no-padding">
                <div className="form-group">
                  <label>Title<div className='error-text'>*</div></label>
                  <input value={this.state.title} type="text" className="form-control"
                    onChange={(e) => this.setState({ title: e.target.value, errors: {} })}
                  />
                  <div className="error-text">
                    { errors.title ? errors.title[0] : null }
                  </div>
                </div>
                <div className="form-group">
                  <label>Subject<div className='error-text'>*</div></label>
                  <input value={this.state.subject} type="text" className="form-control"
                    onChange={(e) => this.setState({ subject: e.target.value, errors: {} })}
                  />
                  <div className="error-text">
                    { errors.subject ? errors.subject[0] : null }
                  </div>
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea value={this.state.description} className="form-control textarea" 
                    rows="3" onChange={(e) => this.setState({ description: e.target.value })} />
                </div>
                <div className="form-group">
                  <label>Add File <small>[upload .jpg, .png, .jpeg, doc, docx, pdf, xlsx, csv]</small></label>
                  <DropBox
                    files={this.state.files}
                    onDrop={(files) => this.setState({ files })}
                  />
                  <div className="error-text">
                    { errors.document_file ? errors.document_file[0] : null }
                  </div>
                </div>
                <div className="text-left buttons">
                  <button type="button" className="btn primary_btn"
                    onClick={this.state.isEdit ? this.editDocument.bind(this) : this.saveDocument.bind(this)}>
                    Save Details</button>
                  <button type="button" className="btn secondary_btn"
                    onClick={() => {
                      this.setState({ title: '', description: '', fileName: 'No file chosen', isEdit: false})
                    }}
                  >
                    Cancel</button>
                </div>
              </form>
            </div>
          }
        </div>
      </div>

    );
  }
}
