import React, { Component } from 'react'
import { fetch_get, ACTIVATE_USER, APP_NAME } from '../common'

export default class ActivateUser extends Component {
  componentDidMount () {
    // fetch_get(`${ACTIVATE_USER}${this.props.match.params.uid}/${this.props.match.params.token}/account/`)
    fetch_get(`${ACTIVATE_USER}account/${this.props.match.params.uid}/${this.props.match.params.token}/`)
      .then(() => {
        this.props.history.push(APP_NAME + '/signin')
      })
  }

  render () {
    return (
      <div>Processing...</div>
    )
  }
}
