import React, { Component } from 'react'
import LeftNav from './admin/LeftNav'
import { SERVER } from './common'

export default class Introduction extends Component {
  constructor (props) {
    super(props)
    this.renderNextPage = this.renderNextPage.bind(this)
  }

  renderNextPage () {
    const company = localStorage.getItem('company')
    const token = localStorage.getItem('token')
    if (localStorage.getItem('role') === 'admin') {
      window.location.href = `//${company + SERVER}/admin`
    } else {
      window.location.href = `//${company + SERVER}/token-login/?token=${token}`
    }
  }

  render () {
    return (
      <div className='wrapper'>
        <div className='main_container'>
          <LeftNav nav='intro' />
          <div className='inner_container'>
            <div className='row justify-content-md-center'>
              <div className='col-md-6'>
                <div className='pm_introduction_blocks'>
                  <a className='pm_intro_block'>
                    <span className='row'>
                      <span className='col-md-4'>
                        <span className='icon'>
                          <i className='fa fa-users green' />
                        </span>
                      </span>
                      <span className='col-md-8'>
                        <span className='title'>
                          HR Management
                        </span>
                        <p>HR Management</p>
                      </span>
                    </span>
                  </a>
                  <a className='pm_intro_block'>
                    <span className='row'>
                      <span className='col-md-4'>
                        <span className='icon'>
                          <i className='fa fa-tasks sky_blue' />
                        </span>
                      </span>
                      <span className='col-md-8'>
                        <span className='title'>
                          Project Management
                        </span>
                        <p>Project Management</p>
                      </span>
                    </span>
                  </a>
                  <a className='pm_intro_block'>
                    <span className='row'>
                      <span className='col-md-4'>
                        <span className='icon'>
                          <i className='fa fa-hdd-o orange' />
                        </span>
                      </span>
                      <span className='col-md-8'>
                        <span className='title'>
                          CRM
                        </span>
                        <p>CRM</p>
                      </span>
                    </span>
                  </a>
                  <div className='get_started text-center'>
                    <a onClick={() => this.renderNextPage()}>Getting Started</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
