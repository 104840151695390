import React, { Fragment } from 'react'
export const Spinner = ({ loadingText = false }) => (
  <>
    <div className='spinner task'>
      <div className='double-bounce1' />
      <div className='double-bounce2' />
    </div>
    {
      loadingText &&
        <div className='spinner task' style={{ marginBottom: '10px' }}> Loading...</div>
    }
  </>
)
