import React, { Component } from 'react';
import Select from 'react-select';
import {
  fetch_get,
  fetch_post,
  PROJECTS_LIST,
  SERVER,
  notify,
  Modal,
  Context
} from '../../../common';

export default class Trello extends Component {
  static contextType = Context
  constructor(props) {
    super(props);
    this.state = {
      domain: '//' + localStorage.getItem('company') + SERVER,
      apiUrl: '',
      errors: {},
      organizations: [],
      projects: [],
      labels: [],
      assignedList: [],
      label: [],
      member: [],
      authToken: '',
      project: '',
      organization: '',
      moreOptions: false,
      trelloDetails: false,
      trelloDetailsList: [],
      priority: '',
      projectId: this.props.projectId,
      edit: false,
      show: false,
      allowSync: 'True',
      loading: true
    }
    this.editTrelloDetails = this.editTrelloDetails.bind(this);
  }

  componentDidMount() {
    this.intialtrelloDetails();
    this.moreSettings();
    this.initialState = this.state;
  }

  componentDidUpdate(prevProps) {
    if(this.props.projectId !== prevProps.projectId) {
      this.setState(this.initialState)
      this.setState({ projectId: this.props.projectId }, () => { 
        this.moreSettings();
        this.intialtrelloDetails();
      })
    }
  }

  intialtrelloDetails() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/trello/details/')
    .then(response => {
      this.setState({ loading: false })
      if(response.response) {
        this.setState({ trelloDetailsList: response.response.trello.data, trelloDetails: true });
      } else {
        notify(response.message)
      }
    })
  }

  getOrganizations(authToken=this.state.authToken) {
    this.setState({ errors: {} });
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/trello/connect/?token='+ authToken +'&project_id=' + this.state.projectId)
    .then(response => {
      if(response.message) {
        notify(response.message)
      } else {
        if(response.error) { 
          this.setState({ errors: response.errors})
        } else {
          this.setState({ organizations: response.response.organizations })
        }
      }
    })
  }

  getProjects(authToken=this.state.authToken, org=this.state.organization) {
    this.setState({ errors: {} });
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/trello/get-org-projects/?org=' + org +'&trello_token=' + authToken)
    .then(response => {
      if(response.error) {
        this.setState({ errors: response.errors });
      } else {
        this.setState({ projects: response.projects })
      }
    })
  }

  moreSettings() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/trello/sync-trello-issues/')
    .then(response => {
      var members = [];
      var labelsList = [];
      response.labels.data.filter(function( obj ) {
        return (
          labelsList.push({label: obj.name, value: obj.id})
        )
      });
      response.profiles.data.filter(function( obj ) {
        return (
          members.push({
            label: 
            <div>
              <img className="select_img" src={obj.profile_img}  alt={obj.employee_name}/>
              {obj.employee_name.split(" ")[0]}
            </div>, 
            value: obj.id
          })
        )
      });
      this.setState({ 
        labels: labelsList,
        assignedMembersList: members,
        moreOptions: true,
        priorities: response.priorities,
      })
    })
  }

  onLabelSelect(value) {
    this.setState({ label: value });
  }

  onMemberSelect(value) {
    this.setState({ member: value });
  }

  saveDefaultSettings() {
    let orgName = document.getElementById('organization') && document.getElementById('organization').options[document.getElementById('organization').selectedIndex].text;
    let boardName = document.getElementById('board') && document.getElementById('board').options[document.getElementById('board').selectedIndex].text
    this.setState({ errors: {} });
    const data = new FormData();
    if(this.state.label.length > 0) {
      this.state.label.filter(function( obj ) {
        return (
          data.append('labels', obj.value)
        )
      });
    }
    if(this.state.member) {
      this.state.member.filter(function( obj ) {
        return (
          data.append('assigned_to', obj.value)
        )
      });
    }
    data.append('token', this.state.authToken);
    data.append('organization', this.state.organization);
    data.append('org_name', orgName);
    data.append('board_id', this.state.project);
    data.append('board_name', boardName);
    data.append('project', this.state.projectId)
    data.append('allow_sync', this.state.allowSync)
    data.append('priority', this.state.priority)
    var url = this.state.edit ? ('update-defaults/?project_id=' + this.state.projectId) : 'sync-trello-issues/';
    fetch_post(PROJECTS_LIST + this.state.projectId +'/integrations/trello/'+ url, data)
    .then(response => {
      if(response.error) {
        this.setState({ errors: response.errors })
      } else {
        this.intialtrelloDetails();
        notify(response.response);
        this.context.updateCount('true');
      }
    });
  }

  editTrelloDetails() {
    const { trelloDetailsList } = this.state;
    var labels = [];
    if(trelloDetailsList.default_values.labels.data) {
      trelloDetailsList.default_values.labels.data.filter(function( obj ) {
        return (
          labels.push({label: obj.name, value: obj.id})
        )
      });
    }
    if(trelloDetailsList.default_values.assigned_to.data.length > 0) {
      var member = [];
      trelloDetailsList.default_values.assigned_to.data.filter(function( obj ) {
        return (
          member.push({
            label: 
            <div>
              <img className="select_img" src={obj.profile_img} alt={obj.employee_name}/>
              {obj.employee_name.split(" ")[0]}
            </div>, 
            value: obj.id
          })
        )
      });
    }
    this.setState({ 
      authToken: trelloDetailsList.token,
      organization: trelloDetailsList.org_id,
      project: trelloDetailsList.board_id,
      trelloDetails: false,
      label: labels,
      priority: trelloDetailsList.default_values.priority.data.id,
      moreOptions: true,
      edit: true,
      allowSync: trelloDetailsList.allow_sync
    });
    trelloDetailsList.allow_sync === true ? this.setState({ allowSync: 'True' }) : this.setState({ allowSync: 'False' })
    this.getOrganizations(trelloDetailsList.token);
    this.getProjects(trelloDetailsList.token, trelloDetailsList.org_id);
  }

  setValue(e) {
    this.setState({ visibleStatus: e.target.value });
  }

  setValueIssue(e) {
    this.setState({ issueStatus: e.target.value });
  }

  deleteTrelloSettings() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/trello/disconnect/?project_id=' + this.state.project)
    .then(response => {
      if(response.message) {
        this.setState({ displayDeleteModal: false })
        notify(response.message);
        this.context.updateCount('true');
        this.setState(this.initialState);
        this.intialtrelloDetails();
        this.moreSettings();
      }
    })
  }

  cancelDetails() {
    if(this.state.edit) {
      this.setState({ moreOptions: true, trelloDetails: true })
    }
  }

  render() {
    const { trelloDetailsList, priorities } = this.state;
    return (
      <div className="row integration_list_view ">
        <div className="col-lg-12 pl-0 sentry_left_container">
          <strong>Trello</strong>
          {this.state.show &&
            <div className="integration_content" style={{ display: 'block' }}>
              <p>
                Trello is the easy, free, flexible, and visual way to manage your projects and organize anything, trusted by millions of people from all over the world.
              </p>
            </div>
          }
          <div className="sliding_bar">
            {this.state.show &&
              <span className="arrow-up" onClick={() => this.setState({ show: false }) }>
                <i className="fa fa-times"></i>
              </span>
            }
            { !this.state.show &&
              <span className="arrow-down" onClick={() => this.setState({ show: true }) }>
                <i className="fa fa-question"></i>
              </span>
            }
          </div>
        </div>
        {!this.state.trelloDetails ?
          <div className="col-lg-12 pl-0 form">
            {this.state.loading ? 
              <div>Loading ...</div>
            :
              <form>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Token<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                      <input 
                        type="text" 
                        value={this.state.authToken} 
                        onChange={(e) => this.setState({ authToken: e.target.value})} 
                        placeholder="Enter Trello token"
                        className="form-control " 
                        disabled={this.state.edit}
                      />
                      <div className="error-text">
                        {this.state.errors.token ? this.state.errors.token : null }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    {!this.state.edit && 
                      <div className="save_application">
                        <button type="button" className="btn primary_btn" onClick={() => {this.getOrganizations()}}>connect to trello</button>
                      </div>
                    }
                    {this.state.organizations.length > 0 &&
                      <div className="form-group ">
                        <label>Organizations<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                        {this.state.edit ? 
                          <input 
                            type="text"
                            className="form-control" 
                            value={trelloDetailsList.org_name}
                            onChange={() => {}}
                            disabled
                          /> 
                          :
                          <select 
                            id="organization"
                            className="form-control "
                            value={this.state.organization}
                            onChange={(e) => this.setState({ organization: e.target.value })}
                          >
                            <option value=""> ----- </option>
                            {this.state.organizations.map((Organization) => 
                              <option key={Organization[0]} value={Organization[0]}>{Organization[1]}</option>
                            )}
                          </select> 
                        }
                        <div className="error-text">
                          {this.state.errors.organization ? this.state.errors.organization : null }
                        </div>
                      </div>
                    }
                  </div>
                  {this.state.organization && 
                    <div className="col-lg-6">
                      {!this.state.edit ?
                        <div className="save_application">
                          <button type="button" className="btn primary_btn" onClick={() => {this.getProjects()}}>Get Projects</button>
                        </div>
                      : null}
                      {this.state.projects.length > 0 ? 
                        <div className="form-group ">
                          <label>Projects<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                          {this.state.edit ?
                            <input 
                              type="text"
                              className="form-control" 
                              value={trelloDetailsList.board_name}
                              onChange={() => {}}
                              disabled
                            /> 
                            : 
                            <select 
                              id="board"
                              className="form-control "
                              value={this.state.project}
                              onChange={(e) => {this.setState({ project: e.target.value}); this.moreSettings(e.target.value) }}
                            >
                              <option value=""> ----- </option>
                              {this.state.projects.map((projectObj) => 
                                <option key={projectObj[0]} value={projectObj[0]}>{projectObj[1]}</option>
                              )}
                            </select>
                          }
                          <div className="error-text"></div>
                        </div>
                      : null }
                    </div>
                  }
                </div>
                {this.state.project && this.state.moreOptions && 
                  <div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="form-group ">
                              <label>Labels</label>
                              <Select
                                isMulti
                                onChange={(e) => {this.onLabelSelect(e)}}
                                options={this.state.labels}
                                value={this.state.label}
                                placeholder="select label(s)"
                              />
                              <div className="error-text">
                                {this.state.errors.labels ? this.state.errors.labels[0] : null }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group ">
                              <label>Priority<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                              <select 
                                className="form-control "
                                value={this.state.priority}
                                onChange={(e) => this.setState({ priority: e.target.value })}
                              >
                                <option value=""> ----- </option>
                                {priorities ? priorities.data.map((priority) => 
                                  <option key={priority.id} value={priority.id}>{priority.name}</option>
                                ) : null}
                              </select>
                              <div className="error-text">
                                {this.state.errors.priorities ? this.state.errors.priorities[0] : null }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label>Assign To</label>
                              <Select
                                isMulti
                                onChange={(e) => {this.onMemberSelect(e)}}
                                options={this.state.assignedMembersList}
                                value={this.state.member}
                                placeholder="select member(s)"
                              />
                              <div className="error-text"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label>Sync<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                              <select 
                                className="form-control " 
                                onChange={(e) => {this.setState({ allowSync: e.target.value }) }}
                                value={this.state.allowSync}
                              >
                                <option value="True"> True </option>
                                <option value="False">False</option>
                              </select>
                              <div className="error-text">
                                {this.state.errors.allow_sync && this.state.errors.allow_sync}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mar_lr_0">
                      <div className="col-lg-12 text-center buttons">
                        <button type="button" className="btn primary_btn" onClick={() => this.saveDefaultSettings()}>Proceed</button>
                        <button type="button" className="btn secondary_btn" onClick={() => this.cancelDetails()}>Cancel</button>
                      </div>
                    </div>
                  </div>
                }
              </form>
            }
          </div>
          : 
          <div className="col-lg-12 detailed_content">
            <div className="row">
              <div className="col-lg-12 text-right">
                <button type="button" className="btn primary_btn" onClick={() => this.editTrelloDetails()}>Edit</button>
                <button type="button" className="btn secondary_btn" onClick={() => this.setState({displayDeleteModal: true})}>Delete</button>
              </div>
            </div>
            {trelloDetailsList &&
              <div className="row">
                <div className="col-lg-6">
                  <table className="table table-hover table-user-information ">
                    <tbody>
                      <tr>
                        <td>Token:</td>
                        <td>{trelloDetailsList.token}</td>
                      </tr>
                      <tr>
                        <td>Organization:</td>
                        <td>{trelloDetailsList.org_name}</td>
                      </tr>
                      <tr>
                        <td>Project:</td>
                        <td>{trelloDetailsList.board_name}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-6">
                  <table className="table table-hover table-user-information ">
                    <tbody>
                      <tr>
                        <td>Labels:</td>
                        <td>
                          {trelloDetailsList.default_values.labels.data &&
                            trelloDetailsList.default_values.labels.data.map((label) => 
                              <a key={label.id}>{label.name}</a>
                            )
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Priority:</td>
                        <td>{trelloDetailsList.default_values.priority && trelloDetailsList.default_values.priority.data.name}</td>
                      </tr>
                      <tr>
                        <td>Assigned To:</td>
                        <td>
                          <div className="team_members">
                            {trelloDetailsList.default_values.assigned_to && 
                              <div className="desc assign">
                                {trelloDetailsList.default_values.assigned_to.data.length > 0 ? trelloDetailsList.default_values.assigned_to.data.map((member) => 
                                  <a key={member.id} className="member">
                                    <img 
                                      src={member.profile_img} 
                                      data-toggle="tooltip" 
                                      data-placement="bottom" 
                                      title={member.employee_name} 
                                      data-original-title={member.employee_name}
                                      alt={member.employee_name} 
                                    />
                                  </a>
                                ) : '--'}
                              </div>
                            }
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top">Allow Sync:</td>
                        <td>{trelloDetailsList.allow_sync === true ? 'True' : 'False'}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </div>
        }
        <Modal
          display={this.state.displayDeleteModal}
          bodyText='Are you sure? You want to delete?'
          modalAction={this.deleteTrelloSettings.bind(this)}
          removeModal={() => this.setState({ displayDeleteModal: false })}
        />
      </div>
    );
  }
}
