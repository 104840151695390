export const formatDate = (date, format = 'YYYY-MM-DD') => {
  return typeof (date) === 'object' ? date.format(format) : date
}

export const secondsToHms = (d) => {
  if (d !== '0.00') {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor(d % 3600 / 60)
    // var s = Math.floor(d % 3600 % 60);

    const hDisplay = h > 0 ? h < 10 ? '0' + h + ':' : h + ':' : '00:'
    const mDisplay = m > 0 ? m < 10 ? '0' + m : m : '00'
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay
  } else {
    return '0:00'
  }
}
