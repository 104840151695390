import React, { Component, Fragment } from 'react'
import moment from 'moment'

export default class Task extends Component {
  constructor (props) {
    super(props)
    this.state = {
      task: this.props.task,
      taskId: this.props.task && this.props.task.pt_id,
      updateTask: this.props.updateTaskDetails,
      updateTaskId: this.props.updateTaskId,
      assignedToMenu: false,
      today: moment()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.updateTask !== prevProps.updateTaskDetails) {
      if (this.state.taskId === this.props.updateTaskId) {
        this.setState({ task: this.props.task })
        this.props.updateTaskDetail(false, null)
      }
    }
  }

  showTaskDetail (id) {
    this.props.showTaskDetail(id)
  }

  timeSpent (seconds) {
    seconds = moment.duration(parseInt(seconds, 10), 'seconds')
    let format = Math.floor(seconds.asHours()) > 0 ? Math.floor(seconds.asHours()) + 'h ' : ''
    format += seconds.minutes() + 'm'
    return format
  }

  dueDate (deadLineDate) {
    return 'due ' + moment(deadLineDate, 'YYYY-MM-DD').fromNow()
  }

  labelFilter (label) {
    this.props.labelUpdate(label)
  }

  labelText (hex) {
    const rgb = (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) { return parseInt(hex.length % 2 ? l + l : l, 16) }).join(',').split(',')
    const colorRange = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000)
    const color = (colorRange > 200) ? '#000' : '#fff'
    return color
  }

  renderMembers (members) {
    let list1 = []
    let list2 = []
    list1 = members.slice(0, 2)
    list2 = members.slice(2, members.length)
    return (
      <>
        {list1.map((member) =>
          <span className='avatar' key={member.id}>
            {member.has_profile_pic
              ? <img src={member.profile_img} data-toggle='tooltip' data-placement='bottom' title={member.employee_name} alt='' />
              : <span className='profile_name' data-toggle='tooltip' data-placement='bottom' title={member.employee_name}>{member.employee_name && member.employee_name[0] + (member.employee_name.split(' ')[1] && member.employee_name.split(' ')[1][0])}</span>}
          </span>
        )}
        <span
          className='btn-group dropdown people task_people text-right no-padding'
          tabIndex={0}
          onClick={() => this.setState({ assignedToMenu: !this.state.assignedToMenu })}
          onBlur={() => this.setState({ assignedToMenu: false })}
        >
          {list2.length > 0
            ? <a className='profile_name'>
              {list2.length}+
            </a>
            : ''}
          {this.state.assignedToMenu &&
            <ul className='menu' style={{ display: 'block' }}>
              {list2.map((member) =>
                <li className='submenu' key={member.id}>
                  <a>
                    {member.has_profile_pic
                      ? <img src={member.profile_img} data-toggle='tooltip' data-placement='right' title={member.employee_name} alt='' />
                      : <span className='profile_name' data-toggle='tooltip' data-placement='bottom' title={member.employee_name}>{member.employee_name && member.employee_name[0] + member.employee_name.split(' ')[1][0]}</span>}
                    {member.employee_name}
                  </a>
                </li>
              )}
            </ul>}
        </span>
      </>
    )
  }

  compareDates () {
    if (this.state.task.dead_line && this.state.task.status_text !== 'Finished') {
      const today = this.state.today.format('YYYY-MM-DD')
      return (today > this.state.task.dead_line)
    }
    return false
  }

  compareDays (deadline) {
    const endDate = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
    const timeDiff = Date.parse(endDate) - Date.parse(deadline)
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
    return daysDiff > 1 ? 'Past due by ' + daysDiff + ' days' : 'Past due by ' + daysDiff + ' day'
  }

  render () {
    const { task } = this.state
    return (
      <div className='task_info'>
        <div className='task_detail'>
          <p onClick={() => { this.showTaskDetail(task.pt_id) }}>{task.name}</p>
          <div className='detail_info'>
            <span>#{task.pt_id}</span><span>Created {task.task_created_on ? task.task_created_on : '--'} {task.created_by_user_json && `by ${task.created_by_user_json.data.employee_name}`}</span>
            <span style={{ color: 'red' }}>{this.compareDates() && this.compareDays(task.dead_line)}</span>
            <ul>
              {task.comment_count > 0 && <li className='milestone'> <a><i className='far fa-comment-alt' />({task.comment_count})</a></li>}
              {task.attachment_count > 0 && <li className='milestone'> <a><i className='fas fa-paperclip' />({task.attachment_count})</a></li>}
              {task.priority_text && <li><i className='far fa-clock' /> <a style={{ backgroundColor: 'rgb(66, 139, 202)' }}>{task.priority_text}</a> </li>}
              {task.milestones_text && <li className='milestone'><i className='fas fa-flag' /><a>{task.milestones_text}</a></li>}
              {task.labels_text && task.labels_text.data && task.labels_text.data.length > 0 &&
                <li>
                  <i className='fas fa-tags' />
                  {task.labels_text.data.map((label, index) =>
                    <a key={label.id} onClick={() => this.labelFilter(label, index)} style={{ background: label.color, color: this.labelText(label.color) }}>{label.name}</a>
                  )}
                </li>}
            </ul>
          </div>
        </div>
        <div className='task_intro text-right'>
          {parseInt(task.total_time) > 0 && <small> Time Spent : <b>{this.timeSpent(parseInt(task.total_time))} </b></small>}
          <div className='git_icons'>
            {task.is_from_trello && <a href={task.web_url_text ? task.web_url_text.trello : ''} rel='noopener noreferrer' target='_blank' title='Trello'><i className='fab fa-trello' /></a>}
            {task.is_from_gitlab && <a href={task.web_url_text ? task.web_url_text.gitlab : ''} rel='noopener noreferrer' target='_blank' title='GitLab'><i className='fab fa-gitlab' /></a>}
            {task.is_from_github && <a href={task.web_url_text ? task.web_url_text.github : ''} rel='noopener noreferrer' target='_blank' title='GitHub'><i className='fab fa-github' /></a>}
            {task.is_from_sentry && <a href={task.web_url_text ? task.web_url_text.sentry : ''} rel='noopener noreferrer' target='_blank' title='Sentry'><img alt='' src='https://sentry-brand.storage.googleapis.com/sentry-glyph-black.svg' /></a>}
          </div>
          {task.assigned_to_list && task.assigned_to_list.data && task.assigned_to_list.data.length > 0
            ? <div className='avatars'>
              {task.assigned_to_list.data.length > 3
                ? <>
                  {this.renderMembers(task.assigned_to_list.data)}
                  </>
                : <>
                  {task.assigned_to_list.data.map((member) =>
                    <span className='avatar' key={member.id}>
                      {member.has_profile_pic
                        ? <img
                            src={member.profile_img}
                            data-toggle='tooltip'
                            data-placement='bottom'
                            title={member.employee_name}
                            data-original-title={member.employee_name}
                            alt=''
                          />
                        : <span className='profile_name' title={member.employee_name}>{member.employee_name && member.employee_name[0] + (member.employee_name.split(' ')[1] && member.employee_name.split(' ')[1][0])}</span>}
                    </span>
                  )}
                  </>}
              </div>
            : null}
        </div>
      </div>
    )
  }
}
