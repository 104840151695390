import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { APP_NAME, Context } from '../common'

export default class LeftNav extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      selectedChild: window.location.pathname.split('/')[3],
    };
    this.isActiveChild = this.isActiveChild.bind(this);
  }

  componentDidMount() {
    this.collapseActions()
    if (!localStorage.getItem('token')) {
      this.props.props.history.push(APP_NAME);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (prevState.selectedChild !== window.location.pathname.split('/')[3]) ? {
      selectedChild: window.location.pathname.split('/')[3]
    } : null
  }

  isActiveChild(child) {
    return (child === this.state.selectedChild) ? "active" : "";
  }

  collapseActions() {
    let MenuBlock = document.getElementsByClassName("menu_block");
    let  Sidebar = document.getElementsByClassName("sidebar");
    let Stage = document.getElementsByClassName("stage");
    let SideMenu = document.getElementsByClassName("side_menu");
    if(SideMenu[0].style.width != "25%") {
      MenuBlock[0].style.display = "inline-block";
      Sidebar[0].style.width = "15%";
      Stage[0].style.marginLeft = "15%";
      SideMenu[0].style.width = "25%";
    }
  }

  componentDidUpdate() {
    let SideMenu = document.getElementsByClassName("side_menu");
    if(SideMenu[0].style.width != "25%") {
      this.collapseActions()
    }
  }
  
  render() {
    return (
      <div>
        <ul>
          <li onClick={()=> this.setState({ selectedChild: 'dashboard' })}>
            <Link
              to={APP_NAME + this.context.company + '/marketing/dashboard'}
              className={this.isActiveChild('dashboard')}
            >
              <span className="fa_icon">
                <i className="fa fa-tachometer-alt fa-w-20" title="Dashboard" />
              </span>
              <span className="menu_text">Dashboard</span>
            </Link>
          </li>
          <li onClick={()=> this.setState({ selectedChild: 'contact-lists' })}>
            <Link
              to={APP_NAME + this.context.company + '/marketing/contact-lists'}
              className={this.isActiveChild('contact-lists')}
            >
              <span className="fa_icon">
                <i className="fa fa-users" title="Contacts Lists" />
              </span>
              <span className="menu_text">Contact Lists</span>
            </Link>
          </li>
          <li onClick={()=> this.setState({ selectedChild: 'contacts' })}>
            <Link
              to={APP_NAME + this.context.company + '/marketing/contacts'}
              className={this.isActiveChild('contacts')} 
            >
              <span className="fa_icon">
                <i className="fa fa-users" title="Contacts" />
              </span>
              <span className="menu_text">Contacts</span>
            </Link>
          </li>
          <li onClick={()=> this.setState({ selectedChild: 'email-templates' })}>
            <Link
              to={APP_NAME + this.context.company + '/marketing/email-templates'}
              className={this.isActiveChild('email-templates')}
            >
              <span className="fa_icon">
                <i className="fa fa-users" title="Email Templates" />
              </span>
              <span className="menu_text">Email Templates</span>
            </Link>
          </li>
          <li onClick={()=> this.setState({ selectedChild: 'campaigns' })}>
            <Link
              to={APP_NAME + this.context.company + '/marketing/campaigns'}
              className={this.isActiveChild('campaigns')} 
            >
              <span className="fa_icon">
                <i className="fa fa-users" title="Campaigns" />
              </span>
              <span className="menu_text">Campaigns</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

