import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MEETINGS, fetch_get, notify, fetch_post, Context, APP_NAME } from '../../common';

export default class MeetingDetails extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      meetingDetails: [],
      minutesOfMeetingData: [],
      minutesOfMeetingDesc: '',
      editMomId: null,
      addMinutesOfMeeting: false,
      errors: {},
      users_attended: [],
      users_attending: [],
      users_not_attending_details: []
    };
  }

  componentDidMount() {
    this.fetchMeetings();
    this.fetchMinutesOfMeetings()
  }

  fetchMeetings() {
    this.setState({ isLoading: true, isEditMeeting: false });
    fetch_get(MEETINGS + `${this.props.match.params.mid}/details/`)
    .then((response) => {
/*      let users_attended = [];
      response.data.users_attended_details.data.map((obj) =>
        users_attended.push(obj.id)
      )*/
      let users_not_attending_details = [];
      response.data.users_not_attending_details.data.map((obj) =>
        users_not_attending_details.push(obj.id)
      )
      let users_attending = [];
      response.data.users_attending_details.data.map((obj) =>
        users_attending.push(obj.id)
      )
      this.setState({ meetingDetails: response.data, users_attending, users_not_attending_details })
      // if (response.meetings_list) {
      //   this.setState({
      //     upcomingMeetings: response.meetings_list.data,
      //     finishedMeetings: response.completed_meetings_list.data
      //   })
      // }
      // let users = [];
      // response.users.data.map((obj) =>
      //   users.push({label: obj.email, value: obj.id})
      // )
      // if (response.meetings_list) {
      //   this.setState({ 
      //     meetingsList: response.meetings_list.data,
      //     closedMeetings: response.completed_meetings_list.data,
      //     meeting_choices: response.meeting_choices,
      //     reminder_choices: response.reminder_choices,
      //     users,
      //     isLoading: false
      //   });
      // }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  fetchMinutesOfMeetings() {
    fetch_post(`${MEETINGS}${this.props.match.params.mid}/mom/list/`)
    .then((response) => {
      if(!response.error) {
        this.setState({ minutesOfMeetingData: response.mom_list })
      }
    })
  }

  addEditMinutesOfMeeting() {
    const { minutesOfMeetingDesc, meetingDetails, editMomId } = this.state;
    const data = new FormData();
    data.append('meeting_date', meetingDetails.date_of_meeting);
    data.append('description', minutesOfMeetingDesc);
    let url = editMomId ? (`${editMomId}/update/`) : 'create/';
    fetch_post(`${MEETINGS}${meetingDetails.id}/mom/${url}`, data)
    .then((response) => {
      if(!response.error) {
        this.setState({ addMinutesOfMeeting: false, editMomId: null, errors: {}, minutesOfMeetingDesc: '' }, this.fetchMinutesOfMeetings)
      } else {
        this.setState({ errors: response.errors })
      }
    })
  }

  deleteMinutesOfMeeting(id) {
    fetch_get(`${MEETINGS}${this.state.meetingDetails.id}/mom/${id}/delete/`)
    .then((response) => {
      if(!response.error) {
        notify(response.message)
        this.setState({
          editMomId: null,
          minutesOfMeetingDesc: '',
        })
        this.fetchMinutesOfMeetings()
      }
    })
  }

  acceptMeeting(url) {
    fetch_get(url)
    .then((response) => {
        notify(response.message);
        this.fetchMeetings();
      }
    )
  }

  render() {
    const { meetingDetails, minutesOfMeetingDesc, errors, minutesOfMeetingData } = this.state;
    return (
      <div className="secondary_block">
        <div className="row mar_lr_0">
          <span className="arrow">
            <Link to={{pathname: `${APP_NAME}${this.context.company}/pm/${this.props.match.params.id}/meetings`, state: {is_closed : meetingDetails.is_closed} }} data-toggle="tooltip" data-placement="bottom" title="Go to Lead list">
              <i className="fa fa-backward" aria-hidden="true"></i>
            </Link>
          </span>
          <h2 className="heading col-lg-12">Meeting Details<span className="float-right"><Link className="primary_btn btn" to={{pathname: APP_NAME + this.context.company + `/pm/${this.props.match.params.id}/meetings/add`, state: {addFromDetails: true}}}>Add Meeting</Link></span></h2>
        </div>
        <div className="meeting_container row mar_lr_0">
          <div className="col-lg-12 meeting_view">
            <div className="meeting_item">
              <h4 className="heading mt-0">{meetingDetails.title} <small className="meeting_type">  {meetingDetails.meeting_option_display}</small></h4>
              {/*<div className="more_details action_change">
                <span className="detail"><b>Created By : </b>{meetingDetails.created_by_data && meetingDetails.created_by_data.data.nickname}</span>
              </div>*/}
              <div className="more_details action_change mb-5">
                <span className="detail"><b>Date : </b> {meetingDetails.date_of_meeting}</span> 
                <span className="detail"><b>Starts At : </b> {meetingDetails.formatted_time_start}</span> 
                <span className="detail"><b>Duration : </b> {meetingDetails.duration_display ? meetingDetails.duration_display : 'Not Mentioned'}</span> 
                <span className="detail to-go">{meetingDetails.start_date_timesince}</span>
                <span className="detail">
                  { (meetingDetails.is_closed === false && meetingDetails.status !== 'self') &&
                    (meetingDetails.status === 'pending' ?
                      <Fragment>
                        <a className="accept" onClick={() => this.acceptMeeting(meetingDetails.accept_url)}>Accept</a>
                        <a className="reject" onClick={() => this.acceptMeeting(meetingDetails.reject_url)}>Reject</a>
                      </Fragment> :
                      meetingDetails.status === 'accepted' ?
                        <a className="reject" onClick={() => this.acceptMeeting(meetingDetails.reject_url)}>Reject</a> :
                        <a className="accept" onClick={() => this.acceptMeeting(meetingDetails.accept_url)}>Accept</a>
                    )
                  }
                </span> 
              </div>
              <div className="mb-5">
                <p><b>Agenda : </b> </p>
                <p>{meetingDetails.description}</p>
              </div>
              <table className="table table-hover ">
                <thead>
                  <tr>
                    <th  scope="col">Employee</th>
                    <th scope="col" className="text-center">Accepted</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    meetingDetails.users_invited_details && meetingDetails.users_invited_details.data.length > 0 ?
                    meetingDetails.users_invited_details.data.map((obj) =>
                      <tr key={obj.id}>
                        <td>
                          <a>
                            <span className="profile_name">
                              {obj.employee_name[0]}
                            </span>
                            <span className="employee_name">{obj.employee_name}<small>{obj.employee_id ? obj.employee_id : '--'}</small>
                              <p>{obj.email}</p>
                            </span>
                          </a>
                        </td>
                        {
                          <td className="text-center">
                          {this.state.users_attending.includes(obj.id) &&
                            <span><i className="fa fa-check"></i></span>
                          }
                          {this.state.users_not_attending_details.includes(obj.id) &&
                            <span><i className="fa fa-times"></i></span>
                          }
                          {(!this.state.users_attending.includes(obj.id) && !this.state.users_not_attending_details.includes(obj.id)) &&
                            <span>pending</span>
                          }
                          </td>
                        }
                      </tr>
                    )
                  :null}
                </tbody>
              </table>
              <br/>
              {minutesOfMeetingData.length > 0 ? 
                <Fragment>
                  <h4 className="sml_heading">Minutes Of Meeting</h4>
                  <table className="table table-hover minutes_meeting_table">
                    <thead>
                      <tr>
                        <th scope="col" width="60%">Description</th>
                        <th scope="col" width="20%" className="text-center">Created On</th>
                        {meetingDetails.can_edit &&
                          <th width="20%" className="text-center">Actions</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {minutesOfMeetingData.map((data) =>
                        <tr key={data.data.id}>
                          <td>
                            <span className="minutes_content">{data.data.description}</span>
                          </td>
                          <td className="text-center">{data.created_on_format}</td>
                          {meetingDetails.can_edit &&
                            <td className="actions" align="center">
                              <a className="action_edit" onClick={() => this.setState({ editMomId: data.data.id, errors: {}, minutesOfMeetingDesc: data.data.description, addMinutesOfMeeting: true })}>
                                <i className="fas fa-edit"></i>
                              </a>
                              <a className="action_delete" onClick={() => this.deleteMinutesOfMeeting(data.data.id)}>
                                <i className="fas fa-times"></i> 
                              </a>
                            </td>
                          }
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Fragment>
              : null}
            </div>
            {meetingDetails.can_edit &&
              <div className="minutes_meeting row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea 
                      className="form-control textarea" 
                      rows="8"
                      placeholder="Add Minutes Of Meeting"
                      value={minutesOfMeetingDesc}
                      onChange={(e) => this.setState({ minutesOfMeetingDesc: e.target.value })}
                    >
                    </textarea>
                    <div className="error-text"> {errors.description ? errors.description : ''}</div>
                  </div>
                  <div className="col-lg-12 no-padding">
                    <div className="text-right buttons_div">
                      <button type="button" className="btn primary_btn" onClick={() => this.addEditMinutesOfMeeting()}>Save Details</button>
                      <button type="button" className="btn secondary_btn" onClick={() => this.setState({ minutesOfMeetingDesc: '', editMomId: null, addMinutesOfMeeting: false, errors: {} })}>Cancel</button>
                    </div>
                  </div>  
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
