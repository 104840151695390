import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { fetch_get, WORKLOG, Spinner } from '../../common'
import DisplayScreenShots from './DisplayScreenShots'

export default class UserScreenshots extends Component {
  constructor (props) {
    super(props)
    this.state = {
      screenShot: '',
      screens: [],
      displayScreens: false,
      openModel: false,
      isLoading: false,
      next_page_number: 1,
      loadingMore: false,
      datesArray: [],
      currentDisplayDate: [this.props.filteredDate],
      nextDateIndex: 0,
      isUpdate: null,
      setOnce: true
    }
    this.changeDate = this.changeDate.bind(this)
  }

  componentDidMount () {
    if (this.props.filteredDate === this.props.toDate && !this.state.datesArray.length > 0) {
      this.setState({ datesArray: [this.props.filteredDate] })
      var startDate = this.props.filteredDate
      var endDate = this.props.toDate
    } else {
      const listDate = []
      // var startDate = this.props.filteredDate
      // var endDate = this.props.toDate
      const dateMove = new Date(startDate)
      let strDatee = startDate
      while (strDatee < endDate) {
        strDatee = dateMove.toISOString().slice(0, 10)
        listDate.push(strDatee)
        dateMove.setDate(dateMove.getDate() + 1)
        // strDatee = dateMove.toISOString().slice(0,10);
        // strDatee = dateMove
        // console.log('llist',dateMove)
      }
      this.setState({ datesArray: this.state.datesArray.concat(listDate), nextDateIndex: 1 })
    }
  }

  activeTime (seconds) {
    seconds = moment.duration(parseInt(seconds, 10), 'seconds')
    let format = Math.floor(seconds.asHours()) > 0 ? Math.floor(seconds.asHours()) + 'h ' : ''
    format += seconds.minutes() + 'm'
    return format
  }

  fetchScreens (loadingMore) {
    const page = this.state.next_page_number
    if (this.state.displayScreens && page) {
      this.setState({
        // displayScreens : !this.state.displayScreens,
        loadingMore,
        isLoading: !loadingMore
      })
      const { screen } = this.props
      fetch_get(`${WORKLOG}profile/${screen.profile.data.id}/project/${screen.project.data.id}/screens/?filtered_date=${this.props.filteredDate}&to_date=${this.props.toDate}&per_page=24&page=${page}`)
        .then((response) => {
          this.setState({
            screens: this.state.screens.concat(response.screenshots_dict.data),
            next_page_number: response.screenshots_dict.next_page_number,
            isLoading: false,
            loadingMore: false
          })
        })
    }
  }

  changeDate (date, openModel = false, screenShot = this.state.screenShot) {
    if (!this.state.currentDisplayDate.includes(this.state.datesArray[this.state.nextDateIndex])) {
      this.setState({ currentDisplayDate: this.state.currentDisplayDate.concat(this.state.datesArray[this.state.nextDateIndex]), nextDateIndex: this.state.nextDateIndex + 1, isUpdate: null })
    }
    if (openModel && !this.state.openModel) {
      this.setState({ openModel: true, screenShot })
    }
  }

  setFun () {
    this.setState({ isUpdate: this.state.datesArray[this.state.nextDateIndex], setOnce: false })
  }

  render () {
    const { screen } = this.props
    const { displayScreens, next_page_number } = this.state
    return (
      <div>
        <div className='row mar_lr_0 no-gutters-p5'>
          <div className='col-lg-4 p-0'>
            <button
              type='button'
              className='primary_btn'
              onClick={() => this.setState({ displayScreens: !this.state.displayScreens }, this.fetchScreens.bind(this))}
            >
              {screen.title ? screen.title : screen.profile.data.employee_name}
            </button>
          </div>
          <div className='col-lg-8 p-0'>
            <ul className='time_calculator screen_log'>
              <li><b>Active Time:</b> {this.activeTime(screen.screen_active_time)} </li>
              <li><b>Inactive Time:</b> {this.activeTime(screen.screen_total_inactive_time)} </li>
              <li><b>Total Time:</b> {this.activeTime(screen.screen_total_time)} </li>
            </ul>
          </div>
        </div>
        <br />
        {
          displayScreens &&
            <div className='row mar_lr_0'>
              {this.state.datesArray.length > 0 && this.state.datesArray.map((date) =>
                (this.state.currentDisplayDate.includes(date) &&
                  <DisplayScreenShots
                    screens={this.state.screens}
                    date={date}
                    id={this.props.id}
                    isLoading={this.state.isLoading}
                    key={date}
                    changeDate={this.changeDate}
                    currentDisplayDate={this.state.currentDisplayDate}
                    isUpdate={this.state.isUpdate}
                  />
                )
              )}
              {
              next_page_number > 1
                ? <div className='col-lg-12 p-0'>
                  {
                  this.state.loadingMore
                    ? <Spinner />
                    : <button type='button' className='btn primary_btn' onClick={() => this.fetchScreens(true)}>
                      View More
                      </button>
                }
                </div>
                : <>{(this.state.datesArray.length !== this.state.currentDisplayDate.length) && this.state.next_page_number === null ? this.state.setOnce ? this.setFun() : '' : ''}</>
            }
            </div>
        }
        <div className='overlay' style={{ display: this.state.openModel ? 'block' : 'none' }} onClick={() => this.setState({ openModel: false })} />
        <div className='slide_section screenshot_modal' style={{ display: this.state.openModel ? 'block' : 'none' }}>
          <div className='slide_content' style={{ display: this.state.openModel ? 'block' : 'none' }}>
            <div className='col-lg-12'>
              <h2 className='heading text-center'>{this.state.screenShot.time}
                <a className='float-right' onClick={() => this.setState({ openModel: false })}><i className='fa fa-times' /></a>
              </h2>
              <div className='detailed_content'>
                <div className='shot'>
                  <img src={this.state.screenShot.present} alt='' />
                </div>
                <span className='arrows float-right'>
                  {this.state.screenShot.index > 0
                    ? <a
                        className='left'
                        onClick={() => {
                          this.setState({
                            screenShot: {
                              present: this.state.screens[this.state.screenShot.index - 1].image_path,
                              index: this.state.screenShot.index - 1,
                              id: this.state.screenShot.id,
                              length: this.state.screenShot.length,
                              time: (this.props.toDate === this.props.filteredDate) ? this.state.screens[this.state.screenShot.index - 1].formatted_created_on : this.state.screens[this.state.screenShot.index - 1].formatted_created_on_date
                            }
                          })
                        }}
                      >
                      <i className='fas fa-caret-square-left' />
                      </a>
                    : null}
                  {this.state.screenShot.length > this.state.screenShot.index
                    ? <a
                        className='right float-right'
                        onClick={() => {
                          this.setState({
                            screenShot: {
                              present: this.state.screens[this.state.screenShot.index + 1].image_path,
                              index: this.state.screenShot.index + 1,
                              id: this.state.screenShot.id,
                              length: this.state.screenShot.length,
                              time: (this.props.toDate === this.props.filteredDate) ? this.state.screens[this.state.screenShot.index + 1].formatted_created_on : this.state.screens[this.state.screenShot.index - 1].formatted_created_on_date
                            }
                          })
                        }}
                      >
                      <i className='fas fa-caret-square-right' />
                      </a>
                    : null}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
