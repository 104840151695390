import React, { Component, Fragment } from 'react';
import {
  fetch_get,
  Context,
  // DOCUMENTS_LIST,
  PROJECTS_LIST,
  Modal
} from '../../common';
// import AddDocument from './AddDocument';
// import EditDocument from './EditDocument';
// import pdf from '../../../static/images/pdf.png';

export default class Documents extends Component {
  static contextType = Context;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      content:''
    };
  }

  componentDidMount() {
    // fetch_get(DOCUMENTS_LIST + this.props.match.params.pk + '/')
    // .then((response) => {
    //   this.setState({
    //     profile: response.profile.data,
    //     documentsList: response.documents.data
    //   });
    // })
    // .catch(() => {
    //   this.setState({ error: 'Something went wrong, Please try again' });
    // });
    if(this.props.location.state){
      localStorage.setItem('document_view',this.props.location.state.path)
      this.setState({content:localStorage.getItem('document_view')})
    }
    else{
      this.setState({content:localStorage.getItem('document_view')})
    }    
  }
  

  deleteDocument() {
    fetch_get(PROJECTS_LIST + `${this.props.match.params.id}/document/${this.props.match.params.did}/delete/`, true)
    .then((response) => {
      if (response.status === 204) {
        // this.fetchDocumentsList();
        this.setState({ displayDeleteModal: false })
        this.props.history.goBack()
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }  

  render() {    
    return (
      <Fragment>
        <div className="row mar_lr_0 documents_top_bar">
          <div className="col-lg-2">
            <a className="back"><i className="fas fa-arrow-left"></i> Documents </a>
          </div>
          <div className="col-lg-10">
           <span className="file_content">Client Work Requirements <small>16 Sep, 2020 at 11:05 4.85Mb</small></span>
            <div className="doc_actions">
              { this.props.location.state ? this.props.location.state.path ? <span><a className="trash" onClick={() => this.setState({ displayDeleteModal: true })} ><i className="far fa-trash-alt"></i></a></span> : null : null }
              { this.props.location.state ? this.props.location.state.path ? <span><a className="download" href={this.props.location.state ? this.props.location.state.path : null}><i className="fas fa-download"></i></a></span> : null : null }
            </div>
          </div>
        </div>
        <div className="row mar_lr_0 documents_view justify-content-center">
           <div className="col-lg-9 text-center" style={{marginTop : '4rem', position:'absolute',left:'270px'}}>
            {this.state.content.split('.').pop() == 'png' || this.state.content.split('.').pop() == 'jpg' || this.state.content.split('.').pop() == 'jpeg' ?
            <img src={this.state.content}  alt="document_image_view" width='1500px' height="850px" /> :  this.state.content.split('.').pop() == 'pdf' ? <embed src={this.state.content} type="application/pdf"   height="800px" width="1000"/> : null }
          </div>

        </div>
        <Modal
          display={this.state.displayDeleteModal}
          bodyText='Are you sure? You want to delete?'
          modalAction={() => this.deleteDocument()}
          removeModal={() => this.setState({ displayDeleteModal: false })}
        />        
      </Fragment>
    )
  }
}
