import React, { Component } from 'react'

export default class NotFoundPage extends Component {
  render () {
    return (
      <div>
        <center><h4>Page not found(404)</h4></center>
      </div>
    )
  }
}
