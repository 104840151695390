import React, { Component } from 'react'

export class Modal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      display: props.display
    }
    this.onPressEsc = this.onPressEsc.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    return (nextProps.display !== prevState.display) ? {
      display: nextProps.display
    } : null
  }

  // componentWillReceiveProps(nextProps) {
  //   if(this.state.display !== nextProps.display) {
  //     this.setState({ display: nextProps.display });
  //   }
  // }

  onPressEsc (event) {
    if (event.keyCode === 27) {
      this.props.removeModal()
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.onPressEsc, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.onPressEsc, false)
  }

  render () {
    if (!this.state.display) {
      return null
    }
    return (
      <div>
        <div className="overlay" onClick={this.props.removeModal}></div>
        <div className="slide_section modal">
          <div className="slide_content delete">
            <h2 className="heading">Confirmation
              <span className="float-right close pointer" onClick={this.props.removeModal}>
                <i className="fas fa-times-circle"/>
              </span>
            </h2>
            <div className='body'>
              <p>{this.props.bodyText}</p>
            </div>
            <div className='footer'>
              <div className='buttons text-right'>
                <button type='button' className='btn primary_btn' autoFocus onClick={this.props.modalAction}>Yes</button>
                <button type='button' className='btn secondary_btn' onClick={this.props.removeModal}>No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
