import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { fetch_get, fetch_post, PROJECTS_LIST, notify, Modal, Context } from '../../common'
import { Link } from 'react-router-dom';

export default class ProjectSettingsStatus extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      projectStatus: [],
      errors: {},
      showStatusPicker: false,
      editStatus: false,
      statusColor: '#ddd',
      loading: true
    };
    this.onDeleteStatus = this.onDeleteStatus.bind(this)
    this.nameRef = React.createRef();
  }

  componentDidMount() {
    this.fetchProjectStatus(this.props.projectId);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if(this.props !== prevProps) {
  //     this.fetchProjectStatus(this.props.projectId);
  //   }
  // }

  fetchProjectStatus(id) {
    fetch_get(PROJECTS_LIST + id + '/task-status/')
    .then((response) => {
      this.setState({ 
        projectStatus: response.data,
        loading: false
      })
      this.props.updateCount('status', response.data.length)
    })
  }

  editStatus(status) {
    this.setState({ 
      editStatus: true,
      statusColor: status.color,
      errors: {},
      statusId: status.id
    })
    this.nameRef.current.value = status.name
  }

  addEditStatus() {
    const data = new FormData();
    if(this.state.editStatus) {
      data.append('name', this.nameRef.current.value);
      data.append('color', this.state.statusColor);
      fetch_post(PROJECTS_LIST + this.props.projectId + '/task-status/' + this.state.statusId + '/update/', data)
      .then((response) => {
        if(response.error) {
          this.setState({ errors: response.errors })
        } else {
          notify("Status Edited successfully");
          this.clear();
          this.fetchProjectStatus(this.props.projectId);
        }
      })
    } else {
      data.append('name', this.nameRef.current.value);
      data.append('color', this.state.statusColor);
      fetch_post(PROJECTS_LIST + this.props.projectId + '/task-status/create/', data)
      .then((response) => {
        if(response.error) {
          this.setState({ errors: response.errors })
        } else {
          notify("Status Added successfully");
          this.clear()
          this.fetchProjectStatus(this.props.projectId)
        }
      })
    }
  }

  handleChangeComplete(color){
    this.setState({ statusColor: color.hex })
  }

  onDeleteStatus() {
    fetch_get(PROJECTS_LIST + this.props.projectId + '/task-status/' + this.state.statusId + '/delete/', true)
    .then(response => {
      if(response.status === 204) {
        this.setState({ displayDeleteModal: false })
        notify("Status Deleted successfully");
        this.clear();
        this.fetchProjectStatus(this.props.projectId)
      }
    })
  }

  clear() {
    this.setState({
      statusColor: '#ddd',
      editStatus: false,
      errors: {}
    })
    this.nameRef.current.value = "";
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.addEditStatus();
    }
  }

  render() {
    const { projectStatus } = this.state;
    return (
      <div className="row priority_content tab-content">
        <div className="col-lg-8 pl-0">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Color</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {projectStatus.length > 0 ? projectStatus.map((status,index) =>
                <tr key={status.id}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={{ pathname: `${this.context.company}/pm/${this.props.projectId}/tasks`, state: {projectStatusDetails: status, projectStatusIndex: index }}}>
                      {status.name}
                    </Link>
                  </td>
                  <td>
                    <div style={{color: status.color}}><i className="fas fa-square"></i></div>
                  </td>
                  <td className="actions" align="center">
                    <a className="action_edit" onClick={() => {this.editStatus(status)}}><i className="fa fa-edit"></i> </a>
                    <a className="action_delete" onClick={() => this.setState({ displayDeleteModal: true, statusId: status.id})}><i className="fa fa-trash"></i> </a>
                  </td>
                </tr>
                )
              : 
                <tr>
                  <td>{this.state.loading ? 'Loading ...' : 'No Status Found'}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <div className="col-lg-4">
          <h2 className="heading">{this.state.editStatus ? 'Edit Status' : 'Add Status'}</h2>
           <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Name<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                  <input 
                    type="text" 
                    className="form-control" 
                    ref={this.nameRef}
                    onKeyPress={this.handleKeyPress}  
                  />
                  <div className="error-text">
                    {this.state.errors.name ? this.state.errors.name : null }
                  </div>
                </div>
              </div>
               <div className="col-lg-12">
                <div className="form-group">
                  <label>Color</label>
                  <div className="color_pick">
                    <span
                      style={{ color: this.state.statusColor }} 
                      onClick={() => this.setState({ showStatusPicker: !this.state.showStatusPicker })}
                      onBlur={() => this.setState({ showStatusPicker: false })} 
                    >
                      <i className="fas fa-square"></i>
                    </span>
                  </div>
                </div>
                {this.state.showStatusPicker &&
                  <div style={{position: 'absolute', zIndex: '9999999999'}}>
                    <div
                      style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                      onClick={() => this.setState({ showStatusPicker: false })}
                    />
                    <SketchPicker
                      color={ this.state.statusColor }
                      onChangeComplete={ this.handleChangeComplete.bind(this) }
                    /> 
                  </div>
                }
              </div>
              <div className="col-lg-12 buttons text-left mt-5">
                <button type="button" className="primary_btn" onClick={() => {this.addEditStatus()}}>Save</button>
                <button type="button" className="secondary_btn" onClick={() => {this.clear()}}>Cancel</button>
              </div>
            </div>
          </form>
        </div>
        <Modal
          display={this.state.displayDeleteModal}
          bodyText='Are you sure? You want to delete?'
          modalAction={this.onDeleteStatus}
          removeModal={() => this.setState({ displayDeleteModal: false })}
        />
      </div>
    );
  }
}