import { DOMAIN_NAME } from './apiUrls'
import { showAlert, removeAlert, notify } from './notify'
export const fetch_get = (url, status = false, alert = false) => {
  alert && showAlert('Loading ...')
  return fetch(DOMAIN_NAME + url, {
    method: 'GET',
    headers: {
      Authorization: 'Token ' + localStorage.getItem('token'),
      company: localStorage.getItem('company')
    }
  })
    .then(response => {
      alert && removeAlert()
      if (response.status === 401) {
        localStorage.clear()
        window.location = '/signin'
      }

    if(response.status === 403) {                                                                 
      notify('You DO not access to this page', true)
      return response.json()
    }
    else if(status) {
      return response
    } else {
      return response.json()
    }
  })
  .catch(() => {
    notify('Server busy, Please try again.', true)
  });
}

export const fetch_post = (url, data, status = false, alert = false) => {
  alert && showAlert('Loading ...')
  const button = document.getElementsByTagName('button')
  for (let i = 0; i < button.length; i++) {
    button[i].disabled = true
    button[i].style.cursor = 'not-allowed'
  }
  return fetch(DOMAIN_NAME + url, {
    method: 'post',
    headers: {
      Authorization: 'Token ' + localStorage.getItem('token'),
      company: localStorage.getItem('company')
    },
    body: data
  })
    .then(response => {
      alert && removeAlert()
      for (let i = 0; i < button.length; i++) {
        button[i].disabled = false
        button[i].style.cursor = 'pointer'
      }
      if (response.status === 403) {
        notify('You DO not access to this page', true)
        return {}
      }
      if (response.status === 401) {
        localStorage.clear()
        window.location = '/signin'
        return {}
      }
      if (status) {
        return response
      } else {
        return response.json()
      }
    })
    .catch(() => {
      notify('Server busy, Please try again.', true)
    })
}
