import React, { Fragment } from 'react';
import moment from 'moment';

export default class Message extends React.Component{
  state = {
    data: [],
    message: null,
    openModel: false,
    imgUrl: ''
  }
  componentDidMount() {
    this.props.calculateEachMessageHeight(this.message.clientHeight);
  }

  getuser() {
    var userImage = '', newUser = ''
    this.props.users.map((user) => {
      newUser = (user.userid === this.props.messageText.by) ? user : null;
      if(newUser) {
        userImage = <h4>{newUser.employee_name}</h4>
      }
    })
    return userImage
  }

  getuserPic() {
    var userImage = '', newUser = ''
    this.props.users.map((user) => {
      newUser = (user.userid === this.props.messageText.by) ? user : null;
      if(newUser) {
        if(newUser.has_profile_pic) {
          userImage = <img className="message_inner_pro" src={newUser.profile_img} />
        } else {
          userImage = <span className="message_inner_pro">{newUser.profile_text && newUser.profile_text[0]}  </span>
        }
      }
    })
    return userImage
  }

  renderMessage(msg){
    if(this.props.messageText.mime_type) {
      var url = 'https://chat.pietrack.com/get_file/' + this.props.messageText.hash
      if(this.props.messageText.mime_type.split('/')[0] === 'image') {
        if(this.props.messageText.mime_type.split('/')[1] === 'png' || this.props.messageText.mime_type.split('/')[1] === 'jpeg') {
          return <img height="300px" src={url} onClick={() => this.setState({ imgUrl: url, openModel: true })}/>
        }
         else {
          return <a href={url} download>{this.props.messageText.m}</a>
        } 
      }else if(this.props.messageText.mime_type.split('/')[0] === 'video') {
        return(
          <video controls width="250">
            <source src={url} type={this.props.messageText.mime_type} />
          </video>
        )
      } else if(this.props.messageText.mime_type.split('/')[0] === 'audio') {
        return(
          <audio controls>
            <source src={url} type={this.props.messageText.mime_type} />
          </audio>
        )
      } else {
        return <a href={url} target="_blank" rel="noreferrer" download>{this.props.messageText.file_name}</a>
      } 
    } else {
      var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
      var mentionRegex = /\B@[a-z0-9_.-]+/gi;
      if(msg.startsWith('*') && msg.endsWith('*') && msg.length>=3) {
        return <p><strong style={{ marginRight: '5px' }}>{msg.substr(1,msg.length-2)}</strong></p>
      } else if(msg.startsWith('_') && msg.endsWith('_') && msg.length>=3) {
        return <p><em style={{ marginRight: '5px' }}>{msg.substr(1,msg.length-2)}</em></p>
      } else if(msg.startsWith('!') && msg.endsWith('!') && msg.length>=3) {
        return <p><i style={{ marginRight: '5px' }}>{msg.substr(1,msg.length-2)}</i></p>
      } else if(msg.startsWith('```') && msg.endsWith('```') && msg.length>=3) {
        return <p><pre style={{ marginRight: '5px' }}>{msg.substr(3,msg.length-6)}</pre></p>
      }
      else {
      var renderMsg =  msg.split(' ').map((w)=>{
        if(w.startsWith('*') && w.endsWith('*') && w.length>=3){
           w=w.substr(1,w.length-2);
           w=<strong style={{ marginRight: '5px' }}>{w}</strong>;
         }else if(w.startsWith('_') && w.endsWith('_') && w.length>=3){
          w=w.substr(1,w.length-2);
          w=<em>{w}</em>;
        }else if(w.startsWith('!') && w.endsWith('!') && w.length>=3){
          w=w.substr(1,w.length-2);
          w=<i>{w}</i>;
        }else if(w.startsWith('```') && w.endsWith('```') && w.length>=3){
          w=w.substr(3,w.length-6);
          w=<pre>{w}</pre>;
        } else if(w.match(urlRegex)) {
          w=<a target="_blank" rel="noreferrer" href={w}>{w}</a>;
        }else if(w.match(mentionRegex)) {
          w= <span className="mention_name">{w}</span>;
        }
        return w;
      })
      return <p>{renderMsg.map((x, index) => <span key={index} className="showSpaces"> {x} </span>)}</p>

      }
    }
  }

  getDate(presentDate) {
    // console.log(this.props.lastMsgId, this.props.messageText._id)
    if(moment(this.props.previousMsgDate).format('MM-DD-YYYY') !== moment(presentDate).format('MM-DD-YYYY') && this.props.messageText.by !== 'Admin'){
      return <li className="date_styles">
        <div>{moment(presentDate).format('MMM D, YYYY')}</div>
        <hr></hr>
        </li>
    } else if(this.props.lastMsgId === this.props.messageText._id){
      return <li className="date_styles">
        <div>{moment(presentDate).format('MMM D, YYYY')}</div>
        <hr></hr>
        </li>
    }
  }

  render(){
    const formattedTime = moment(this.props.messageText.at).format('h:mm a');
    // console.log(this.props.previousMsgDate, 'previousMsgDate', this.props.index)
    return (
      <Fragment>
        {this.getDate(this.props.messageText.at)}
        <li className="message" ref={(el) => { this.message = el; }}>
          <div className="message__title">
            <div>{this.getuserPic()}</div>
            {this.props.messageText.by === 'Admin' ? <h4>Admin</h4> : this.getuser()}
            <span>{formattedTime}</span>
          </div>
          {this.props.messageText.m &&
            <div className="message__body" style={{ paddingLeft: '45px'}}>
              {this.renderMessage(this.props.messageText.m)}
            </div>
          }
        </li>
          <div className="overlay" style={{ display: this.state.openModel ? 'block' : 'none' }} onClick={() => this.setState({ openModel: false })}></div>
        {this.state.openModel &&

          <div className="slide_section screenshot_modal chat-board-image" style={{ display: this.state.openModel ? 'block' : 'none' }}>
            <div className="slide_content2" style={{ display: this.state.openModel ? 'block' : 'none' }}>
              <div className="col-lg-12 p-0">
                <div className="detailed_content">
                  <div className="shot">
                    <img src={this.state.imgUrl} alt="" width="100%"/>
                  </div>
                </div>  
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
  }
}
