import React, { Component, Fragment } from 'react';
import { fetch_post, APP_NAME, Context, fetch_get, LOGOUT } from './common';
import mtimages from '../static/images/mt-images.png';

class Dashboard extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
      loading: false,
      company: '',
      logoutButton: false
    };
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true })
    document.body.classList.add("introduction_body");
    if(localStorage.getItem('token')) {
      this.getCompanies()
    }
    else {
      this.props.history.push('/signin')
    }
  }

  getCompanies() {
    let formData = new FormData()
    formData.append('company_name', this.state.company);
    fetch_post('/auth/user-sub-domains/', formData)
    .then((response) => {
      if(response) {
        if(response.status == 401){
          localStorage.clear()
          window.location = '/signin';
        }else{
        this.setState({ profiles: response.profiles.data, loading: false })          
        }
      }
    })
    .catch((error) => console.log(error))
  }

  componentWillUnmount() {
    document.body.classList.remove("introduction_body");
  }

  renderIcon(module) {
    if(module === 'HCM') {
      return <i className="fas fa-users"></i>
    } else if(module === 'PM'){
      return <i className="fas fa-cogs"></i>
    } else if(module === 'Sales') {
      return <i className="fas fa-dollar-sign"></i>
    } else {
      return <i className="fas fa-bullseye"></i>
    }
  }

  redirectToModule(code, profile) {
    localStorage.setItem('company', profile.company_data.data.id);
    localStorage.setItem('CompanyName', profile.company_data.data.subdomain);
    this.context.setData(profile)
    if(code === 'HCM') {
      this.props.history.push(APP_NAME + "/" + profile.company_data.data.id + "/hr/")
    } else if(code === 'PM') {
      this.props.history.push("/" + profile.company_data.data.id + "/pm/")
    } else if(code === 'SALES') {
      this.props.history.push("/" + profile.company_data.data.id + "/sales/")
    } else if(code === 'MARKETING') {
      this.props.history.push("/" + profile.company_data.data.id + "/marketing/")
    }
  }

  onClickAdmin(profile) {
    localStorage.setItem('company', profile.company_data.data.id);
    localStorage.setItem('CompanyName', profile.company_data.data.subdomain);
    this.context.setData(profile); 
    this.props.history.push("/" + profile.company_data.data.id + "/admin/")
  }

  logout() {
    fetch_get(LOGOUT)
    .then((response) => {
      if(response) {
        if(!response.error){
          localStorage.clear()
          window.location = '/signin';
        }
      }
    })
    .catch((error) => console.log(error))
  }

  render() {
    const { profile_pic } = this.context;
    const { profiles } = this.state;
    return (
      <div className="wrapper row justify-content-center no-gutters" onClick={() => this.state.logoutButton && this.setState({ logoutButton: false })}>
        <div className="user_tool intlo_logout">
          <div className="col-lg-1 form-group relative">         
            <div className="form-group mb-3 dropdown">
              <div className="toggle" onClick={() => this.setState({ logoutButton: !this.state.logoutButton })}><span className="menu_text"><img alt="" src={profile_pic ? profile_pic : mtimages} width="100%"/></span></div>
              <ul className="menu" style={{ display: this.state.logoutButton ? 'block' : 'none' }}>
                <li className="submenu">
                  <a onClick={() => this.logout()}>Logout</a>       
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-xl-12 introduction_container">
          <div className="row justify-content-center marl" style={{backgroundColor:"#f1f1f1"}}>
            <div className="col-lg-11">
              <div className="intro_container">
                <div className="intro_search ">
                  <div className="input-group mb-3">
                    <input 
                      type="text" 
                      className="border-0 form-control" 
                      placeholder="Search by Company Name" 
                      aria-label="Select User" 
                      aria-describedby="button-addon2" 
                      value={this.state.company}
                      onChange={(e) => this.setState({ company: e.target.value}, this.getCompanies)}
                    />
                    <div className="input-group-append">
                      <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="fas fa-search"></i></button>
                    </div>
                  </div>
                </div>
                <div className="row marl justify-content-center">
                  {profiles.length > 0 ? profiles.map((profile) => 
                    <div className="col-lg-3" key={profile.id}>
                      <div className="project_domain">
                        <div className="content">
                          <div className="card_pro">
                            <div className="image">
                              <img src={profile.company_data.data.logo_path} />
                            </div>
                            <h4>{profile.company_data.data.name}</h4>
                            <span>Created on : {profile.company_data.data.created_on.split('T')[0]} </span>
                          </div>
                          <ul>
                            {profile.is_organization_admin &&
                              <li>
                                <a onClick={() => this.onClickAdmin(profile)}>
                                <i className="fas fa-user"></i>
                                <b>Admin</b>
                                </a>
                              </li>
                            }
                            {profile.allowed_modules.data.length > 0 && profile.allowed_modules.data.map((module, index) => 
                              <Fragment key={index}>
                                {(module.name !== 'Seo' && module.name !== 'Marketing') &&
                                <li key={module.id}>
                                  <a onClick={() =>  this.redirectToModule(module.code, profile)}>
                                  {this.renderIcon(module.menu)}
                                  <b>{module.name == "Human Capital Management" ? "HR Management" : module.name }</b>
                                  </a>
                                </li>
                                }
                              </Fragment>
                            )}
                          </ul>
                        </div>
                        <div className="url">
                          <a href={profile.company_data.data.website}>{profile.company_data.data.website}</a>
                        </div>
                      </div>
                    </div>
                  ) : 
                  <div>{this.state.loading ? 'Loading ...' : 'No companies found'}</div>
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Dashboard;
