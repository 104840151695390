import React from 'react';
import InputTrigger from 'react-input-trigger';
import { Context } from '../../common';

const utilizeFocus = () => {
  const ref = React.createRef()
  const setFocus = () => {ref.current &&  ref.current.focus()}
  return {setFocus, ref} 
}

String.prototype.splice = function(index,del,...newStrs){
  let str = this.split('');
  str.splice(index,del,newStrs.join('') || '');
  return str.join('');
}

export default class SendMessage extends React.Component{
  static contextType = Context;
  constructor(props) {
    super(props)
    this.state = {
      error: undefined,
      text: null,
      currentSelection: 0,
      textareaValue: '',
      users: [],
      allUsers: []
    }
    this.toggleSuggestor = this.toggleSuggestor.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.input1Focus = utilizeFocus()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return ((prevState.allUsers !== nextProps.users)) ? {
      allUsers: nextProps.users, users: nextProps.users 
    } : null
  }

  toggleSuggestor(metaInformation) {
    const { hookType, cursor } = metaInformation;

    if (hookType === 'start') {
      this.setState({
        showSuggestor: true,
        left: cursor.left,
        top: cursor.bottom + cursor.height, // we need to add the cursor height so that the dropdown doesn't overlap with the `@`.
      });
    }
    
    if (hookType === 'cancel') {
      // reset the state

      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        users: this.props.users
      });
    }
  }

  handleInput(metaInformation) {
    let value = metaInformation.text.split(" ")[0]
    //this.setState({ labelSearchText: value });
    var filterUsers = this.props.users.filter(function (user) {
      return (user.employee_name.toLowerCase().includes(value.toLowerCase()));
    });
    this.setState({ users: filterUsers, text: value })
  }

  fileAppend(e) {
    // let data = new FormData()
    // data.append('uploadFile', e.target.files[0])
    // fetch('http://localhost:8080/ciu/',{
    //   method: 'POST',
    //   headers: {
    //     'Authorization': 'Token ' + localStorage.getItem('token'),
    //     'company': localStorage.getItem('company'),
    //     'ChatRoom': this.context.room
    //   },
    //   body: data
    // })
    // .then((response) => { 
    //   console.log(response)
    // })
    this.props.handleFileUpload(e.target.files[0], e)
  }

  handleKeyDown(event, onClick=false, name) {
    const { currentSelection, users } = this.state;
    
    if (event && event.keyCode === 40 ) { // 40 is the character code of the down arrow
      //...
      event.preventDefault();
      
      this.setState({
        currentSelection: (currentSelection + 1) % users.length,
      });
    }
    if (event && event.keyCode === 13 || onClick) { // 13 is the character code for enter
      //event.preventDefault();
      const { startPosition, textareaValue } = this.state;
      // const user = users[currentSelection];
      // const newText2 = `${textareaValue.slice(0, startPosition - 1)}@${name}${textareaValue.slice(startPosition + name.length, textareaValue.length)}`
      // const newText = `${textareaValue.slice(0, startPosition - 1)} @${name}`
      // console.log(startPosition, 'currentselection', this.state.text ? this.state.text.length : 0)
      // let val = 
      // console.log(val, 'value', name, startPosition)
      // reset the state and set new text

      this.setState({
        showSuggestor: false,
        users: this.props.users,
        left: null,
        top: null,
        startPosition: null,
        textareaValue: textareaValue.splice(startPosition-(this.state.text ? this.state.text.length : 0), this.state.text ? this.state.text.length : 0, name),
        text: null,
      });
      this.input1Focus.setFocus() 
      this.endHandler();
    }
  }

  handleTextareaInput(event) {
    // console.log(event.key, event.keyCode)
    const { value } = event.target;
    this.setState({
      textareaValue: value, 
      //showSuggestor: false,
      startPosition: event.target.selectionStart  
    })
  }

  keyPressed(e) {
    // console.log()
    //if(e.keyCode === 8) {}
    if(e.key === 'Enter') {
      const error = this.props.handleAddMessage(this.state.textareaValue);
      if(error) {
        this.setState(()=> ({ error }));
      } else {
        this.setState({ textareaValue: ''  })
      }
    }
  }

  onFormSubmit = (event) => {
    event.preventDefault();
    const error = this.props.handleAddMessage(this.state.textareaValue);
    if(error) {
      this.setState(()=> ({ error }));
    } else {
      this.setState({ textareaValue: ''  })
    }
  };

  // onKeyDown(e) {
  //   console.log(e.key, e.code)
  //   if(e.key === 'Backspace') {
  //     let textLen = this.state.text ? this.state.text.length : 0
  //     console.log(this.state.textareaValue.substring(this.state.startPosition-5, this.state.startPosition))

  //   }
  // }

  render(){
    // console.log(this.state.users)
    return (
      <div className="chat__footer">
        <form id="message-form" onSubmit={this.onFormSubmit}>
          <div
        style={{
          position: 'relative',
          width: '100%'      
        }}
      >
        <InputTrigger
          trigger={{
            keyCode: 50,
            shiftKey: true,
          }}
          onStart={(metaData) => { this.toggleSuggestor(metaData); }}
          onCancel={(metaData) => { this.toggleSuggestor(metaData); }}
          onType={(metaData) => { this.handleInput(metaData); }}
          endTrigger={(endHandler) => { this.endHandler = endHandler; }}
        >
          <textarea
            style={{
              height: '70px',
              width: '100%',
              lineHeight: '1em',
            }}
            onChange={this.handleTextareaInput.bind(this)}
            value={this.state.textareaValue}
            ref={this.input1Focus.ref}
            onKeyPress={this.keyPressed.bind(this)}
            // onKeyDown={this.onKeyDown.bind(this)}
          />
        </InputTrigger>

        <div
          id="dropdown"
          className="chatUsers"
          style={{
            display: this.state.showSuggestor ? "block" : "none",
            bottom: this.state.top,
            left: this.state.left,
          }}
        >
          {
            this.state.users.length > 0 && this.state.users
            //.filter(user => user.employee_name[0] === this.state.text)
            .map((user, index) => (
              <div
                key={index}
                style={{
                  padding: '10px 20px',
                  background: index === this.state.currentSelection ? '#eee' : ''
                }}
                onClick={() => this.handleKeyDown(undefined, true, user.employee_name.split(" ")[0])}
              >
                { user.employee_name.split(" ")[0] }
              </div>
            ))  
          }
        </div>
      </div>
          <span className="head">
            <label className="float-right add_project" htmlFor="chatFile" style={{ marginBottom: '0px'}} ><i className="fa fa-paperclip"></i></label>
            <input type="file" id="chatFile" style={{ display: 'none' }} onChange={this.fileAppend.bind(this)}/>
          </span>
          <button className="primary_btn">Send</button>
        </form>
        {/*<Geolocation
          sendLocation={this.props.sendLocation}
          locationButtonText = {this.props.locationButtonText}
        />
      */}
      </div>
    );
  }
}
