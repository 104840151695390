import React from 'react'
import PropTypes from 'prop-types'
import loadScript from 'load-script'
// const loadScript = require('load-script');

const defaultScriptUrl = 'https://cdn.ckeditor.com/4.10.1/standard-all/ckeditor.js'
/**
 * @author codeslayer1
 * @description CKEditor component to render a CKEditor textarea with defined configs and all CKEditor events handler
 */
let commentUsers = []
let isUser = false
class CKEditorMarketing extends React.Component {
  constructor (props) {
    super(props)
    // Bindings
    this.onLoad = this.onLoad.bind(this)
    this.updateChanges = this.updateChanges.bind(this)
    // State initialization
    this.state = {
      isScriptLoaded: this.props.isScriptLoaded,
      config: this.props.config,
      content: this.props.content,
      commentUsers: this.props.commentUsers ? this.props.commentUsers : []
    }
    isUser = this.props.isUser || false
  }

  // load ckeditor script as soon as component mounts if not already loaded
  componentDidMount () {
    // if(window.CKEDITOR) {
    //   Object.keys(window.CKEDITOR.instances).forEach((obj) => {
    //     window.CKEDITOR.instances[obj].destroy()
    //   })
    // }
    if (!this.state.isScriptLoaded) {
      loadScript(this.props.scriptUrl, this.onLoad)
      commentUsers = this.state.commentUsers
    } else {
      // this.onLoad();
      this.updateChanges(this.state.content)
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if ((nextProps.commentUsers && nextProps.commentUsers.length) !== (prevState.commentUsers && prevState.commentUsers.length)) {
      return { commentUsers: nextProps.commentUsers }
    }
    return (nextProps.editCKEditor) ? {
      content: nextProps.content
    } : null
  }

  componentDidUpdate () {
    if (this.state.commentUsers) {
      if (this.state.commentUsers.length !== commentUsers.length) {
        this.updateCommentUsers()
      }
    }
    if (this.props.editCKEditor) {
      this.updateChanges(this.props.content)
    }
  }

  updateCommentUsers () {
    commentUsers = this.state.commentUsers
  }

  // componentWillReceiveProps(nextProps) {
  //   if(nextProps.editCKEditor && this.state.isScriptLoaded){
  //     this.setState({ content: nextProps.content })
  //     this.updateChanges(nextProps.content)
  //   }
  // }

  updateChanges (ckbody) {
    if (this.state.isScriptLoaded) {
      this.editorInstance.setData(ckbody)
    }
  }

  componentWillUnmount () {
    this.unmounting = true
  }

  onLoad () {
    if (this.unmounting) return
    commentUsers = this.state.commentUsers

    if (!window.CKEDITOR) {
      console.error('CKEditor not found')
      return
    }
    if (!this.state.isScriptLoaded) {
      this.editorInstance = window.CKEDITOR.replace(
        document.getElementById('content'),
        {
          plugins: 'mentions,basicstyles,undo,link,wysiwygarea,toolbar,clipboard,sourcedialog,maximize,image',
          toolbar: [
            { name: 'document', items: ['Sourcedialog'] },
            { name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', 'NumberedList', 'BulletedList'] },
            { name: 'tools', items: ['Maximize'] },
            { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
            { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
            '/'
          ],
          contentsCss: [
            'https://cdn.ckeditor.com/4.10.1/full-all/contents.css',
            'https://sdk.ckeditor.com/samples/assets/mentions/contents.css',
            '../../public/css/main.css'
          ],
          height: 150,
          removeDialogTabs: 'image:advanced;link:advanced;link:target',
          mentions: [
            {
              feed: dataFeed,
              marker: '@',
              itemTemplate: '<li data-id="{id}"><img className="photo" style=width:30px src="{profile_img}" /><span class={profileImageClass}>{profile_text}</span> {nickname}</li>',
              outputTemplate: '<a href="mailto:{nickname}">@{nickname}</a><span>&nbsp;</span>',
              minChars: 0
            }
          ],
          removePlugins: 'elementspath',
          resize_enabled: true,
          resize_maxWidth: 5000,
          resize_maxHeight: 5000,
          resize_dir: 'both'
        }
      )
      this.editorInstance.setData(this.state.content)
      for (const event in this.props.events) {
        const eventHandler = this.props.events[event]

        this.editorInstance.on(event, eventHandler)
      }
    }
    this.setState({
      isScriptLoaded: true
    })
  }

  render () {
    return <div className={this.props.activeClass} />
  }
}

function getMatchObject (item) {
  const matchProperty = 'nickname'
  if (isUser) {
    return item[matchProperty]
  }
  return item.user_details.data[matchProperty]
}

function dataFeed (opts, callback) {
  if (commentUsers && commentUsers.length > 0) {
    let data = commentUsers.filter((item) => {
      const name = getMatchObject(item)
      return name.indexOf(opts.query.toLowerCase()) === 0
    })
    data = data.sort((a, b) => {
      a.nickname = getMatchObject(a)
      b.nickname = getMatchObject(b)
      a.profileImageClass = classNameEntry(a.has_profile_pic)
      b.profileImageClass = classNameEntry(b.has_profile_pic)
      return getMatchObject(a).localeCompare(getMatchObject(b), undefined, { sensitivity: 'accent' })
    })
    callback(data)
  }
}

function classNameEntry (value) {
  if (value) {
    return ''
  } else {
    return 'profile_name'
  }
}

CKEditorMarketing.defaultProps = {
  content: '',
  config: {},
  isScriptLoaded: false,
  scriptUrl: defaultScriptUrl,
  activeClass: '',
  events: {}
}

CKEditorMarketing.propTypes = {
  content: PropTypes.any,
  config: PropTypes.object,
  isScriptLoaded: PropTypes.bool,
  scriptUrl: PropTypes.string,
  activeClass: PropTypes.string,
  events: PropTypes.object
}

export { CKEditorMarketing }
