import React, { Component } from 'react';
import AddEditMilestone from './AddEditMilestone';
import AddTasks from '../tasks/AddTasks';
import {
  fetch_get,
  PROJECTS_LIST,
  Pagination,
  notify,
  Modal,
  Context,
  Spinner,
} from '../../common';
import { Link } from 'react-router-dom';

let milestonesData = {};
let pagination = {}
export default class MilestonesList extends Component { 
  static contextType = Context;
   _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      milestoneStatus: 'open',
      openMilestones: [],
      closedMilestones: [],
      allMilestones: [],
      currentPage: 1,
      loading: true,
      openMilestonesPages: { number: null, numOfPages: null},
      closedMilestonesPages: { number: null, numOfPages: null},
      allMilestonesPages: { number: null, numOfPages: null},
      milestonesCount: { allCount: 0, openCount: 0, closedCount: 0},
      addMilestone: false,
      editMilestoneId: null,
      milestoneId: null,
      closedMilestoneId: null,
      displayCloseTask: false,
      addTask: false,
      addTaskMilestone: { name: '', id: null },
    };
    this.activeTab = this.activeTab.bind(this);
    this.addTaskOrMilestone = this.addTaskOrMilestone.bind(this);
    this.closeMilestone = this.closeMilestone.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchOpenedMilestones();
    this.fetchAllMilestones();
    this.fetchClosedMilestones();
  }

  fetchAllMilestones(page=1) {
    fetch_get(PROJECTS_LIST + this.props.match.params.id + '/milestones/?page=' + page)
    .then((response) => {
      if(response.milestones && this._isMounted) {
        this.setState({
          allMilestones: response.milestones.data,
          allMilestonesPages: { number: response.milestones.number, numOfPages: response.milestones.num_pages },
          milestonesCount: { allCount: response.all_count, openCount: response.opened_count, closedCount: response.finished_count}
        })
      }
    });
  }

  fetchOpenedMilestones(page=1) {
    fetch_get(PROJECTS_LIST + this.props.match.params.id + '/milestones/?status=opened&page='+ page)
    .then((response) => {
      if(response.milestones && this._isMounted) {
        this.setState({
          openMilestones: response.milestones.data,
          openMilestonesPages: { number: response.milestones.number, numOfPages: response.milestones.num_pages },
          milestonesCount: { allCount: response.all_count, openCount: response.opened_count, closedCount: response.finished_count},
          loading: false
        })
      }
      milestonesData = response.milestones.data;
    });
  }

  fetchClosedMilestones(page=1) {
    fetch_get(PROJECTS_LIST + this.props.match.params.id + '/milestones/?status=closed&page='+ page)
    .then((response) => {
      if(response.milestones && this._isMounted) {
        this.setState({
        closedMilestones: response.milestones.data,
        closedMilestonesPages: { number: response.milestones.number, numOfPages: response.milestones.num_pages },
        milestonesCount: { allCount: response.all_count, openCount: response.opened_count, closedCount: response.finished_count},
        })
      }
    });
  }

  activeTab(value) {
    milestonesData = {};
    switch (this.state.milestoneStatus) {
      case 'open':
        milestonesData = this.state.openMilestones;
        pagination = this.state.openMilestonesPages;
        break;
      case 'closed':
        milestonesData = this.state.closedMilestones;
        pagination = this.state.closedMilestonesPages;
        break;
      case 'all':
        milestonesData = this.state.allMilestones;
        pagination = this.state.allMilestonesPages;
        break;
      default :
    }
    return (this.state.milestoneStatus === value ?  ' active' : '');
  }

  closeMilestone(milestoneId) {
    fetch_get(PROJECTS_LIST + this.props.match.params.id + '/milestones/' + milestoneId+ '/close/')
    .then(response => {
      notify(response.response);
      this.context.updateCount('true');
      this.fetchOpenedMilestones();
      this.fetchClosedMilestones();
      this.fetchAllMilestones();
      this.setState({ displayCloseTask: false, closedMilestoneId: null })
    })
  }

  getCurrentPage(currentPage) {
    switch (this.state.milestoneStatus) {
      case 'open':
        this.fetchOpenedMilestones(currentPage);
        break;
      case 'closed':
        this.fetchClosedMilestones(currentPage);
        break;
      case 'all':
        this.fetchAllMilestones(currentPage);
        break;
      default :
    }
  }

  addTaskOrMilestone() {
    this.fetchOpenedMilestones(this.state.openMilestonesPages.number);
    this.fetchClosedMilestones(this.state.closedMilestonesPages.number);
    this.fetchAllMilestones(this.state.allMilestonesPages.number);
    this.context.updateCount('true');
    this.setState({ addMilestone: false, editMilestoneId: null, addTask: false, addTaskMilestone: { name: '', id: null } })
  }

  onCancel() {
    this.setState({ addMilestone: false, editMilestoneId: null, addTask: false, addTaskMilestone: { name: '', id: null } }) 
  }

  overlayControl() {
    this.setState({ addMilestone: false, addTask: false })
    this.props.history.push(`${this.context.company}/pm/${this.props.match.params.id}/milestones`)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return(
      <div className="secondary_block">
        <div className="row mar_lr_0 table_height ">
          <div className="row tabs_block mar_lr_0 tasks_tab_block">
          <div className="col-lg-5">
            <h2 className="heading mt-0">Milestones</h2>
          </div>
          <div className="col-lg-7">
            <div className="tabs mt-4">
              <a className={'tab all' + this.activeTab('all') + ' br-0'} onClick={() => this.setState({ milestoneStatus: 'all', currentPage: 1 })}>
                <strong>{this.state.milestonesCount ? this.state.milestonesCount.allCount : ''} </strong> 
                <span>All</span>
              </a>
              <a className={'tab open' + this.activeTab('open')} onClick={() => this.setState({ milestoneStatus: 'open', currentPage: 1 })}>
                <strong>{this.state.milestonesCount ? this.state.milestonesCount.openCount : ''} </strong> 
                <span>Open</span>
              </a>
              <a className={'tab closed' + this.activeTab('closed') + ' closed'} onClick={() => this.setState({ milestoneStatus: 'closed', currentPage: 1 })}>
                <strong>{this.state.milestonesCount ? this.state.milestonesCount.closedCount : ''} </strong> 
                <span>Closed</span>
              </a>
              <a className="add_project" onClick={() => this.setState({ addMilestone: true })}><i className="fas fa-plus"></i></a>
            </div>
          </div>
          </div>
          <div className="row tabs_block mar_lr_0 tasks_tab_block">
            <div className="col-lg-12">
              <table className="table table-hover task_table">
                <thead>
                  <tr>
                    <th width="13%">Name</th>
                    <th width="9%" className="text-center">Total Tasks</th>
                    <th width="9%" className="text-center">Tasks Completed</th>
                    <th width="9%" className="text-center">Tasks Pending</th>
                    <th width="8%">Project Health</th> 
                    <th width="8%">End Date</th>
                    <th width="10%">Action</th> 
                  </tr>
                </thead>
                {Object.keys(milestonesData).length > 0 ? milestonesData.map((milestone) =>
                  <tbody key={milestone.id}>
                    <tr>
                      <td>
                        <div className="task_title" style={{cursor:"pointer"}}>
                          <Link to={{ pathname: `${this.context.company}/pm/${this.props.match.params.id}/tasks/`, state: {milestoneName: milestone.name, milestoneId: milestone.id }}} className="ellipsis">
                            {milestone.name}
                          </Link>
                        </div>
                      </td>
                      <td className="text-center">{milestone.all_tasks}</td>
                      <td className="text-center">{milestone.closed_tasks}</td>
                      <td className="text-center">{milestone.opened_tasks}</td>
                      <td>
                      <div className="progress" data-percentage={milestone.tasks_percentage}>
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                        <div className="progress-value">
                          <div>
                            {milestone.tasks_percentage}%
                          </div>
                        </div>
                      </div>
                      </td>  
                      <td>{milestone.estimated_finish ? milestone.estimated_finish : '--'}</td>  
                      <td className="actions">
                        <Link 
                          className="edit" 
                          to={{ pathname:`${this.context.company}/pm/${this.props.match.params.id}/milestones/${milestone.id}/edit`}}
                        >
                          <i className="far fa-edit"></i>
                        </Link>
                        <a className="edit" onClick={() => this.setState({ closedMilestoneId: milestone.id, displayCloseTask: true })}><i className="fas fa-times"></i></a>
                      </td>
                    </tr>
                  </tbody>
                )
                :
                  <tbody className="col-lg-12 tab-content open_content no-padding">
                    <tr>
                      <td colSpan="9">
                        {this.state.loading ?
                          <Spinner loadingText /> :
                          <div>No Milestones Found</div>
                        }
                      </td>
                    </tr>
                  </tbody>
                }
              </table>
            </div>
          </div>
          {(this.state.addMilestone || this.props.match.params.mid) &&
            <AddEditMilestone 
              addMilestone={this.addTaskOrMilestone}
              onCancel={this.onCancel}
              props={this.props}
            />
          }
          {this.state.addTask &&
            <AddTasks 
              id={this.props.match.params.id}
              selectedMilestone={this.state.addTaskMilestone} 
              milestoneTask={true}
              addTaskOrMilestone={this.addTaskOrMilestone}
              onCancel={this.onCancel}
            />
          }
          <div className="overlay" style={{ display: (this.state.addMilestone || this.props.match.params.mid || this.state.addTask) ? 'block' : 'none' }} onClick={() => this.overlayControl()}></div>
          <Modal
            display={this.state.displayCloseTask}
            bodyText='Are you sure? You want to Close?'
            modalAction={() => {
              this.closeMilestone(this.state.closedMilestoneId);
              this.setState({ displayCloseTask: false })
            }}
            removeModal={() => this.setState({ displayCloseTask: false })}
          />
        </div>
        {pagination.number && 
          <div className="row mar_lr_0 justify-content-center">
            <div className="col-lg-4 text-center">
              {Number(pagination.numOfPages) > 1 &&
                <Pagination
                  currentPage={pagination.number}
                  totalPages={pagination.numOfPages}
                  getCurrentPage={this.getCurrentPage.bind(this)}
                /> 
              }
            </div>
          </div>
        }
      </div>
    )
  }
}