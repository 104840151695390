import React, { Component } from 'react'
import Datetime from 'react-datetime'
import moment from 'moment'
import { fetch_get, fetch_post, PROFILE, PROFILE_UPDATE, APP_NAME, notify, DropBox } from './common'

export default class Profile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      timezone_choices: [],
      gender: '',
      date_of_birth: moment(),
      profile_pic: '',
      errors: {},
      files: [],
      disableButton: false
    }
    this.isActive = this.isActive.bind(this)
    this.first_nameRef = React.createRef();
    this.last_nameRef = React.createRef();
    this.timezoneRef = React.createRef();
    this.father_nameRef = React.createRef();
    this.phone_numberRef = React.createRef();
    this.emergency_mobile_numberRef = React.createRef();
    this.pan_numRef = React.createRef();
    this.adhar_numberRef = React.createRef();
    this.passportRef = React.createRef();
    this.present_addressRef = React.createRef();
    this.permanent_addressRef = React.createRef();
    this.aboutRef = React.createRef();
  }

  componentDidMount () {
    fetch_get(PROFILE)
      .then((response) => {
        const profile = response.data
        const user = response.data.user_details.data
        this.setState({
          gender: profile.gender,
          timezone_choices: response.timezone_choices,
          date_of_birth: moment(profile.date_of_birth, 'YYYY-MM-DD'),
          profile_pic: profile.profile_img
        })
        this.first_nameRef.current.value = user.first_name
        this.last_nameRef.current.value = user.last_name
        this.timezoneRef.current.value = user.timezone
        this.father_nameRef.current.value = profile.father_name
        this.phone_numberRef.current.value = profile.phone_number
        this.emergency_mobile_numberRef.current.value = profile.emergency_mobile_number
        this.pan_numRef.current.value = profile.pan_num
        this.adhar_numberRef.current.value = profile.adhar_number
        this.passportRef.current.value = profile.passport
        this.present_addressRef.current.value = profile.present_address
        this.permanent_addressRef.current.value = profile.permanent_address
        this.aboutRef.current.value = profile.about
      })
  }

  isActive (value) {
    return 'btn ' + ((this.state.gender === value) ? 'primary_btn' : 'secondary_btn')
  }

  saveDetails () {
    this.setState({ errors: {}, disableButton: true })
    const { date_of_birth, gender } = this.state
    const data = new FormData()
    // for (const name in this.refs) {
    //   data.append(name, this.refs[name].value)
    // }
    data.append('first_name',this.first_nameRef.current.value);
    data.append('last_name',this.last_nameRef.current.value);
    data.append('timezone',this.timezoneRef.current.value);
    data.append('father_name',this.father_nameRef.current.value);
    data.append('phone_number',this.phone_numberRef.current.value);
    data.append('emergency_mobile_number',this.emergency_mobile_numberRef.current.value);
    data.append('pan_num',this.pan_numRef.current.value);
    data.append('adhar_number',this.adhar_numberRef.current.value);
    data.append('passport',this.passportRef.current.value);
    data.append('present_address',this.present_addressRef.current.value);
    data.append('permanent_address',this.permanent_addressRef.current.value);
    data.append('about',this.aboutRef.current.value);
    date_of_birth && data.append('date_of_birth', date_of_birth.format('YYYY-MM-DD'))
    data.append('gender', gender)
    // const imagedata = document.querySelector('input[type="file"]').files[0];
    // if (imagedata) {
    //   data.append('', imagedata);
    // }
    this.state.files.map((file) => data.append('profile_pic', file))
    fetch_post(PROFILE_UPDATE, data)
      .then((response) => {
        if (!response.error) {
          alert('User details updated')
          this.props.history.push(APP_NAME + '/' + localStorage.getItem('company') + '/profile/')
          notify('User details updated')
        } else {
          window.scrollTo(0, 0)
          if (this.adhar_numberRef.current.value === '') {
            document.getElementById('adhar').style.visibility = 'visible'
          }
          this.setState({ errors: response.errors, profile_pic_form: response.profile_pic_form, disableButton: false })
        }
      })
  }

  render () {
    const { errors } = this.state
    return (
      <div className='row mar_lr_0 justify-content-center'>
        <div className='col-lg-9'>
          <div className='row h-100 mar_lr_0 justify-content-center'>
            <div className='col-lg-12'>
              <h2 className='heading'>Update Profile Details</h2>
            </div>
            <div className='col-lg-12 tab-content personal-content pt-5'>
              <div className='col-lg-12 proifle_form'>
                <form>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label>First Name<div className='error-text'>*</div></label>
                        <input type='text' ref={this.first_nameRef} className='form-control' />
                        <div className='error-text'>
                          {errors.first_name ? errors.first_name : null}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label>Last Name<div className='error-text'>*</div></label>
                        <input type='text' ref={this.last_nameRef} className='form-control' />
                        <div className='error-text'>
                          {errors.last_name ? errors.last_name : null}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label>Time Zone  <div className='error-text'>*</div></label>
                        <select id='e1' ref={this.timezoneRef} className='form-control'>
                          {this.state.timezone_choices
                             ? this.state.timezone_choices.map((obj) =>
                             <option value={obj[0]} key={obj[0]}>{obj[0]}</option>
                               ) : null}
                        </select>
                        <div className='error-text'>
                          {errors.timezone ? errors.timezone : null}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label>Father Name <div className='error-text'>*</div></label>
                        <input type='text' ref={this.father_nameRef} className='form-control' />
                        <div className='error-text'>
                          {errors.father_name ? errors.father_name : null}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label>Date Of Birth <div className='error-text'>*</div></label>
                        <Datetime
                          dateFormat='YYYY-MM-DD'
                          timeFormat=''
                          closeOnSelect
                          value={this.state.date_of_birth}
                          onChange={(date_of_birth) => this.setState({ date_of_birth })}
                        />
                        <div className='error-text'>
                          {errors.date_of_birth ? errors.date_of_birth : null}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label>PAN </label>
                        <input type='text' ref={this.pan_numRef} className='form-control' />
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label>Passport </label>
                        <input type='text' ref={this.passportRef} className='form-control' />
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label>Aadhar Number <div className='error-text'>*</div></label>
                        <input type='text' ref={this.adhar_numberRef} className='form-control' />
                      </div>
                      {this.refs.adhar_number &&
                  this.refs.adhar_number.value === ''
                        ? <div className='error-text' id='adhar' style={{ visibility: 'hidden' }}>
                          This field is required.
                    </div>
                        : <div className='error-text'>
                          {errors.adhar_number ? errors.adhar_number : null}
                          </div>}
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label>Phone Number <div className='error-text'>*</div></label>
                        <input type='text' ref={this.phone_numberRef} className='form-control' />
                        <div className='error-text'>
                          {errors.phone_number ? errors.phone_number : null}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label>Emergency Mobile Number </label>
                        <input type='text' ref={this.emergency_mobile_numberRef} className='form-control' />
                        <div className='error-text'>
                          {errors.emergency_mobile_number ? errors.emergency_mobile_number : null}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group '>
                        <label>
                          Gender <div className='error-text'>*</div>
                        </label>
                        <div className='btn-group btn-toggle'>
                          <button type='button' className={this.isActive('M')} onClick={() => this.setState({ gender: 'M' })}>Male</button>
                          <button type='button' className={this.isActive('F')} onClick={() => this.setState({ gender: 'F' })}>Female</button>
                        </div>
                        <div className='error-text'>
                          {errors.gender ? errors.gender : null}
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label>Present Address<div className='error-text'>*</div> </label>
                        <textarea className='form-control textarea' ref={this.present_addressRef} rows='3' />
                        <div className='error-text'>
                          {errors.present_address ? errors.present_address : null}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label>Permanent Address<div className='error-text'>*</div> </label>
                        <textarea className='form-control textarea' ref={this.permanent_addressRef} rows='3' />
                        <div className='error-text'>
                          {errors.permanent_address ? errors.permanent_address : null}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label>Profile Picture</label>
                        <br />
                        {this.state.profile_pic &&
                          <img
                             className='uploaded-logo'
                             id='blah'
                             src={this.state.profile_pic}
                             height={120}
                             alt='logo'
                           />}
                        {this.state.files.length !== 0 &&
                          <img
                             className='uploaded-logo'
                             id='blah'
                             src={URL.createObjectURL(this.state.files[0])}
                             height={120}
                             alt='logo'
                           />}
                        <DropBox
                          files={this.state.files}
                          accept='image/*'
                          isImage
                          imageStyle={{ height: 120 }}
                          onDrop={(files) => this.setState({ files, profile_pic: '' })}
                        />
                        <div className='error-text'>
                          {this.state.profile_pic_form ? this.state.profile_pic_form.profile_pic : ''}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group '>
                        <label>
                          About <textarea className='form-control textarea' ref={this.aboutRef} rows='3' />
                        </label>
                        <div className='error-text'>
                          {errors.about ? errors.about : null}
                        </div>
                      </div>
                    </div>

                    <div className='buttons col-lg-12 mb-4 text-center'>
                      <button type='button' className='btn primary_btn' disabled={this.state.disableButton} onClick={this.saveDetails.bind(this)}>Save</button>
                      <button type='button' className='btn secondary_btn' onClick={() => this.props.history.goBack()}>Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
