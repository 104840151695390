import React, { Component, Fragment } from 'react'
import { Spinner } from '../../common'

export default class DisplayScreenShots extends Component {
  constructor (props) {
    super(props)
    this.state = {
      screens: this.props.screens,
      date: this.props.date,
      id: this.props.id,
      isLoading: this.props.isLoading,
      isUpdate: this.props.isUpdate,
      dateWithNoScreenshots: false
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    return (prevState.screens.length !== nextProps.screens.length) ? { screens: nextProps.screens } : null
  }

  componentDidUpdate () {
    if (this.props.isUpdate !== null) {
      this.updateDate(this.props.isUpdate)
    }
  }

  updateDate (sDate) {
    if (!this.props.currentDisplayDate.includes(sDate)) {
      this.props.changeDate(sDate)
      this.setState({ dateWithNoScreenshots: true })
    }
  }

  render () {
    return (
      <>
        <div className='col-lg-12'>
          <div className='col-lg-8 p-0'>
            <button
              type='button'
              className='primary_btn'
              onClick={() => {}}
            >
              {this.state.date}
            </button>
          </div>
          {this.state.dateWithNoScreenshots && <div className='screen_col' style={{ width: '100%' }}>No screenshots found</div>}
        </div>
        {
          this.state.isLoading
            ? <Spinner />
            : this.state.screens.length > 0
              ? this.state.screens.map((screenshot, index, arr) =>
                <Fragment key={index}>
                  {this.state.date === screenshot.created_date
                    ? <>
                      <div className='row mar_lr_0'>
                        <div className='screen_col col-lg-12' key={index}>
                          <div className='screen_item'>
                            <div
                          className='screen_item'
                          onClick={() => {
                            this.setState({
                              screenShot: {
                                id: this.state.id,
                                index: index,
                                present: screenshot.url,
                                length: arr.length - 1,
                                time: (this.props.toDate === this.props.filteredDate) ? screenshot.formatted_created_on : screenshot.formatted_created_on_date
                              },
                              openModel: true
                            }, () => this.props.changeDate(null, true, this.state.screenShot))
                          }}
                        >
                          <img src={screenshot.url} alt='screenshot' />
                          <div className='time'>
                            <span>{(this.props.toDate === this.props.filteredDate) ? screenshot.formatted_created_on : screenshot.formatted_created_on_date}</span>
                          </div>
                        </div>
                          </div>
                        </div>
                      </div>
                    </>
                    : <>
                      {this.updateDate(screenshot.created_date)}
                    </>}
                </Fragment>
                )
              : (!this.state.dateWithNoScreenshots && <div className='screen_col' style={{ width: '100%' }}>No screenshots found</div>)
        }

      </>
    )
  }
}
