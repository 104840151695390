import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import GoogleLogin from './GoogleLogin'
import logo from '../../static/images/logo.png'
import {
  LOGIN,
  GOOGLE_LOGIN,
  APP_NAME,
  LOGIN_DOMAIN_NAME
} from '../common'

export default class Signin extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      error: '',
      errors: {},
      isLoading: true
    }
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
  }

  componentDidMount () {
    this.setState({ isLoading: false })
    document.body.classList.add('authentication_body')
    if (localStorage.getItem('token')) {
      this.props.history.push(APP_NAME + '/intro')
    } else {
      // const sub_domain = window.location.host.split('.')[0];
      // if(sub_domain !== 'microtrack') {
      //   let data = new FormData()
      //   data.append('sub_domain', sub_domain)
      //   fetch(VALIDATE_SUBDOMAIN, {
      //     method: 'POST',
      //     body: data
      //   })
      //   .then(response => response.json())
      //   .then((response) => {
      //     this.setState({ isLoading: false })
      //     if (response.error) {
      //       this.props.history.push(APP_NAME + '/login-domain')
      //     }
      //   });
      // } else {
      //   this.props.history.push(APP_NAME + '/login-domain')
      // }
    }
  }

  handleChangeEmail (event) {
    this.setState({ email: event.target.value, error: '', errors: {} })
  }

  handleChangePassword (event) {
    this.setState({ password: event.target.value, error: '', errors: {} })
  }

  handleSubmit (event) {
    event.preventDefault()
    // const company = window.location.hostname.split('.')[0];
    // const domain = SCHEME + SERVER;
    // const domain = 'https://' + company + SERVER;
    const { email, password } = this.state
    const data = new FormData()
    // data.append('sub_domain', company)
    data.append('email', email)
    data.append('password', password)
    fetch(LOGIN_DOMAIN_NAME + LOGIN, {
      method: 'post',
      body: data
    })
      .then(response => response.json())
      .then((response) => {
        if (response.token) {
          localStorage.setItem('token', response.token)
          localStorage.setItem('id', response.id)
          localStorage.setItem('username', response.employee_name)
          // localStorage.setItem('role', response.role.toLowerCase())
          this.props.history.push(APP_NAME + '/intro')
        } else {
          this.setState({ errors: response.errors })
        }
      })
      // .catch((error) => {
      .catch(() => {
        this.setState({ error: 'Something went wrong, Please try again' })
      })
  }

  responseGoogle (data) {
    if (data.accessToken) {
      const formData = new FormData()
      formData.append('accessToken', data.accessToken)
      fetch(LOGIN_DOMAIN_NAME + GOOGLE_LOGIN, {
        method: 'post',
        body: formData
      })
        .then(response => response.json())
        .then((response) => {
          if (!response.error) {
            const token = response.token
            localStorage.setItem('token', token)
            localStorage.setItem('id', response.id)
            localStorage.setItem('username', response.employee_name)
            // localStorage.setItem('role', response.role.toLowerCase());
            this.props.history.push(APP_NAME + '/intro')
          } else {
            alert('Something went wrong. Please Try again!!')
          }
        })
    }
  }

  registerRedirect () {
    window.location.href = `//app.${process.env.REACT_APP_DOMAIN}/register`
    // window.location.href = `//mt.io:3000/app/register`;
  }

  // subdomainRedirect() {
  //   // const redirectUrl = `//app.pietrack.com/app/change-domain`;
  //   const redirectUrl = `//mt.io:3000/app/register`;
  //   window.location.href = redirectUrl;
  // }

  componentWillUnmount () {
    document.body.classList.remove('authentication_body')
  }

  render () {
    const { errors } = this.state
    if (this.state.isLoading) {
      return null
    }
    return (
      <div className='authentication_wrapper'>
        <div className='authentication_block'>
          <div className='right login_right'>
            <img alt='' src={logo} />
            <strong>Don&apos;t have an account?</strong>
            <div className='buttons'>
              <a onClick={() => this.registerRedirect()} className='primary_btn btn'>Register</a>
            </div>
          </div>
          <div className='left login_left'>
            <div className='heading'>Login</div>
            <div className='auth_form'>
              <form>
                <div className='form-group'>
                  <input type='text' id='name' className='form-control1' value={this.state.email} onChange={this.handleChangeEmail} required />
                  <label className='form-control-placeholder' htmlFor='name'>Email</label>
                  <div className='error-text'>
                    {errors.email ? errors.email[0] : null}
                  </div>
                </div>
                <div className='form-group'>
                  <input type='password' id='password' className='form-control1' value={this.state.password} onChange={this.handleChangePassword} required />
                  <label className='form-control-placeholder' htmlFor='password'>Password</label>
                  <div className='error-text'>
                    {errors.password ? errors.password[0] : errors.__all__ ? errors.__all__[0] : null}
                  </div>
                </div>
                <div className='buttons'>
                  <button type='submit' className='btn primary_btn' onClick={this.handleSubmit.bind(this)}>Login</button>
                  <GoogleLogin
                    clientId='639302738050-np85664n6o4rak4pu3q2qk68kkebjvt1.apps.googleusercontent.com'
                    buttonText=''
                    onSuccess={this.responseGoogle.bind(this)}
                    onFailure={this.responseGoogle.bind(this)}
                    autoLoad={false}
                  />
                  <Link to={APP_NAME + '/forgot-password'} id='to-recover' className='forgot'>
                    Forgot Password?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
