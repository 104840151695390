import React, { Component } from 'react'

export class TableFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showFilter: false,
      filters: props.filters,
      isUpdated: false
    }
  }

  // componentWillReceiveProps(nextProps) {
  //    if(this.state.filters !== nextProps.filters) {
  //      this.setState({filters: nextProps.filters})
  //    }
  //  }

  // static getDerivedStateFromProps(nextProps, prevState) {

  //   return null;
  // }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.setState({ filters: this.props.filters })
    }
  }

  setFilterState (value) {
    this.setState({ filters: { ...this.state.filters, [value]: !this.state.filters[value] } }
    )
    this.props.setFilterState({ ...this.state.filters, [value]: !this.state.filters[value] })
  }

  // toggleFilter(target) {
  //   const { id } = this.props;
  //   if(!['check-list'+id, 'checkbox'+id].includes(target.getAttribute('id')) ) {
  //     this.setState({
  //       showFilter: [target.getAttribute('id'), target.parentNode.getAttribute('id'),
  //         target.parentNode.parentNode.getAttribute('id')].includes('toggle'+id) ?
  //         !this.state.showFilter : !['check-list'+id, 'input'+id].includes(target.getAttribute('id')) ?
  //         false : false
  //     })
  //   }
  // }

  render () {
    const { filters } = this.state
    const { id } = this.props
    return (
      <div className='btn-group dropdown no-caret table_dropdown no-padding lead_filter'>
        <a
          className='toggle'
          id={'toggle' + id}
          onClick={() => this.setState({ showFilter: !this.state.showFilter })}
          style={{ color: 'white' }}
        ><i className={`fas fa-${this.props.icon} ${this.props.icon} fa-w-16`} />
        </a>
        {
          this.state.showFilter &&
            <div>
              <div className='overlay light' onClick={() => this.setState({ showFilter: false })} />
              <ul className='menu task_detail_menu table_dropdwn' style={Object.assign({}, this.props.style, { display: 'block' })}>
                {
                Object.entries(filters).map((obj) =>
                  <li className='check-list' id={'check-list' + id} key={obj[0]} onClick={() => this.setFilterState(obj[0])}>
                    <input
                      id={'checkbox' + id}
                      type='checkbox'
                      checked={obj[1]}
                      onChange={() => this.setFilterState(obj[0])}
                    />{obj[0].split('_').join(' ')}
                  </li>
                )
              }
              </ul>
            </div>
        }
      </div>
    )
  }
}
