import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import {
  fetch_get,
  fetch_post,
  PROJECTS_LIST,
  notify,
  Modal,
  Context
} from '../../../common';

export default class GitHub extends Component {
  static contextType = Context
  constructor(props) {
    super(props);
    this.state = {
      projectUrl: '',
      errors: {},
      projects: [],
      labels: [],
      assignedList: [],
      label: [],
      member: [],
      hostScheme: 'https://',
      accessToken: '',
      project: '',
      moreOptions: false,
      visibleStatus: '',
      githubDetails: false,
      githubDetailsList: [],
      priority: '',
      issueStatus: '',
      projectId: this.props.projectId,
      edit: false,
      hostName: '',
      show: false,
      allowSync: 'True',
      authToken: '',
      loading: true,
      githubUrl: null,
      loginError: true
    }
    this.redirectToGithub = React.createRef();
  }

  componentDidMount() {
    this.initialState = this.state;
    this.initialGitHubDetails()
  }

  initialGitHubDetails() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/github/')
    .then(response => {
      if(response.github_project.data) {
        this.setState({ githubDetailsList: response.github_project.data, loginError: false, githubDetails: true });
      } else {
        if(this.props.githubCode) {
          this.githubLogin()
        }
      }
    })
  }

  githubConnect() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/github/get-login-redirect-url/')
    .then(response => {
      this.setState({ githubUrl: response.github_login_url })
      this.redirectToGithub.current.click();
    })
  }

  githubLogin() {
    let loginStatus = this.props.loginStatus === 'c' ? 'code=' : 'error=';
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/github/login-success/?'+ loginStatus  + this.props.githubCode)
    .then(response => {
      if(!response.error) {
        this.setState({ 
          projects: response.projects_list,
          accessToken: response.access_token,
          loginError: false
        })
      } else {
        this.setState({ loginError: true })
      }
    })
  }

  // click() {
  //   fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/github/add/')
  //   .then(response => {
  //     this.setState({ 
  //       projectUrl: response.default_api_url,
  //       loading: false
  //     });
  //     if(response.gitlab.data) {
  //       this.setState({ githubDetailsList: response.gitlab.data, gitlabDetails: true });
  //     }
  //   })
  // }

  // getProjects(hostName=this.state.hostName, accessToken=this.state.accessToken) {
  //   this.setState({ errors: {} });
  //   const data = new FormData();
  //   data.append('host_name', hostName)
  //   data.append('scheme', this.state.hostScheme);
  //   data.append('secret_key', accessToken);
  //   data.append('api_url', this.state.projectUrl);
  //   fetch_post(PROJECTS_LIST + this.state.projectId +'/integrations/gitlab/get_gitlab_projects/', data)
  //   .then(response => {
  //     if(response.error) {
  //       this.setState({ errors: response.errors });
  //     } else {
  //       this.setState({ projects: response.projects_list })
  //     }
  //   })
  // }

  moreSettings() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/github/link/')
    .then(response => {
      var members = [];
      var labelsList = [];
      response.labels.data.filter(function( obj ) {
        return (
          labelsList.push({label: obj.name, value: obj.id})
        )
      });
      response.profiles.data.filter(function( obj ) {
        return (
          members.push({
            label: 
            <div>
              <img className="select_img" src={obj.profile_img}  alt={obj.employee_name}/>
              {obj.employee_name.split(" ")[0]}
            </div>, 
            value: obj.id
          })
        )
      });
      this.setState({ 
        labels: labelsList,
        assignedMembersList: members,
        moreOptions: true,
        priorities: response.priorities,
        issueStatuses: response.issue_status_choices,
        visiblleStatuses: response.visible_status_choices,
        errors: {}
      })
    })
  }

  saveDefaultSettings() {
    this.setState({ errors: {} });
    const data = new FormData();
    if(this.state.label.length > 0) {
      this.state.label.filter(function( obj ) {
        return (
          data.append('labels', obj.value)
        )
      });
    }
    if(this.state.member) {
      this.state.member.filter(function( obj ) {
        return (
          data.append('assigned_to', obj.value)
        )
      });
    }
    data.append('issue_status', this.state.issueStatus)
    data.append('visible_status', this.state.visibleStatus)
    data.append('projects_list', JSON.stringify(this.state.projects));
    data.append('host_name', this.state.hostName)
    data.append('scheme', this.state.hostScheme);
    data.append('access_token', this.state.accessToken);
    data.append('allow_sync', this.state.allowSync)
    data.append('url', this.state.projectUrl);
    data.append('project', this.state.project);
    data.append('priorities', this.state.priority)
    var url = this.state.edit ? 'update/' : 'link/';
    fetch_post(PROJECTS_LIST + this.state.projectId +'/integrations/github/'+ url, data)
    .then(response => {
      if(response.error) {
        this.setState({ errors: response.errors })
      } else {
        this.initialGitHubDetails();
        notify(response.response);
        this.context.updateCount('true');
      }
    });
  }

  onLabelSelect(value) {
    this.setState({ label: value });
  }

  onMemberSelect(value) {
    this.setState({ member: value });
  }

  setValue(e) {
    this.setState({ visibleStatus: e.target.value });
  }

  setValueIssue(e) {
    this.setState({ issueStatus: e.target.value });
  }

  cancelDetails() {
    if(this.state.edit) {
      this.setState({ moreOptions: true, githubDetails: true })
    }else{
      this.setState({ githubDetails: false})
    }
    this.setState({ errors: {} })
  }

  editGithubDetails() {
    const { githubDetailsList } = this.state;
    var labels = [];
    if(githubDetailsList.default_values.labels.data) {
      githubDetailsList.default_values.labels.data.filter(function( obj ) {
        return (
          labels.push({label: obj.name, value: obj.id})
        )
      });
    }
      var member = [];
    if(githubDetailsList.default_values.assigned_to.data) {
      githubDetailsList.default_values.assigned_to.data.filter(function( obj ) {
        return (
          member.push({
            label: 
            <div>
              <img className="select_img" src={obj.profile_img} alt={obj.employee_name}/>
              {obj.employee_name.split(" ")[0]}
            </div>, 
            value: obj.id
          })
        )
      });
    }
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/github/update/')
    .then(response => {
      var labelsList = [];
      var members = [];
      response.labels.data.filter(function( obj ) {
        return (
          labelsList.push({label: obj.name, value: obj.id})
        )
      });
      response.profiles.data.filter(function( obj ) {
        return (
          members.push({
            label: 
            <div>
              <img className="select_img" src={obj.profile_img} alt=""/>
              {obj.employee_name.split(" ")[0]}
            </div>, 
            value: obj.id
          })
        )
      });
      this.setState({ 
        assignedMembersList: members,
        projectUrl: githubDetailsList.github_name,
        project: githubDetailsList.github_name,
        githubDetails: false,
        label: labels,
        labels: labelsList,
        member: member,
        priorities: response.priorities,
        priority: githubDetailsList.default_values.priorities.data ? githubDetailsList.default_values.priorities.data.id : '',
        visiblleStatuses: response.visible_status_choices,
        issueStatuses: response.issue_status_choices,
        moreOptions: true,
        issueStatus: githubDetailsList.default_values.issue_status,
        visibleStatus: githubDetailsList.default_values.visible_status,
        edit: true
      });
    })
    githubDetailsList.allow_sync === true ? this.setState({ allowSync: 'True' }) : this.setState({ allowSync: 'False' })
    // this.getProjects(githubDetailsList.host_name.split('//')[1], githubDetailsList.secret_key, githubDetailsList.org_slug);
  }

  deleteGitHubSettings() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/github/delete/', true)
    .then(response => {
      if(response.status === 204) {
        this.setState({ displayDeleteModal: false })
        this.setState(this.initialState);
        notify('Deleted Github Default settings');
        this.context.updateCount('true');
        this.initialGitHubDetails();
        this.moreSettings();
      }
    })
  }

  LoadGitLabIssues() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/gitlab/load-issues/' + this.state.githubDetailsList.id + '/')
    .then(() => {})
  }

  onChange(event) {
    // var index = event.nativeEvent.target.selectedIndex;
    var projectUrl = event.target.options[event.target.selectedIndex].id;
    this.setState({ project: event.target.value, projectUrl}, this.moreSettings() )
  }

  render() {
    const { permissions, role } = this.context;
    const { visiblleStatuses, issueStatuses, githubDetailsList, priorities } = this.state;
    return(
      <div className="row integration_list_view ">
        <div className=" col-lg-12 pl-0 integration_container">
          <strong> GitHub</strong>
          {this.state.show &&
            <div className="integration_content" style={{ display: 'block' }}>
              <p>GitHub brings together the world&apos;s largest community of developers to discover, share, and build better software.
                It offers all of the distributed version control and source code management functionality of Git as well as adding its own features.
              </p>
              {/*<strong> Instructions</strong>
                            <p>
                              <b>Host Name</b><br/>
                              Host name must be your registered organization gitlab host_url
                              Example: https://abc.xyz.com (or) http://abc.xyz.com
                            </p>
                            <p>
                              <b>The Private token is available in your User Settings</b><br />
                               >> Settings <br/> >> Access Tokens (Keep these tokens secret, anyone with access to them can interact with GitLab as if they were you.)
                            </p>*/}
            </div>
          }
          <div className="sliding_bar">
            {this.state.show &&
              <span className="arrow-up" onClick={() => this.setState({ show: false }) }>
                <i className="fa fa-times"></i>
              </span>
            }
            { !this.state.show &&
              <span className="arrow-down" onClick={() => this.setState({ show: true }) }>
                <i className="fa fa-question"></i>
              </span>
            }
          </div>
        </div>
        {!this.state.githubDetails ?
          <div className="col-lg-12 pl-0 form">
            <form>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row justify-content-start">
                    <div className="col-lg-6">
                      {this.state.loginError &&
                        <div className="save_application">
                          <button 
                            type="button" 
                            className="btn primary_btn" 
                            onClick={() => this.githubConnect()}
                          >
                            Connect to GitHub
                          </button>
                          <a href={this.state.githubUrl} ref={this.redirectToGithub}></a>
                        </div>
                      }
                      {(this.state.projects.length > 0 || this.state.edit) && 
                        <div className="form-group ">
                          <label>Projects<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                          {this.state.edit ?
                            <input 
                              type="text"
                              className="form-control" 
                              placeholder="Enter Host name" 
                              value={githubDetailsList.github_name}
                              onChange={() => {}}
                              disabled
                            /> 
                            : 
                            <select 
                              className="form-control " 
                              value={this.state.project}
                              onChange={this.onChange.bind(this)}
                            >
                              <option value=""> ----- </option>
                              {this.state.projects.map((projectObj) => 
                                <option key={projectObj.id} id={projectObj.url} value={projectObj.id}>{projectObj.name}</option>
                              )}
                            </select>
                          }
                          <div className="error-text"></div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {this.state.project && this.state.moreOptions && 
                <div> 
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="form-group ">
                            <label>Labels</label>
                            <Select
                              isMulti
                              onChange={(e) => {this.onLabelSelect(e)}}
                              options={this.state.labels}
                              value={this.state.label}
                              placeholder="select label(s)"
                            />
                            <div className="error-text">
                              {this.state.errors.labels ? this.state.errors.labels[0] : null }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group ">
                            <label>Priority</label>
                            <select 
                              className="form-control "
                              value={this.state.priority}
                              onChange={(e) => this.setState({ priority: e.target.value })}
                            >
                              <option value=""> ----- </option>
                              {priorities ? priorities.data.map((priority) => 
                                <option key={priority.id} value={priority.id}>{priority.name}</option>
                              ) : null}
                            </select>
                            <div className="error-text">
                              {this.state.errors.priorities ? this.state.errors.priorities[0] : null }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group ">
                            <label>Assign To{this.state.visibleStatus === 'private' && <sup className="error-text" style={{top: '0px'}}>*</sup>}</label>
                            <Select
                              isMulti
                              onChange={(e) => {this.onMemberSelect(e)}}
                              options={this.state.assignedMembersList}
                              value={this.state.member}
                              placeholder="select member(s)"
                            />
                            <div className="error-text"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Visible Status<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                            {visiblleStatuses ? visiblleStatuses.map((visible) =>
                              <div key={visible[0]} className="form-check">
                                <input 
                                  type="radio" 
                                  className="form-check-input"
                                  id={visible[0]}
                                  value={visible[0]}
                                  checked={this.state.visibleStatus == visible[0]}
                                  onChange={this.setValue.bind(this)}
                                />
                                <label htmlFor={visible[0]} className="form-check-label">
                                  {visible[1]}
                                </label>
                              </div>
                            ) : null}
                            <div className="error-text">
                              {this.state.errors.visible_status ? this.state.errors.visible_status[0] : null }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 ">
                          <div className="form-group">
                            <label>Issue Status<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                            {issueStatuses ? issueStatuses.map((issue) => 
                              <div key={issue[0]} className="form-check">
                                <input 
                                  type="radio" 
                                  className="form-check-input" 
                                  id={issue[0]}
                                  value={issue[0]}
                                  checked={this.state.issueStatus == issue[0]}
                                  onChange={this.setValueIssue.bind(this)}
                                />
                                <label htmlFor={issue[0]} className="form-check-label">
                                  {issue[1]}
                                </label>
                              </div>
                            ): null}
                            <div className="error-text">
                              {this.state.errors.issue_status ? this.state.errors.issue_status[0] : null }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group ">
                            <label>Sync<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                            <select 
                              className="form-control " 
                              onChange={(e) => {this.setState({ allowSync: e.target.value }) }}
                              value={this.state.allowSync}
                            >
                              <option value="True"> True </option>
                              <option value="False">False</option>
                            </select>
                            <div className="error-text">
                              {this.state.errors.allow_sync && this.state.errors.allow_sync}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="error-text">
                      { this.state.errors.__all__ && this.state.errors.__all__[0] }
                    </div>
                  </div>
                  <div className="row mar_lr_0">
                    <div className="col-lg-12 text-center buttons">
                      <button type="button" className="btn primary_btn" onClick={() => this.saveDefaultSettings()}>Sync</button>
                      <button type="button" className="btn secondary_btn" onClick={() => this.cancelDetails()}>Cancel</button>
                    </div>
                  </div>
                </div>
              }
            </form>
          </div>
        :
          <div className="col-lg-12 detailed_content">
            <div className="row">
              {(permissions.includes('PROJECT_ADMIN') || role) &&
                <div className="col-lg-12 text-right">
                  <button type="button" className="btn primary_btn" onClick={() => this.editGithubDetails()}>Edit</button>
                  <button type="button" className="btn secondary_btn" onClick={() => this.setState({ displayDeleteModal: true })}>Delete</button>
                </div>
              }
              {githubDetailsList &&
                <Fragment>
                  <div className="col-lg-6">
                    <table className="table table-hover  table-user-information ">
                      <tbody>
                        <tr>
                          <td>Project:</td>
                          <td>{githubDetailsList.github_name}</td>
                        </tr>
                        <tr>
                          <td>Labels:</td>
                          <td>
                            {githubDetailsList.default_values.labels.data ? githubDetailsList.default_values.labels.data.map((label) => 
                              <a className="tag" key={label.id}>{label.name}</a>)
                              : '--'
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Priority:</td>
                          <td>{githubDetailsList.default_values.priorities.data ? githubDetailsList.default_values.priorities.data.name : '--'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-6">
                    <table className="table table-hover  table-user-information ">
                      <tbody>
                        <tr>
                          <td>Assigned To:</td>
                          <td>
                            <div className="team_members">
                            {githubDetailsList.default_values.assigned_to.data ? githubDetailsList.default_values.assigned_to.data.map((member) =>
                              <a className="member" key={member.id}>
                                <img src={member.profile_img} title={member.employee_name} alt="" />
                              </a>)
                            :
                              '--' 
                            }
                          </div>
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">Issue Status:</td>
                          <td>{githubDetailsList.default_values.issue_status}</td>
                        </tr>
                        <tr>
                          <td valign="top">Visible Status:</td>
                          <td>{githubDetailsList.default_values.visible_status}</td>
                        </tr>
                        <tr>
                          <td valign="top">Issue Level:</td>
                          <td>{githubDetailsList.default_values.allow_sync}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Fragment>
              }
            </div>
          </div>
          
        }
        <Modal
          display={this.state.displayDeleteModal}
          bodyText='Are you sure? You want to delete?'
          modalAction={this.deleteGitHubSettings.bind(this)}
          removeModal={() => this.setState({ displayDeleteModal: false })}
        />
      </div>
    )
  }
}