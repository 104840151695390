import React, { Component } from 'react';
import AdminLeftNav from './admin/LeftNav'
import HrLeftNav from './hr/LeftNav';
import PmLeftNav from './pm/LeftNav';
import SalesLeftNav from './sales/LeftNav';
import MarketingLeftNav from './marketing/LeftNav';
import { APP_NAME } from './common';
import Context from '../Context';
import logo from '../static/images/logo.png'; 
import admin from '../static/images/admin.png'; 
import HR from '../static/images/HR.png'; 
import MARKETING from '../static/images/MARKETING.png'; 
import SALES from '../static/images/SALES.png'; 
import PM from '../static/images/PM.png'; 
import Intro from '../static/images/home.png'; 
import mtimages from '../static/images/mt-images.png';
import { fetch_post, fetch_get, LOGOUT } from './common';

export default class LeftMenu extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      selectedNav: window.location.pathname,
      role: '',
      isNewBundle: false,
      logoutButton: false,
      companiesLength: 0
    };
    this.isActive = this.isActive.bind(this);
    this.logout = this.logout.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getCompanies()
    this.fetchStatus();
    this.intervalId = setInterval(() => this.fetchStatus(), 100000);
  }

   componentDidUpdate() {
    let SideMenu = document.getElementsByClassName("side_menu");
    if(SideMenu[0].style.width != "100%") {
      this.collapseActions()
    }
  }

  collapseActions() {
    let MenuBlock = document.getElementsByClassName("menu_block");
    let  Sidebar = document.getElementsByClassName("sidebar");
    let Stage = document.getElementsByClassName("stage");
    let SideMenu = document.getElementsByClassName("side_menu");
    if([`${this.context.company}/profile/`, `${this.context.company}/profile/update`, `${this.context.company}/change-password/`].includes(window.location.pathname)) {
      if(MenuBlock[0]) {
        MenuBlock[0].style.display = "none"
      }
      Sidebar[0].style.width = "3.5%";
      Stage[0].style.marginLeft = "3.5%";
      SideMenu[0].style.width = "100%";
    } else if(!Number.isInteger(parseInt(window.location.pathname.split("/")[1]))) {
      this.props.history.push("/page-not-found")
    } else {
      if(MenuBlock[0]) {
        MenuBlock[0].style.display = "inline-block"
      }
        Sidebar[0].style.width = "15%";
        SideMenu[0].style.width = "25%";
        Stage[0].style.marginLeft = "15%";
    }
  }

  getCompanies() {
    let formData = new FormData()
    formData.append('company_name', this.state.company);
    fetch_post('/auth/user-sub-domains/', formData)
    .then((response) => {
      if(response) {
        if(response.status == 401){
          localStorage.clear()
          window.location = '/signin';
        }else{

        this.setState({ companiesLength: response.companies.data.length, loading: false })
      }
      }
    })
    .catch((error) => console.log(error))
  }

  fetchStatus() {
    const url = `//app.pietrack.com/static/js/update.chunk.js`
    try {
      var req = new XMLHttpRequest();
      req.open("HEAD", url, false);
      req.send(null);
      if(req.status === 200 || req.status === 304) {
        const prevModified = localStorage.getItem('lastModified')
        const lastModified = req.getResponseHeader('Last-Modified')
        if(prevModified !== lastModified) {
          this.setState({isNewBundle: true, lastModified})
        }
      }
    } catch(er) {
        console.log(er.message);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  onClick(value) {
    this.setState({ selectedNav: value });
    // console.log(this.context.company + `/${value}/`)
      this.props.history.push(this.context.company + `/${value}/`);
  }

  isActive(value) {
    return 'nav-link ' + ((value === this.state.selectedNav) ? ' active' : '');
  }

  logout() {
    fetch_get(LOGOUT)
    .then((response) => {
      if(response) {
        if(!response.error){
          localStorage.clear()
          window.location = '/signin';
        }
      }
    })
    .catch((error) => console.log(error))
  }

  handleChange = (selectedMenuName) => {
    if(selectedMenuName){
      this.setState({ selectedNav : selectedMenuName.value});
      this.onClick(selectedMenuName.value); 
    }
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    return (window.location.pathname.split('/')[2] !== prevState.selectedNav) ? { selectedNav: window.location.pathname.split('/')[2] } : { }
  }

  render() {
    const { selectedNav } = this.state;
    const { role, permissions, profile_pic, company } = this.context;
    let leftnav = '';
    let selectedMenuName = '';
    if(selectedNav === 'hr' || selectedNav === 'HR' ) {
      if(permissions.includes('HCM')) {
        leftnav =  <HrLeftNav permissions={permissions} props={this.props} />
        selectedMenuName = 'HR Management';
      }
    } 
    else if(selectedNav === 'admin' || selectedNav === 'ADMIN') {
        if(role) {
          leftnav =  <AdminLeftNav props={this.props} />
          selectedMenuName = 'Admin';
        }
      }
    else if(selectedNav === 'pm' || selectedNav === 'PM' ) {
      if(permissions.includes('PM')) {
        leftnav =  <PmLeftNav permissions={permissions} props={this.props} history={this.props.history}  />
        // selectedMenuName = 'Project Management';
      }
    }
    else if(selectedNav === 'sales' || selectedNav === 'SALES') {
      if(permissions.includes('SALES')) {
        leftnav =  <SalesLeftNav permissions={permissions} selectedMenu={selectedNav} />
        selectedMenuName = 'Sales' ;
      }
    }
    else if(selectedNav === 'marketing' || selectedNav === 'MARKETING'  ) {
      if(permissions.includes('MARKETING')) {
        leftnav =  <MarketingLeftNav permissions={permissions} selectedMenu={selectedNav} props={this.props} />
        selectedMenuName = 'Marketing' ;
      }
    }
    // console.log(permissions, 'permissions')
    return (
      <div className="main_container" onClick={() => this.state.logoutButton && this.setState({ logoutButton: false })}>
        <div className="modal fade" id="marketingModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Work In Progress</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Comming Soon...
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <aside className="sidebar">
          <div className="main_module">
            <div className="side_menu"> 
              <div className="logo">
                <a href="">
                <img alt="" src={logo} width="65%"/>
                </a>
              </div>
              <ul className="nav_menu">
                {this.state.companiesLength > 0 &&
                  <li className={this.isActive('intro')} >
                    <a onClick={() => this.props.history.push('/intro')}>
                      <img alt="" src={Intro} data-toggle="tooltip" data-placement="bottom" title="Introduction" width="100%"/>
                      <span>Intro</span>
                    </a>
                  </li>
                }
                {permissions.includes('HCM') &&
                  <li className={this.isActive('hr')} >
                    <a onClick={() => this.onClick('hr', 'HR Management')}>
                      <img alt="" src={HR} data-toggle="tooltip" data-placement="bottom" title="HR Management" width="100%"/>
                      <span>HR</span>
                    </a>
                  </li>
                }
                {permissions.includes('PM') &&
                  <li className={this.isActive('pm')} id="pm">
                    <a onClick={() => this.onClick('pm', 'Project Management')}>
                      <img alt="" src={PM} data-toggle="tooltip" data-placement="bottom" title="Project Management" width="100%"/>
                      <span>PM</span>
                    </a>
                  </li>
                }
                {permissions.includes('SALES') &&
                  <li className={this.isActive('sales')}>
                    <a onClick={() => this.onClick('sales', 'Sales')}>
                      <img alt="" src={SALES} data-toggle="tooltip" data-placement="bottom" title="Sales" width="100%"/>
                      <span>Sales</span>
                    </a>
                  </li>
                }
                {permissions.includes('MARKETING') &&
                  <li className={this.isActive('marketing')}>
                    <a data-toggle="modal" data-target="#marketingModal">
                      <img alt="" src={MARKETING} data-toggle="tooltip" data-placement="bottom" title="Marketing" width="100%"/>
                      <span>Marketing</span>
                    </a>
                  </li>
                }
                {role &&
                  <li className={this.isActive('admin')}>
                    <a onClick={() => this.onClick('admin', 'Admin')}>
                      <img alt="" src={admin} data-toggle="tooltip" data-placement="bottom" title="Admin" width="100%"/>
                      <span>Admin</span>
                    </a>
                  </li>
                }
              </ul>
              <div className="user_tool">
                <div className="form-group relative">         
                  <div className="form-group mb-3 dropdown">
                    <div className="toggle" onClick={() => this.setState({ logoutButton: !this.state.logoutButton })}><span className="menu_text"><img alt="" src={profile_pic ? profile_pic : mtimages} width="100%"/></span></div>
                    <ul className="menu" style={{ display: this.state.logoutButton ? 'block' : 'none' }}>
                      <li className="submenu">
                        <a onClick={() => {
                          this.setState({showDropdown: false, logoutButton: !this.state.logoutButton})
                          this.props.history.push(APP_NAME + company + '/profile/')}  
                        }>Profile</a>
                      </li>
                      <li className="submenu change">
                         <a onClick={() => {
                            this.setState({showDropdown: false, logoutButton: !this.state.logoutButton})
                            this.props.history.push(APP_NAME + company + '/change-password/')}
                          }>Change Password</a>
                      </li>
                      <li className="submenu">
                        <a onClick={() => this.logout()}>Logout</a>       
                      </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
            <div className="menu_block">
              <div className="company_menu_logo">
                <h4>{selectedMenuName}</h4>
              </div>
              <div className="menu_left" style={{ width: '100%' }}>
                {leftnav}
              </div>
            </div>
          </div>
        </aside>
        {
          this.state.isNewBundle &&
          <div className='flyover'>
            <span onClick={() => {
              localStorage.setItem('lastModified', this.state.lastModified);
              window.location.reload(true)
            }}>
            <a>Click here</a> to get latest updates in Pietrack 
            </span>
          </div>
        }
      </div>
    );
  }
}
