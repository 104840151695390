import React from 'react';
import io from 'socket.io-client';
// import { connect } from 'react-redux';

import People from './People';
import SendMessage from './SendMessage';
import Messages from './Messages';
import { Context, fetch_get, PROJECTS_LIST } from '../../common';

var socket = ''
export class ChatScreenPage extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      messages: [],
      locationButtonText: true,
      room: '',
      allUsers: [],
      loading: false,
      allMessagesRender: false,
      lastMsgId: null
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  
  // socket = io.connect('https://chat.pietrack.com')

  handleAddMessage = (message) => {
    if(!message){
      return 'Enter valid message'; //If empty message is submitted
    }
    const messageObject = {
      text: message,
      userId: localStorage.getItem('id')
    };
    //const input = event.target.elements.message;
    socket.emit('createMessage', messageObject, () => {
      //input.value = '';
    });
  };

  handleFileUpload(file){
    const reader = new FileReader();
    reader.addEventListener('loadend', function() {
      console.log('finished reading file');
      socket.emit('uploadFile', {file: reader.result, name: file.name }, () => {
      })
    })
    reader.readAsArrayBuffer(file);
  }

  componentDidMount(){
    socket = io.connect('https://chat.pietrack.com')
    this.getProjectMembers()
    document.getElementById('chat').addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScroll);
    var room = { room: this.context.room, name: this.context.employee_name, projectId: this.props.match.params.id, token: localStorage.getItem('token') }
    socket.on('connect', () => {
      // console.log('connect')
      socket.emit('join', room, (err) => {
        // console.log(err, 'join')
        if(err){
          alert(err);
          // this.props.history.push('/');
        } 
      });
    });

    socket.on('disconnect', () => {
      console.log('Disconnected to server');
    });
    
    socket.on('updateUserList', (users) => {
        if (this.refs.chatScreenRef) {
        this.setState(() => ({users}));
      }
    });

    socket.on('defaultPage', (msgs) => {
      // console.log('defaultPage')
      this.setState((prevState)=>({ messages: prevState.messages.concat(msgs) }));
      if(msgs.length < 9){
        this.setState({ lastMsgId: msgs.length > 0 ? (msgs[0]._id || msgs[1]._id) : null})
      }
    });

    socket.on('lastPage', (messages) => {
      // console.log('messages', messages)
      if(messages.length === 0) {
        // console.log('in')
        this.setState({ allMessagesRender: true, lastMsgId: this.state.messages.length > 0 ? (this.state.messages[0]._id || this.state.messages[1]._id) : null, loading: false })
      } else {
        // console.log('in', this.state.loading) 
        let newMessages = messages.concat(this.state.messages)
        // console.log(messages, 'messages', newMessages)
        setTimeout(()=> { 
          this.setState({ messages: newMessages, loading: false });
        }, 500)
      }
    })

    socket.on('newMessage', (message) => {
      // console.log(message, 'newMessage')
      if (this.refs.chatScreenRef) {
        if(message.from === 'Admin' && message.text === 'Welcome to the chat app.' && this.state.messages.length != 0){
          message.text = 'Connected back.';
          // this.setState((prevState)=>({ messages: prevState.messages.concat(message) }));
        } else{
          this.setState((prevState)=>({ messages: prevState.messages.concat(message) }));//Add message to the messages array
        }
      }
    })
  }

  handleScroll() {
    if(document.getElementById('chat') && this.state.messages.length > 1) { 
      var chatWindow = document.getElementById('chat');
      if(chatWindow.scrollTop === 0 && !this.state.allMessagesRender && this.state.lastMsgId !== this.state.messages[1]) {
        this.setState({ loading: true })
        // chatWindow.style.top = "30px";
        // console.log(this.state.messages, this.state.messages[1], 'handleScroll')
        let page = { room: this.context.room, at: this.state.messages[1].at }
        socket.emit('page', page, () => {

        });
      }
    }
  }

  getProjectMembers() {
    fetch_get(PROJECTS_LIST + window.location.pathname.split('/')[3] + '/get_users/')
    .then((response) => {
      this.setState({ allUsers: response.data })
    })
  }

  componentWillUnmount() {
    if(document.getElementById('chat')) {
      document.getElementById('chat').removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  render() {
    return (
      <div className="chat" ref="chatScreenRef">
        <People
          users={this.state.allUsers}
         />

        {/* <Header title={title} /> */}
        <div className="chat__main">
          <Messages
            loading={this.state.loading}
            users={this.state.allUsers}
            messages={this.state.messages}
            lastMsgId={this.state.lastMsgId}
          />
          <SendMessage
            users={this.state.allUsers}
            handleAddMessage={this.handleAddMessage}
            handleFileUpload={this.handleFileUpload}
            // sendLocation={this.sendLocation}
            locationButtonText={this.state.locationButtonText}
          />
        </div>
      </div>
    )
  }
}

// const mapStateToProps = (state)=>{
//   return {
//     rooms: state.rooms
//   };
// };

export default ChatScreenPage;
