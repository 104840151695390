export const notify = (text, isError) => {
  const notif = document.getElementById('message')
  if (notif) {
    notif.innerHTML = text
    notif.classList.remove('fadeOut')
    notif.classList.add('fadeIn')
    notif.classList.add(isError ? 'delete' : 'success')
    setTimeout(() => {
      notif.classList.remove('fadeIn')
      notif.classList.add('fadeOut')
      notif.classList.remove(isError ? 'delete' : 'success')
    }, 3000)
  }
}

export const showAlert = (text) => {
  const notif = document.getElementById('message')
  if (notif) {
    notif.innerHTML = text
    notif.classList.remove('fadeOut')
    notif.classList.add('fadeIn')
  }
}

export const removeAlert = () => {
  const notif = document.getElementById('message')
  if (notif) {
    notif.classList.remove('fadeIn')
    notif.classList.add('fadeOut')
  }
}
