import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { APP_NAME, Context } from '../common';

export default class LeftNav extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      settingsLabel: false,
      selectedMenu: window.location.pathname.split('/')[3],
      settingsMenu: false
      // collapse: this.props.collapse
    };

    this.onClickMenu = this.onClickMenu.bind(this);
    this.isActive = this.isActive.bind(this);
  }

  componentDidMount() {
    this.collapseActions()
    if (!localStorage.getItem('token')) {
      this.props.props.history.push(APP_NAME);
    }
  }

  collapseActions() {
    let MenuBlock = document.getElementsByClassName("menu_block");
    let  Sidebar = document.getElementsByClassName("sidebar");
    let Stage = document.getElementsByClassName("stage");
    let SideMenu = document.getElementsByClassName("side_menu");
    if(SideMenu[0].style.width !== "25%") {
      MenuBlock[0].style.display = "inline-block";
      Sidebar[0].style.width = "15%";
      Stage[0].style.marginLeft = "15%";
      SideMenu[0].style.width = "25%";
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const menu = nextProps.props.history.location.pathname.split('/')[3];
    if(menu !== prevState.selectedMenu) {
      return { selectedMenu: menu };
    }
    return null;
  }

  componentDidUpdate() {
    let SideMenu = document.getElementsByClassName("side_menu");
    if(SideMenu[0].style.width !== "25%") {
      this.collapseActions()
    }
  }

  onClickMenu(obj) {
    this.setState({ selectedMenu: obj.menu });
    this.props.props.history.push({
      pathname: APP_NAME + obj.url,
      state: {
        sub_menu: obj.sub_menu,
        // collapse: this.state.collapse
      }
    });
  }

  isActive(value) {
    if (this.state.selectedMenu) {
      if(!this.state.settingsLabel || (this.state.selectedMenu === 'workingdays' || this.state.selectedMenu === 'designations' || this.state.selectedMenu === 'default-leave-settings') ){
        return ((value.toLowerCase() === this.state.selectedMenu.toLowerCase()) ? 'active' : '');
      }
    }
    return '';
  }

  render() {
    const {permissions} = this.props;

    // if(this.props.menu.HR) {
    //   let sub_menu = this.props.menu.HR.sub_menu

    //   for (var key in sub_menu) {
    //     if(sub_menu[key].url) { 
    //       permissions.push(sub_menu[key])
    //     }
    //   }

    //   if(sub_menu.BASIC_HR) {
    //     for (key in sub_menu.BASIC_HR.sub_menu) {
    //       let isDuplicate = false;
    //       permissions.map((obj) => {
    //         if(obj.url === sub_menu.BASIC_HR.sub_menu[key].url) {
    //           isDuplicate = true;
    //         }
    //       })
    //       if(sub_menu.BASIC_HR.sub_menu[key].url && (!isDuplicate)) { 
    //         permissions.push(sub_menu.BASIC_HR.sub_menu[key]);
    //         isDuplicate = false
    //       }
    //     }
    //   }

    //   if(sub_menu.SETTINGS) {
    //     for (key in sub_menu.SETTINGS.sub_menu) {
    //       let isDuplicate = false;
    //       permissions.map((obj) => {
    //         if(obj.url === sub_menu.SETTINGS.sub_menu[key].url) {
    //           isDuplicate = true;
    //         }
    //       });
    //       if(sub_menu.SETTINGS.sub_menu[key].url && (!isDuplicate)) { 
    //         permissions.push(sub_menu.SETTINGS.sub_menu[key])
    //       }
    //     }
    //   }
    // }
    return (
      <ul>
        {
          /*permissions.map((obj) =>
            <li key={obj.menu}>
              <a className={this.isActive(obj.menu)} onClick={() => this.onClickMenu(obj)}>
              <i className="fa fa-users" title={obj.menu}></i>
              <span className="menu_text">{obj.menu}</span> 
              </a>
            </li>
          )*/
        }
        <li>
          <Link className={this.isActive('dashboard')} to={this.context.company +'/hr/dashboard/'} onClick={() => this.setState({ selectedMenu: 'dashboard', settingsMenu: false, settingsLabel: false })}>
            <span className="fa_icon">
              <i className="fa fa-tachometer-alt" title="Dashboard"></i>
            </span>
            <span className="menu_text">Dashboard</span>
          </Link>
        </li>
        {
          permissions.includes('USERS') &&
          <li>
            <Link className={this.isActive('users')} to={this.context.company +'/hr/users/'} onClick={() => this.setState({ selectedMenu: 'users', settingsMenu: false, settingsLabel: false })}>
              <span className="fa_icon">
                <i className="fa fa-user" title="Users"></i>
              </span>
              <span className="menu_text">Users</span>
            </Link>
          </li>
        }
        {
          permissions.includes('BASIC_HR') &&
          <Fragment>
            <li>
              <Link className={this.isActive('leaves')} to={this.context.company +'/hr/leaves/'} onClick={() => this.setState({ selectedMenu: 'leaves', settingsMenu: false, settingsLabel: false })}>
                <span className="fa_icon">
                  <i className="fa fa-calendar-check" title="Leaves"></i>
                </span>
                <span className="menu_text">Leaves</span> 
              </Link>
            </li>
            <li>
              <Link className={this.isActive('work-from-home')} to={this.context.company +'/hr/work-from-home/'} onClick={() => this.setState({ selectedMenu: 'work-from-home', settingsMenu: false, settingsLabel: false })}>
                <span className="fa_icon">
                  <i className="fa fa-calendar-check" title="Work From Home"></i>
                </span>
                <span className="menu_text">Work From Home</span> 
              </Link>
            </li>
            <li>
              <Link className={this.isActive('holidays')} to={this.context.company +'/hr/holidays/'} onClick={() => this.setState({ selectedMenu: 'holidays', settingsMenu: false, settingsLabel: false })}>
                <span className="fa_icon">
                  <i className="fa fa-calendar-alt" title="Holidays"></i>
                </span>
                <span className="menu_text">Holidays</span> 
              </Link>
            </li>
            {permissions.includes('PAYROLLS') &&
              <li>
                <Link className={this.isActive('payroll')} to={this.context.company +'/hr/payroll/'} onClick={() => this.setState({ selectedMenu: 'payroll', settingsLabel: false })}>
                  <span className="fa_icon">
                    <i className="fa fa-dollar-sign" title="Payroll"></i>
                  </span>
                  <span className="menu_text">Payroll</span> 
                </Link>
              </li>
            }
            {
              <li>
                <Link className={this.isActive('payslips')} to={this.context.company +'/hr/payslips/'} onClick={() => this.setState({ selectedMenu: 'payslips', settingsMenu: false, settingsLabel: false })}>
                  <span className="fa_icon">
                    <i className="fa fa-folder" title="Payslips"></i>
                  </span>
                  <span className="menu_text">Payslips</span> 
                </Link>
              </li>
            }
            <li>
              <Link className={this.isActive('resources')} to={this.context.company +'/hr/resources/'} onClick={() => this.setState({ selectedMenu: 'resources', settingsMenu: false, settingsLabel: false })}>
                <span className="fa_icon">
                  <i className="fa fa-folder" title="Payslips"></i>
                </span>
                <span className="menu_text">Resources</span> 
              </Link>
            </li>
          </Fragment>
        }
        <li>
          <Link className={this.isActive('activity')} to={this.context.company +'/hr/activity/'} onClick={() => this.setState({ selectedMenu: 'activity', settingsMenu: false, settingsLabel: false })}>
            <span className="fa_icon">
              <i className="fa fa-history" title="Worklog"></i>
            </span>
            <span className="menu_text">Activity</span> 
          </Link>
        </li>
        {
          permissions.includes('BASIC_HR') &&
          <Fragment>
            <li>
              <Link className={this.isActive('documents')} to={this.context.company +'/hr/documents/'} onClick={() => this.setState({ selectedMenu: 'documents', settingsMenu: false, settingsLabel: false })}>
                <span className="fa_icon">
                  <i className="fa fa-folder" title="Documents"></i>
                </span>
                <span className="menu_text">Documents</span> 
              </Link>
            </li>
            <li>
              <Link className={this.isActive('meetings')} to={this.context.company +'/hr/meetings/'} onClick={() => this.setState({ selectedMenu: 'meetings', settingsMenu: false, settingsLabel: false })}>
                <span className="fa_icon">
                  <i className="fa fa-users" title="Meetings"></i>
                </span>
                <span className="menu_text">Meetings</span> 
              </Link>
            </li>
          </Fragment>
        }
        {
          permissions.includes('SETTINGS') &&
          <Fragment>
            <li className="btn-group dropdown task_dropdown">
              <a className={(this.state.settingsLabel || this.state.selectedMenu === 'workingdays' || this.state.selectedMenu === 'designations' || this.state.selectedMenu === 'default-leave-settings') ? 'settings active' : ''}
                onClick={() => this.setState({ settingsMenu: !this.state.settingsMenu, settingsLabel: true})}>
                <span className="fa_icon"><i className="fa fa-cogs fa-w-12" title="Settings" /></span>
                <span className="menu_text">Settings</span>
              </a>
              {this.state.settingsMenu && 
                <ul className="settings_menu">
                  <li>
                    <Link className={this.isActive('workingdays')} to={this.context.company +'/hr/workingdays/'} onClick={() => this.setState({ selectedMenu: 'workingdays', settingsMenu: false})}>
                      <span className="menu_text">Worksets</span> 
                    </Link>
                  </li>
                  <li>
                    <Link className={this.isActive('designations')} to={this.context.company +'/hr/designations/'} onClick={() => this.setState({ selectedMenu: 'designations', settingsMenu: false})}>
                      <span className="menu_text">Designations</span> 
                    </Link>
                  </li>
                  <li>
                    <Link className={this.isActive('default-leave-settings')} to={this.context.company +'/hr/default-leave-settings/'} onClick={() => this.setState({ selectedMenu: 'default-leave-settings', settingsMenu: false})}>
                      <span className="menu_text">Default Leave Settings</span> 
                    </Link>
                  </li>
                </ul>
              }
            </li>
          </Fragment>
        }
        <li>
          <Link className={this.isActive('live')} to={this.context.company +'/hr/live/'} onClick={() => this.setState({ selectedMenu: 'live', settingsMenu: false, settingsLabel: false })}>
            <span className="fa_icon">
              <i className="fa fa-signal" title="Live"></i>
            </span>
            <span className="menu_text">Online</span> 
          </Link>
        </li>
        {permissions.includes('HR_ADMIN') &&
          <li>
            <Link className={this.isActive('work-summary')} to={this.context.company +'/hr/work-summary/'} onClick={() => this.setState({ selectedMenu: 'work-summary', settingsMenu: false, settingsLabel: false })}>
              <span className="fa_icon">
                <i className="fa fa-history" title="Work Summary"></i>
              </span>
              <span className="menu_text">Work Summary</span> 
            </Link>
          </li>
        }
      </ul>
    );
  }
}

