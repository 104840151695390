import React, { Component } from 'react';
import { fetch_get, PROFILE, APP_NAME, Context } from './common';

export default class Profile extends Component {
  static contextType =  Context;
  constructor(props) {
    super(props);
    this.state = {
      timezone_choices: [],
      gender: '',
      date_of_birth: '',
    };
  }

  componentDidMount() {
    fetch_get(PROFILE)
    .then((response) => {
      const profile = response.data;
      const user = response.data.user_details.data;
      this.setState({
        gender: profile.gender,
        timezone_choices: response.timezone_choices,
        date_of_birth: profile.date_of_birth,
        first_name: user.first_name,
        last_name: user.last_name,
        timezone: user.timezone,
        father_name: profile.father_name,
        phone_number: profile.phone_number,
        emergency_mobile_number: profile.emergency_mobile_number,
        pan_num: profile.pan_num,
        adhar_number: profile.adhar_number,
        passport: profile.passport,
        present_address: profile.present_address,
        permanent_address: profile.permanent_address,
        about: profile.about,
        profile_pic_path: profile.profile_img
      });
      this.context.updateProfilePic(profile.profile_img)
    });
  }

  render() {
    var company = '/' + localStorage.getItem('company');
    const { first_name, last_name, father_name, phone_number, gender,
      emergency_mobile_number, present_address, permanent_address, adhar_number,
      timezone, pan_num, about, date_of_birth, passport, profile_pic_path } = this.state;
    return (
      <div className="row mar_lr_0 justify-content-center">
      <div className="col-lg-10">
        <div className="col-lg-12">
          <h2 className="heading">Profile Details<span onClick={() => this.props.history.push(APP_NAME + company + '/profile/update')} className="float-right primary_btn">Edit</span></h2>
        </div>
        <div className="col-lg-12 tab-content personal-content pt-5">
          <div className="row mar_lr_0 h-100 justify-content-center profile-detail-user">
            <div className="col-lg-6">
              <table className="table table-hover table-user-information">
                <tbody>
                  <tr>
                    <td>First Name :</td>
                    <td>{first_name}</td>
                  </tr>
                  <tr>
                    <td>Last Name : </td>
                    <td>{last_name ? last_name : '--'}</td>
                  </tr>
                  <tr>
                    <td>Date of Birth   :</td>
                    <td>{date_of_birth ? date_of_birth : '--'}</td>
                  </tr>
                  <tr>
                    <td>Father Name:</td>
                    <td>{father_name ? father_name : '--'}</td>
                  </tr>
                  <tr>
                    <td>Phone Number:</td>
                    <td>{phone_number ? phone_number : '--'}</td>
                  </tr>
                  <tr>
                    <td>Emergency Mobile Number :</td>
                    <td>{emergency_mobile_number ? emergency_mobile_number : '--'}</td>
                  </tr>
                  <tr>
                    <td>PAN Number :</td>
                    <td>{pan_num ? pan_num : '--'}</td>
                  </tr>
                  <tr>
                    <td>Gender :</td>
                    <td>{gender ? gender : '--'}</td>
                  </tr>
                  <tr>
                    <td>Passport Number :</td>
                    <td>{passport ? passport : '--'}</td>
                  </tr>
                  <tr>
                    <td>Time Zone :</td>
                    <td>{timezone ? timezone : '--'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-6">
              <table className="table table-hover table-user-information ">
                <tbody>
                  <tr>
                    <td>Aadhar Number :</td>
                    <td>{adhar_number ? adhar_number : '--'}</td>
                  </tr>
                  <tr>
                    <td>Present Address :</td>
                    <td>{present_address ? present_address : '--'}</td>
                  </tr>
                  <tr>
                    <td>Permanent Address :</td>
                    <td>{permanent_address ? permanent_address : '--'}</td>
                  </tr>
                  <tr>
                    <td>About :</td>
                    <td>{about ? about : '--'}</td>
                  </tr>
                  <tr>
                    <td>Profile Picture </td>
                    <td>
                      <br/>
                      {
                        profile_pic_path ?
                        <img src={profile_pic_path} style={{height: '120px', width: '120px'}} alt='' /> :
                        null
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}