import React, { Component } from 'react'
import { RESET_PASSWORD, APP_NAME, LOGIN_DOMAIN_NAME } from '../common'
import logo from '../../static/images/logo.png'

export default class ResetPassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
      newPassword: '',
      confirmPassword: '',
      errors: {}
    }
    this.handleNewPassword = this.handleNewPassword.bind(this)
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this)
  }

  handleNewPassword (event) {
    this.setState({ newPassword: event.target.value, error: '' })
  }

  handleConfirmPassword (event) {
    this.setState({ confirmPassword: event.target.value, error: '' })
  }

  savePassword () {
    const subDomain = LOGIN_DOMAIN_NAME
    const data = new FormData()
    data.append('new_password1', this.state.newPassword)
    data.append('new_password2', this.state.confirmPassword)
    data.append('uidb64', this.props.match.params.uid)
    data.append('token', this.props.match.params.token)
    fetch(subDomain + RESET_PASSWORD, {
      method: 'post',
      body: data
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          alert(response.message)
          this.props.history.push(APP_NAME + '/signin')
        } else {
          this.setState({ errors: response.errors })
        }
      })
      .catch(() => {
        this.setState({ error: 'Something went wrong, Please try again' })
      })
  }

  render () {
    const { errors } = this.state
    return (
      <div className='auth_wrapper'>
        <div style={{ textAlign: 'center' }}>
          <img alt='' src={logo} />
        </div>
        <div className='title'>
          Reset Password
        </div>
        <form>
          <div className='form-group'>
            <label htmlFor='password'>New Password</label>
            <input
              type='password'
              className='form-control'
              id='password'
              placeholder='Enter new password'
              value={this.state.newPassword}
              onChange={this.handleNewPassword}
            />
            <div className='error-text'>
              {errors.new_password1 ? errors.new_password1[0] : null}
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='password1'>Confirm Password</label>
            <input
              type='password'
              className='form-control'
              id='password1'
              placeholder='Enter Confirm Password'
              value={this.state.confirmPassword}
              onChange={this.handleConfirmPassword}
            />
            <div className='error-text'>
              {errors.new_password2 ? errors.new_password2[0] : errors.__all__ ? errors.__all__[0] : null}
            </div>
          </div>
          <div className='auth_buttons'>
            <button type='button' className='btn btn-primary' onClick={this.savePassword.bind(this)}>Reset</button>
            <button type='button' className='btn btn-primary' onClick={() => this.props.history.push(APP_NAME + '/signin')}>Login</button>
            <br clear='all' />
          </div>
        </form>
      </div>
    )
  }
}
