import React, { Component, Fragment } from 'react'
import { fetch_post, fetch_get, PROJECTS_LIST, notify, Spinner } from '../../common'
import LabelBoard from './LabelBoard'
// import './App.css';

export default class TasksKanban extends Component {
  constructor (props) {
    super(props)
    this.state = {
      statusName: '',
      showLabelPicker: false,
      labelColor: '#ca2222',
      boards: [],
      errors: {},
      boardIds: [],
      loading: false,
      statusId: []
    }
    this.addEditStatus = this.addEditStatus.bind(this)
    this.updateBoards = this.updateBoards.bind(this)
    this.updateOnlyBoard = this.updateOnlyBoard.bind(this)
  }

  componentDidMount () {
    this.setState({ loading: true })
    this.fetchBoards()
  }

  fetchBoards () {
    fetch_get(PROJECTS_LIST + this.props.props.match.params.id + '/kanban/')
      .then((response) => {
        this.setState({ boards: response, loading: false })
      })
  }

  componentDidUpdate () {
    if (this.props.boardIds.length > 0) {
      this.updateBoards(this.props.boardIds)
      this.props.updateKanbanBoards([])
    }
  }

  addEditStatus (statusId = null, statusName = this.state.statusName, labelColor = this.state.labelColor) {
    if (statusName !== '') {
      const data = new FormData()
      data.append('name', statusName)
      data.append('color', labelColor)
      data.append('is_visible_in_board', true)
      const url = statusId ? `${statusId}/update/` : 'create/'
      fetch_post(`${PROJECTS_LIST}${this.props.props.match.params.id}/task-status/${url}`, data)
        .then((response) => {
          if (response.error) {
            if (statusId) {
              (response.errors.name) && notify(response.errors.name)
            } else {
              this.setState({ errors: response.errors })
            }
          } else {
            notify(`Status ${statusId ? 'updated' : 'Added'} successfully`)
            if (statusId) {
              this.setState({ statusId: [statusId] })
            } else {
              this.fetchBoards()
            }
          }
        })
    }
  }

  updateBoards (boardIds) {
    this.setState({ boardIds })
  }

  updateOnlyBoard (statusId) {
    this.setState({ statusId })
  }

  render () {
    return (
      <div className='kanban'>
        {this.state.loading
          ? <Spinner />
          : <section className='lists-container'>
            {this.state.boards.task_statuses && this.state.boards.task_statuses.data.length > 0 ? this.state.boards.task_statuses.data.map((board) =>
              Object.entries(this.state.boards.tasks_data).map(([key, value], index) =>
                <Fragment key={index}>
                  {(parseInt(key) === board.id) &&
                    <LabelBoard
                      tasks={value}
                      key={board.id}
                      labelBoard={board}
                      props={this.props}
                      updateBoardIds={this.state.boardIds}
                      updateBoards={this.updateBoards}
                      addEditStatus={this.addEditStatus}
                      updateOnlyBoardId={this.state.statusId}
                      updateOnlyBoard={this.updateOnlyBoard}
                      showTaskDetail={this.props.showTaskDetail}
                      addTask={this.props.addTask}
                    />}
                </Fragment>
              )
            ) : null}
            <div className='list to_do'>
              <div className='task_detail_heading'>
                <div className='task_title list-title form-group'>
                  {this.state.createStatus
                    ? <textarea
                        autoFocus
                        className='task_detail_title form-control'
                        rows='1'
                        placeholder='Enter Status Name'
                        value={this.state.statusName}
                        onChange={(e) => this.setState({ statusName: e.target.value })}
                        onBlur={() => { this.addEditStatus(null, this.state.statusName, this.state.labelColor); this.setState({ createStatus: false }) }}
                      >
                      Tasks to Do
                      </textarea>
                    :
                    // <div className="heading list-title">{board.data.name}</div>
                    <div className='heading list-title' onClick={() => this.setState({ createStatus: true })}>Add another Status Board</div>}
                </div>
                <div className='kanban_model'><span className='count' onClick={() => this.setState({ createStatus: true })}><i className='fas fa-plus' /></span></div>
              </div>
            </div>
          </section>}
      </div>
    )
  }
}
