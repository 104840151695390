import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FORGOT_PASSWORD, APP_NAME, LOGIN_DOMAIN_NAME } from '../common';
import logo from '../../static/images/logo.png'; 
export default class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
    this.resetPassword = this.resetPassword.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  componentDidMount() {
    document.body.classList.add("authentication_body");
    if (localStorage.getItem('token')) {
      this.props.history.push(APP_NAME + '/intro')
    }
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value, error: '' });
  }

  componentWillUnmount() {
    document.body.classList.remove("authentication_body");
  }

  resetPassword(e) {
    e.preventDefault();
    const email = this.state.email;
    if (email !== '') {
      let data = new FormData()
      data.append('email', email)
      fetch(LOGIN_DOMAIN_NAME + FORGOT_PASSWORD, {
        method: 'post',
        body: data
      })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          alert(response.message);
          this.props.history.push(APP_NAME + '/signin');
        }
        else {
          this.setState({ error: response.errors.__all__ });
        }
      })
      .catch(() => {
        this.setState({ error: 'Something went wrong, Please try again' });
      });
    }
    else {
      this.setState({ error: 'This field is required' });
    }  
  }

  handleKeyPress = (event) => {
    event.preventDefault();
    if(event.key === 'Enter'){
      this.resetPassword()
    }
  }

  render() {
    return (
      <div className="authentication_wrapper">
        <div className="authentication_block">
          <div className="left">

            <div className="heading">Forgot Password</div>
            <div className="auth_form">
              <form>
                <div className="form-group">
                  <input type="text" id="name" className="form-control1" required onChange={this.handleChangeEmail} value={this.state.email} />
                  <label className="form-control-placeholder" htmlFor="name">Enter Your Email Address</label>
                  <small className="hint">A temporary link will be sent to your registered email id</small>
                  <div className="error-text">{this.state.error}</div>
                </div>
                <div className="buttons">
                  <button type="submit" className="btn primary_btn" onClick={this.resetPassword.bind(this)}>Reset Password</button>
                </div>
              </form>
            </div>
          </div> 
          <div className="right forgot-psd">
            <img alt="" src={logo}/>
            <strong>Back to Login?</strong>
            <div className="buttons">
              <Link to={APP_NAME + '/signin'} className="primary_btn btn">Login</Link>
            </div>
          </div> 
        </div>
      </div>
    );
  }
}
