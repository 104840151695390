import React, { Component } from 'react';
import Select from 'react-select';
import {
  fetch_get,
  fetch_post,
  PROJECTS_LIST,
  notify,
  Modal,
  Context
} from '../../../common';

export default class GitLab extends Component {
  static contextType = Context
  constructor(props) {
    super(props);
    this.state = {
      apiUrl: '',
      errors: {},
      projects: [],
      labels: [],
      assignedList: [],
      label: [],
      member: [],
      hostScheme: 'https://',
      secretKey: '',
      project: '',
      moreOptions: false,
      visibleStatus: '',
      gitlabDetails: false,
      gitlabDetailsList: [],
      priority: '',
      issueStatus: '',
      projectId: this.props.projectId,
      edit: false,
      hostName: '',
      show: false,
      allowSync: 'True',
      authToken: '',
      loading: true
    }
  }

  componentDidMount() {
    this.intialGitlabDetails();
    this.moreSettings();
    this.initialState = this.state;
  }

  intialGitlabDetails() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/gitlab/add/')
    .then(response => {
      this.setState({ 
        apiUrl: response.default_api_url,
        loading: false
      });
      if(response.gitlab.data) {
        this.setState({ gitlabDetailsList: response.gitlab.data, gitlabDetails: true });
      }
    })
  }

  getProjects(hostName=this.state.hostName, secretKey=this.state.secretKey) {
    this.setState({ errors: {} });
    const data = new FormData();
    data.append('host_name', hostName)
    data.append('scheme', this.state.hostScheme);
    data.append('secret_key', secretKey);
    data.append('api_url', this.state.apiUrl);
    fetch_post(PROJECTS_LIST + this.state.projectId +'/integrations/gitlab/get_gitlab_projects/', data)
    .then(response => {
      if(response.error) {
        this.setState({ errors: response.errors });
      } else {
        this.setState({ projects: response.projects_list })
      }
    })
  }

  moreSettings() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/gitlab/default-options/')
    .then(response => {
      var members = [];
      var labelsList = [];
      response.labels.data.filter(function( obj ) {
        return (
          labelsList.push({label: obj.name, value: obj.id})
        )
      });
      response.profiles.data.filter(function( obj ) {
        return (
          members.push({
            label: 
            <div>
              <img className="select_img" src={obj.profile_img}  alt={obj.employee_name}/>
              {obj.employee_name.split(" ")[0]}
            </div>, 
            value: obj.id
          })
        )
      });
      this.setState({ 
        labels: labelsList,
        assignedMembersList: members,
        moreOptions: true,
        priorities: response.priorities,
        issueStatuses: response.issue_status_choices,
        visiblleStatuses: response.visible_status_choices,
        errors: {}
      })
    })
  }

  saveDefaultSettings() {
    this.setState({ errors: {} });
    const data = new FormData();
    if(this.state.label.length > 0) {
      this.state.label.filter(function( obj ) {
        return (
          data.append('labels', obj.value)
        )
      });
    }
    if(this.state.member) {
      this.state.member.filter(function( obj ) {
        return (
          data.append('assigned_to', obj.value)
        )
      });
    }
    data.append('issue_status', this.state.issueStatus)
    data.append('visible_status', this.state.visibleStatus)
    data.append('projects_list', JSON.stringify(this.state.projects));
    data.append('host_name', this.state.hostName)
    data.append('scheme', this.state.hostScheme);
    data.append('secret_key', this.state.secretKey);
    data.append('allow_sync', this.state.allowSync)
    data.append('api_url', this.state.apiUrl);
    data.append('project', this.state.project);
    data.append('priorities', this.state.priority)
    var url = this.state.edit ? 'update/' + this.state.gitlabDetailsList.id + '/' : 'default-options/';
    fetch_post(PROJECTS_LIST + this.state.projectId +'/integrations/gitlab/'+ url, data)
    .then(response => {
      if(response.error) {
        this.setState({ errors: response.errors })
      } else {
        this.intialGitlabDetails();
        notify(response.response);
        this.context.updateCount('true');
      }
    });
  }

  onLabelSelect(value) {
    this.setState({ label: value });
  }

  onMemberSelect(value) {
    this.setState({ member: value });
  }

  setValue(e) {
    this.setState({ visibleStatus: e.target.value });
  }

  setValueIssue(e) {
    this.setState({ issueStatus: e.target.value });
  }

  cancelDetails() {
    if(this.state.edit) {
      this.setState({ moreOptions: true, gitlabDetails: true })
    }
  }

  editGitlabDetails() {
    const { gitlabDetailsList } = this.state;
    var hostScheme = gitlabDetailsList.host_name.split('//')[0] + '//';
    var labels = [];
    if(gitlabDetailsList.default_values.labels.data) {
      gitlabDetailsList.default_values.labels.data.filter(function( obj ) {
        return (
          labels.push({label: obj.name, value: obj.id})
        )
      });
    }
    if(gitlabDetailsList.default_values.assigned_to.data.length > 0) {
      var member = [];
      gitlabDetailsList.default_values.assigned_to.data.filter(function( obj ) {
        return (
          member.push({
            label: 
            <div>
              <img className="select_img" src={obj.profile_img} alt={obj.employee_name}/>
              {obj.employee_name.split(" ")[0]}
            </div>, 
            value: obj.id
          })
        )
      });
    }
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/gitlab/update/' + gitlabDetailsList.id + '/')
    .then(response => {
      this.setState({ 
        apiUrl: gitlabDetailsList.api_url,
        authToken: gitlabDetailsList.auth_token,
        hostScheme:  hostScheme,
        hostName: gitlabDetailsList.host_name.split('//')[1],
        project: gitlabDetailsList.project_uid,
        gitlabDetails: false,
        label: labels,
        member: member,
        priority: gitlabDetailsList.default_values.priorities.data ? gitlabDetailsList.default_values.priorities.data.id : '',
        visiblleStatuses: response.visible_status_choices,
        issueStatuses: response.issue_status_choices,
        moreOptions: true,
        issueStatus: gitlabDetailsList.default_values.issue_status,
        visibleStatus: gitlabDetailsList.default_values.visible_status,
        secretKey: gitlabDetailsList.secret_key,
        edit: true
      });
    })
    gitlabDetailsList.allow_sync === true ? this.setState({ allowSync: 'True' }) : this.setState({ allowSync: 'False' })
    this.getProjects(gitlabDetailsList.host_name.split('//')[1], gitlabDetailsList.secret_key, gitlabDetailsList.org_slug);
  }

  deleteGitlabSettings() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/gitlab/delete/' + this.state.gitlabDetailsList.id + '/', true)
    .then(response => {
      if(response.status === 204) {
        this.setState({ displayDeleteModal: false })
        notify('Deleted Gitlab Default settings');
        this.context.updateCount('true');
        this.setState(this.initialState);
        this.intialGitlabDetails();
        this.moreSettings();
      }
    })
  }

  LoadGitLabIssues() {
    fetch_get(PROJECTS_LIST + this.state.projectId +'/integrations/gitlab/load-issues/' + this.state.gitlabDetailsList.id + '/')
    .then( () => {})
  }

  render() {
    const { permissions, role } = this.context;
    const { visiblleStatuses, issueStatuses, gitlabDetailsList, priorities } = this.state;
    return(
      <div className="row integration_list_view ">
        <div className="col-lg-12 pl-0 integration_container">
          <strong> Gitlab</strong>
          {this.state.show &&
            <div className="integration_content" style={{ display: 'block' }}>
              <p>GitLab is an online Git repository manager with a wiki, issue tracking, CI and CD. It is a great way to manage git repositories on a centralized server. GitLab gives you complete control over your repositories or projects and allows you to decide whether they are public or private for free.</p>
              <strong> Instructions</strong>
              <p>
                <b>Host Name</b><br/>
                Host name must be your registered organization gitlab host_url
                Example: https://abc.xyz.com (or) http://abc.xyz.com
              </p>
              <p>
                <b>The Private token is available in your User Settings</b><br />
                 &gt;&gt; Settings <br/> &gt;&gt; Access Tokens (Keep these tokens secret, anyone with access to them can interact with GitLab as if they were you.)
              </p>
            </div>
          }
          <div className="sliding_bar">
            {this.state.show &&
              <span className="arrow-up" onClick={() => this.setState({ show: false }) }>
                <i className="fa fa-times"></i>
              </span>
            }
            { !this.state.show &&
              <span className="arrow-down" onClick={() => this.setState({ show: true }) }>
                <i className="fa fa-question"></i>
              </span>
            }
          </div>
        </div>
        {!this.state.gitlabDetails ?
          <div className="col-lg-12 pl-0 form">
            {!this.state.loading ?
              <form>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>Host Name<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                        {this.state.edit ? 
                          <input 
                            type="text"
                            className="form-control" 
                            placeholder="Enter Host name" 
                            value={this.state.hostScheme + this.state.hostName}
                            onChange={() => {}}
                            disabled
                          />
                        :
                          <div className="input-group">
                            <span className="input-group-btn">
                              <select 
                                className="form-control select"
                                value={this.state.hostScheme} 
                                onChange={(e) => this.setState({ hostScheme: e.target.value })}
                              >
                                <option value="http://">http://</option>
                                <option value="https://">https://</option>
                              </select>
                            </span>
                            <input 
                              type="text" 
                              className="form-control" 
                              placeholder="Enter Host name" 
                              value={this.state.hostName}
                              onChange={(e) => this.setState({ hostName: e.target.value })}
                            /> 
                          </div>
                        }
                        <div className="error-text">
                          {this.state.errors.scheme && this.state.errors.scheme }
                          {this.state.errors.host_name && this.state.errors.host_name}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>API Url<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                        <input type="text" className="form-control " value={this.state.apiUrl} onChange={() => {}} placeholder=" url" disabled />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>Secret Key<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                        <input 
                          type="text" 
                          value={this.state.secretKey} 
                          onChange={(e) => this.setState({ secretKey: e.target.value})} 
                          className="form-control " 
                          placeholder="token" 
                          disabled={this.state.edit}
                        />
                        <div className="error-text">
                          {this.state.errors.secret_key && this.state.errors.secret_key }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8">
                      {this.state.errors.message &&
                        <div className="error-text" dangerouslySetInnerHTML={{ __html: this.state.errors.message }} />
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row justify-content-start">
                        <div className="col-lg-6">
                          {!this.state.edit && 
                            <div className="save_application">
                              <button type="button" className="btn primary_btn" onClick={() => this.getProjects()}>Get Project</button>
                            </div>
                          }
                          {this.state.projects.length > 0 && 
                            <div className="form-group ">
                              <label>Projects<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                              {this.state.edit ?
                                <input 
                                  type="text"
                                  className="form-control" 
                                  placeholder="Enter Host name" 
                                  value={gitlabDetailsList.project_slug}
                                  onChange={() => {}}
                                  disabled
                                /> 
                                : 
                                <select 
                                  className="form-control " 
                                  value={this.state.project}
                                  onChange={(e) => {this.setState({ project: e.target.value}); this.moreSettings(e.target.value) }}
                                >
                                  <option value=""> ----- </option>
                                  {this.state.projects.map((projectObj) => 
                                    <option key={projectObj.value} value={projectObj.value}>{projectObj.name}</option>
                                  )}
                                </select>
                              }
                              <div className="error-text"></div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                {this.state.project && this.state.moreOptions && 
                  <div> 
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="form-group ">
                              <label>Labels</label>
                              <Select
                                isMulti
                                onChange={(e) => {this.onLabelSelect(e)}}
                                options={this.state.labels}
                                value={this.state.label}
                                placeholder="select label(s)"
                              />
                              <div className="error-text">
                                {this.state.errors.labels ? this.state.errors.labels[0] : null }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group ">
                              <label>Priority</label>
                              <select 
                                className="form-control "
                                value={this.state.priority}
                                onChange={(e) => this.setState({ priority: e.target.value })}
                              >
                                <option value=""> ----- </option>
                                {priorities ? priorities.data.map((priority) => 
                                  <option key={priority.id} value={priority.id}>{priority.name}</option>
                                ) : null}
                              </select>
                              <div className="error-text">
                                {this.state.errors.priorities ? this.state.errors.priorities[0] : null }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label>Assign To{this.state.visibleStatus === 'private' && <sup className="error-text" style={{top: '0px'}}>*</sup>}</label>
                              <Select
                                isMulti
                                onChange={(e) => {this.onMemberSelect(e)}}
                                options={this.state.assignedMembersList}
                                value={this.state.member}
                                placeholder="select member(s)"
                              />
                              <div className="error-text"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label>Visible Status<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                              {visiblleStatuses ? visiblleStatuses.map((visible) =>
                                <div key={visible[0]} className="form-check">
                                  <input 
                                    type="radio" 
                                    className="form-check-input"
                                    id={visible[0]}
                                    value={visible[0]}
                                    checked={this.state.visibleStatus == visible[0]}
                                    onChange={this.setValue.bind(this)}
                                  />
                                  <label htmlFor={visible[0]} className="form-check-label">
                                    {visible[1]}
                                  </label>
                                </div>
                              ) : null}
                              <div className="error-text">
                                {this.state.errors.visible_status ? this.state.errors.visible_status[0] : null }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 ">
                            <div className="form-group">
                              <label>Issue Status<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                              {issueStatuses ? issueStatuses.map((issue) => 
                                <div key={issue[0]} className="form-check">
                                  <input 
                                    type="radio" 
                                    className="form-check-input" 
                                    id={issue[0]}
                                    value={issue[0]}
                                    checked={this.state.issueStatus == issue[0]}
                                    onChange={this.setValueIssue.bind(this)}
                                  />
                                  <label htmlFor={issue[0]} className="form-check-label">
                                    {issue[1]}
                                  </label>
                                </div>
                              ): null}
                              <div className="error-text">
                                {this.state.errors.issue_status ? this.state.errors.issue_status[0] : null }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label>Sync<sup className="error-text" style={{top: '0px'}}>*</sup></label>
                              <select 
                                className="form-control " 
                                onChange={(e) => {this.setState({ allowSync: e.target.value }) }}
                                value={this.state.allowSync}
                              >
                                <option value="True"> True </option>
                                <option value="False">False</option>
                              </select>
                              <div className="error-text">
                                {this.state.errors.allow_sync && this.state.errors.allow_sync}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="error-text">
                        { this.state.errors.__all__ && this.state.errors.__all__[0] }
                      </div>
                    </div>
                    <div className="row mar_lr_0">
                      <div className="col-lg-12 text-center buttons">
                        <button type="button" className="btn primary_btn" onClick={() => this.saveDefaultSettings()}>Sync</button>
                        <button type="button" className="btn secondary_btn" onClick={() => this.cancelDetails()}>Cancel</button>
                      </div>
                    </div>
                  </div>
                }
              </form>
              :
              <div>Loading ...</div>
            }
          </div>
          :
          <div className="col-lg-12 detailed_content">
            <div className="row">
              {(permissions.includes('PROJECT_ADMIN') || role == true) &&
                <div className="col-lg-12 text-right">
                  <button type="button" className="btn primary_btn" onClick={() => this.editGitlabDetails()}>Edit</button>
                  <button type="button" className="btn secondary_btn" onClick={() => this.setState({ displayDeleteModal: true })}>Delete</button>
                  <button type="button" className="btn secondary_btn" onClick={() => this.LoadGitLabIssues()}>Refresh</button>
                </div>
              }
              {gitlabDetailsList &&
                <div className="row ">
                  <div className="col-lg-6">
                    <table className="table table-hover table-user-information ">
                      <tbody>
                        <tr>
                          <td>Host Name:</td>
                          <td>{gitlabDetailsList.host_name}</td>
                        </tr>
                        <tr>
                          <td>API Url:</td>
                          <td>{gitlabDetailsList.api_url}</td>
                        </tr>
                        <tr>
                          <td>Secret Key:</td>
                          <td>{gitlabDetailsList.secret_key}</td>
                        </tr>
                        <tr>
                          <td>Project:</td>
                          <td>{gitlabDetailsList.project_slug}</td>
                        </tr>
                        <tr>
                          <td>Git Lab ID:</td>
                          <td>{gitlabDetailsList.project_uid}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-6">
                    <table className="table table-hover table-user-information ">
                      <tbody>
                        <tr>
                          <td>Labels:</td>
                          <td>
                            {gitlabDetailsList.default_values.labels.data ? gitlabDetailsList.default_values.labels.data.map((label) => 
                              <a key={label.id}>{label.name}</a>
                            ) : '--'}
                          </td>
                        </tr>
                        <tr>
                          <td>Priority:</td>
                          <td>{gitlabDetailsList.default_values.priorities.data ? gitlabDetailsList.default_values.priorities.data.name : '--'}</td>
                        </tr>
                        <tr>
                          <td>Assigned To:</td>
                          <td>
                            <div className="team_members">
                              {gitlabDetailsList.default_values.assigned_to.data.length > 0 ? gitlabDetailsList.default_values.assigned_to.data.map((member) => 
                                <a key={member.id} className="member">
                                  <img 
                                    src={member.profile_img} 
                                    data-toggle="tooltip" 
                                    data-placement="bottom" 
                                    title={member.employee_name} 
                                    data-original-title={member.employee_name}
                                    alt={member.employee_name} 
                                  />
                                </a>
                              ) : '--'}
                          </div>
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">Issue Status:</td>
                          <td>{gitlabDetailsList.default_values.issue_status}</td>
                        </tr>
                        <tr>
                          <td valign="top">Visible Status:</td>
                          <td>{gitlabDetailsList.default_values.visible_status}</td>
                        </tr>
                        <tr>
                          <td valign="top">Sync:</td>
                          <td>{gitlabDetailsList.allow_sync === true ? 'True' : 'False'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              }
            </div>
          </div>
        }
        <Modal
          display={this.state.displayDeleteModal}
          bodyText='Are you sure? You want to delete?'
          modalAction={this.deleteGitlabSettings.bind(this)}
          removeModal={() => this.setState({ displayDeleteModal: false })}
        />
      </div>
    )
  }
}
