import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../static/images/logo.png'
import {
  VALIDATE_SUBDOMAIN,
  VALIDATE_NEW_SUBDOMAIN,
  APP_NAME,
  LOGIN_SERVER
} from '../common'

export default class RegisterDomain extends Component {
  constructor (props) {
    super(props)
    this.state = {
      subDomain: this.props.history.location.state ? this.props.history.location.state.domain : '',
      error: '',
      nextRoute: ''
    }
    this.handleChangeSubdomain = this.handleChangeSubdomain.bind(this)
  }

  componentDidMount () {
    document.body.classList.add('authentication_body')
    localStorage.clear()
    if (localStorage.getItem('token')) {
      this.props.history.push(APP_NAME + '/intro')
    } else {
      if (window.location.host.split('.')[0] !== 'microtrack') {
        const sub_domain = window.location.host.split('.')[0]
        const data = new FormData()
        data.append('sub_domain', sub_domain)
        fetch(VALIDATE_SUBDOMAIN, {
          method: 'POST',
          body: data
        })
          .then(response => response.json())
          .then((response) => {
            if (!response.error) {
              localStorage.setItem('company', sub_domain)
              const redirectUrl = `//${sub_domain + LOGIN_SERVER}/signin`
              window.location.href = redirectUrl
            }
          })
      }
    }
  }

  handleChangeSubdomain (event) {
    this.setState({ subDomain: event.target.value })
    if (event.target.value === '') {
      this.setState({ error: '' })
    }
  }

  validateNewSubdomain (e) {
    e.preventDefault()
    const { subDomain } = this.state
    const data = new FormData()
    data.append('sub_domain', subDomain)
    fetch(VALIDATE_NEW_SUBDOMAIN, {
      method: 'POST',
      body: data
    })
      .then(response => response.json())
      .then((response) => {
        if (!response.error) {
          localStorage.setItem('company', subDomain)
          const redirectUrl = `//${subDomain + LOGIN_SERVER}/register`
          window.location.href = redirectUrl
        } else {
          this.setState({ error: response.errors.sub_domain[0] })
        }
      })
      .catch(() => {
        this.setState({ error: 'Something went wrong, Please try again' })
      })
  }

  componentWillUnmount () {
    document.body.classList.remove('authentication_body')
  }

  render () {
    return (
      <div className='authentication_wrapper'>
        <div className='authentication_block'>
          <div className='left'>
            <div className='heading'>Microtrack</div>
            <div className='auth_form'>
              <form>
                <div className='form-group'>
                  <input
                    type='text'
                    id='name'
                    className='form-control1'
                    onChange={this.handleChangeSubdomain}
                    value={this.state.subDomain}
                    required
                  />
                  <label className='form-control-placeholder' htmlFor='name'>Sub domain</label>
                  {this.state.error &&
                    <small className='error-text'>{this.state.error}</small>}
                </div>
                <div className='buttons'>
                  <button type='submit' className='btn primary_btn' onClick={this.validateNewSubdomain.bind(this)}>Register</button>
                </div>
              </form>
            </div>
            <br clear='all' />
          </div>
          <div className='right forgot-psd'>
            <img alt='' src={logo} />
            <strong>Already have an account?</strong>
            <div className='buttons'>
              <Link to={APP_NAME + '/login-domain'} className='primary_btn btn'>Login</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
