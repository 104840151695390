import React, { Component } from 'react'

export default class Unauthorized extends Component {
  render () {
    return (
      <div>
        <center>You DO not have permission to access this page</center>
      </div>
    )
  }
}
