import React, { Component } from 'react'
import { 
  Context,
  fetch_post  
   } from '../../common'  
import { Link } from 'react-router-dom'

export default class TestTypes extends Component {
  static contextType = Context;   
  _isMounted = false;
  constructor(props) {
    super(props)        
    this.state = {
      loading: true,
      testType: '',
      allTestTypes: '',
      errors: '',
      modal: false
    }
    this.projectId = this.props.match.params.id      
  }

  componentDidMount() {
    this._isMounted = true
    this.fetchTestTypes()
  }

  fetchTestTypes() {
    const data = new FormData();
    data.append('project_id', this.projectId)    
    fetch_post('/test/types/', data)
      .then(response => {
        if(this._isMounted) {
          this.setState({ allTestTypes: response.test_type.data })
        }
      })
  }

  addTestType() {        
    const data = new FormData();
    data.append('project_id', this.projectId)
    data.append('name', this.state.testType)
    fetch_post('/test/type/create/', data)
      .then(response => {        
        if(response.error) {
          this.setState({ errors: response.errors })
        } else {          
          document.querySelector('.modal-backdrop').remove()          
          document.querySelector('.modal-open').classList.remove('modal-open')
          this.setState({ modal: false })
          this.setState({ errors: '' })
          this.fetchTestTypes()
        }
      })
      .catch(() => {
        this.setState({ errors:  'Something went wrong, Please try again' })
      })
  }

  addNewTestType() {
    this.setState({ testType: '' , errors: '', modal: true })
  }
  

  render() {               
    return(
      <div className="secondary_block">
        <div className="row mar_lr_0 table_height ">
          <div className="col-lg-12">
            <h2 className="heading mt-3">Test Types 
              <span className="float-right new_btn" data-toggle="modal" data-target="#myModal2"
                   onClick={() => this.addNewTestType()}
                  >
                <i className="fas fa-plus" /> New Test Type
              </span>
            </h2>
          </div>
        </div>      

      {
        this.state.modal ?              
          <div className="modal right side_pop_modal fade" id="myModal2" tabIndex="-1" role="dialog" 
               aria-labelledby="myModalLabel2"
          >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" ><i className="fas fa-times"></i> Close</span>
                </button>
                <h4 className="modal-title" id="myModalLabel2">New Test Type</h4>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Test Type Name</label>
                    <input 
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter test type name"
                      value={this.state.testType}
                      onChange={(e) => this.setState( {testType: e.target.value} )} />
                    </div>
                    <div className="error-text">
                      {this.state.errors.name ? this.state.errors.name[0] : ''}
                    </div>
                    <button type="submit" className="btn primary_btn mt-2" onClick={(e) => this.addTestType(e)}>
                      Add
                    </button>
                </form>
              </div>
            </div>
          </div>
        </div>        
         : ''       
      }       

      <div className="row mar_lr_0 mt-2">
        <div className="col-lg-8">

          <div className="test_types">
            {this._isMounted && this.state.allTestTypes.map(test => (
              <div className="card" key={test.id}>
                <div className="card-body">
                  <Link to={`testing/testcase/${test.id}`} className="title">{test.name}</Link>
                  <div className="options">
                    <span className="option">Total Test Cases: <b>{test.test_cases_count.total_test_cases}</b></span>
                    {test.test_cases_count.passed_test_cases != 0 ? <span className="badge pass">Passed <small>{test.test_cases_count.passed_test_cases}</small></span> : ''}
                    {test.test_cases_count.failed_test_cases != 0 ? <span className="badge fail">Failed <small>{test.test_cases_count.failed_test_cases}</small></span> : ''}
                    {test.test_cases_count["on-hold_test_cases"] != 0 ? <span className="badge na">On Hold <small>{test.test_cases_count["on-hold_test_cases"]}</small></span> : ''}
                    {test.test_cases_count.blocked_test_cases != 0 ? <span className="badge hold">N/A <small>{test.test_cases_count.blocked_test_cases}</small></span> : ''}
                    {test.test_cases_count["N/A_test_cases"] != 0 ? <span className="badge block">Block <small>{test.test_cases_count["N/A_test_cases"]}</small></span> : ''}                                        
                  </div>
                </div>
              </div>
            ))}            
          </div>

        </div> 
      </div> 

    </div> 
    )
  }
}