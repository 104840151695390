import React, { Component } from 'react';
import GoogleLogin from './GoogleLogin';
import { SIGNUP, GOOGLE_SIGNUP, APP_NAME, VALIDATE_NEW_SUBDOMAIN, LOGIN_DOMAIN_NAME } from '../common';
import logo from '../../static/images/logo.png'; 

export default class SignUp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      subDomainUrl: '',
      errors: {},
      value: false,
      validateDomain: null,
      domain: ''
    }

    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
  }

  componentDidMount () {
    document.body.classList.add('authentication_body')
    if (localStorage.getItem('token')) {
      this.props.history.push(APP_NAME + '/intro')
    }
  }

  handleChangeEmail (event) {
    this.setState({ email: event.target.value, errors: {} })
  }

  handleChangePassword (event) {
    this.setState({ password: event.target.value, errors: {} })
  }

  validateNewSubdomain (e) {
    e.preventDefault()
    const { domain } = this.state
    const data = new FormData()
    data.append('sub_domain', domain)
    fetch(VALIDATE_NEW_SUBDOMAIN, {
      method: 'POST',
      body: data
    })
      .then(response => response.json())
      .then((response) => {
        if (!response.error) {
          this.setState({ validateDomain: true })
        // localStorage.setItem('company', subDomain);
        // const redirectUrl = `//${subDomain + LOGIN_SERVER}/app/register`;
        // window.location.href = redirectUrl;
        } else {
          this.setState({ errors: response.errors, validateDomain: false, value: true })
        }
      })
      .catch(() => {
        this.setState({ errors: 'Something went wrong, Please try again' })
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    this.setState({ value: true })
    const { email, password, domain } = this.state
    this.setState({ error: '' })
    const formData = new FormData()
    formData.append('email', email)
    formData.append('password', password)
    formData.append('sub_domain', domain)
    fetch(SIGNUP, {
      method: 'post',
      body: formData
    })
      .then(response => response.json())
      .then((response) => {
        if (!response.error) {
          this.setState({ url: response.subDomainUrl, validateDomain: true })
          this.props.history.push(APP_NAME + '/signin')
          alert(response.message)
        } else {
          if (response.errors.sub_domain) {
            this.setState({ validateDomain: false })
          } else {
            this.setState({ validateDomain: true })
          }
          this.setState({ errors: response.errors, value: true })
        }
      })
  }

  // loginRedirect() {
  //   window.location.href = `//app.pietrack.com/app/login-domain`;
  // }

  responseGoogle (data) {
    if (data.accessToken) {
      const company = localStorage.getItem('company')
      // const domain = '//' + company + SERVER;
      const formData = new FormData()
      formData.append('accessToken', data.accessToken)
      formData.append('sub_domain', company)
      fetch(LOGIN_DOMAIN_NAME + GOOGLE_SIGNUP, {
        method: 'post',
        body: formData
      })
        .then(response => response.json())
        .then((response) => {
          if (!response.error) {
          // const token = response.token;
          // localStorage.setItem('token', token);
          // localStorage.setItem('role', response.role.toLowerCase());
            this.props.history.push(APP_NAME + '/signin')
          } else {
            alert('Something went wrong. Please Try again!!')
          }
        })
    }
  }

  subdomainRedirect () {
    const redirectUrl = '//app.pietrack.com/signin'
    // const redirectUrl = `//mt.io:3000/app/signin`;
    window.location.href = redirectUrl
  }

  componentWillUnmount () {
    document.body.classList.remove('authentication_body')
  }

  render () {
    const { errors } = this.state
    return (
      <div className='authentication_wrapper'>
        <div className='authentication_block pad_authentication_block'>

          <div className='left'>
            <div className='heading'>Register</div>
            <div className='auth_form'>
              <form>
                <div className='form-group'>
                  <div className='input-group'>
                    <input
                      type='text'
                      id='company'
                      className='form-control1'
                      onChange={(e) => this.setState({ domain: e.target.value, errors: {} })}
                      value={this.state.domain}
                      required
                      onBlur={this.validateNewSubdomain.bind(this)}
                    />
                    <label className='form-control-placeholder' htmlFor='company'>Company <div className='error-text'>*</div></label>
                    <div className='input-group-append text-right'>
                      {this.state.validateDomain &&
                        <span className='input-group-text '><i className='fas fa-check' /></span>}
                      {(!this.state.validateDomain && this.state.validateDomain !== null) &&
                        <span className='input-group-text '><i className='fas fa-times' /></span>}
                    </div>
                  </div>
                  <div className='error-text' style={{ visibility: !this.state.value ? 'hidden' : 'visible' }}>
                    {errors.sub_domain ? errors.sub_domain[0] : null}
                  </div>
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    id='email'
                    className='form-control1'
                    onChange={this.handleChangeEmail}
                    value={this.state.email}
                    required
                  />
                  <label className='form-control-placeholder' htmlFor='email'>Email <div className='error-text'>*</div> </label>
                  <div className='error-text' style={{ visibility: !this.state.value ? 'hidden' : 'visible' }}>
                    {errors.email ? errors.email[0] : null}
                  </div>
                </div>
                <div className='form-group'>
                  <input
                    type='password'
                    id='password'
                    className='form-control1'
                    onChange={this.handleChangePassword}
                    value={this.state.password}
                    required
                  />
                  <label className='form-control-placeholder' htmlFor='password'>Password <div className='error-text'>*</div> </label>
                  <div className='error-text' style={{ visibility: !this.state.value ? 'hidden' : 'visible' }}>
                    {errors.password ? errors.password[0] : errors.__all__ ? errors.__all__[0] : null}
                  </div>
                </div>
                <div className='buttons'>
                  <button type='submit' className='btn primary_btn' onClick={this.handleSubmit.bind(this)}>Register</button>
                  <GoogleLogin
                    clientId='639302738050-np85664n6o4rak4pu3q2qk68kkebjvt1.apps.googleusercontent.com'
                    buttonText=''
                    onSuccess={this.responseGoogle.bind(this)}
                    onFailure={this.responseGoogle.bind(this)}
                    autoLoad={false}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className='right login_right pad_right'>
            <img alt='' className='pt-1' src={logo} />
            <strong>Already have an account?</strong>
            <div className='buttons'>
              <button onClick={() => this.subdomainRedirect()} className='primary_btn btn'>Login</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
