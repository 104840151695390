import React, { Component } from 'react';
import Project from './Project';
import CreateProject from './CreateProject';
import { fetch_get, Context } from '../common';

export default class Projects extends Component { 
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      allProjects: [],
      openProjects: [],
      closedProjects: [],
      projectStatus: 'open',
      loading: false,
      tasksCount: null,
      allProjectsCount: '',
      openProjectsCount: '',
      closedProjectsCount: '',
      searchText: '',
      overlay: false,
      addProject: false,
      initialOpenProj: [],
      initialClosedProj: [],
      initialAllProj: [],
      projectsData: [],
      usersList:[],
    }
    this.overlayControl = this.overlayControl.bind(this);
    this.fetchProjects = this.fetchProjects.bind(this);
  }

  componentDidMount() {
    this.fetchProjects();
  }

  fetchProjects(loading) {
    // let projectsData = this.state;
    if(!loading){  
      this.setState({ loading: true });
    }
    fetch_get('/pm/dashboard/')
    .then((response) => {
      let allProjects = []
      let allProjectsList = response.all_projects.data;
      let bookmarked = []
      let bookmarkProjects = []
      let usersList = response.users_details.data;
      for(let i=0; i<allProjectsList.length; i++) {
        if(allProjectsList[i].bookmarked_users) {
          for(let j=0; j<allProjectsList[i].bookmarked_users.length; j++) {
            if(parseInt(allProjectsList[i].bookmarked_users[j].profile_id) === parseInt(this.context.id) && allProjectsList[i].bookmarked_users[j].is_bookmark) {
              bookmarked.push(allProjectsList[i].id)
              bookmarkProjects.push(allProjectsList[i])
              break;
            }
          }
        }
      }
      for(const id of bookmarked){
        const index = allProjectsList.findIndex(item => item.id === id);
        if(index !== -1) {
          allProjectsList.splice(index, 1);
        }
      }
      allProjects = bookmarkProjects.concat(allProjectsList)
      let openProjects = [];
      let closedProjects = [] 

      for(let i=0; i < allProjects.length; i++){
        if(allProjects[i].project_status !== "completed"){

          openProjects.push(allProjects[i])
        }
        else{
          closedProjects.push(allProjects[i])

        }
      }   
      this.setState({
        openProjects: openProjects,
        initialOpenProj: openProjects,
        closedProjects: closedProjects,
        initialClosedProj: closedProjects,
        allProjects: allProjects,
        initialAllProj: allProjects,
        loading: false,
        allProjectsCount: allProjects.length,
        openProjectsCount: openProjects.length,
        closedProjectsCount: closedProjects.length,
        projectsData: openProjects,
        projectStatus: 'open',
        usersList:usersList,
      })
      //activeProjects(this.state.projectStatus)
    })
    .catch((e) => {
      console.log(e)
    });

  }

  
  activeTab(value) {
    return (this.state.projectStatus === value ?  ' active' : '');
  }

  searchProjetcs() {
    if(this.state.searchText.length >= 2 || this.state.searchText.length === 0 ){
      this.fetchProjects(this.state.searchText)
    }
  }

  overlayControl() {
    this.setState({ overlay: false, addProject: false })
  }

  onSearch(value) {
    this.setState({ searchText: value });
    var openProjectsSearch = this.state.initialOpenProj.filter(function (project) {
      return (project.name.toLowerCase().includes(value.toLowerCase()));
    });
    var closedProjectsSearch = this.state.initialClosedProj.filter(function (project) {
      return (project.name.toLowerCase().includes(value.toLowerCase()));
    });
    var allProjectsSearch = this.state.initialAllProj.filter(function (project) {
      return (project.name.toLowerCase().includes(value.toLowerCase()));
    });
    this.setState({ 
      allProjects: allProjectsSearch || [] , closedProjects: closedProjectsSearch || [] , openProjects: openProjectsSearch || [],
      openProjectsCount: openProjectsSearch.length, closedProjectsCount: closedProjectsSearch.length, allProjectsCount: allProjectsSearch.length
    })
    if(this.state.projectStatus === 'open'){
      this.setState({projectsData: openProjectsSearch})
    }else if(this.state.projectStatus === 'closed'){
      this.setState({projectsData: closedProjectsSearch})      
    }else {
      this.setState({projectsData: allProjectsSearch})

    }
  }

  render() {
    const { loading, projectsData, allProjects, openProjects, closedProjects,usersList } = this.state; 
    return(
      <div className="secondary_block">
        <div className="overlay" style={{ display: this.state.overlay ? 'block' : 'none'}} onClick={() => this.overlayControl()}></div>
        <div className="row tabs_block mar_lr_0 tasks_tab_block">
          <div className="col-lg-12">
            <div className="project_dashboard_container secondary_block">
              <div className="project_dashboard">
                <div className="row mar_lr_0">
                <div className="col-lg-12">
                  <h4 className="heading mt-3">Projects Dashboard</h4>
                </div>
              </div>
              <div className="row mar_lr_0 tabs_block tasks_tab_block pm_tabs_dashboard">
                <div className="col-lg-5">
                  <form className="filter_row task_filter_row">
                    <div className="form-group relative">
                      <div className="input-group mb-3">
                      <div className="input-group-append left">
                        </div>
                        <input 
                          type="text" 
                          className="border-0 form-control" 
                          placeholder="Search" 
                          aria-label="Select User" 
                          aria-describedby="button-addon2" 
                          value={this.state.searchText}
                          onChange={(e) => this.onSearch(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="fas fa-search"></i></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-7">
                  <div className="tabs">
                    <a className={'tab all' + this.activeTab('all')} data-href="open-content" onClick={() => this.setState({ projectStatus: 'all', projectsData: allProjects })} id="inactive_button">
                      {this.state.openProjectsCount !== '' ? <strong>{ this.state.allProjectsCount }</strong> : ''}<span>All</span>
                    </a>
                    <a className={'tab' + this.activeTab('open')} data-href="close-content" onClick={() => this.setState({ projectStatus: 'open', projectsData: openProjects  })} id="active_button">
                      {this.state.openProjectsCount !== '' ? <strong>{ this.state.openProjectsCount }</strong> : ''}<span>Open</span>
                    </a>
                    <a className={'tab closed' + this.activeTab('closed')} data-href="all-content" onClick={() => this.setState({ projectStatus: 'closed', projectsData: closedProjects })} id="inactive_button">
                      {this.state.openProjectsCount !== '' ? <strong>{ this.state.closedProjectsCount }</strong> : ''}<span>Closed</span>
                    </a>
                    <a className="add_project" onClick={() => {this.setState({ addProject: true, overlay: true }) }}><i className="fas fa-plus"></i></a>
                  </div>
                </div>
              </div>
              <div className="projects_container row mar_lr_0">
                <div className="col-lg-12">
              {(projectsData.length > 0 && !loading) ? projectsData.map((project, index) => 
                <Project index={index} key={project.id} project={project} projectStatus={this.state.projectStatus} updateList={this.fetchProjects}/>
              )
              : 
                  <div>{loading ? 'Loading ...' : 'No Projects Found'}</div>
              }
                </div>
              </div>
              </div>
              <div className="user_accordion">
                <div className="heading">User Wise Pending Hours</div>
                <div className="accordion" id="accordionExample">
                <div className="all_cards">
                  {usersList && usersList.map((user,id)=>
                    <div key={id} className="card">
                      <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                          {user.profile_img ?
                          <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target={"#user"+id} aria-expanded="false" aria-controls="collapseTwo">
                            <span className="profile_name" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Sai Krishna"><img src={user.profile_img}/></span>{user.employee_name}<span className="float-right tasks" style={{paddingRight:'12px',top:'4px',position:'relative'}}><small>{user.user_pending_hours ? user.user_pending_hours : '0h' } </small> </span>
                          </button>:
                          <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target={"#user"+id} aria-expanded="false" aria-controls="collapseTwo">
                            <span className="profile_name" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Sai Krishna"> {user.employee_name[0]}</span>{user.employee_name}<span className="float-right tasks" style={{paddingRight:'12px',top:'4px',position:'relative'}}><small>{user.user_pending_hours ? user.user_pending_hours : '0h'  } </small> </span>
                          </button>}                        
                        </h2>
                      </div>
                      <div id={"user"+id} className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div className="card-body">
                          <div className="projects">
                            <ul>
                            {user.get_projects_serializer.data.map((detail,id) =>
                              <li key={id}><span className="profile_name red" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Sai Krishna">{detail.project_name[0]}</span> <span className="title"><a href="">{detail.project_name}</a></span> {detail.pending_task_work_hours ? <span className="float-right tasks"><small>{detail.pending_task_work_hours } </small> </span> : <span className="float-right tasks"><small>0h </small> </span> }</li>
                            )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                </div>
              </div>
            </div>
            {(this.state.addProject || this.state.overlay) && 
              <CreateProject
                overlayControl={this.overlayControl}
                updateList={this.fetchProjects}
                props={this.props}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}
