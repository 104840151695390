import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  fetch_get,
  Context,
  PROJECTS_LIST,
  Modal,
  APP_NAME
} from '../../common';
import AddDocument from './AddDocument';
import EditDocument from './EditDocument';
import CreateDocument from './CreateDocument';
import pdf from '../../../static/images/pdf.png';
import jpg from '../../../static/images/jpg.png';
import docu from '../../../static/images/doc.png';
import png from '../../../static/images/png.png';
import xls from '../../../static/images/xls.png';

export default class Documents extends Component {
  static contextType = Context;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      users: 'active',
      documentsList: [],
      archivedDocumentsList: [],
      profile: '',
      title: '',
      subject: '',
      addDocument: false,
      editDocument: false,
      createDocument: false,
      errors: {},
      fileName: 'No file chosen',
      isLoading: true,
      files: [],
      overlay: false,
      document_id: null,
      file_type:'',
      is_public:'',
    };
    this.fetchDocumentsList = this.fetchDocumentsList.bind(this);
    this.active = this.active.bind(this);
    this.isActive = this.isActive.bind(this);
    this.isActiveUpload = this.isActiveUpload.bind(this);
    this.overlayControl = this.overlayControl.bind(this);
    this.cancelOverlay  =  this.cancelOverlay.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchDocumentsList();
  }

  isActive(value) {
    return 'tab' + ((value === this.state.users) ? ' active' : '');
  }

  active(state) {
    this.setState({ users: state });
  }
    
  fetchDocumentsList() {
    fetch_get(PROJECTS_LIST +`${this.props.match.params.id}/documents/list/`)
    .then((response) => {
      if (response.documents && this._isMounted) {
        this.setState({ 
          documentsList: response.documents.data,
          isLoading: false,
       });
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  isActiveUpload() {
    return ( this.state.fileName === 'No file chosen') ? "file-upload" : "file-upload active";
  }

  overlayControl() {
    this.setState({ overlay: false, addDocument: false, editDocument: false, createDocument: false, isLoading:true, documentsList:[] })
    // this.props.history.push(`/pm/${this.props.match.params.id}/documents`)
  }

  cancelOverlay(){
    this.setState({ overlay: false, addDocument: false, editDocument: false, createDocument: false})
  }

  deleteDocument() {
    fetch_get(PROJECTS_LIST + `${this.props.match.params.id}/document/${this.state.document_id}/delete/`, true)
    .then((response) => {
      if (response.status === 204) {
        this.setState({ displayDeleteModal: false,isLoading:true })
        this.fetchDocumentsList();
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
  render() {
    return (
      <Fragment>
        <div className="row mar_lr_0 tabs_block tasks_tab_block pm_tabs_dashboard">
          <div className="col-lg-6"><h2 className="heading mt-0">Project documents</h2></div>
          <div className="col-lg-6 text-right">
            <button onClick={() => this.setState({ createDocument: true, overlay: true })} className="primary_btn btn"><i className="fas fa-plus"></i>Create Document</button>
            <button onClick={() => this.setState({ addDocument: true, overlay: true })} className="primary_btn btn">
              <i className="fas fa-paperclip"></i>
              Upload Document
            </button>
          </div>
        </div>
        <div className="overlay" style={{ display: this.state.overlay ? 'block' : 'none'}} onClick={() => this.cancelOverlay()}></div>
          <div className="row documents mar_lr_0">
          {this.state.isLoading ? <div>Loading...</div> :
          this.state.documentsList.length > 0 && this.state.documentsList.map((doc) =>
            <div className="col-lg-4" key={doc.id}>
              <div className="document">
              {doc.file_type_code[0] === 'no-file' ?
                <div className="format" style={{cursor:'pointer'}} onClick={()=>this.props.history.push({pathname: APP_NAME + this.context.company + `/pm/${this.props.match.params.id}/document/${doc.id}/details/`, state:{ path: doc.document_file_path } })}>                
                  <img alt="" src={doc.document_file_path ? doc.document_file_path.split('.').pop() === 'png' ? png : doc.document_file_path.split('.').pop() === 'jpg' ? jpg : doc.document_file_path.split('.').pop() === 'pdf' ? pdf : doc.document_file_path.split('.').pop() === 'xls' ? xls : doc.document_file_path.split('.').pop() === 'docx' ? docu : png  : docu} />
                </div> :
                <div className="format" style={{cursor:'pointer'}} onClick={()=>this.props.history.push({pathname: APP_NAME + this.context.company + `/pm/${this.props.match.params.id}/document/${doc.id}/view/`, state:{ path: doc.document_file_path } })}>                
                  <img alt="" src={doc.document_file_path ? doc.document_file_path.split('.').pop() === 'png' ? png : doc.document_file_path.split('.').pop() === 'jpg' ? jpg : doc.document_file_path.split('.').pop() === 'pdf' ? pdf : doc.document_file_path.split('.').pop() === 'xls' ? xls : doc.document_file_path.split('.').pop() === 'docx' ? docu : png  : docu} />
                </div>
              }
              {doc.file_type_code[0] === 'no-file' ?
                <div className="content" style={{cursor:'pointer',width:'280px'}} onClick={()=>this.props.history.push({pathname: APP_NAME + this.context.company + `/pm/${this.props.match.params.id}/document/${doc.id}/details/`, state:{ path: doc.document_file_path } })}>                
                  <span className="title">{doc.title}</span>
                  <span className="date">{moment(doc.created_on).format('DD-MMM-YYYY')}</span>
                </div>:
                <div className="content" style={{cursor:'pointer',width:'280px'}} onClick={()=>this.props.history.push({pathname: APP_NAME + this.context.company + `/pm/${this.props.match.params.id}/document/${doc.id}/view/`, state:{ path: doc.document_file_path } })}>                
                  <span className="title">{doc.title}</span>
                  <span className="date">{moment(doc.created_on).format('DD-MMM-YYYY')} {doc.file_size}</span>
                </div>
              }
                <div className="doc_actions">
                  <span className="edit"
                      onClick={() => 
                        this.setState({
                          overlay: true,
                          editDocument: true,
                          title: doc.title,
                          description: doc.description,
                          document_id: doc.id,
                          file_type: doc.file_type_code[0],
                          is_public: doc.is_public
                        })
                      }>
                       <i className="fas fa-pencil-alt"></i>
                  </span>
                  <span className="trash" onClick={() => this.setState({ document_id:doc.id, displayDeleteModal: true })}>
                      <i className="far fa-trash-alt"></i>
                  </span>
                  {doc.file_type_code[0] === 'no-file' ? null :
                    <a className="download" download href={doc.document_file_path}
                    ><i className="fas fa-download"></i></a>
                  }
                </div>
                { doc.file_type_code[0] === 'no-file' ?
                  <Link to={{pathname: APP_NAME + this.context.company + `/pm/${this.props.match.params.id}/document/${doc.id}/details/`, state:{ path: doc.document_file_path } }} className="go">
                    <i className="fas fa-angle-double-right"></i>
                  </Link> :
                  <Link to={{pathname: APP_NAME + this.context.company + `/pm/${this.props.match.params.id}/document/${doc.id}/view/`, state:{ path: doc.document_file_path } }} className="go">
                    <i className="fas fa-angle-double-right"></i>
                  </Link>                  
                }                
              </div>
            </div>
          )}
          </div>
        <Modal
          display={this.state.displayDeleteModal}
          bodyText='Are you sure? You want to delete?'
          modalAction={() => this.deleteDocument()}
          removeModal={() => this.setState({ displayDeleteModal: false })}
        />
        {this.state.addDocument && 
          <AddDocument
            projectId={this.props.match.params.id}
            overlayControl={this.overlayControl}
            fetchDocumentsList={this.fetchDocumentsList}
            cancelOverlay={this.cancelOverlay}
          />
        }

        {this.state.createDocument &&
          <CreateDocument
            projectId={this.props.match.params.id}
            overlayControl={this.overlayControl}
            fetchDocumentsList={this.fetchDocumentsList}
            cancelOverlay={this.cancelOverlay}

          />
        }            

        {this.state.editDocument &&
          <EditDocument
            projectId={this.props.match.params.id}
            overlayControl={this.overlayControl}
            editDocumentId={this.state.document_id}
            fetchDocumentsList={this.fetchDocumentsList}
            fileType={this.state.file_type}
            is_public={this.state.is_public}
            cancelOverlay={this.cancelOverlay}
          />
        }
      </Fragment>
    )
  }
}
