import React, { Component } from 'react';
import Datetime from 'react-datetime';
import { fetch_post, PROJECTS_LIST, notify, DropBox, QuillEditor, Context } from '../common';

export default class CreateProject extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      advancedSettings: false,
      name: '',
      description: '',
      logo: null,
      duedate: '',
      projectStatus: 'on-going',
      errors: {},
      files: [],
      editCKEditor: false,
      billable: false
    }
    this.ContentChange = this.ContentChange.bind(this);
  }

  onCreateProject() {
    const { name, description, duedate, projectStatus, billable } = this.state;
    const data = new FormData();
    data.append('name', name);
    data.append('project_status', projectStatus);
    data.append('description', description)
    data.append('dead_line', duedate ? duedate.format("YYYY-MM-DD") : '');
    this.state.files.map((file) => data.append('logo', file))
    data.append('is_billable', billable);
    fetch_post(PROJECTS_LIST + 'add/', data)
    .then((response) => {
      if(response.error) {
        this.setState({ errors: response.errors });
      } else {
        notify('Project created successfully');
        this.props.overlayControl()
        this.props.updateList()
        this.props.props.history.push(`${this.context.company}/pm/${response.project_id}/dashboard`);
      }
    })
  }

  ContentChange(e){
    // var newContent = e.editor.getData();
    this.setState({
      description: e,
      editCKEditor: false, 
    })
  }

  render() {
    return (
      <div className="secondary_block">
        <div className="row mar_lr_0 justify-content-center">
          <div className="col-lg-4">
            <div className="slide_section ">
              <div className="add_task slide_content">
                <h2 className="heading">Create Project</h2>
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Title<div className='error-text'>*</div> </label>
                        <input type="text" className="form-control" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value})} />
                        <div className="error-text">
                          {this.state.errors.name ? this.state.errors.name : null }
                        </div>
                      </div>
                    </div> 
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Status<div className='error-text'>*</div> </label>
                        <select 
                          className="form-control "
                          value={this.state.projectStatus}
                          onChange={(e) => this.setState({ projectStatus: e.target.value })}
                        > 
                          <option value="on-going">On Going</option>
                        </select>
                      </div>
                    </div> 
                    {this.state.advancedSettings &&
                      <div className="col-lg-12">
                        <button className="primary_btn" type="button" onClick={() => {this.setState({ advancedSettings: !this.state.advancedSettings })} }>Advanced Settings <i className="fas fa-caret-up"></i></button>
                      </div>
                    }
                    {!this.state.advancedSettings &&
                      <div className="col-lg-12">
                        <button className="primary_btn" type="button"  style={{ marginBottom: '5px'}} onClick={() => {this.setState({ advancedSettings: !this.state.advancedSettings })} }>Advanced Settings <i className="fas fa-caret-down"></i></button>
                      </div>
                    }
                    {this.state.advancedSettings && 
                      <div className="row mar_lr_0">
                        <div className="col-lg-12">
                          <div className="form-group check_box">
                            <label className="Billable">Billable</label>
                            <input 
                              type="checkbox" 
                              value={this.state.billable}
                              checked={this.state.billable === true} 
                              onChange={() => { 
                                this.setState({billable:  !this.state.billable }) 
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Due Date</label>
                            <Datetime
                              dateFormat="YYYY-MM-DD"
                              timeFormat=""
                              inputProps={{ placeholder: 'Enter Due date'}}
                              value={this.state.dueDate}
                              onChange={(e) => { this.setState({ duedate: e }) }}
                              closeOnSelect
                            />
                            <div className="error-text">
                              {this.state.errors.dead_line ? this.state.errors.dead_line[0] : null }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Logo</label>
                            <DropBox
                              files={this.state.files}
                              accept="image/*"
                              onDrop={(files) => this.setState({ files })}
                            />
                            <div className="error-text">
                              {this.state.errors.logo ? this.state.errors.logo[0] : null }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Description</label>
                            {/*<textarea
                              className="form-control"
                              rows="1"
                              value={this.state.description}
                              onChange={(e) => this.setState({ description: e.target.value})}
                            >
                            </textarea>*/}
                            <QuillEditor 
                              value={this.state.description}
                              onChange={this.ContentChange}
                            />
                          </div>
                        </div>
                      </div>
                    }
                    <div className="col-lg-12 mt-5 text-left">
                      <a className="btn primary_btn" onClick={() => this.onCreateProject()}>Create Project</a>
                      <a className="btn secondary_btn" onClick={() => this.props.overlayControl()}>Cancel</a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
