import React from 'react'
import ReactQuill from 'react-quill'

import 'quill-mention'
import 'quill-mention/dist/quill.mention.min.css'

class QuillEditor extends React.Component {
  constructor (props) {
    super(props)
    this.formats = [
      'mention',
      'font',
      'size',
      'bold', 'italic', 'underline',
      'list', 'bullet',
      'align',
      'color', 'background'
    ]
    this.state = { modules: {}, commentUsers: this.props.commentUsers }
    this.handleMentions = this.handleMentions.bind(this)
  }

  componentDidMount () {
    this.handleMentions(this.props.commentUsers)
  }

  handleMentions (item) {
    const mention_data = item
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ['link'],
        ['blockquote', 'code-block'],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ size: ['small', false, 'large', 'huge'] }]
      ],
      mention: {
        onSelect: (item, insertItem) => {
          this.props.mentionValue(item)
          insertItem(item)
        },
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@', '#'],
        renderItem: function renderItem (items) {
          if (items.has_profile_pic) {
            return `<a className="member"><img className="photo" style=width:30px src=${items.profile_img} /></a><span>${items.value || items.nickname}</span>`
          } else {
            return `<span className="profile_name">${items.profile_text || items.nickname[0]}</span><span>${items.value || items.nickname}</span>`
          }
        },
        source: function (searchTerm, renderList, mentionChar) {
          let values
          if (mentionChar === '@') {
            values = mention_data
          }
          if (searchTerm.length === 0) {
            renderList(values, searchTerm)
          } else {
            const matches = []
            for (let i = 0; i < values.length; i++) {
              if (
                ~(values[i].value || values[i].nickname).toLowerCase().indexOf(searchTerm.toLowerCase())
              ) { matches.push(values[i]) }
            }
            renderList(matches, searchTerm)
          }
        }
      }
    }
    this.setState({ modules: modules })
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if ((nextProps.commentUsers && nextProps.commentUsers.length) !== (prevState.commentUsers && prevState.commentUsers.length)) {
      return { commentUsers: nextProps.commentUsers }
    }
    else{
      return null
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.commentUsers && prevProps.commentUsers.length == 0 && this.props.commentUsers && this.props.commentUsers.length > 0) {
      this.handleMentions(this.props.commentUsers)
    }
  }

  render () {
    return (
      <div>
        <ReactQuill
          value={this.props.value}
          modules={this.state.modules}
          onChange={this.props.onChange}
          onKeyPress={this.props.onKeyPress}
        />
      </div>
    )
  }
}

export { QuillEditor }
