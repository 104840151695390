import Dropzone from 'react-dropzone'
import React, { Fragment } from 'react'

const DropBox = ({ files = [], onDrop, multiple = false, accept = '', isImage = false, imageStyle, isList = true }) => (
  <>
    {
      isImage && files.length > 0 &&
        <img alt='' src={files[0].preview} style={{ marginBottom: '2px', height: '50px', ...imageStyle }} />
    }
    <Dropzone
      className='form-control'
      onDrop={(newFiles) =>
        onDrop(isImage ? newFiles : [...newFiles])}
      multiple={multiple}
      accept={accept}
      acceptStyle={{ borderColor: 'green' }}
      style={{ height: 'auto' }}
    >
      {({ getRootProps, getInputProps }) => (
        <div>
          <div>
            <input {...getInputProps()} />
            <button
              {...getRootProps({
                className: 'dropzone',
                onDrop: event => event.stopPropagation()
              })}
              type='button' className='secondary_btn'
            >Browse file
            </button>
            <p>Click on the button to browse and attach a file  or drag and drop to upload</p>
          </div>
        </div>
      )}
    </Dropzone>
    {isList === true &&
      <ul>
        {!isImage && files.map((file, index) => (
          <li
            key={file.name} onClick={() => {
              const filteredFiles = files
              filteredFiles.splice(index, 1)
              onDrop(filteredFiles)
            }}
          >
            {file.name} <i className='fas fa-times' title='Remove' />
          </li>
        ))}
      </ul>}
  </>
)

export { DropBox }
