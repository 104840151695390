import React, { Component, Fragment } from 'react'

export class RenderUsers extends Component {
  constructor (props) {
    super(props)
    this.state = {
      displayMenu: false
    }
  }

  render () {
    const { users } = this.props
    const list1 = users.length > 3 ? users.slice(0, 2) : users
    const list2 = users.length > 3 ? users.slice(2, users.length) : []
    return (
      <div className='avatars right'>
        <>
          {
            list1.map((user) =>
              user.has_profile_pic
                ? <span className='avatar' key={user.id}>
                  <img src={user.profile_img} title={user.employee_name} alt='' />
                  </span>
                : <span
                    key={user.id}
                    className='btn-group dropdown people task_people text-right no-padding'
                    title={user.employee_name}
                  >
                  <a className='btn toggle avatar'>{user.employee_name[0]}</a>
                  </span>
            )
          }
          <span
            className='btn-group dropdown people task_people text-right no-padding'
            tabIndex={0}
            onClick={() => this.setState({ displayMenu: !this.state.displayMenu })}
            onBlur={() => this.setState({ displayMenu: false })}
          >
            {
              list2.length > 0
                ? <a className='btn toggle avatar'>
                  {list2.length}+
                  </a> : ''
            }
            {
              this.state.displayMenu &&
                <ul className='menu' style={{ display: 'block' }}>
                  {list2.map((user) =>
                    <li className='submenu' key={user.id}>
                      <a>
                        {user.has_profile_pic
                          ? <img src={user.profile_img} title={user.employee_name} alt='' />
                          : <span className='fa_icon'>
                            <i className='fa fa-user' title='Users' />
                            </span>}
                        {user.employee_name}
                      </a>
                    </li>
                  )}
                </ul>
            }
          </span>
        </>
      </div>
    )
  }
}
