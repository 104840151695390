export const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME || 'https://api.pietrack.com'
export const MAIN_SERVER = process.env.REACT_APP_MAIN_SERVER || 'pietrack.com'
export const SERVER = process.env.REACT_APP_SERVER || 'pietrack.com'
export const SCHEME = process.env.REACT_APP_SCHEME || '//'

export const LOGIN_DOMAIN_NAME = process.env.REACT_APP_LOGIN_DOMAIN_NAME || 'https://api.pietrack.com'
export const LOGIN_SERVER = process.env.REACT_APP_LOGIN_SERVER || 'pietrack.com:'
export const APP_NAME = ''
// export const COMPANY_ID = '';
export const APP_URL = process.env.REACT_APP_URL || 'pietrack.com'

export const VALIDATE_SUBDOMAIN = DOMAIN_NAME + '/auth/validate-subdomain/'
export const VALIDATE_NEW_SUBDOMAIN = DOMAIN_NAME + '/auth/validate-new-subdomain/'

export const SIGNUP = DOMAIN_NAME + '/auth/register/'
export const LOGIN = '/auth/login/'
export const LOGOUT = '/auth/logout/'

export const FORGOT_PASSWORD = '/auth/forgot-password/'
export const RESET_PASSWORD = '/auth/password-reset/'
export const CHANGE_PASSWORD = '/auth/change/password/'

export const USERS = '/admin/profiles/'
export const EDITUSER = '/admin/profiles/id/'
export const DELETEUSER = '/admin/profiles/id/'
export const USERDETAILS = '/admin/profiles/id/'
export const GOOGLE_LOGIN = '/auth/google-login/'
export const GOOGLE_SIGNUP = '/auth/google-signup/'

export const PERMISSIONS = '/admin/permissions/'
export const COMPANY = '/admin/company/'
export const INFO = '/admin/info/'
export const COUNTRIES = '/admin/countries/'
export const SETTINGS = '/admin/verify-domain/'
export const RECORDS = '/admin/verify-records/'

export const HR_DASHBOARD = '/hr/dashboard/'
export const HR_DAILY_EVENTS = '/hr/daily/events/'
export const PROFILE = '/hr/profile/'
export const ACTIVATE_USER = '/hr/activate/'
export const PROFILE_UPDATE = '/hr/profile/update/'
export const HR_USERS = '/hr/users/'
export const LEAVES = '/hr/leaves/'

export const WFH_LIST = '/hr/workfromhome/list/'
export const WFH = '/hr/workfromhome/'
export const WFH_APPLY = '/hr/workfromhome/apply/'

export const LEAVE_ACCOUNT = '/hr/leaves/apply/'
export const LEAVES_HALF_DAY = '/hr/leaves/half-day/'
export const LEAVES_FULL_DAY = '/hr/leaves/full-day/'
export const DEAULT_LEAVES = '/hr/default-leave-settings/'
export const DOCUMENTS_PROFILES = '/hr/documents/profiles/'
export const DOCUMENTS_PROFILES_ARCHIVED = '/hr/documents/profiles/archived/'
export const DOCUMENTS_LIST = '/hr/documents/list/'
export const DOCUMENTS_CREATE = '/hr/documents/list/create/'
export const DOCUMENTS = '/hr/documents/'
export const MEETINGS = '/hr/meetings/'
export const PAYSHEETS = '/hr/paysheets/'
export const WORKING_DAYS = '/hr/workingdays/'
export const EMP_WORKING_DAYS = '/hr/workingdays/employee/'
export const WORKING_DAYS_CREATE = '/hr/workingdays/create/'
export const EMP_WORKING_DAYS_CREATE = '/hr/workingdays/employee/bulk-create/'
export const DESIGNATIONS = '/hr/designations/'
export const DESIGNATIONS_CREATE = '/hr/designations/create/'
export const HOLIDAYS = '/hr/holidays/'
export const HOLIDAYS_CALENDAR = '/hr/holidays/calendar/'
export const SAMPLE_DOWNLOAD = '/hr/holidays/sample-download/'
export const PAYSLIP_GEN = '/hr/payslips/payslip/'
export const PAYSLIPS = '/hr/payslips/'
export const PAYROLL = '/hr/payrolls/'
export const LIVE = '/hr/users/live/status/'
export const GRAPH = '/hr/worklog/graph/'
export const WORK_HOURS_LIST = '/hr/worklog/worklog-hours-list/'
export const WORKLOG = '/hr/worklog/'
export const MANUAL_WORKLOG = '/hr/worklog/manual-worklog/'
export const HR_SCREENS = '/hr/worklog/screens/'
export const RESOURCES = '/hr/resources/'
export const RESOURCE_TYPES = '/hr/resources/resource_types/'
export const RESOURCE_REQUEST = '/hr/resources/requests/'

// pm urls
export const PROJECTS_LIST = '/pm/projects/'
export const PROJECT_ACTIVITY = '/pm/activity/'
export const WORK_SUMMARY = '/pm/work-summary/'

// CRM Marketing Urls
export const CONTACTLISTS = '/crm/marketing/contact-lists/'
export const CONTACTS = '/crm/marketing/campaign-contacts/'
export const EMAILTEMPLATES = '/crm/marketing/email-templates/'
export const SENDCAMPAIGN = '/crm/marketing/send-campaign/'
export const CAMPAIGNS = '/crm/marketing/campaign-lists/'
export const CAMPAIGNLOG = '/crm/marketing/campaign-log/'
export const SAMPLECSV = CONTACTLISTS + 'sample-download/'

// CRM Sales Urls
export const SALES = '/crm/sales/'
export const SALES_DASHBOARD = '/crm/sales/dashboard/'
export const LEADS = '/crm/sales/leads/'
export const OPPORTUNITIES = '/crm/sales/opportunity/'
export const TEAMS = '/crm/sales/teams/'
export const SALES_CONTACTS = '/crm/sales/contacts/'
export const ACCOUNTS = '/crm/sales/accounts/'
export const EVENTS = '/crm/sales/events/'
export const SALES_DOCUMENTS = '/crm/sales/documents/'
export const SALES_COMPANY = '/crm/sales/lead-company/'
export const SALES_EMAIL = '/crm/sales/emails/'
export const SALES_COUNTRY = '/crm/sales/country/'
export const SALES_COMMENTS = '/crm/sales/comments/'
export const SETTINGS_TAGS = '/crm/sales/tags/'
export const LEADS_KANBAN = '/crm/sales/leads/kanban/'
export const SALES_DOMAINS = '/crm/sales/domains/'
export const DOMAINS_CREATE = '/crm/sales/domains/create/'

export const GOOGLE_CONNECT = '/auth/google-connect/'
export const TAGS = '/crm/sales/categories/'

export const SALESEMAILTEMPLATES = '/crm/sales/email-templates/'
