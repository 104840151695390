/* eslint-disable no-unused-expressions */
import React, { Component, Fragment } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import { fetch_post, fetch_get, PROJECTS_LIST, notify, QuillEditor, Modal, Linkify, Context } from '../../common';

let filesData = [];
let deleteIds = [];
let memberIds = [];
let visibleTeamIds = [];
let activities = []
export default class TaskDetailsPage extends Component {
  static contextType = Context
  constructor(props) {
    super(props);
    this.state = {
      integratedWithGitlab: false,
      integratedWithGithub: false,
      editTaskName: false,
      taskDetails: [],
      comments: {},
      activities: [],
      errors: {},
      labels: [],
      profiles: [],
      visibilityTeamProfiles: [],
      milestones: [],
      priorities: [],
      allStatus: [],
      editTask: {
        label: false,
        assignedTo: false,
        visibleToTeam: false
      },
      labelsIds: [],
      activityList: "view_list",
      comment: '',
      commentId: '',
      editComment: false,
      loading: {
        comments: true,
        worklog: true,
        details: true
      },
      currentTab: 'comments',
      assignedToMenu: false,
      labelsMenu: false,
      milestonesMenu: false,
      statusMenu: false,
      priorityMenu: false,
      visibilityMenu: false,
      isPublic: false,
      activityPagination: { hasNext: false, nextPageNumber: 0 },
      totalWorklogHours: 0,
      description: '',
      displayDeleteTask: false,
      displayCloseTask: false,
      isTaskFound: true,
      commentUsers: [],
      syncWithGitlab: false,
      syncWithGithub: false,
      deleteAttachment: false,
      deleteFileId: '',
      deleteFileIndex: '',
      updatedLabelIds: [],
      reopenStatus: null,
      finishedStatus: null,
      milestoneDetails: [],
      mentionValue: ''
    };
    this.onBlur = this.onBlur.bind(this);
    this.dateOnBlur = this.dateOnBlur.bind(this);
    this.estimateTimeOnBlur = this.estimateTimeOnBlur.bind(this);
    this.ContentChange = this.ContentChange.bind(this);
    this.CommentChange = this.CommentChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.mentionValue = this.mentionValue.bind(this);
  }

  componentDidMount() {
    activities = []
    if(this.props.taskId !== null) { 
      this.setState({ taskId: this.props.taskId }, () => {
        this.fetchTaskDetails('fetch');
        this.fetchTaskComments();
        this.fetchTaskActivity();
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return ((prevState.taskId !== nextProps.taskId && nextProps.taskId !== null)) ? {
      taskId: nextProps.taskId 
    } : null
  }

  componentDidUpdate(prevProps) {
    if((prevProps.taskId !== this.props.taskId && this.props.taskId !== null)) {
      this.fetchTaskDetails('fetch')
      this.fetchTaskComments();
      this.fetchTaskActivity()
    }
  }

  fetchTaskDetails(fetch=false) {
    var projectIdIsNum = /^\d+$/.test(this.props.id);
    var taskIdIsNum = /^\d+$/.test(this.state.taskId);
    if(projectIdIsNum && taskIdIsNum) {
      fetch_get(PROJECTS_LIST + this.props.id + '/tasks/' + this.state.taskId + '/')
      .then((response) => {
        if(!response.error) {
          this.setState({ 
            milestoneDetails: response.task.data.milestone_details,
            isPublic: response.task.data.is_public,
            taskDetails: response.task.data,
            description: response.task.data.description,
            isTaskFound: true,
            syncWithGitlab: response.task.data.sync_with_gitlab,
            integratedWithGitlab: response.integrated_with_gitlab,
            syncWithGithub: response.task.data.sync_with_github,
            integratedWithGithub: response.integrated_with_github,
            loading: { ...this.state.loading, details: false }
          }, fetch ? this.createInitialData : () => {})
          if(this.state.taskDetails.estimated_time) {
            this.setState({ 
              taskDetails: {
                ...this.state.taskDetails,
                estimated_time: moment(this.state.taskDetails.estimated_time, 'HH:mm:ss')
              }
            })
          }
          if(this.state.taskDetails.dead_line) {
            this.setState({
              taskDetails: {
                ...this.state.taskDetails,
                dead_line: moment(this.state.taskDetails.dead_line, 'YYYY-MM-DD')
              }
            }) 
          }
        } else {
          this.setState({ isTaskFound: false })
        }
      })
    } else {
      this.setState({ isTaskFound: false })
    }
  }

  fetchTaskActivity(page=1) {
    var projectIdIsNum = /^\d+$/.test(this.props.id);
    var taskIdIsNum = /^\d+$/.test(this.state.taskId);
    if(projectIdIsNum && taskIdIsNum) {
      fetch_get(PROJECTS_LIST + this.props.id + '/tasks/' + this.state.taskId + '/activity/?page=' + page)
      .then((response) => {
        if(response) {
          activities = activities.concat(response.activities.data); 
          this.setState({ 
            activities: activities, 
            loading: { ...this.state.loading, worklog: false },
            hasNext: response.activities.has_next,
            nextPageNumber: response.activities.next_page_number,
            totalWorklogHours: response.total_active_hours
          });
        }
      });
    }
  }

  fetchTaskComments() {
    var projectIdIsNum = /^\d+$/.test(this.props.id);
    var taskIdIsNum = /^\d+$/.test(this.state.taskId);
    if(projectIdIsNum && taskIdIsNum) {
      fetch_get(PROJECTS_LIST + this.props.id + '/tasks/' + this.state.taskId + '/comments/')
      .then((response) => {
        response && this.setState({ comments: Object.values(response.comments), loading: { ...this.state.loading, comments: false } });
      });
    }
  }

  createInitialData() {
    const { taskDetails } = this.state;
    fetch_get(PROJECTS_LIST + this.props.id + '/tasks/create/')
    .then((response) => {
      if(response) {
        let members = [];
        let labelsIds = [];
        let visibleTeam = [];
        let users_for_mentions = [];
        memberIds = []
        visibleTeamIds = []
        if((taskDetails.labels_details && taskDetails.labels_details.data.length > 0)) {
          for(let i in taskDetails.labels_details.data) {
            labelsIds.push(taskDetails.labels_details.data[i].id)
          }
        }
        if((taskDetails.assigned_to_list && taskDetails.assigned_to_list.data.length > 0)) {
          taskDetails.assigned_to_list.data.map((obj) => {
            memberIds.push(obj.id)
          })
        }
        members = response.profiles.data.filter((member) => {
          return !memberIds.includes(member.id)
        })
        if((taskDetails.visible_to_team_list && taskDetails.visible_to_team_list.data.length > 0)) {
          for(let i in taskDetails.visible_to_team_list.data) {
            visibleTeamIds.push(taskDetails.visible_to_team_list.data[i].id)
          }
        }
        visibleTeam = response.visible_to_team.data.filter((member) => {
          return !visibleTeamIds.includes(member.id)
        })
        let finishedStatus = response.task_statuses.data.filter((status) => {
          return status.name === 'Finished'
        })
        let reopenStatus = response.task_statuses.data.filter((status) => {
          return status.name === 'Reopened'
        })
        response.profiles.data.map((user) => {
          let user_dict = {};
          user_dict['id'] = user.id;
          user_dict['value'] = user.nickname;
          user_dict['profile_img'] = user.profile_img;
          user_dict['profile_text'] = user.profile_text;
          user_dict['has_profile_pic'] = user.has_profile_pic;
          users_for_mentions.push(user_dict);
        })
        this.setState({ 
          labelsIds,
          commentUsers: users_for_mentions,
          // commentUsers: response.profiles.data,
          labels: response.labels.data, 
          profiles: members,
          milestones: response.open_milestones_data,
          priorities: response.priorities.data,
          visibilityTeamProfiles: visibleTeam,
          allStatus: response.task_statuses.data,
          updatedLabelIds: labelsIds,
          finishedStatus,
          reopenStatus
        })
      }
    })
  }

  onBlur() {
    this.setState({ editTaskName: false }, () => { this.taskUpdate() })
  }

  dateOnBlur() {
    this.setState({ taskDetails: this.state.taskDetails }, () => { this.taskUpdate()})
  }

  estimateTimeOnBlur() {
    this.setState({ taskDetails: this.state.taskDetails }, () => { this.taskUpdate()})
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.setState({ editTaskName: false }, () => { this.taskUpdate() })
    }
  }

  ContentChange(e){
    // var newContent = e.editor.getData();
    // this.state.taskDetails.description = newContent
    this.setState({
      description: e
    })
  }

  editComment(comment) {
    this.setState({ 
      editComment: true, 
      commentId: comment.id,
      comment: comment.comment,
    }); 
  }

  CommentChange(e) {
    this.setState({
      comment: e,
      errors: {}
    })
  }

  taskUpdate(attachments=false) {
    if(!this.state.loading.details) {
      this.setState({ errors: {} })
      const { taskDetails, milestoneDetails } = this.state;
      const data = new FormData();
      data.append('name', taskDetails.name);
      data.append('sync_with_gitlab', this.state.syncWithGitlab)
      data.append('sync_with_github', this.state.syncWithGithub)
      data.append('status', taskDetails.status_details ? taskDetails.status_details.data.id : '');
      data.append('description', this.state.description);
      data.append('estimated_time', taskDetails.estimated_time ? taskDetails.estimated_time.format('HH:mm:ss') : '')
      data.append('dead_line', taskDetails.dead_line ? taskDetails.dead_line.format('YYYY-MM-DD') : '')
      data.append('is_public', this.state.isPublic);
      if(taskDetails.priority_details.data.name) {
        data.append('priority', taskDetails.priority_details.data.id);
      }
      if(milestoneDetails.data && milestoneDetails.data.id) {
        data.append('milestone', milestoneDetails.data.id);
      }
      if(taskDetails.labels_details.data.length > 0) {
        taskDetails.labels_details.data.filter((obj) =>
          data.append('label', obj.id)
        );
      }
      if (deleteIds.length > 0) {
        deleteIds.filter((id) =>
          data.append('delete_attachment', id)
        );
      }
      if (filesData.length > 0) {
        filesData.filter((obj) => 
          data.append('files', obj)
        );
      }
      if(taskDetails.assigned_to_list.data.length > 0) {
        taskDetails.assigned_to_list.data.filter((obj) =>{
          data.append('assigned_to', obj.id)
        });
      }
      if(taskDetails.visible_to_team_list.data.length > 0) {
        taskDetails.visible_to_team_list.data.filter((obj) =>
          data.append('visible_to_team', obj.id)
        );
      }
      if(window.navigator.onLine) {
        fetch_post(`${PROJECTS_LIST}${this.props.id}/tasks/${taskDetails.pt_id}/update/`, data)
        .then((response) => {
          if(!response.error) {
            this.props.updateTask(taskDetails.pt_id, false, parseInt(this.props.kanbanStatusId) === parseInt(taskDetails.status_details.data.id) ? this.props.kanbanStatusId : taskDetails.status_details.data.id)
            deleteIds = [];
            if(attachments) {
              filesData = [];
              this.fetchTaskDetails();
            }
            this.setState({ descriptionCKEditor: false })
          } else {
              if(response.errors == 'File Size Should be less than 10MB'){
                filesData = []
                alert('File Size Should be less than 10MB')
              }            
              this.setState({ isPublic: !this.state.isPublic, errors: response.errors })
          }
        })
      } else {
        notify('Cannot update file, Please check your internet connection')
      }
    } else {
      notify('Loading Details, Please Wait')
    }
  }

  mentionValue(value){
    this.setState({ mentionValue: value.value })
  }

  addComment() {
    let url = '';
    const data = new FormData();
    // var pattern = /\B@[a-z0-9_.-]+/gi;
    // var mentioned_users = this.state.comment.match(pattern);
    data.append('comment', this.state.comment)
    data.append('mentioned_user', this.state.mentionValue ? '@'+this.state.mentionValue : '')
    if(this.state.editComment) {
      data.append('comment_id', this.state.commentId)
      data.append('task_id', this.state.taskId)
      url = PROJECTS_LIST + this.props.id + '/tasks/comments/update/'
    }
    else {
     url = PROJECTS_LIST + this.props.id + '/tasks/' + this.state.taskId + '/comments/create/'
    }
    fetch_post(url, data)
    .then((response) => {
      if(response.error) {
        this.setState({ errors: response.errors })
      } else {
        this.setState({ comment: '' }, () => {
          notify(response.response)
          // this.props.updateTask(this.state.taskDetails.pt_id, false)
          if(!this.state.editComment) {
              this.props.updateTask(this.state.taskId, false, this.props.kanbanStatusId);
          }
          this.fetchTaskComments(this.state.taskId);
          this.setState({ attachments: [], errors: {}, editComment: false, commentId: null })
        })
      }
    });
  }

  activityView(value) {
    this.setState({ activityList: value });
  }

  isActiveActivityView(value) {
    return (value === this.state.activityList) ? (value + ' active') : value
  }

  reopenTask(taskId) {
    fetch_get(PROJECTS_LIST + this.props.id + '/tasks/' + taskId+ '/reopen/', true)
    .then(response => {
      if(response.status === 204) {
        notify("Task Reopend Successfully");
        this.props.kanbanStatusId ? this.props.updateTask(undefined, undefined, this.state.reopenStatus[0].id, false) : this.props.reopenTask(taskId);
        this.context.updateCount('true');
        this.props.overlayControl()
      }
    });
  }

  closeTask() {
    fetch_get(PROJECTS_LIST + this.props.id + '/tasks/' + this.state.taskDetails.pt_id+ '/close/', true)
    .then(response => {
      if(response.status === 204) {
        this.setState({ displayCloseTask: false })
        notify("task closed successfully");
        this.props.kanbanStatusId ? this.props.updateTask(undefined, undefined, this.state.finishedStatus[0].id, false) : this.props.closeTask(this.state.taskDetails.pt_id, 'close')
        this.context.updateCount('true')
        this.props.overlayControl()
      }
    });
  }

  deleteTask() {
    fetch_get(PROJECTS_LIST + this.props.id + '/tasks/' + this.state.taskDetails.pt_id + '/delete/')
    .then((response) => {
      if(!response.error) {
        notify(response.message)
        this.context.updateCount('true')
        this.props.kanbanStatusId ? this.props.updateTask(undefined, undefined, this.props.kanbanStatusId, false) : this.props.closeTask(this.state.taskDetails.pt_id, 'delete');
        this.props.overlayControl()
      } else {
        notify(response.message)
      }
    });
  }

  labelUpdate(label) {
    if(this.state.labelsIds.includes(label.id)) {
      this.state.labelsIds.splice(this.state.labelsIds.indexOf(label.id), 1 );
      this.state.taskDetails.labels_details.data.map((labelDetails, index) => {
        if(labelDetails.id === label.id) {
          this.state.taskDetails.labels_details.data.splice(index, 1)  
        }
      })
      this.setState({ taskDetails: this.state.taskDetails, labelsIds: this.state.labelsIds }, () => {this.taskUpdate()})
    } else {
      this.state.labelsIds.push(label.id)
      this.state.taskDetails.labels_details.data.push(label)
      this.setState({ taskDetails: this.state.taskDetails, labelsIds: this.state.labelsIds }, () => {this.taskUpdate()})
    }
    if(!this.state.updatedLabelIds.includes(label.id)) {
      this.setState({ updatedLabelIds: [...this.state.updatedLabelIds, label.id] })
    }
  }

  profileUpdate(member, index, action) {
    if(action === 'add') {
      this.state.taskDetails.assigned_to_list.data.push(member)
      this.state.profiles.splice(index, 1)
      this.setState({ taskDetails: this.state.taskDetails }, () => {this.taskUpdate()})
    } else {
      this.state.profiles.push(member)
      this.state.taskDetails.assigned_to_list.data.splice(index, 1)
      this.setState({ taskDetails: this.state.taskDetails }, () => {this.taskUpdate()})
    }
  }

  visibleToTeamUpdate(member, index, action) {
    if(window.navigator.onLine) {
      if(action === 'add') {
        this.state.taskDetails.visible_to_team_list.data.push(member)
        this.state.visibilityTeamProfiles.splice(index, 1)
        this.setState({ taskDetails: this.state.taskDetails }, () => {this.taskUpdate()})
      } else {
        this.state.visibilityTeamProfiles.push(member)
        this.state.taskDetails.visible_to_team_list.data.splice(index, 1)
        this.setState({ taskDetails: this.state.taskDetails }, () => {this.taskUpdate()})
      } 
    }else {
      notify('Cannot update members, Please check your internet connection')
    }
  }

  milestoneUpdate(milestone) {
    if(window.navigator.onLine) {
      if(this.state.milestoneDetails.data && this.state.milestoneDetails.data.id === milestone.data.id) {
        // this.state.milestoneDetails = [];
        this.setState({ milestoneDetails: [] }, () => {this.taskUpdate()})
      } else {
        // this.state.milestoneDetails = milestone;
        this.setState({ milestoneDetails: milestone }, () => {this.taskUpdate()})
      }
    }else {
      notify('Cannot update milestone, Please check your internet connection')
    }
  }

  priorityUpdate(priority) {
    if(window.navigator.onLine) {
      if(this.state.taskDetails.priority_details.data.id === priority.id) {
        this.state.taskDetails.priority_details.data = [];
        this.setState({ taskDetails: this.state.taskDetails }, () => {this.taskUpdate()})
      } else {
        this.state.taskDetails.priority_details.data = priority;
        this.setState({ taskDetails: this.state.taskDetails }, () => {this.taskUpdate()})
      }
    } else {
      notify('Cannot update priority, Please check your internet connection')
    }
  }

  statusUpdate(status) {
    if(window.navigator.onLine) {
      if(this.state.taskDetails.status_details.data.id !== status.id) {
        this.state.taskDetails.status_details.data = status;
        this.setState({ taskDetails: this.state.taskDetails }, () => {this.taskUpdate()})
      }
    } else {
      notify('Cannot update status, Please check your internet connection')
    }
  }

  fileAppend(e) {
    if(window.navigator.onLine) {
      filesData.push(e.target.files[0]);
      this.taskUpdate('attachments')
    } else {
      notify('Cannot update file, Please check your internet connection')
    }
  }

  removeSavedFile() {
    let fileId = this.state.deleteFileId;
    let index = this.state.deleteFileIndex;
    if(window.navigator.onLine) {
      deleteIds = deleteIds.concat(fileId)
      this.state.taskDetails.attachments_list.data.splice(index, 1)
      this.setState({ taskDetails: this.state.taskDetails, deleteAttachment: false }, () => {this.taskUpdate()})
    } else {
      notify('Cannot update file, Please check your internet connection')
    }
  }

  onCancel() {
    if(this.state.comment !== '') {
      this.setState({ comment: ''})
    }else{
      this.setState({ errors: {} })
    }
  }

  activeTab(value) {
    return 'tab' + (this.state.currentTab === value ? ' active' : '');
  }

  commentDelete(commentId) {
    fetch_get(PROJECTS_LIST + this.props.id + '/tasks/' + this.state.taskDetails.pt_id + '/comments/' + commentId + '/delete/', true)
    .then((response) => {
      if(response.status === 204) {
        notify("comment deleted");
        this.fetchTaskComments(this.state.taskId)
        this.props.updateTask(this.state.taskId, false, this.props.kanbanStatusId);
      }
    });
  }

  labelText(hex) {
    let rgb = (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).join(',').split(",")
    var o = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000);
    var fore = (o > 200) ? '#000' : '#fff';
    return fore;
  }

  render() {
    const { taskDetails, milestoneDetails } = this.state;
    return (
      <div className="slide_section" style={{ display: 'block'}}>
        <div className="detail_task slide_content" style={{ display: 'block'}}>
          {this.state.isTaskFound ?
            <Fragment>
              <div className="task_detail_heading">
                <div className="task_title form-group">
                  <div className="task_title form-group">
                    {this.state.editTaskName ?
                      <textarea 
                        className="task_detail_title form-control" 
                        rows="1" 
                        value={this.state.taskDetails.name} 
                        onChange={(e) => {taskDetails.name = e.target.value; this.setState({ taskDetails: this.state.taskDetails})} } 
                        onBlur={this.onBlur}
                        onKeyPress={this.handleKeyPress}
                      >
                      </textarea>
                    :
                      <div className="heading" onClick={() => this.setState({ editTaskName: true })}>{this.state.loading.details ? 'Loading ...' : `#${taskDetails.pt_id} ${taskDetails.name}`}</div>
                    }
                  </div>
                </div>
                <a className="float-right close" onClick={() => this.props.overlayControl()}><i className="fa fa-times"></i></a>
              </div>
              <div className="detailed_content task_detailed row mar_lr_0">
              
                <div className="col-lg-8 left_detail" style={{ paddingLeft: '0' }}>

                  {this.state.descriptionCKEditor ?
                    <div className="task_ck_editor">
                      <QuillEditor 
                        value={this.state.description}
                        onChange={this.ContentChange}
                      />
                      <br />
                      <div className="text-right">
                        <button 
                          type="button" 
                          className="btn primary_btn"
                          onClick={() => 
                            {
                              this.state.taskDetails.description = this.state.description
                              this.setState({ taskDetails: this.state.taskDetails })
                              this.taskUpdate()
                            }
                          }
                        >
                          Save
                        </button>
                        <button 
                          type="button" 
                          className="btn secondary_btn" 
                          onClick={() => this.setState({ descriptionCKEditor: false, description: taskDetails.description}) }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  :
                    <div className="task_desc" onClick={() => this.setState({ descriptionCKEditor: true, editTask: true })}>
                      {taskDetails.description ? 
                        <span dangerouslySetInnerHTML={{ __html: Linkify(taskDetails.description) }} />
                      : 
                        <span><p>No Description Provided</p></span>
                      }
                    </div>
                  }
                  <div className="tabs_block col-lg-12 p-0">
                    <div className="tabs m-0">
                      <a className={this.activeTab('comments')} onClick={() => this.setState({ currentTab: 'comments' })}>Comments <span className="count">{this.state.comments.length}</span></a>
                      <a className={this.activeTab('worklog') + ' closed'} onClick={() => this.setState({ currentTab: 'worklog' })}>Worklog {this.state.totalWorklogHours} </a>
                    </div>
                  </div>
                  <div className="row tab-contents" style={{ display: 'block' }}>
                    {this.state.currentTab === 'comments' &&
                      <div className="col-lg-12 tab-contents comment-content" style={{ display: 'block' }}>
                        <div className="comments-list">   
                          {(this.state.comments.length > 0) ? this.state.comments.map((comment) => 
                            <div key={comment.id} className="media">
                              <a className="media-left">
                                
                                {comment.commented_by.data.has_profile_pic ? 
                                  <img 
                                    src={comment.commented_by.data.profile_img} 
                                    title={comment.commented_by.data.employee_name}
                                    alt="pic" 
                                  />
                                :
                                  <span className="fa_icon">
                                    <i className="fa fa-user" title="Users"></i>
                                  </span>
                                }
                              </a>
                              <div className="media-body">
                                <div className="name"><a>{comment.commented_by.data.employee_name.split(" ")[0]}</a> <span className="float-right">{comment.created_on_since}</span></div>
                                  <div className="title">
                                    <p className="img_patterns" dangerouslySetInnerHTML={{ __html: Linkify(comment.comment) }} /> 
                                  </div>
                              </div>
                              {comment.commented_by.data.id === this.context.id &&
                                <div className="actions">
                                  <a className="action_edit" style={{ fontSize: '1rem', marginRight: '0.2rem'}} onClick={() => {this.editComment(comment) }}><i className="far fa-edit"></i></a>
                                  <a className="action_delete" style={{ fontSize: '1rem', marginRight: '0.2rem'}} onClick={() => {this.commentDelete(comment.id)}}><i className="fas fa-trash"></i></a>
                                </div>
                              }
                            </div>
                          )
                          :
                          <div><center>{this.state.loading.comments ? 'Loading ...'  : 'No comments found'}</center></div>
                          }
                        </div>
                        <div className="comment_container">
                          <div>
                            <QuillEditor 
                              value={this.state.comment}
                              onChange={this.CommentChange}
                              commentUsers={this.state.commentUsers}
                              mentionValue={this.mentionValue}
                            />
                          </div>
                          {this.state.errors.comment &&
                            <div className="error-text">
                              {this.state.errors.comment}
                            </div>
                          }
                          <br />
                          <div className="text-right">
                            <button type="button" className="btn primary_btn" onClick={this.addComment.bind(this)}>Post Comment</button>
                            <button type="button" className="btn secondary_btn" onClick={() => this.onCancel()}>Cancel</button>
                          </div>
                        </div>
                      </div>
                    }
                    {this.state.currentTab === 'worklog' &&
                      <div className="tab-contents col-lg-12 worklog-content" style={{ display: 'block' }}>
                        <div className="view_block">
                          <ul className="float-right">
                            <li className={this.isActiveActivityView("view_list")} onClick={() => this.activityView("view_list")} data-toggle="tooltip" data-placement="bottom" title="view list"><i className="fas fa-list-ul" ></i></li>
                            <li className={this.isActiveActivityView("view_table")} onClick={() => this.activityView("view_table")}  data-toggle="tooltip" data-placement="bottom" title="view table"><i className="fas fa-table"></i></li>
                          </ul>
                        </div>
                        <br />
                        {this.state.activityList === 'view_list' &&
                          <div className="comments-list row mar_lr_0">
                            {this.state.activities.length > 0 ?
                              <Fragment>
                                {this.state.activities.map((activity) =>
                                  <div className="media" key={activity.id}>
                                    <a className="media-left">
                                      <img src={activity.profile_details.data.profile_img} alt=""/>
                                    </a>
                                    <div className="media-body">
                                      <div className="name"><a>{activity.profile_details.data.employee_name.split(" ")[0]} on {activity.c_start_time} for {activity.formatted_active_time}</a></div>
                                      <div className="content">
                                        <p>{activity.description}</p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {this.state.hasNext ?
                                  <a className="media" onClick={() => this.fetchTaskActivity(this.state.nextPageNumber)}>Load More ...</a>
                                :
                                 null
                                }
                              </Fragment>
                            :
                              <div><center>{this.state.loading.worklog ? 'Loading... ' : 'No activities found' }</center></div>
                            } 
                          </div>
                        }
                        {this.state.activityList === 'view_table' &&
                          <Fragment>
                            <table className="worklog_table_view_block table">
                              <thead>
                                <tr>
                                  <th >User</th>
                                  <th width="65">Time</th>
                                  <th >Description</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.activities.length > 0 ? this.state.activities.map((activity) =>
                                  <Fragment key={activity.id}>
                                    <tr>
                                      <td>
                                        <a className="link profile_pic">
                                          <img 
                                            src={activity.profile_details.data.profile_img}  
                                            alt="" 
                                            data-toggle="tooltip" 
                                            data-placement="bottom" 
                                            title={activity.profile_details.data.employee_name.split(" ")[0]}
                                          />
                                        </a>
                                      </td>
                                      <td><span className="date">{activity.formatted_active_time}</span></td>
                                      <td>{activity.description}</td>
                                    </tr>
                                  </Fragment>
                                ) : <div><center>{this.state.loading.worklog ? 'Loading... ' : 'No activities found' }</center></div> }
                              </tbody>
                            </table>
                            {this.state.hasNext ?
                              <a onClick={() => this.fetchTaskActivity(this.state.nextPageNumber)}>Load More ...</a>
                            :
                             null
                            }
                          </Fragment>
                        }
                      </div>
                    }
                  </div>
                </div>
                <div className="col-lg-4 right_detail">
                  <div className="more_details">
                    Created By  
                    {taskDetails.created_by_user ?
                        <a><b> {taskDetails.created_by_user.data.employee_name.split(" ")[0]} </b></a>
                      : ' -- '
                    } 
                    <span className="float-right">{taskDetails.task_created_on}</span>
                  </div>
                  <div className="detail_block">
                    <div className="sml_heading">Labels 
                      {/*<span className="float-right edit_detail"onClick={() => { this.setState({ editTask: { ...this.state.editTask, label: !this.state.editTask.label } })}}>
                        {this.state.editTask.label ? 'Cancel' : 'Edit'}
                      </span>*/}
                    </div>
                    <div className="tags no-margin">
                      {(taskDetails.labels_details && taskDetails.labels_details.data.length > 0 ) && 
                        taskDetails.labels_details.data.map((label, index) =>
                          <a key={label.id} className="tag" style={{ backgroundColor: label.color, color: this.labelText(label.color) }}>{label.name} 
                            {this.state.editTask.label ? <span className="float-right" onClick={() => {this.labelUpdate(label, index)}}><i className="fas fa-times icon"></i></span> : null }
                          </a>
                      )}
                      <span 
                        className="btn-group dropdown no-caret task_dropdown "
                        tabIndex={0}
                        onClick={() => this.setState({ labelsMenu: !this.state.labelsMenu})}
                        onBlur={() => this.setState({ labelsMenu: false })}
                      >
                        <a className="toggle tag add" style={{ color: '#fff' }}>
                         Label <span className="float-right"><i className="fas fa-plus"></i></span>
                        </a>
                        {this.state.labelsMenu &&
                          <ul className="menu task_detail_menu label_menu" style={{ display: 'block' }}>
                            {this.state.labels.length > 0 ? this.state.labels.map((label, index) => {
                              return (
                              <li key={label.id} className="submenu" onClick={() => this.labelUpdate(label, index)}>
                                <a className="orange" style={{ backgroundColor: label.color, color: this.labelText(label.color) }}> {label.name} 
                                  {this.state.labelsIds.includes(label.id) && 
                                    <span className="float-right" style={{ display: 'block' }}><i className="fas fa-check"></i></span>
                                  }
                                  {!this.state.labelsIds.includes(label.id) && 
                                    <span className="float-right"><i className="fas fa-check"></i></span>
                                  }
                                </a>
                              </li>
                            )})
                            :
                              null
                            }
                          </ul>
                        }
                      </span>
                    </div>
                  </div>
                  <div className="detail_block">
                    <div className="sml_heading">Assign To 
                      <span className="float-right edit_detail"onClick={() => { this.setState({ editTask: { ...this.state.editTask, assignedTo: !this.state.editTask.assignedTo } })}}>{this.state.editTask.assignedTo ? 'Cancel' : 'Edit'}</span>
                    </div>
                    <div className="more_details team_members">
                      <span className="detail no-margin">
                        {(taskDetails.assigned_to_list && taskDetails.assigned_to_list.data) && 
                          taskDetails.assigned_to_list.data.map((member, index) => {
                          return <a key= {member.id} className="member">
                            {member.has_profile_pic ?
                              <img 
                                src={member.profile_img}
                                data-toggle="tooltip" 
                                data-placement="bottom" 
                                title={member.employee_name} 
                                data-original-title={member.employee_name}
                                alt=""  
                              />
                            :
                              <span className="profile_name" data-toggle="tooltip" data-placement="bottom" title={member.employee_name}>{member.employee_name.split(" ")[1] && member.employee_name.split(" ")[0][0] + member.employee_name.split(" ")[1][0]}</span>
                            }
                            {this.state.editTask.assignedTo ?
                              <span className="option1" onClick={() => this.profileUpdate(member, index, 'delete')}>&#10006;</span>
                            : null 
                            }
                          </a>
                        })}
                      </span>
                      {this.state.profiles.length > 0 ? 
                        <span 
                          className="btn-group dropdown people text-right no-padding"
                          tabIndex={0}
                          onClick={() => this.setState({ assignedToMenu: !this.state.assignedToMenu})}
                          onBlur={() => this.setState({ assignedToMenu: false })}
                        >
                          <a className="btn toggle">
                            +
                          </a>
                          {this.state.assignedToMenu &&
                            <ul className="menu" style={{ display: 'block' }}>
                              {this.state.profiles.map((profile, index) =>
                                <li key={profile.id} className="submenu">
                                  <a onClick={() => this.profileUpdate(profile, index, 'add')}>
                                    {profile.has_profile_pic ? 
                                      <img src={profile.profile_img} alt=""/>
                                    :
                                      <span className="profile_name" data-toggle="tooltip" data-placement="bottom" title={profile.employee_name}>{profile.profile_text &&  profile.profile_text}</span>
                                    }{profile.employee_name.split(" ")[0]}
                                  </a>
                                </li>
                              )}
                            </ul>
                          }
                        </span>
                      :
                        null
                      }
                    </div>
                  </div>
                  <div className="row mar_lr_0 detail_task_block">
                  <div className="col-lg-6 pl-0 detail_block">
                    <div className="sml_heading"> Milestone</div>
                    <div className="">
                      <span 
                        className="btn-group dropdown no-caret task_dropdown no-padding"
                        tabIndex={0}
                        onClick={() => this.setState({ milestonesMenu: !this.state.milestonesMenu})}
                        onBlur={() => this.setState({ milestonesMenu: false })}
                      >
                        <a className="toggle">
                          {(milestoneDetails.data && milestoneDetails.data.name) ? milestoneDetails.data.name : 'None'} <small className="caret"> <i className="fas fa-caret-down"></i> </small>
                        </a>
                        {this.state.milestonesMenu &&
                          <ul className="menu task_detail_menu" style={{ display: 'block' }}>
                            {this.state.milestones.length > 0 ? this.state.milestones.map((milestone) =>
                              <li key={milestone.data.id} className="submenu">
                                <a onClick={() => this.milestoneUpdate(milestone)}> {milestone.data.name} 
                                  {(milestoneDetails.data && milestoneDetails.data.id === milestone.data.id) && 
                                    <span className="float-right" style={{ display: 'block'}}><i className="fas fa-check"></i></span> 
                                  }
                                  {(milestoneDetails.data && milestoneDetails.data.id !== milestone.data.id) && 
                                    <span className="float-right"><i className="fas fa-check"></i></span> 
                                  }
                                </a>
                              </li>
                            ) : null}
                          </ul>
                        }
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 pr-0 detail_block">
                    <div className="sml_heading"> Estimated time 
                      <span className="date custom_border" style={{fontWeight: '500'}}>
                        <Datetime 
                          onBlur={this.estimateTimeOnBlur}
                          dateFormat="" 
                          timeFormat="HH : mm : ss" 
                          inputProps={{ className: 'form-control detail_input'}} 
                          value={taskDetails.estimated_time ? taskDetails.estimated_time: 'None'} 
                          onChange={(e) => { taskDetails.estimated_time = e }} 
                          closeOnSelect
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 pl-0 detail_block">
                    <span className="sml_heading">
                      <span className="head"> Priority</span>
                      <span 
                        className="btn-group dropdown no-caret task_dropdown no-padding"
                        tabIndex={0}
                        onClick={() => this.setState({ priorityMenu: !this.state.priorityMenu})}
                        onBlur={() => this.setState({ priorityMenu: false })}
                      >
                        <a className="toggle">
                          {(taskDetails.priority_details && taskDetails.priority_details.data.id) ? taskDetails.priority_details.data.name : 'None'}<small className="caret"> <i className="fas fa-caret-down"></i> </small>
                        </a>
                        {this.state.priorityMenu &&
                          <ul className="menu task_detail_menu" style={{ display: 'block' }}>
                            {this.state.priorities.length > 0 ? this.state.priorities.map((priority) =>
                              <li className="submenu" key={priority.id}>
                                <a onClick={() => this.priorityUpdate(priority)}> {priority.name} 
                                  {(taskDetails.priority_details && taskDetails.priority_details.data.id === priority.id) && 
                                    <span className="float-right" style={{ display: 'block'}}><i className="fas fa-check"></i></span> 
                                  }
                                  {(taskDetails.priority_details && taskDetails.priority_details.data.id !== priority.id) && 
                                    <span className="float-right"><i className="fas fa-check"></i></span> 
                                  }
                                </a>
                              </li>
                            ):null}
                          </ul>
                        }
                      </span>
                    </span>
                  </div>
                  <div className="col-lg-6 pr-0 detail_block">
                    <span className="sml_heading">
                      <span className="head"> Status</span>
                      <span 
                        className="btn-group dropdown no-caret task_dropdown no-padding"
                        tabIndex={0}
                        onClick={() => this.setState({ statusMenu: !this.state.statusMenu})}
                        onBlur={() => this.setState({ statusMenu: false })}
                      >
                        <a className="toggle">
                          {(taskDetails.status_details && taskDetails.status_details.data.id) ? taskDetails.status_details.data.name : 'None'} <small className="caret"> <i className="fas fa-caret-down"></i> </small>
                        </a>
                        {this.state.statusMenu &&
                          <ul className="menu task_detail_menu" style={{ display: 'block' }}>
                            {this.state.allStatus.length > 0 ? this.state.allStatus.map((status) => 
                              <li key={status.id} className="submenu">
                                <a onClick={() => this.statusUpdate(status)}> {status.name}
                                  {(taskDetails.status_details && taskDetails.status_details.data.id === status.id) && 
                                    <span style={{ display: 'block'}} className="float-right"><i className="fas fa-check"></i></span> 
                                  }
                                  {(taskDetails.status_details && taskDetails.status_details.data.id !== status.id) && 
                                    <span className="float-right"><i className="fas fa-check"></i></span> 
                                  }
                                </a>
                              </li>
                            ) : null}
                          </ul>
                        }
                      </span>
                    </span>
                  </div>
                  <div className="col-lg-6 pl-0 detail_block">
                    <div className="sml_heading"> Due Date 
                      <span className="date custom_border" style={{fontWeight: '500'}}>
                        <Datetime 
                          style={{ width: '140px' }}
                          onBlur={this.dateOnBlur}
                          dateFormat="YYYY-MM-DD" 
                          timeFormat="" 
                          inputProps={{ className: 'form-control detail_input'}} 
                          value={taskDetails.dead_line ? taskDetails.dead_line : 'No Deadline'} 
                          onChange={(e) => { taskDetails.dead_line = e }} 
                          closeOnSelect
                        />
                      </span>
                    </div>
                  </div>
                  </div>
                  <div className="detail_block">
                    <span className="attachment_heading">
                      <span className="head"> Attachments
                        <label className="float-right add_project" htmlFor="taskdetailsFile" style={{ marginBottom: '0px'}} ><i className="fas fa-plus"></i></label>
                        <input type="file" id="taskdetailsFile" style={{display: 'none' }} onChange={this.fileAppend.bind(this)}/>
                      </span>
                    </span>
                    {(taskDetails.attachments_list && taskDetails.attachments_list.data.length > 0) && 
                      <div className="attachents_block">
                        <ul>
                          {taskDetails.attachments_list.data.map((file, index) => {
                            return (
                              <li key={file.id}> 
                                <a href={ file.attached_file_path } target="_blank" rel="noreferrer">{ file.attached_file_name }</a>
                                <span className="float-right remove" onClick={() => this.setState({ deleteAttachment: true, deleteFileIndex: index, deleteFileId: file.id }) }>
                                  <i className="fas fa-times"></i>
                                </span>
                                <a href={ file.attached_file_path } className="float-right download" download>
                                  <i className="fas fa-download"></i>
                                </a>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    }
                  </div>
                  <div className="form-group">   
                   <label>Is Public</label>
                        <span className="switch_block">
                          <label className="switch">
                            <input 
                              type="checkbox" 
                              value={this.state.isPublic}
                              checked={this.state.isPublic} 
                              onChange={(e) => { 
                                this.state.isPublic = e.target.checked
                                this.setState({isPublic: e.target.checked}, this.taskUpdate) 
                              }}/>
                            <span className="slider round"></span>
                          </label>
                        </span>
                  </div>
                  {this.state.integratedWithGitlab &&
                    <div className="form-group">
                      <label>Sync With Gitlab</label>
                      <span className="switch_block">
                        <label className="switch">
                          <input 
                            type="checkbox" 
                            value={this.state.syncWithGitlab}
                            checked={this.state.syncWithGitlab === true } 
                            onChange={(e) => { 
                              this.setState({ syncWithGitlab:  e.target.checked }, this.taskUpdate) 
                            }}
                          />
                          <span className="slider round"></span>  
                        </label>
                      </span>
                    </div>
                  }
                  {this.state.integratedWithGithub &&
                    <div className="form-group mb-4">
                      <label>Sync With Github</label>
                      <span className="switch_block">
                        <label className="switch">
                          <input 
                            type="checkbox" 
                            value={this.state.syncWithGithub}
                            checked={this.state.syncWithGithub === true} 
                            onChange={(e) => { 
                              this.setState({ syncWithGithub:  e.target.checked }, this.taskUpdate) 
                            }}
                          />
                          <span className="slider round"></span>  
                        </label>
                      </span>
                    </div>
                  }
                  {!this.state.isPublic &&
                    <div>
                      <div className="detail_block mt-4">
                        <div className="sml_heading">Visible To Team 
                          <span className="float-right edit_detail"onClick={() => { this.setState({ editTask: { ...this.state.editTask, visibleToTeam: !this.state.editTask.visibleToTeam } })}}>{this.state.editTask.visibleToTeam ? 'Cancel' : 'Edit'}</span>
                        </div>
                        <div className="more_details team_members">
                          <span className="detail no-margin">
                            {(taskDetails.visible_to_team_list && taskDetails.visible_to_team_list.data) && 
                              taskDetails.visible_to_team_list.data.map((member, index) => {
                              return <a key= {member.id} className="member">
                                <img 
                                  src={member.profile_img}
                                  data-toggle="tooltip" 
                                  data-placement="bottom" 
                                  title={member.employee_name} 
                                  data-original-title={member.employee_name}
                                  alt=""  
                                />
                                {this.state.editTask.visibleToTeam ?
                                  <span className="option1" onClick={() => this.visibleToTeamUpdate(member, index, 'delete')}><i className="fas fa-times"></i></span>
                                : null 
                                }
                              </a>
                            })}
                          </span>
                          {this.state.visibilityTeamProfiles.length > 0 ? 
                            <span 
                              className="btn-group dropdown people text-right no-padding"
                              tabIndex={0}
                              onClick={() => this.setState({ visibilityMenu: !this.state.visibilityMenu})}
                              onBlur={() => this.setState({ visibilityMenu: false })}
                            >
                              <a className="btn toggle">
                                +
                              </a>
                              {this.state.visibilityMenu &&
                                <ul className="menu" style={{ display: 'block' }}>
                                  {this.state.visibilityTeamProfiles.map((profile, index) => {
                                    return (
                                      <li key={profile.id} className="submenu">
                                        <a onClick={() => this.visibleToTeamUpdate(profile, index, 'add')} >
                                          <img src={profile.profile_img} alt="" />{profile.employee_name.split(" ")[0]}
                                        </a>
                                      </li>
                                    )}
                                  )}
                                </ul>
                              }
                            </span>
                          :
                            null
                          }
                        </div>
                      </div>
                      <div className="error-text">
                        {this.state.errors.is_public ? this.state.errors.is_public[0] : '' }
                      </div>
                    </div>
                  }
                  <div style={{ marginTop: '10px' }} className="detail_block">
                    {taskDetails && taskDetails.is_closed ? 
                      <a className="secondary_btn" onClick={() => {this.reopenTask(taskDetails.pt_id)}}>Re open</a>
                    :
                      <a className="primary_btn" onClick={() => this.setState({ displayCloseTask: true })}>Close Task</a>
                    }
                    {taskDetails && taskDetails.can_delete_task &&
                      <span className="secondary_btn" onClick={() => this.setState({ displayDeleteTask: true })}>
                        Delete Task
                      </span>
                    }   
                  </div>          
                </div>
                <Modal
                  display={this.state.displayCloseTask}
                  bodyText={`Are you sure? You want to Close "${taskDetails.name}" ?`}
                  modalAction={this.closeTask.bind(this)}
                  removeModal={() => this.setState({ displayCloseTask: false })}
                />
                <Modal
                  display={this.state.displayDeleteTask}
                  bodyText={`Are you sure? You want to Delete "${taskDetails.name}" ?`}
                  modalAction={this.deleteTask.bind(this)}
                  removeModal={() => this.setState({ displayDeleteTask: false })}
                />
                <Modal
                  display={this.state.deleteAttachment}
                  bodyText='Are you sure? You want to delete?'
                  modalAction={this.removeSavedFile.bind(this)}
                  removeModal={() => this.setState({ deleteAttachment: false })}
                />
              </div>
            </Fragment>
          :
            <div className="col-lg-12 text-center"><center> Please enter a valid url, Task not found </center></div>
          }
        </div>
      </div>  
    )
  }
}