import React, { Component } from 'react';
import moment from 'moment-timezone';
import Context from '../../Context';

export default class TimeSince extends Component {
  static contextType = Context;
  constructor(props, context) {
    super(props);
    this.state = {
      time_since: moment(this.props.date).tz(context.timezone || 'Asia/Kolkata').fromNow()
    };
  }
  componentDidMount() {
    this.intervalId = setInterval(() => this.setState({
      time_since: moment(this.props.date).tz(this.context.timezone || 'Asia/Kolkata').fromNow()
    }), 1000 * 60)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    return (
      <span
        data-toggle="tooltip"
        data-placement="bottom"
        title={moment(this.props.date).format(this.props.displayDate ? 'MMMM DD, YYYY' : 'MMMM DD, YYYY hh:mm A')}
      >{this.state.time_since}</span>);
  }
}
