import React, { Component, Fragment } from 'react'
import { fetch_get, PROJECTS_LIST } from '../common'

export default class ProjectDetails extends Component {
  constructor (props) {
    super(props)
    this.refs = {}
    this.state = {
      projectDetails: [],
      projectTeam: [],
      openIssuesCount: '',
      closedIssuesCount: ''
    }
    this.fetchProjectDetails = this.fetchProjectDetails.bind(this)
  }

  componentDidMount () {
    this.fetchProjectDetails(this.props.match.params.id)
  }

  fetchProjectDetails (id) {
    fetch_get(PROJECTS_LIST + id + '/')
      .then((response) => {
        this.setState({
          projectDetails: response.project.data,
          openIssuesCount: response.project.issues_count,
          closedIssuesCount: response.project.close_issues_count,
          projectTeam: response.project.team_data.data
        })
      })
  }

  render () {
    const { projectDetails } = this.state
    return (
      <div className='secondary_block'>
        {this.state.projectDetails.project_status &&
          <div className='row mar_lr_0 table_height'>
            <div className='col-lg-12'>
              <h2 className='heading'>Project Details</h2>
              <table className='table table-hover task_table'>
                <thead>
                  <tr>
                    <th width='16%'>Created By</th>
                    <th width='8%'>Status</th>
                    <th width='8%'>Integrations</th>
                    <th width='8%'>Tasks</th>
                    <th width='8%'>Milestones</th>
                    <th width='12%'>Overdue Issues</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{projectDetails.created_by_user.data.employee_name}</td>
                    <td>{projectDetails.project_status == 'on-going' ? 'Ongoing' : 'Completed'}</td>
                    {projectDetails.integrated_type.length > 0
                      ? <td>
                        <div className='git_icons text-left'>
                          {projectDetails.integrated_type.map((integration) =>
                            <Fragment key={integration}>
                              {integration === 'GitLab' && <a><i className='fab fa-gitlab' /></a>}
                              {integration === 'GitHub' && <a><i className='fab fa-github' /></a>}
                              {integration === 'Trello' && <a><i className='fab fa-trello' /></a>}
                              {integration === 'Sentry' && <a><img src='https://sentry-brand.storage.googleapis.com/sentry-glyph-black.svg' alt='' /></a>}
                            </Fragment>
                          )}
                        </div>
                        </td>
                      : <td>--</td>}
                    <td><span className='green'>{this.state.openIssuesCount}</span> / <span className='orange'>{this.state.closedIssuesCount}</span></td>
                    <td><span className='green'>{projectDetails.open_milestones_count}</span> / <span className='orange'>{projectDetails.close_milestones_count}</span></td>
                    <td><span className='green'>{projectDetails.overdue_tasks_count}</span></td>

                  </tr>
                </tbody>
              </table>
              <div className='col-lg-12 p-0 mt-4 p-team'>
                <h2 className='heading'>Team Members</h2>
                {this.state.projectTeam.length > 0 &&
                  <div className='avatars project_members'>
                    {this.state.projectTeam.map((profile) =>
                      <a key={profile.id}>
                        {profile.user_profile.data.has_profile_pic
                          ? <span className='avatar tooltips'>
                            <img src={profile.user_profile.data.profile_img} title={profile.user_profile.data.employee_name} alt='' />
                            {profile.access_level === 'admin' && <small><i className='fa fa-star' /></small>}
                          </span>
                          : <span className='profile_name avatar' data-toggle='tooltip' data-placement='bottom' title={profile.user_profile.data.employee_name}>{profile.user_profile.data.profile_text}
                            {profile.access_level === 'admin' && <small><i className='fa fa-star' /></small>}
                          </span>}
                      </a>
                    )}
                  </div>}
              </div>
            </div>
          </div>}
        <div className='row mar_lr_0 comments project_detail_activity'>
          <div className='col-lg-12' />
          <div className='activity_block' />
        </div>
      </div>
    )
  }
}
