import React, { Component } from 'react';
import { APP_NAME, Context } from '../common';

export default class LeftNav extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: window.location.pathname.split('/')[3]
    };
    this.onClickMenu = this.onClickMenu.bind(this);
    this.isActive = this.isActive.bind(this);
  }
  
  componentDidMount() {
    if (!localStorage.getItem('token')) {
      this.props.props.history.push(APP_NAME);
    }
    this.collapseActions()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const menu = nextProps.props.history.location.pathname.split('/')[3];
    return (menu !== prevState.selectedMenu) ? {
      selectedMenu: menu
    } : null
  }

  collapseActions() {
    let MenuBlock = document.getElementsByClassName("menu_block");
    let  Sidebar = document.getElementsByClassName("sidebar");
    let Stage = document.getElementsByClassName("stage");
    let SideMenu = document.getElementsByClassName("side_menu");
    if(SideMenu[0].style.width != "25%") {
      MenuBlock[0].style.display = "inline-block";
      Sidebar[0].style.width = "15%";
      Stage[0].style.marginLeft = "15%";
      SideMenu[0].style.width = "25%";
    }
  }

  componentDidUpdate() {
    let SideMenu = document.getElementsByClassName("side_menu");
    if(SideMenu[0].style.width != "25%") {
      this.collapseActions()
    }
  }

  onClickMenu(menu) {
    this.setState({ selectedMenu: menu });
    // if(menu === 'crm') {
    //   this.props.props.history.push(APP_NAME + '/admin/crm/settings');
    // }
    // else {
      this.props.props.history.push(APP_NAME + this.context.company + '/admin/' + menu);
    // }
  }

  isActive(value) {
    return ((value === this.state.selectedMenu) ? ' active' : '');
  }

  render() {
    return (
      <ul>
        <li>
          <a className={this.isActive('dashboard')} onClick={() => this.onClickMenu('dashboard')}>
            <span className="fa_icon">
              <i className="fa fa-tachometer-alt" title="Dashboard"></i>
            </span>
            <span className="menu_text">Dashboard</span>
          </a>
        </li>
        <li>
          <a className={this.isActive('users')} onClick={() => this.onClickMenu('users')}>
            <span className="fa_icon">
              <i className="fa fa-users" title="Users" />
            </span>
            <span className="menu_text">Users</span>
          </a>
        </li>
        <li>
          <a className={this.isActive('company')} onClick={() => this.onClickMenu('company')}>
            <span className="fa_icon">
              <i className="fa fa-users" title="Company" />
            </span>
            <span className="menu_text">Company</span>
          </a>
        </li>
      </ul>
    );
  }
}

