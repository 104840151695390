import React from 'react';
import { Switch, Route,Redirect } from 'react-router-dom';
// import Reports from './Reports';
import Projects from './Projects';
import CreateProject from './CreateProject';
import NotFoundPage from '../NotFoundPage';
import ProjectRoute from './ProjectRoute';
import { Context } from '../common';
// import Unauthorized from '../Unauthorized';


let value = 0
class Component extends React.Component {
    static contextType = Context;
    render(){
      var uri = window.location.pathname.toString();
      var url = uri.toLowerCase();
      const { company } = this.context;
      return(
        <main>
          <Switch>
            {value = ++value}
            <Route exact sensitive path={ company + '/pm' } component={Projects} />
            <Route exact sensitive path={ company + '/pm/dashboard' } component={Projects} />
            {/*<Route exact sensitive path={ company +'/pm/reports'} component={permissions.includes('REPORTS') ? Reports : Unauthorized} />*/}
            <Route exact sensitive path={ company + '/pm/create-project' } component={CreateProject} />
            {value === 1?
            <Route path="/" render={() => (<Redirect to={url}/>)}/>:<Route path={ company + '/pm/:id' } component={ProjectRoute} />}
            <Route component={NotFoundPage} />
          </Switch>
        </main>
      )
    }
}

export default Component

