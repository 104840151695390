import React from 'react'

const IndividualPerson = (props) => (
  <div>
    {props.user.has_profile_pic
      ? <img src={props.user.profile_img} width='43px' title={props.user.employee_name} style={{ borderRadius: '50px', margin: '8px' }} />
      : <li data-toggle='tooltip' data-placement='bottom' title={props.user.employee_name}>{props.user.profile_text && props.user.profile_text[0].toUpperCase()}</li>}
  </div>
)

export default IndividualPerson
