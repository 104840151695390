import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { fetch_get, Context, } from '../common';

export default class Project extends Component { 
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.project,
      index: this.props.index,
      bookmark: '',
      projectMembersMenu: false,
      openIssuesCount: parseInt(this.props.project.opened_issues_count),
      closedIssuesCount: parseInt(this.props.project.closed_issues_count),
      allIssuesCount: parseInt(this.props.project.opened_issues_count) + parseInt(this.props.project.closed_issues_count),
      users:false
    }
  }

  componentDidMount() {
    this.state.project.bookmarked_users && this.state.project.bookmarked_users.filter((obj) => {
      if(obj.profile_id == this.context.id && obj.is_bookmark){ 
        this.setState({ bookmark: true,users:true })
      }
    })
  }


  AddOrRemoveBookmark(projectId) {
    fetch_get('/pm/projects/' + projectId + '/bookmark/')
    .then((response) => {
      response.message && this.setState({ bookmark: !this.state.bookmark }, () => {alert(response.message); this.props.updateList();});
    })
  }

  renderMembers(members) {
    let list1 = [];
    let list2 = [];
    list1 = members.slice(0,4);
    list2 = members.slice(4, members.length)
    return (
      <div>
        {list1.map((profile) => 
          <a key={profile.profile_id}>
            {profile.has_profile_pic ?
              <span className="avatar tooltips">
                <img src={profile.profile_img} data-toggle="tooltip" data-placement="bottom" title={profile.name} alt=""/>
              </span>
            :
              <span className="profile_name" data-toggle="tooltip" data-placement="bottom" title={profile.name}>{profile.name && profile.name[0]+(profile.name.split(" ")[1] && profile.name.split(" ")[1][0])}</span>
            }
          </a>
        )}
        <span 
          className="btn-group dropdown people text-right"
          tabIndex={0}
          onClick={() => this.setState({ projectMembersMenu: !this.state.projectMembersMenu})}
          onBlur={() => this.setState({ projectMembersMenu: false })}
        >
          <a className="btn toggle">
            {list2.length}+
          </a>
          {this.state.projectMembersMenu &&
            <ul className="menu" style={{ display: 'block' }}>
              {list2.map((profile) => 
                <li key={profile.profile_id} className="submenu"> 
                  <a>
                    {profile.has_profile_pic ?
                      <img 
                        src={profile.profile_img} 
                        data-toggle="tooltip" 
                        data-placement="bottom" 
                        title={profile.name}
                        alt=""  
                      /> 
                    :
                      <span className="profile_name" data-toggle="tooltip" data-placement="bottom">{profile.name && profile.name[0]}{profile.name.split(" ")[1] && profile.name.split(" ")[1][0]}</span>
                    }{profile.name.split(' ')[0]}
                  </a>
                </li>
              )}
            </ul>
          }
        </span>
      </div>
    )
  }

  isProjectClosed(projectStatus) {
    if(this.props.projectStatus === 'all' || this.props.projectStatus === 'open'){
      if(this.state.project.deadline && this.state.project.deadline.includes('completed')){
        return true
      }else if(projectStatus === 'completed'){
        return true
      }
    } 
  }

  render() {
    const { project, closedIssuesCount, allIssuesCount } = this.state;
    // const { project } = this.state
    return(
      <div className="">
        <div className="project">
          <div className="left">
            <h4 className="title">
              <Link to={{
                pathname:`${this.context.company}/pm/${project.id}/tasks`,
                state:{projectId: project.id}
                }}>{project.name} 
              </Link>
            </h4>
            <div className="results">
              <div className="result">Tasks : <span className="value"><small>{project.opened_issues_count}</small>/<small>{project.closed_issues_count}</small></span></div>              
              <div className="result">Milestones : <span className="value"><small>{project.milestones_data.opened_count}</small>/<small>{project.milestones_data.finished_count}</small></span></div>
              <div className="result">Pending Hours : <span className="value p_hours">{project.pending_work_hours === null ? '--' : project.pending_work_hours}</span></div>
              <div className="result">Due Date : <span className="value d_date">{project.deadline ? project.deadline : '--'}</span></div>
              <div className="avatars project_members">
                {project.assigned_users.length < 6 ? 
                  project.assigned_users.map((profile) => 
                    <a key={profile.profile_id}>
                      {profile.has_profile_pic ? 
                        <span className="avatar tooltips">
                          <img src={profile.profile_img} data-toggle="tooltip" data-placement="bottom" title={profile.name} alt=""/>
                        </span>
                      :
                        <span className="profile_name" data-toggle="tooltip" data-placement="bottom" title={profile.name}>{profile.name && profile.name[0]}{profile.name.split(" ")[1] && profile.name.split(" ")[1][0]}</span>
                      }
                    </a>
                  )
                :
                  this.renderMembers(project.assigned_users)
                }
              </div>
            </div>
          </div>
          <div className="right">
            <div className="progress">
              <div className="progress-bar" role="progressbar" style={{width: allIssuesCount > 0 ? parseInt((closedIssuesCount / allIssuesCount)*100) : 100 + '%'}} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div className="progress_value_tasks">
              <span className="progress_value">{allIssuesCount > 0 ? parseInt((closedIssuesCount / allIssuesCount)*100) : 100}%Completed</span>
            </div>
          </div>
        </div>
      </div>

    )
  }
}