import React, { Component } from 'react';
import { Context } from '../../../common';

export default class RedirectFromGitHub extends Component {
  static contextType = Context;
  componentDidMount() {
    this.props.history.push(
      { 
        pathname: this.context.company + '/pm/' + this.props.match.params.id + '/project-settings', 
        state: { code: this.props.location.search.split('=')[1], loginStatus: this.props.location.search.split('=')[0][1] }
      }
    )
  }
  render() {
    return(
      <div>Processing...</div>
    );
  }
}
