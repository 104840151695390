import React, { Component } from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { fetch_post, PROJECTS_LIST, notify, fetch_get, Context } from '../../common';

export default class AddEditMilestone extends Component { 
  static contextType = Context
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      startDate: '',
      endDate: '',
      status: 'started',
      errors: {},
      statuses: [],
      openStartPicker: false,
      openEndPicker: false
    };
  }

  componentDidMount() {
    this.props.props.match.params.mid ? this.fetchMilestoneDetails() : this.fetchMilestoneStatus()
  }

  fetchMilestoneStatus() {
    fetch_get(PROJECTS_LIST + this.props.props.match.params.id + '/milestones/create/')
    .then(response => {
      this.setState({ statuses: response.statuses || [] });
    })
  }

  fetchMilestoneDetails() {
    fetch_get(PROJECTS_LIST + this.props.props.match.params.id + '/milestones/' + this.props.props.match.params.mid + '/update/')
    .then(response => {
      this.setState({ 
        statuses: response.statuses,
        name: response.data.name,
        description: response.data.description,
        status: response.data.status,
        startDate: response.data.estimated_start ? moment(response.data.estimated_start, 'YYYY-MM-DD') : '',
        endDate: response.data.estimated_finish ? moment(response.data.estimated_finish, 'YYYY-MM-DD') : ''
      })
    })
  }

  addEditMilestone() {
    const { name, status, startDate, endDate, description } = this.state;
    const data = new FormData();
    data.append('name', name);
    data.append('status', status);
    data.append('estimated_start', startDate ? startDate.format("YYYY-MM-DD") : '');
    data.append('estimated_finish', endDate ? endDate.format("YYYY-MM-DD") : '');
    data.append('description', description);
    let url = this.props.props.match.params.mid ? ('/milestones/' + this.props.props.match.params.mid + '/update/') : ('/milestones/create/');
    fetch_post(PROJECTS_LIST + this.props.props.match.params.id + url, data)
    .then(response => {
      if(!response.error) {
        notify(response.response);
        this.props.addMilestone()
        this.props.props.history.push(`${this.context.company}/pm/${this.props.props.match.params.id}/milestones`)
        this.setState({ 
          name: '',
          status: 'started',
          startDate: '',
          endDate: '',
          description: '',
          errors: {}
        })
      } else {
        this.setState({ errors: response.errors })
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  onCancel() {
    this.setState({
      name: '',
      startDate: '',
      endDate: '',
      description: '',
      status: 'started',
      errors: {},
    })
    this.props.props.history.push(`${this.context.company}/pm/${this.props.props.match.params.id}/milestones`)
    this.props.onCancel()
  }

  render() {
    return(
      <div className="slide_section ">
        <div className="add_task slide_content">
          <div className="row justify-content-start slide_content_row add_milestone_modal">
            <div className="col-lg-12">
              <h2 className="heading">{this.props.props.match.params.mid ? 'Edit' : 'Add'} Milestone</h2>
              <form>
                <div className="form-group">
                  <label >Milestone Name<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                  <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Milestone Title"
                    value={this.state.name} 
                    onChange={(e) => {this.setState({ name: e.target.value, error: {} })}} 
                  />
                  <div className="error-text">
                    {this.state.errors.name ? this.state.errors.name[0] : null}
                  </div>
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea 
                    className="form-control" 
                    rows="4" 
                    placeholder="Milestone Description"
                    value={this.state.description} 
                    onChange={(e) => {this.setState({ description: e.target.value })}}
                  >
                  </textarea>
                </div>            
                <div className="form-group">
                  <label>Status<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                  <ul className="filter_list ">
                    {this.state.statuses.length > 0 && this.state.statuses.map((status) =>
                      <li key={status[0]}>
                        <input 
                          type="radio" 
                          name="modal_status" 
                          id={status[0]} 
                          value={status[0]} 
                          checked={this.state.status === status[0] } 
                          onChange={(e) => this.setState({ status: e.target.value }) }
                        /> 
                        <label htmlFor={status[0]}>{status[1]}</label>
                      </li>
                    )}
                    <br clear="all" />
                  </ul>
                </div>
                <div className="row">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label>Estimated Start Date</label>
                      <div className="input-group mb-3">
                        <Datetime 
                          dateFormat="YYYY-MM-DD" 
                          timeFormat="" 
                          inputProps={{ className: 'form-control', placeholder: 'start date'}} 
                          value={this.state.startDate} 
                          onChange={(e) => { this.setState({ startDate: e }) }}
                          closeOnSelect 
                          open={this.state.openStartPicker ? true : false}
                          onBlur={() => this.setState({ openStartPicker: false })}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-outline-secondary" type="button" onClick={() => this.setState({ openStartPicker: !this.state.openStartPicker })}><i className="fas fa-calendar"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>  
                  <div className="col-lg-7">
                    <div className="form-group">
                      <label>Estimated End Date</label>
                      <div className="input-group mb-3">
                        <Datetime 
                          dateFormat="YYYY-MM-DD" 
                          timeFormat="" 
                          inputProps={{ className: 'form-control', placeholder: 'end date'}} 
                          value={this.state.endDate} 
                          onChange={(e) => { this.setState({ endDate: e }) }}
                          closeOnSelect
                          open={this.state.openEndPicker ? true : false} 
                          onBlur={() => this.setState({ openEndPicker: false })}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-outline-secondary" type="button" onClick={() => this.setState({ openEndPicker: !this.state.openEndPicker })}><i className="fas fa-calendar"></i></button>
                        </div>
                      </div>
                    </div>
                    {this.state.errors.estimated_finish ? 
                      <div className="error-text">
                        {this.state.errors.estimated_finish[0]}
                      </div> 
                    :
                      null
                    }
                  </div>
                </div>
                <div className="buttons">
                  <a className="btn primary_btn" onClick={() => {this.addEditMilestone()}}>{this.props.props.match.params.mid ? 'Update' : 'Add'} Milestone</a>
                  <a className="btn secondary_btn" onClick={() => this.onCancel()}>Cancel</a>
                </div>
                <br clear="all" />
              </form>
            </div>
          </div>      
        </div>
      </div>
    )
  }
}

