import React, { PureComponent } from 'react'

export default class AsyncComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      Component: null
    }
  }

  componentDidMount () {
    if (!this.state.Component) {
      this.props.moduleProvider().then(({ Component }) => {
        this.setState({ Component })
      })
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   if(!this.state.Component) {
  //     nextProps.moduleProvider().then( ({Component}) => {
  //       this.setState({ Component })
  //     });
  //   }
  // }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (!prevState.Component) {
      nextProps.moduleProvider().then(({ Component }) => {
        return { Component }
      })
    }
    return null
  }

  render () {
    const { Component } = this.state
    return (
      <div>
        {Component ? <Component /> : <div>Loading..</div>}
      </div>
    )
  }
}
