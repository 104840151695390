import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import download from "downloadjs";
import Datetime from 'react-datetime';
import AddWorklog from './AddWorklog';
import {
  PROJECTS_LIST,
  PROJECT_ACTIVITY,
  fetch_get,
  Pagination,
  notify,
  Spinner,
  Context
} from '../../common';

export default class Worklog extends Component {
  static contextType = Context;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment(),
      toDate: moment(),
      userProjects: [],
      activityList: [],
      profile: '',
      projectMembers: [],
      loading: false,
      filterProject: '',
      errors: {},
      currentPage: 1,
      projectId: this.props.match.params.id,
      pagination: { number: null, numOfPages: null },
      totalActiveTime: 0,
      totalInactiveTime: 0,
      totalTime: 0,
      numberOfDays: 0,
      can_create_worklog: false,
      addWorklog: false,
      editWorklogId: null,
      overlay: false
    };
    this.activeTime = this.activeTime.bind(this);
    this.overlayControl = this.overlayControl.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    this.filterActivity()
  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.setState({ 
        projectId: this.props.match.params.id,
        profile: '',
        fromDate: moment(),
        toDate: moment()
      }, () => {
        this.filterActivity();
      })
    }
  }

  filterActivity(projectId=this.state.projectId, fromDate=this.state.fromDate, toDate=this.state.toDate, page=1) {
    this.setState({ errors: false })
    var url = '/activity/list/?page='+ page + '&from_date=' + fromDate.format("YYYY-MM-DD") + '&to_date=' + toDate.format("YYYY-MM-DD") ;
    if(this.state.profile) {
      url += '&profile=' + this.state.profile;
    }
    fetch_get(PROJECTS_LIST +  projectId +  url, null, true)
    .then((response) => {
      if(!response.error && this._isMounted) {
        let dates_list = []
        var getDates = function(startDate, endDate) {
          var dates = [],
          currentDate = startDate,
          addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
          };
          while (currentDate <= endDate) {
            dates.push(currentDate);
            currentDate = addDays.call(currentDate, 1);
          }
          return dates;
        };
        var dates = getDates(fromDate, toDate);                                                                                                           
        dates.forEach(function(date) {
          var new_date = moment(date).format("YYYY-MM-DD")
          dates_list.push(new_date)
        });
        let new_date_list = []
        for(let i=0; i < dates_list.length; i++){
          let a = [];
          response.activities.data.forEach((obj) => {
            if(obj.c_start_time === dates_list[i]){
              a.push(obj)
            }
          })
          new_date_list.push(a)
        }

        let users_list = []
        response.activities.data.forEach((name) => {
          if(!users_list.includes(name.profile_details.data.email)){
            users_list.push(name.profile_details.data.email)
          }
        })
        let res = response.activities.data
        let list_based_on_date = []
        for(let i=0; i < dates_list.length; i++){
          for(var j=0; j < users_list.length; j++){
            let fields_of_user = []
            for(var k=0; k < res.length ; k++){
              if(res[k].profile_details.data.email === users_list[j] && res[k].c_start_time === dates_list[i]){
                fields_of_user.push(response.activities.data[k])
              }
            }
            if(fields_of_user.length > 0){
              list_based_on_date.push(fields_of_user)
            }
          }
        }
        this.setState({ 
          activityList: list_based_on_date,
          projectMembers: response.profiles.data,
          loading: false,
          pagination: { number: response.activities.number, numOfPages: response.activities.num_pages },
          totalTime: response.total_time,
          totalActiveTime: response.total_active_time,
          totalInactiveTime: response.total_inactive_time,
          numberOfDays: response.no_of_days,
          can_create_worklog: response.can_create_worklog
        }); 
      }
      else {
        this.setState({ errors: response.errors, loading: false })
      }
    });
  }

  handleChangeFromDate(fromDate) {
    this.setState({ fromDate });
  }

  handleChangeToDate(toDate) {
    this.setState({ toDate });
  }

  activeTime(seconds) {
    seconds = moment.duration(parseInt(seconds),'seconds')
    var format =  Math.floor(seconds.asHours()) > 0 ? Math.floor(seconds.asHours()) + 'h ' : '';
    format += seconds.minutes() + 'm';
    return format
  }

  clearFilterData() {
    this.setState({
      profile: '',
      fromDate: moment(),
      toDate: moment()
    }, () => { this.filterActivity() })
  }

  userName(name) {
    return name.split(' ')[0];
  }

  deleteActivity(id) {
    fetch_get(PROJECT_ACTIVITY + id + '/delete/', true)
    .then((response) => {
      if(response.status === 204) {
        notify("Activity Deleted Successfully")
        this.filterActivity();
      }
    });
  }

  getCurrentPage(currentPage) {
    this.setState({ currentPage })
    this.filterActivity(this.state.projectId, this.state.from_date, this.state.to_date, currentPage);
  }
  
  startEndTime(startTime, endTime, inactiveTime) {
    return "Start Time : " + startTime + "\nEnd time : " + endTime + "\nInactive time : " + this.activeTime(inactiveTime)
  }

  downloadExcel() {
    let userEmail = document.getElementById('userEmail') && document.getElementById('userEmail').options[document.getElementById('userEmail').selectedIndex].text;
    let { fromDate, toDate, projectId, profile } = this.state;
    fromDate = fromDate.format("YYYY-MM-DD");
    toDate = toDate.format("YYYY-MM-DD");
    var url = `export/xls/?from_date=${fromDate}&to_date=${toDate}&project=${projectId}&email=${profile !== '' ? userEmail : ''}`;
    fetch_get(PROJECT_ACTIVITY + url, true)
    .then((response) => response.blob())
    .then((response) => {
      download(response, `Worklog_${fromDate}_to_${toDate}.xls`);
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  overlayControl(){
    this.setState({ overlay: false, addWorklog: false })
    this.filterActivity()
  }

  render() {
    const { fromDate, toDate, errors, activityList, projectMembers, pagination } = this.state;
    return (
      <div className="secondary_block">
        <div className="overlay" style={{ display: this.state.overlay ? 'block' : 'none'}} onClick={() => this.overlayControl()}></div>
        <div className="row mar_lr_0">
          <div className="col-lg-12">
            <h2 className="heading">Worklog</h2>
            <div className="row filter_row">
              <form className="col-lg-12">
                <div className="row tabs_block mar_lr_0 tasks_tab_block">
                  <div className="col-lg-3 pl-0 form-group">
                    <Datetime
                      placeholderText="From Date" 
                      value={fromDate}
                      dateFormat="YYYY-MM-DD"
                      timeFormat=""
                      onChange={this.handleChangeFromDate.bind(this)}
                      closeOnSelect
                    />
                    <div className="error-text">
                      {errors.from_date && errors.from_date }
                    </div>
                  </div>
                  <div className="col-lg-3 form-group">
                    <Datetime
                      placeholderText="Enter Start Time" 
                      value={toDate}
                      dateFormat="YYYY-MM-DD"
                      timeFormat=""
                      onChange={this.handleChangeToDate.bind(this)}
                      closeOnSelect
                    />
                    <div className="error-text">
                      {errors.to_date && errors.to_date }
                    </div>
                  </div>
                  {projectMembers.length > 1 && 
                    <div className="col-lg-3 form-group">
                      <select 
                        id="userEmail"
                        className="form-control " 
                        value={this.state.profile}
                        onChange={(e) => this.setState({ profile: e.target.value })}
                      >
                        <option value="">Select Project member</option>
                        {projectMembers.map((profile) => 
                          <option key={profile.id} value={profile.id}>{profile.employee_name}</option>
                        )}
                      </select>
                    </div>
                  }
                  <div className="col-lg-3">
                    <button type="button" className="btn primary_btn" onClick={() => {this.setState({ activityList: [], loading: true }, () => { this.filterActivity() }) }}>Filter</button>
                    <button type="button" className="btn secondary_btn" onClick={() => {this.setState({ activityList: [], loading: true }, () => { this.clearFilterData() }) }}>Clear</button>
                    {this.state.can_create_worklog &&
                      <span
                        className="add_project"
                        onClick={() => this.setState({ overlay: true, addWorklog: true})}
                      >
                        <i className="fas fa-plus"></i>
                      </span>
                    }
                  </div>
                </div>
              </form>
           </div>
          </div>
          <div className="col-lg-12">
            <div className="row  justify-content-end">
              <div className="col-lg-8 text-left">
                <ul className="time_calculator"><li><b>Active Time:</b> {this.state.totalActiveTime} </li><li><b>Inactive Time:</b> {this.state.totalInactiveTime} </li><li><b>Total Time:</b> {this.state.totalTime} </li><li><b>Number of days:</b> {this.state.numberOfDays} </li></ul>
              </div>
              <div className="col-lg-4 text-right">
                <span onClick={this.downloadExcel.bind(this)} className="primary_btn">
                  Download Excel
                </span>
              </div>
            </div>
          </div>
          <div className="row tabs_block mar_lr_0 tasks_tab_block mt-5">
            <div className="col-lg-12">
              <table className="table table-hover worklog_table">
                <thead>
                  <tr>
                    <th width="7%">User</th>
                    <th width="10%" className="text-left">Task</th>
                    <th width="9%" className="text-left">Active Time</th>
                    <th width="25%">Description</th> 
                    <th width="8%">Type</th>
                    <th width="5%">Comments</th> 
                  </tr>
                </thead>
                <tbody>
                  {activityList.length > 0 ? activityList.map((activity) => 
                    activity.length === 1 ? 
                      activity.map((obj, index) => 
                        <tr className="list_worklog" key={index}>
                          <td>
                            <span>{obj.profile_details && obj.profile_details.data && obj.profile_details.data.employee_name}</span>
                          </td>
                          <td className="text-left">{obj.task_details && obj.task_details.data && obj.task_details.data.name}</td>
                          <td className="text-left">
                            <span
                              title={this.startEndTime(obj.formatted_start_time, obj.formatted_end_time, obj.inactive_time)}
                            >
                              <i className="fas fa-clock"></i> {this.activeTime(obj.active_time)} on {obj.start_time_format}
                            </span>
                          </td>
                          <td>
                           <p>{obj.description}</p>
                          </td>  
                          <td>{obj.is_manual_log ? "Manual" : "Client"}</td>  
                          <td className="actions">
                            <Link
                              to={{
                                pathname:`${this.context.company}/pm/${this.state.projectId}/worklog/${obj.id}/comment`, 
                                state:{activity: obj, action: 'create'}
                              }}
                            >
                              <i className="fa fa-comments" aria-hidden="true"></i>
                            </Link>
                            {obj.can_update_record &&
                              <Link
                                title="Update"
                                to={`${this.context.company}/pm/${this.state.projectId}/worklog/${obj.id}/edit`}
                              >
                                <i className="fa fa-edit" aria-hidden="true"></i>
                              </Link>
                            }
                          </td>
                        </tr> 
                      )
                    :
                      activity.map((obj, index) => 
                        <tr className="commom_worklog mt-5" key={index}>
                          <td>
                            <span>{obj.profile_details && obj.profile_details.data && obj.profile_details.data.employee_name}</span>
                          </td>
                          <td className="text-left">{obj.task_details && obj.task_details.data && obj.task_details.data.name}</td>
                          <td className="text-left">
                            <span
                              title={this.startEndTime(obj.formatted_start_time, obj.formatted_end_time, obj.inactive_time)}
                            >
                              <i className="fas fa-clock"></i> {this.activeTime(obj.active_time)} on {obj.start_time_format}
                            </span>
                          </td>
                          <td>
                           <p>{obj.description}</p>
                          </td>  
                          <td>{obj.is_manual_log ? "Manual" : "Client"}</td>  
                          <td className="actions">
                            <Link
                              to={{
                                pathname:`${this.context.company}/pm/${this.state.projectId}/worklog/${obj.id}/comment`, 
                                state:{activity: obj, action: 'create'}
                              }}
                            >
                              <i className="fa fa-comments" aria-hidden="true"></i>
                            </Link>
                            {obj.can_update_record &&
                              <Link
                                title="Update"
                                to={`${this.context.company}/pm/${this.state.projectId}/worklog/${obj.id}/edit`}
                              >
                                <i className="fa fa-edit" aria-hidden="true"></i>
                              </Link>
                            }
                          </td>
                        </tr> 
                      )
                  ) : null}
                </tbody>
              </table>
              {pagination.number && 
                <div>
                  {Number(pagination.numOfPages) > 1 && !this.state.loading &&
                    <Pagination
                      currentPage={this.state.currentPage}
                      totalPages={pagination.numOfPages}
                      getCurrentPage={this.getCurrentPage.bind(this)}
                    /> 
                  }
                </div>
              }
              <div>
                {(activityList.length === 0 &&  !this.state.loading) ?
                    <h5><center>No Worklog Found</center></h5>
                  :
                  this.state.loading &&
                  <Spinner loadingText />
                }
              </div>
            </div>
          </div>
          {(window.location.pathname.split('/')[6] || this.props.match.params.wid || this.state.overlay) &&
            <AddWorklog 
              props={this.props}
              addWorklog={this.state.addWorklog}
              overlayControl={this.overlayControl}
            />
          }
          <div className="overlay" style={{ display: (window.location.pathname.split('/')[6] || this.props.match.params.wid) ? 'block' : 'none' }} onClick={() => this.props.history.push(`${this.context.company}/pm/${this.state.projectId}/worklog`)}></div>
        </div>
      </div>
    );
  }
}