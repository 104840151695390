import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Datetime from 'react-datetime';
import moment from 'moment';
import { fetch_post, APP_NAME, notify, PROJECTS_LIST, fetch_get, MEETINGS, Modal, Context } from '../../common';

export default class Meetings extends Component {
  static contextType = Context;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'upcomingMeetings',
      upcomingMeetings: [],
      finishedMeetings: [],
      activeTabData: [],
      loading: false,
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      errors: {},
      InviteUsers: [],
      InviteUsersList: [],
      memberIds: [],
      title: ''
    };
    this.removeModal = this.removeModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getInitialState()
    if(this.props.match.params.mid) {
      this.setState({ editMeeting: true, showModel: true }, this.setInitialData)
    } 
    if(this.props.history.location.pathname.split('/')[6] == 'add') {
      this.enableAddMeeting()
    }
    if(this.props && this.props.location.state && this.props.location.state.is_closed){
      this.fetchMeetings('','finishedMeetings');
    }else{
      this.fetchMeetings('','upcomingMeetings');
    }
    if(this.props && this.props.location.state && this.props.location.state.addFromDetails){
      this.setState({ showModel: true })
    }
    this.setState({ loading: true })
    // this.fetchMeetings();
  }

  getInitialState() {
    this.setState({
      selectedWeekDays: [],
      durationChoices: [],
      editMeeting: false,
      forAllMeetings: false,
      usersDropdown: false,
      isDeleteModal: false,
      memberIds: [],
      showModel: false,
      title: '',
      description: '',
      meetingOption: '',
      startDate: moment(),
      endDate: moment(),
      startTime: moment(),  
      duration: '',
      InviteUsers: [],
      InviteUsersList: [],
      cancelWholeSeries: false,
      errors: {}
    })
  }

  fetchMeetings(month='', tab) {
    fetch_get(MEETINGS + '?project=' + this.props.match.params.id + '&month=' + month)
    .then((response) => {
      if(this._isMounted) {
        this.setState({
          upcomingMeetings: response.meetings_list,
          finishedMeetings: response.completed_meetings_list,
          activeTabData: tab == 'upcomingMeetings' ? response.meetings_list : response.completed_meetings_list,
          activeTab: tab == 'upcomingMeetings' ? 'upcomingMeetings' : 'finishedMeetings',
          loading: false
        })
      }
    })
  }

  onClickTab(value) {
    if(value === 'upcomingMeetings') {
      this.setState({ activeTabData: this.state.upcomingMeetings })
    }
    else {
      this.setState({ activeTabData: this.state.finishedMeetings }) 
    }
    this.setState({ activeTab: value })
  }

  activeTab(value) {
    return (value === this.state.activeTab) ? 'tab active' : 'tab'
  }

  setInitialData() {
    fetch_get(PROJECTS_LIST + `${this.props.match.params.id}/meeting/create/`)
    .then((response) => {
      this.setState({ 
        InviteUsersList: response.users.data,
        durationChoices: response.duration_choices
      })
    })
    if(this.state.editMeeting) {
      fetch_get(PROJECTS_LIST + `${this.props.match.params.id}/meeting/${this.props.match.params.mid}/update/`)
      .then((response) => {
        let memberIds = [];
        let InviteUsers = [];
        response.data.data.users_invited_details.data.map((obj) => {
          if(obj.id !== this.context.id) {
            memberIds.push(obj.id)
            InviteUsers.push(obj)
          }
        })

        this.setState({ 
          duration: response.data.data.duration ? response.data.data.duration : '',
          title: response.data.data.title,
          meetingOption: response.data.data.meeting_option,
          description:response.data.data.description,
          startDate: moment(response.data.data.start_date),
          startTime: moment(response.data.data.formatted_time_start, "HH:mm A"),
          InviteUsers,
          memberIds
        })
        if(this.props.location.state) {
          this.setState({ 
            editMeeting: true, 
            forAllMeetings: this.state.forAllMeetings,
            endDate: moment(response.data.data.end_date),
            selectedWeekDays: [...this.state.selectedWeekDays, response.data.data.day_of_meeting]
          })
        }
      })
    }
  }

  createEditMeeting() {
    const { title, description, duration, InviteUsers, meetingOption, startTime, startDate, endDate, selectedWeekDays, editMeeting, forAllMeetings } = this.state;
    const data = new FormData();
    const url = editMeeting ? `${this.props.match.params.mid}/update/` : 'create/';
    if(editMeeting) {
      data.append('update_all', forAllMeetings);  
    }
    if(meetingOption === 'recurrence') {
      data.append('end_date', endDate ? endDate.format("YYYY-MM-DD") : '');
      selectedWeekDays.filter((obj) =>
        data.append('days', obj)
      );
    }
    data.append('duration', duration);
    data.append('minutes_of_meeting', '');
    data.append('title', title);
    data.append('meeting_option', meetingOption)
    data.append('project', this.props.match.params.id);
    data.append('description', description);
    data.append('start_date', startDate ? startDate.format("YYYY-MM-DD") : '');
    data.append('start_time', startTime ? startTime.format("HH:mm:ss") : '');
    InviteUsers.map((user) =>
      data.append('users_invited', user.id)
    )
    fetch_post(PROJECTS_LIST + `${this.props.match.params.id}/meeting/` + url, data)
    .then((response) => {
      if (!response.error) {
        notify(`Meeting ${this.state.editMeeting ? "updated" : "created"} Successfully`);
        this.getInitialState()
        this.fetchMeetings('', this.state.activeTab)
        // this.props.history.push(`${this.context.company}/pm/${this.props.match.params.id}/meetings/`)
      }
      else {
        this.setState({ errors: response.errors })
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  usersInviteUpdate(member) {
    if(this.state.memberIds.includes(member.id)) {
      this.state.memberIds.splice(this.state.memberIds.indexOf(member.id), 1 );
      // this.state.InviteUsersList.push(member)
      this.state.InviteUsers.map((memberDetails, index) => {
        if(memberDetails.id === member.id) {
          this.state.InviteUsers.splice(index, 1)
        }
      })
      this.setState({ InviteUsers: this.state.InviteUsers, memberIds: this.state.memberIds })
    } else {
      this.setState({ InviteUsers: [...this.state.InviteUsers, member], memberIds: [...this.state.memberIds, member.id], showVisibleMembers: false })
    }
  }

  deleteMeeting() {
    fetch_get(`${MEETINGS + this.props.match.params.mid}/delete/?delete_all=${this.state.cancelWholeSeries}`, true)
    .then((response) => {
      if (response.status === 200) {
        notify('Meeting Deleted Successfully!!!');
        this.getInitialState()
        this.fetchMeetings('', this.state.activeTab)
        // this.props.history.push(`${this.context.company}/pm/${this.props.match.params.id}/meetings/`)
      }
    });
  }

  removeModal() {
    this.setState({ isDeleteModal: false })
  }

  weekChange(type, week) {
    if(type === 'all') {
      if(this.state.selectedWeekDays.length < 7) {
        this.setState({ selectedWeekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] })
      } else {
        this.setState({ selectedWeekDays: [] })
      }
    } else {
      if(!this.state.selectedWeekDays.includes(week)) {
        this.setState({ selectedWeekDays: [...this.state.selectedWeekDays, week]})
      } else {
        this.state.selectedWeekDays.splice(this.state.selectedWeekDays.indexOf(week), 1 );
        this.setState({ selectedWeekDays: this.state.selectedWeekDays})
      }
    }
  }

  enableAddMeeting() {
    this.getInitialState()
    this.setState({ showModel: true, editMeeting: false }, this.setInitialData)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { activeTabData, loading, upcomingMeetings, finishedMeetings, title, description, weekDays, selectedWeekDays, startTime, startDate, endDate, duration, durationChoices, editMeeting, meetingOption, usersDropdown, errors } = this.state;
    return (
      <div className="secondary_block">
        <div className="row mar_lr_0 table_height ">
          <div className="row tabs_block mar_lr_0 tasks_tab_block">
            <div className="col-lg-5">
              <h2 className="heading mt-0">Meetings</h2>
            </div>
               <div className="col-lg-7">
              <div className="tabs mt-4">
               <a className={this.activeTab('upcomingMeetings') + ' open'} onClick={() => this.onClickTab('upcomingMeetings')}><strong>{upcomingMeetings.length}</strong> <span>Upcoming</span></a>
                <a className={this.activeTab('finishedMeetings') + ' closed'} onClick={() => this.onClickTab('finishedMeetings')}><strong>{finishedMeetings.length}</strong> <span>Finished</span></a>
                <Link className="add_project" to={ APP_NAME + this.context.company + `/pm/${this.props.match.params.id}/meetings/add` } onClick={() => this.enableAddMeeting()}><i className="fas fa-plus"></i></Link>
              </div>
            </div>
          </div>
          <div className="meeting_container row mar_lr_0 justify-content-center">
            <div className="row mar_lr_0 list_of_projects">
              {activeTabData.length > 0 ? activeTabData.map((meeting) => 
                <div className="meetings" key={meeting.data.id}>
                  <div className="card">
                    <div>
                      <Link to={ APP_NAME + this.context.company + `/pm/${this.props.match.params.id}/meetings/${meeting.data.id}/details` }>
                        <div className="card_pro meeting">
                          <h5 className="card-title text-left"> 
                              {meeting.data.title} 
                          </h5>
                          <span>{meeting.data.meeting_option_display}</span>
                        </div>
                        <div className="card-body">
                          <div className="row mar_lr_0">
                            <div className="col-lg-6 p-0 time_span">
                              <p> Date : <span>{meeting.data.date_of_meeting}</span></p>
                            </div>
                            <div className="col-lg-6 p-0 time_span text-right">
                              <p> Time : <span> {meeting.data.formatted_time_start} </span></p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="card-body">
                      <h4 className="card-title d-inline">{meeting.data.start_date_timesince}</h4>
                      {meeting.can_edit && <Link to={{ pathname: `${this.context.company}/pm/${this.props.match.params.id}/meetings/${meeting.data.id}/edit`, state: { editAll: false }}} onClick={() => this.setState({ showModel: true, editMeeting: true }, this.setInitialData)} className="edit on_date"><i className="far fa-edit"></i></Link> }
                    </div>
                  </div>
                </div>
              )
              :
                <div className="col-lg-12 meeting_view">
                  <div className="meeting_item"> {loading ? 'Loading ...' : 'No Meetings Found ' } </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="row mar_lr_0 justify-content-center new_meeting">
          <div className="col-lg-4">
            <div className="overlay" style={{ display: this.state.showModel ? 'block' : 'none' }} onClick={() => this.getInitialState() }></div>
            <div className="slide_section " style={{ display: this.state.showModel ? 'block' : 'none' }}>
              <div className="add_task slide_content">
                <div className="row mar_lr_0">
                  <div className="col-lg-12 p-0">
                    <h2 className="heading">{this.state.editMeeting ? 'Edit' : 'New'} Meeting</h2>
                    <form>
                      <div className="form-group ">
                        <label>
                        Title<sup className="error-text" style={{top: '0px' }}>*</sup>
                        </label>
                        <input type="text" className="form-control" value={title} onChange={(e) => this.setState({ title: e.target.value }) } />
                        {errors.title && 
                          <span className="error-text">{errors.title}</span>
                        }
                      </div>
                      <div className="form-group">
                        <label>
                        Agenda
                        </label>
                        <textarea className="form-control textarea" rows="3" value={description} onChange={(e) => this.setState({ description: e.target.value }) }></textarea>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>How Often?<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                            <input 
                              type="radio" 
                              name="meeting_type" 
                              value="recurrence" 
                              id="recurrence" 
                              checked={meetingOption == 'recurrence'}
                              onChange={(e) => this.setState({ meetingOption: e.target.value })}
                              disabled={this.state.editMeeting}
                            /> 
                            <label htmlFor="recurrence" id="recurrence" className="inline-block">Recurring</label>
                            <span className="or">Or</span>
                            <input 
                              type="radio" 
                              name="meeting_type" 
                              value="day" 
                              id="day" 
                              checked={this.state.meetingOption == 'day'}
                              onChange={(e) => this.setState({ meetingOption: e.target.value })}
                              disabled={this.state.editMeeting}
                            />
                            <label id="day" htmlFor="day" className="inline-block">One Time</label>
                          </div>
                          {errors.meeting_option && 
                            <span className="error-text">{errors.meeting_option}</span>
                          }
                        </div>
                      </div>
                      {meetingOption === 'recurrence' &&
                        <div className="add_meeting_block" id="recurring_type">
                          <div className="section">
                            <div className="heading">Week Days<sup className="error-text" style={{top: '0px' }}>*</sup></div>
                            <div className="row">
                              <div className="col-lg-12">
                                {editMeeting ?
                                  <ul className="filter_list">
                                    <li>
                                      <input type="checkbox" id="weekDay" name="weekDay" value={selectedWeekDays} checked disabled/> 
                                      <label htmlFor="weekDay">{selectedWeekDays}</label>
                                    </li>
                                  </ul>
                                :
                                  <ul className="filter_list">
                                    {weekDays.length > 0 ? weekDays.map((day, index) => 
                                      <li key={index} >
                                        <input type="checkbox" style={{ display: 'block' }} checked={this.state.selectedWeekDays.includes(day)} id={day} name={day} value={day} onChange={(e) => this.weekChange('single', e.target.value) } /> 
                                        <label htmlFor={day}>{day}</label>
                                      </li>
                                    ): null}
                                    <li>
                                      <input type="checkbox" style={{ display: 'block' }} checked={this.state.selectedWeekDays.length >= 7} id="Select All" name="Select All" value="Select All" onChange={() => this.weekChange('all')} /> 
                                      <label htmlFor="Select All">Select All</label>
                                    </li>
                                  </ul>
                                }
                                {errors.days && 
                                  <span className="error-text">{errors.days}</span>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group ">
                                <label>Start Date<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                                <Datetime 
                                  dateFormat="YYYY-MM-DD" 
                                  timeFormat="" 
                                  inputProps={{ className: 'form-control', disabled: editMeeting }} 
                                  value={startDate} 
                                  onChange={(e) => this.setState({ startDate: e })}
                                  closeOnSelect
                                />
                                {errors.start_date && 
                                  <span className="error-text">{errors.start_date}</span>
                                }
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group ">
                                <label>End Date<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                                <Datetime 
                                  dateFormat="YYYY-MM-DD" 
                                  timeFormat="" 
                                  inputProps={{ className: 'form-control', disabled: editMeeting}} 
                                  value={endDate} 
                                  onChange={(e) => this.setState({ endDate: e })}
                                  closeOnSelect
                                />
                                {errors.end_date && 
                                  <span className="error-text">{errors.end_date}</span>
                                }
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group ">
                                <label>Start Time<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                                <Datetime 
                                  dateFormat=""
                                  timeFormat="hh:mm A" 
                                  inputProps={{ className: 'form-control'}} 
                                  value={startTime} 
                                  onChange={(e) => this.setState({ startTime: e })}
                                  closeOnSelect
                                />
                                {errors.start_time && 
                                  <span className="error-text">{errors.start_time}</span>
                                }
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group ">
                                <label>Duration</label>
                                <select 
                                  className="form-control"
                                  value={duration} 
                                  onChange={(e) => this.setState({ duration: e.target.value })}
                                >
                                  <option value="">--</option>
                                  {
                                    durationChoices.map((obj) =>
                                      <option value={obj[0]} key={obj[0]}>{obj[1]}</option>
                                    )
                                  }
                                </select>
                              </div>
                            </div>
                            {this.state.editMeeting &&
                              <div className="col-lg-6">
                                <div className="form-group ">
                                  <label>Update Meeting</label>
                                  <select 
                                    className="form-control"
                                    value={this.state.forAllMeetings} 
                                    onChange={(e) => this.setState({ forAllMeetings: e.target.value })}
                                  >
                                    <option value="true">Whole Series</option>
                                    <option value="false">This Meeting</option>
                                  </select>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      }
                      {meetingOption === 'day' &&
                        <div id="onetime_type">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group ">
                                <label>Date<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                                <Datetime 
                                  dateFormat="YYYY-MM-DD" 
                                  timeFormat="" 
                                  inputProps={{ className: 'form-control'}} 
                                  value={startDate} 
                                  onChange={(e) => this.setState({ startDate: e })}
                                  closeOnSelect
                                />
                                {errors.start_date && 
                                  <span className="error-text">{errors.start_date}</span>
                                }
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group ">
                                <label>Start Time<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                                <Datetime 
                                  dateFormat="" 
                                  timeFormat="hh:mm A" 
                                  inputProps={{ className: 'form-control'}} 
                                  value={startTime} 
                                  onChange={(e) => this.setState({ startTime: e })}
                                  closeOnSelect
                                />
                                {errors.start_time && 
                                  <span className="error-text">{errors.start_time}</span>
                                }
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group ">
                                <label>Duration</label>
                                <select 
                                  className="form-control"
                                  value={duration} 
                                  onChange={(e) => this.setState({ duration: e.target.value })}
                                >
                                  <option>--</option>
                                  {
                                    durationChoices.map((obj) =>
                                      <option key={obj[0]} value={obj[0]}>{obj[1]}</option>
                                    )
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      <div className="form-group">
                        <label className="d-block">
                        Invite Users<sup className="error-text" style={{top: '0px' }}>*</sup>
                        </label>
                        <div className="avatars no-padding">
                          {this.state.InviteUsers.length > 0 ? this.state.InviteUsers.map((user) =>
                            <span 
                              key={user.id}
                              className="profile_name avatar" 
                            >
                            {user.has_profile_pic ?
                              <img alt="" src={ user.profile_img} />
                            :
                              <span className="no-padding" data-toggle="tooltip" data-placement="bottom">{user.profile_text}</span>
                            }
                            </span>
                          ): null}
                        </div>
                        <span 
                          className="btn-group dropdown people task_people no-padding " 
                          tabIndex={0}
                          onBlur={() => this.setState({ usersDropdown: false })}
                          onClick={() => this.setState({ usersDropdown: !this.state.usersDropdown })}
                        >
                          <a className="btn toggle avatar" style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                          +
                          </a>
                          <ul className= {this.state.InviteUsersList.length > 0 ? "menu" : ""} style={{ display: usersDropdown ? 'block' : 'none'}}>
                            {this.state.InviteUsersList.length > 0 ? this.state.InviteUsersList.map((user, index) =>
                              <li className="submenu" key={user.id} onClick={() => this.usersInviteUpdate(user, index)}>
                                <a>
                                  {user.has_profile_pic ?
                                    <img alt="" src={ user.profile_img} />
                                  :
                                   
                                    <span className="profile_name" data-toggle="tooltip" data-placement="bottom">{user.profile_text}</span>

                                  }
                                  {user.employee_name}
                                  {this.state.memberIds.includes(user.id) &&
                                    <span className="float-right" style={{ display: 'block'}}><i className="fas fa-check" style={{ fontSize: '0.9rem' }}></i></span>
                                  }
                                </a>
                              </li>
                            ): <li>
                            No users to Invite</li>}
                          </ul>
                        </span>
                      </div>
                      {errors.users_invited && 
                        <span className="error-text">{errors.users_invited}</span>
                      }
                      <div className="text-left mt-5 buttons_div">
                        <a className="btn primary_btn" onClick={() => this.createEditMeeting()}>{editMeeting ? 'Update' : 'Create New'} Meeting</a>
                        {(editMeeting && meetingOption === 'recurrence') &&  <button type="button" className="btn secondary_btn" onClick={() => this.setState({ isDeleteModal: true, cancelWholeSeries: true })}>Cancel Whole series</button>}
                        {editMeeting && <button type="button" className="btn secondary_btn" onClick={() => this.setState({ isDeleteModal: true, cancelWholeSeries: false })}>Cancel this Meeting</button>}
                        <Link to={ `${this.context.company}/pm/${this.props.match.params.id}/meetings` } onClick={() => this.setState({ showModel: false }, () => {this.getInitialState()})} className="btn secondary_btn">Cancel</Link>
                      </div>
                    </form>
                    <Modal
                      display={this.state.isDeleteModal}
                      bodyText={this.state.cancelWholeSeries ? 'Are you sure? You want to cancel Whole series ?' : 'Are you sure? You want to cancel this Meeting ?'}
                      modalAction={this.deleteMeeting.bind(this)}
                      removeModal={this.removeModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
