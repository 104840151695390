import React, { Component } from 'react';
import { fetch_get, PROJECTS_LIST, notify, Context } from '../common';

export default class Online extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      live_list: [],
      isLoading: true
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    fetch_get(PROJECTS_LIST + this.props.match.params.id + '/members/live/')
    .then((response) => {
      if(!response.error) {
        this.setState({
          live_list: response.data,
          isLoading: false
        })
      }
      else {
        notify('Something went wrong!!!')
      }
    });
  }

  requestStatusColor(status) {
    let color = ''
    {status === 'approved' ? color = "#004E00" : color = "orange"}
    return color;
  }

  render() {
    return (
        <div className="secondary_block">
      <div className="row mar_lr_0">
        <div className="col-lg-12">
          <h2 className="heading">Project Members Online</h2>
          <table className="table table-hover">
            <thead>
              <tr>
                <th width="30%">User</th>
                <th width="40%">Task</th>
                <th width="10%" className="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.isLoading ? <tr><td>Loading...</td></tr> :
                this.state.live_list.map((user, index) =>
                  <tr key={index}>
                    <td className="title">
                      <a className="profile_pic">
                      {
                        user.has_profile_pic ?
                        <img src={user.profile_img}  alt="" /> :
                        <span className="profile_name">
                          {user.profile_text}
                        </span>
                      }
                      <span className="employee_name">{user.username}
                        <p>{user.email}</p>
                      </span>
                      </a>
                    </td>
                    <td>{user.task}</td>
                    <td className="actions" align="center">
                      {
                        user.is_active ?
                        <a className="green"><i className="fa fa-circle" /></a> :
                        <a className="red"><i className="fa fa-circle" /></a>
                      }
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
      </div>
    );
  }
}
