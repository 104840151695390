export const Linkify = (text) => {
  text = text.replace('&nbsp;', ' ')
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig
  const linkText = text.replace(urlRegex, (url) =>
    '<a target="_blank" href="' + url + '">' + url + '</a>'
  )
  return linkText.replace(/\B@[a-z0-9_.-]+/gi, (name) =>
    '<span className="mention_name">' + name + '</span>'
  )
}
