import React, { Component } from 'react';
import { Switch, Route,Redirect } from 'react-router-dom';
import Context from '../Context';

import Dashboard from './Dashboard';

// import Admin from './admin/Main';
// import SALES from './sales/Main';
import PM from './pm/Main';
// import HR from './hr';
import NotFoundPage from './NotFoundPage';
import Unauthorized from './Unauthorized';
import { APP_NAME, USERS, fetch_get } from './common';
import Profile from './Profile';
import EditProfile from './EditProfile';
import ChangePassword from './auth/ChangePassword';
import AsyncComponent from './AsyncComponent';
import LeftMenu from './LeftMenu';
// const PM = () => import(/* webpackChunkName: "pm" */ './pm/Main');
const SALES = () => import(/* webpackChunkName: "sales" */  './sales/Main');
const MARKETING = () => import(/* webpackChunkName: "marketing" */  './marketing/Main');
const HCM = () => import(/* webpackChunkName: "hcm" */ './hr/Main');
const Admin = () => import(/* webpackChunkName: "admin" */ './admin/Main');
const Update = () => import(/* webpackChunkName: "update" */ './Update');

let value = 0
class Home extends Component {
  static contextType = Context;
  componentDidMount() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.props.history.push('/signin')
    }else{
       const company_id = this.props.location.pathname.split('/')[1];
      localStorage.setItem('company', company_id);
    }
  }

  sendEmail() {
    fetch_get(USERS + this.context.id + '/reinvite/')
    .then(() => {
      // notify(response.message)
      alert('An activation link has been sent to your email successfully')
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return (prevState.permissions !== nextProps.permissions) ? {
  //     permissions: nextProps.permissions,
  //     role: nextProps.role
  //   } : null
  // }

  render() {
    var uri = window.location.pathname.toString();
    var url = uri.toLowerCase();
    var company = this.context.company;
    const { permissions, role } = this.context;
    value = ++value
    const Main = (
      <main>
        <Switch>
          <Route exact sensitive path="/" component={Dashboard} />
          <Route exact sensitive path="/" component={Dashboard} />
          <Route exact sensitive path={ APP_NAME + company + "/profile" } component={Profile} />
          <Route exact sensitive path={ APP_NAME + company + "/profile/update" } component={EditProfile} />
          <Route exact sensitive path={ APP_NAME + company + "/change-password" } component={ChangePassword} />
          <Route path={APP_NAME + company + "/pm"} component={permissions.includes('PM') ? PM : Unauthorized} />
          <Route path={APP_NAME + company + "/hr"} component={permissions.includes('HCM') ? () => <AsyncComponent moduleProvider={HCM} /> : Unauthorized} />
          <Route path={APP_NAME + company + "/sales"} component={permissions.includes('SALES') ? () => <AsyncComponent moduleProvider={SALES} /> : Unauthorized} />
          <Route path={APP_NAME + company + "/marketing"} component={permissions.includes('MARKETING') ? () => <AsyncComponent moduleProvider={MARKETING} /> : Unauthorized} />
          <Route path={APP_NAME + company + "/admin"} component={ role ? () => <AsyncComponent moduleProvider={Admin} /> : Unauthorized} />
          {/*<Route path={APP_NAME + "/pm"} component={permissions.includes('PM') ? () => <AsyncComponent moduleProvider={PM} /> : Unauthorized} />
          <Route path={APP_NAME + "/marketing"} component={permissions.includes('MARKETING') ? () => <AsyncComponent moduleProvider={MARKETING} /> : Unauthorized} />
          <Route path={APP_NAME + "/admin"} component={ role === 'admin' ? () => <AsyncComponent moduleProvider={Admin} /> : Unauthorized} />
            <Route path={APP_NAME + "/pm"} component={permissions.includes('PM') ? PM : Unauthorized} />
            <Route path={APP_NAME + "/sales"} component={permissions.includes('SALES') ? CRM : Unauthorized} />
            <Route path={APP_NAME + "/marketing"} component={permissions.includes('MARKETING') ? CRM : Unauthorized} />
            <Route path={APP_NAME + "/admin"} component={ role === 'admin' ? Admin : Unauthorized} />
          */}
          {value === 2?
          <Route path="/" render={() => (<Redirect to={url}/>)}/>:<Route component={NotFoundPage} />}                     
          <Route component={NotFoundPage} />
        </Switch>
      </main>
    );
    return (
      <div>
        <AsyncComponent moduleProvider={Update} />
        <LeftMenu history={this.props.history} />
        <div className="stage">
        {(this.context.id && !this.context.EmailVerified) &&
          <div className="col-lg-12 text-center alert alert-danger" role="alert">
            Your account is not yet activated. Please activate your account now or request for new activation link. 
              <u style={{cursor: "pointer"}} onClick={() => this.sendEmail()}>Here</u> 
          </div>
        }
          {this.context.permissions.length > 0 ? Main : null}
        </div>
      </div>
    );
  }
}

export default Home
