import React, { Component } from 'react'
import { fetch_post, CHANGE_PASSWORD, notify } from '../common'

class ChangePassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
      old_password: '',
      new_password1: '',
      new_password2: '',
      error: '',
      errors: {}
    }
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    // localStorage.setItem('company', window.location.host.split('.')[0]);
  }

  handleChangeEmail (event) {
    this.setState({ email: event.target.value, error: '', errors: {} })
  }

  handleChangePassword (event) {
    this.setState({ password: event.target.value, error: '', errors: {} })
  }

  handleSubmit (event) {
    event.preventDefault()
    const { old_password, new_password1, new_password2 } = this.state
    const data = new FormData()
    data.append('old_password', old_password)
    data.append('new_password1', new_password1)
    data.append('new_password2', new_password2)
    fetch_post(CHANGE_PASSWORD, data)
      .then((response) => {
        if (!response.errors) {
          localStorage.setItem('token', response.token)
          notify(response.message)
          this.props.history.goBack()
        } else {
          this.setState({ errors: response.errors })
        }
      })
      .catch(() => {
        this.setState({ error: 'Something went wrong, Please try again' })
      })
  }

  render () {
    const { errors } = this.state
    return (
      <div className='row h-100 mar_lr_0 justify-content-center'>
        <div className='col-lg-4 change_password'>
          <h2 className='heading'>Change Password</h2>
          <form>
            <div className='form-group '>
              <label>
                Old Password  <div className='error-text'>*</div>
              </label>
              <input
                type='password'
                className='form-control'
                placeholder=''
                value={this.state.old_password}
                onChange={(e) => this.setState({ old_password: e.target.value, errors: {} })}
              />
              <div className='error-text'>
                {errors.old_password && errors.old_password}
              </div>
            </div>
            <div className='form-group '>
              <label>
                New Password  <div className='error-text'>*</div>
              </label>
              <input
                type='password'
                className='form-control'
                placeholder=''
                value={this.state.new_password1}
                onChange={(e) => this.setState({ new_password1: e.target.value, errors: {} })}
              />
              <div className='error-text'>
                {errors.new_password1 ? errors.new_password1 : null}
              </div>
            </div>
            <div className='form-group '>
              <label>
                Confirm Password  <div className='error-text'>*</div>
              </label>
              <input
                type='password'
                className='form-control'
                placeholder=''
                value={this.state.new_password2}
                onChange={(e) => this.setState({ new_password2: e.target.value, errors: {} })}
              />
              <div className='error-text'>
                {errors.new_password2 ? errors.new_password2 : null}
              </div>
            </div>
            <div className='text-right buttons_div'>
              <button type='button' className='btn primary_btn' onClick={this.handleSubmit}>Change password</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default ChangePassword
