import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import {
  fetch_get,
  fetch_post,
  notify,
  Context,
  DropBox,
  PROJECTS_LIST 
} from '../../common';

export default class EditDocument extends Component{
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      assigned_users: [],
      files: [],
      documentsList: [],
      assignedMembersList: [],
      errors: {},
      load:false,
      is__public:this.props.is_public,
    };
    this.handleAssignedUsersSelectChange = this.handleAssignedUsersSelectChange.bind(this);
  }

  componentDidMount() {
    this.fetchEditDocumentData();
  }

  fetchEditDocumentData() {
    let assigned_users = [];
    let assignedMembersList = [];
    fetch_get(PROJECTS_LIST+`${this.props.projectId}/document/${this.props.editDocumentId}/update/`)
    .then((response) => {
      response.document.data.assigned_users_details.data.map((team) =>
          assigned_users.push({label: team.employee_name, value: team.id})
      )
      response.project_team.data.map((team) =>
          assignedMembersList.push({ label: team.user_profile.data.employee_name, value: team.user_profile.data.id })
      )
      if (response.document) {
        this.setState({
          title: response.document.data.title,
          description: response.document.data.description ? response.document.data.description : '',
          // files: response.document.data.file_type_code[0]
          assigned_users,
          assignedMembersList
        });
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  saveDocument(e) {
    e.preventDefault();
    let assigned_users_array = []
    const data = new FormData();
    this.state.files.map((file) => data.append('document_file', file))
    data.append('title', this.state.title);
    this.state.assigned_users && this.state.assigned_users.filter((obj) => assigned_users_array.push(obj.value))
    data.append('assigned_users', JSON.stringify(assigned_users_array));
    data.append('description', this.state.description);
    if(document.getElementById('checkbox4')){
      if(document.getElementById('checkbox4').checked){
        data.append('is_public', true)
      }
      else{
        data.append('is_public', false)
      }
    }  
    let url = this.props.fileType == 'no-file' ? `${PROJECTS_LIST}${this.props.projectId}/create-document/${this.props.editDocumentId}/update/` : `${PROJECTS_LIST}${this.props.projectId}/document/${this.props.editDocumentId}/update/`
    fetch_post(url, data)
    .then((response) => {
      if (!response.error) {
        notify('Document updated Successfully');
        this.props.overlayControl();
        this.props.fetchDocumentsList();
        this.setState({
          fileName: 'No file chosen',
          title: '',
          description: '',
          load:false,
        });
      }
      else {
        this.setState({ errors: response.errors, load:false })
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  handleAssignedUsersSelectChange(value){
    this.setState({  assigned_users: value})
  }

  is_public(){
    if(this.state.is__public){
      this.setState({is__public:false})
    }
    else{
      this.setState({is__public:true})
    }
  }

  render(){
    const { errors, assignedMembersList } = this.state;
    return(
      <div className="slide_section ">
        <div className="add_task slide_content">
          <div className="row justify-content-start slide-section slide_content_row no-gutters">
            <div className="col-lg-12">
              <h2 className="heading">Edit Document</h2>

              <form className="no-padding">
                <div className="form-group">
                  <label>Title<div className='error-text'>*</div></label>
                  <input 
                    type="text" 
                    className="form-control" 
                    value={this.state.title}
                    onChange={(e) => this.setState({ title: e.target.value })}
                  />
                  <div className="error-text">
                    { errors ? errors.title : '' }
                  </div>
                </div>
                <div className="form-group">
                  <label>Description  </label>
                  <textarea 
                    className="form-control textarea" 
                    id="exampleFormControlTextarea1" 
                    value={this.state.description == null ? '' : this.state.description} 
                    rows="3"
                    onChange={(e) => this.setState({ description: e.target.value })}
                  >
                  </textarea>
                  <div className="error-text">
                    { errors ? errors.description : ''}
                  </div>
                </div>

                <div className="form-group">
                  <label>Assigned Users <div className='error-text'>*</div></label>
                  <Select
                      isMulti
                      onChange={this.handleAssignedUsersSelectChange}
                      options={assignedMembersList}
                      placeholder="Select A User(s)"
                      value={this.state.assigned_users}
                    />
                    <div className="error-text">
                    { errors.assigned_users ? errors.assigned_users[0] : null }
                  </div>
                </div>
                { this.props.fileType === 'no-file' ?
                  <div className="form-check">
                    <input id="checkbox4" type="checkbox" checked={this.state.is__public ? true : false} onClick={()=>this.is_public()} />
                    <label htmlFor="checkbox4">Is Visible to Public</label>
                  </div>:
                  null
                }
                {this.props.fileType === 'no-file' ? null :
                <div className="form-group">
                  <label>File<div className='error-text'>*</div> <small>[supported file formats: .jpg, .png, .jpeg, doc, docx, pdf, xlsx, csv]</small></label>
                  <DropBox
                    files={this.state.files}
                    onDrop={(files) => this.setState({ files })}
                  />
                  <div className="error-text">
                    { errors.document_file }
                  </div>
                </div>}

                <div className="text-left buttons">
                  {this.state.load ? 
                    <Fragment>
                      <div className="spinner task">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                      </div>
                    </Fragment> :                
                  <button type="button" className="btn primary_btn" onClick={(e)=> this.setState({load:true},this.saveDocument(e))}>Save Details </button>}
                  <button 
                    type="button" 
                    className="btn secondary_btn"  
                    onClick={this.props.cancelOverlay}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}