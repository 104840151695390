import React, { Component } from 'react';
import Datetime from 'react-datetime';
import { SketchPicker } from 'react-color';
import moment from 'moment';
import ProjectPriorities from './ProjectPriorities';
import ProjectStatus from './ProjectStatus';
import Sentry from './integrations/Sentry';
import GitLab from './integrations/GitLab';
import GitHub from './integrations/GitHub';
import Trello from './integrations/Trello';
import { fetch_get, fetch_post, PROJECTS_LIST, notify, Modal, DropBox, Context, QuillEditor } from '../../common';
import { Link } from 'react-router-dom';

export default class Home extends Component {
  static contextType = Context;
  _isMounted = false;
  constructor(props) {
    super(props);
    // this.refs = {};
    this.state = {
      activeTab: 'team',
      projectDetail: [],
      projectTeam: [],
      projectLabels: [],
      profile: [],
      projectProfiles: [],
      detailsEdit: false,
      projectDeadline: '',
      accessLevels: [],
      editMember: false,
      errors: {},
      employeeProjectId: null,
      teamMember: '',
      accessLevel: '',
      status: '',
      projectDescription: '',
      editLabels: false,
      labelColor: '#ddd',
      priorityColor: '#ddd',
      statusColor: '#ddd',
      showLabelPicker: false,
      loading: { team: true, labels: true},
      currentTab: 'team',
      showMenu: false,
      files: [],
      prioritiesCount: 0,
      statusCount: 0,
      activeAccessLevel: [],
      previousAccessLevel: '',
      actionTitle: true,
      projectName: '',
      billable: false,
      overlay: false
    };
    this.fetchProjectDetails = this.fetchProjectDetails.bind(this);
    this.updatePrioritiesStatusCount = this.updatePrioritiesStatusCount.bind(this);
    this.ContentChange = this.ContentChange.bind(this);
    this.overlayControl = this.overlayControl.bind(this);
    this.labelNameRef = React.createRef();
    this.labelsVisibleRef = React.createRef();

  }

  componentDidMount() {
    this._isMounted = true;
    if(this.props.location.state) {
      this.setState({ currentTab: 'github'})
    }
    this.fetchProjectDetails(this.props.match.params.id);
    this.fetchProjectTeam(this.props.match.params.id);
    this.fetchProjectLables(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.fetchProjectDetails(this.props.match.params.id);
      this.fetchProjectTeam(this.props.match.params.id);
      this.fetchProjectLables(this.props.match.params.id);
    }
  }

  fetchProjectDetails(id) {
    fetch_get(PROJECTS_LIST + id + '/')
    .then((response) => {
      if(this._isMounted) {
        this.setState({ 
          projectDetail: response.project.data,
          prioritiesCount: response.project.data.priority_count,
          statusCount: response.project.data.status_count
        })
      }
    })
  }


  fetchProjectTeam(id) {
    fetch_get(PROJECTS_LIST + id + '/members/')
    .then((response) => {
      if(this._isMounted) {
        var sortedArray = response.project_team.data.sort(function (a, b) {
          return a.user_profile.data.employee_name.toLowerCase().localeCompare(b.user_profile.data.employee_name.toLowerCase());
        });
        this.setState({ 
          // projectTeam: response.project_team.data,
          projectTeam: sortedArray,
          projectProfiles: response.profiles.data,
          accessLevels: response.access_levels,
          loading: {
            ...this.state.loading,
            team: false
          }
        })
      }
    })
  }

  fetchProjectLables(id) {
    fetch_get(PROJECTS_LIST + id + '/labels/')
    .then((response) => {
      if(this._isMounted) {
        this.setState({ 
          projectLabels: response.data,
          loading: {
            ...this.state.loading,
            labels: false
          }
        })
      }
    })
  }

  onEditProject() {
    this.setState({ 
      detailsEdit: true,
      errors: {},
      projectName: this.state.projectDetail.name,
      status: this.state.projectDetail.project_status,
      projectDescription: this.state.projectDetail.description,
      logo: this.state.projectDetail.logo_path,
      billable: this.state.projectDetail.is_billable,
      overlay: true
    })
    if(this.state.projectDetail.dead_line) {
      this.setState({ 
        projectDeadline: moment(this.state.projectDetail.dead_line),
      });
    }
  }

  saveProjectDetails() {
    const { projectDeadline, projectName, status, projectDescription, billable } = this.state;
    const data = new FormData();
    data.append('name', projectName);
    this.state.files.map((file) => data.append('logo', file))
    if(this.state.projectDeadline) {
      data.append('dead_line', projectDeadline ? projectDeadline.format("YYYY-MM-DD") : '');
    }
    data.append('project_status', status);
    data.append('description', projectDescription);
    data.append('is_billable', billable);
    fetch_post(PROJECTS_LIST + this.props.match.params.id + '/update/', data)
    .then((response) => {
      if(response.error) {
        this.setState({ errors: response.errors })
      } else {
        notify(response.message);
        this.context.updateCount('true')
        this.fetchProjectDetails(this.props.match.params.id);
        this.setState({ detailsEdit: false, overlay: false });
      }
    })
  }

  editMember(member) {
    this.setState({
      errors: {},
      editMember: true,
      accessLevel: member.access_level,
      employeeProjectId: member.id,
      previousAccessLevel: member.access_level,
      actionTitle: false,

    })
    fetch_get(PROJECTS_LIST + this.props.match.params.id + '/members/' + member.id + '/update/')
    .then((response) => {
      this.setState({ 
        projectProfiles: response.profiles.data,
        teamMember: member.user_profile.data.id,
        teamMemberName: member.user_profile.data.employee_name,
      })
    })
  }

  deleteSpecific(member){
    let count = 0;
    this.setState({activeAccessLevel: []})
    {this.state.projectTeam.map(p => this.state.activeAccessLevel.push(p.access_level))}
    for(var i=0;i<this.state.activeAccessLevel.length;i++){
      if(this.state.activeAccessLevel[i] === "admin"){
        count++
      }
    }
    {(count > 1) ?
      this.setState({displayRemoveModal: true, memberId: member.id}):
      (count == 1 && member.access_level == "admin") ?
        alert('There must be atleast one admin'):
        this.setState({displayRemoveModal: true, memberId: member.id})
    }
  }

  clearMember() {
    this.setState({
      editMember: false,
      teamMember: 'select user',
      accessLevel: 'select Access level',
      errors: {},
      actionTitle: true,
    })
    this.fetchProjectTeam(this.props.match.params.id);
  }

  addEditMember() {
    const data = new FormData();
    if(this.state.editMember) {
      let editcount = 0;
      this.setState({activeAccessLevel: []})
      {this.state.projectTeam.map(p => this.state.activeAccessLevel.push(p.access_level))}
      for(var i=0;i<this.state.activeAccessLevel.length;i++){
        if(this.state.activeAccessLevel[i] == "admin"){
          editcount++
        }
      }
      if (editcount == 1 && this.state.previousAccessLevel == "admin"){
        alert('There must be atleast one admin')
        this.setState({ actionTitle: true, teamMember: 'select user', accessLevel: 'select access level' })
      } else {
        data.append('profile', this.state.teamMember);
        data.append('access_level', this.state.accessLevel);
        fetch_post(PROJECTS_LIST + this.props.match.params.id + '/members/' + this.state.employeeProjectId + '/update/', data)
        .then((response) => {
          if(response.error) {
            this.setState({ errors: response.errors })
          } else {
            notify("Member Updated successfully");
            this.clearMember();
            this.fetchProjectTeam(this.props.match.params.id);
          }
        })
      }
    } else {
      data.append('profile', this.state.teamMember);
      data.append('access_level', this.state.accessLevel);
      fetch_post(PROJECTS_LIST + this.props.match.params.id + '/members/add/', data)
      .then((response) => {
        if(response.error){
          this.setState({ errors: response.errors })
        } else {
          notify("Member Added successfully");
          this.fetchProjectTeam(this.props.match.params.id)
          this.setState({ teamMember: 'select user', accessLevel: 'select access level', errors: {}})
        }
      })
    }
  }

  removeMember() {
    fetch_get(PROJECTS_LIST + this.props.match.params.id + '/members/' + this.state.memberId + '/remove/', true)
    .then(response => {
      if(!response.error) {
        this.setState({ displayRemoveModal: false })
        this.clearMember()
        notify("Member Removed successfully");
        this.fetchProjectTeam(this.props.match.params.id)
      } else {
        this.setState({ displayRemoveModal: false })
        notify(response.message);
      }
    })
  }

  onEditLabel(label) {
    this.setState({ 
      editLabels: true,
      labelColor: label.color,
      labelId: label.id
    })
    this.labelNameRef.current.value = label.name
    // this.refs['labelId'].value = label.id
    this.labelsVisibleRef.current.checked = label.is_visible_in_board
  }

  addEditLabel() {
    const data = new FormData();
    if(this.state.editLabels) {
      data.append('name', this.labelNameRef.current.value);
      data.append('color', this.state.labelColor);
      data.append('is_visible_in_board', this.labelsVisibleRef.current.checked);
      fetch_post(PROJECTS_LIST + this.props.match.params.id + '/labels/' + this.state.labelId + '/update/', data)
      .then((response) => {
        if(response.error) {
          this.setState({ errors: response.errors })
        } else {
          notify("Label Updated successfully");
          this.setState({ labelId: null })
          this.clear();
          this.fetchProjectLables(this.props.match.params.id);
        }
      })
    } else {
      data.append('name', this.labelNameRef.current.value);
      data.append('color', this.state.labelColor);
      data.append('is_visible_in_board', this.labelsVisibleRef.current.checked);
      fetch_post(PROJECTS_LIST + this.props.match.params.id + '/labels/create/', data)
      .then((response) => {
        if(response.error) {
          this.setState({ errors: response.errors })
        } else {
          notify("Label Added successfully");
          this.clear();
          this.fetchProjectLables(this.props.match.params.id)
        }
      })

    }
  }

  handleChangeComplete(color){
    this.setState({ labelColor: color.hex })
  }

  onDeleteLabel() {
    fetch_get(PROJECTS_LIST + this.props.match.params.id + '/labels/' + this.state.labelId + '/delete/', true)
    .then(response => {
      if(response.status === 204) {
        this.setState({ displayDeleteModal: false, errors: {} })
        notify("Label Deleted successfully");
        this.fetchProjectLables(this.props.match.params.id)
        this.clear();
      }
    })
  }

  clear() {
    this.setState({
      labelColor: '#ddd',
      editLabels: false,
      errors: {}
    })
    this.labelNameRef.current.value = "";
    this.labelsVisibleRef.current.checked = false;
  }

  activeTab(value) {
    return 'tab' + (this.state.currentTab === value ? ' active' : '');
  }

  // onBlur() {
  //   this.setState({ showMenu: false })
  // }

  handleChangeDeadline(projectDeadline) {
    this.setState({ projectDeadline });
  }

  updatePrioritiesStatusCount(type, value) {
    if(type === 'status') {
      this.setState({ statusCount: value })
    } else {
      this.setState({ prioritiesCount: value })
    }
  }

  handleKeyPress = (event) => {
    if(event.key == 'Enter'){
      this.addEditLabel();
    }
  }

  ContentChange(e){
    // var newContent = e.editor.getData();
    this.setState({
      projectDescription: e,
    })
  }

  overlayControl() {
    this.setState({ overlay: false, detailsEdit: false })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { projectDetail, projectLabels, projectTeam, projectProfiles, accessLevels } = this.state;
    let addEditTitle;
    if (this.state.actionTitle){
      addEditTitle = (
        <select 
          className="form-control "
          // ref="status"
          value = {this.state.teamMember}
          onChange={(e) => this.setState({ teamMember: e.target.value })}
        > 
          <option value="">Select member</option>
          {projectProfiles.length > 0 ? projectProfiles.map((employee) => 
            <option key={employee.id} value={employee.id}>{employee.employee_name}</option>
          ) : null}
        </select>
      )
    } else {
      addEditTitle = (
        <input type="text" className="form-control" value={this.state.teamMemberName} />        
      )
    }
    return (
      <div className="secondary_block">
        <div className="overlay" style={{ display: this.state.overlay ? 'block' : 'none'}} onClick={() => this.overlayControl()}></div>
        <div className="row mar_lr_0">
          <div className="col-lg-12">
            <h2 className="heading">Project Settings
              <a  className="float-right detail_edit" onClick={this.onEditProject.bind(this)}><i className="fas fa-edit"></i></a>
            </h2>
            {projectDetail &&
              <div className="detail_project">
                <div className="row">
                  <div className="col-lg-3 profile-main">
                    {projectDetail.logo_path ?
                      <div className="profile-main-pic">
                        <img src={projectDetail.logo_path} alt="projectLogo" /> 
                      </div>
                    :
                      <a className="project_logo">
                        <span className="profile_name_big" data-toggle="tooltip" data-placement="bottom" title={projectDetail.name}>{projectDetail.get_project_text}</span> 
                      </a>
                    }
                    <div className="user-name">
                      <h2 className="heading">{projectDetail.name}</h2>
                      <p>{projectDetail.project_status}</p>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <ul>
                      <li><b>Owner</b> : {projectDetail.created_by_user ? projectDetail.created_by_user.data.employee_name : null}</li>
                      <li><b>Type</b> : {projectDetail.is_billable ? "Billable" : "Non-Billable" }</li>
                      <li><b>Due Date</b> : {projectDetail.dead_line ? projectDetail.dead_line : '--'}</li>
                    </ul>
                    <div className="row">
                      <div className="col-lg-12 discription">
                        <p><b>Description</b> : {projectDetail.description ? projectDetail.description.replace(/<(.|\n)*?>/g, '') : '  --  ' } </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="team_members">
                          {projectTeam && projectTeam.map((member) =>
                            <a key={member.id} className="member">
                              {member.user_profile.data.has_profile_pic ? 
                                <img src={member.user_profile.data.profile_img} data-toggle="tooltip" data-placement="bottom" title={member.user_profile.data.employee_name} alt=""/>
                              :
                                <span className="profile_name" data-toggle="tooltip" data-placement="bottom" title={member.user_profile.data.employee_name}>{member.user_profile.data.profile_text}</span>
                              }
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="row tabs_block project_settings_tab  mar_lr_0 tasks_tab_block">
              <div className="col-lg-12 p-0">
                <div className="tabs">
                  <a className={'tab '+ this.activeTab('team')}  onClick={() => this.setState({ currentTab: 'team' })}>{this.state.projectTeam.length > 0 && <strong>{this.state.projectTeam.length}</strong>}<span>Team</span></a>
                  <a className={'tab br-0 ' + this.activeTab('priorities')}  onClick={() => this.setState({ currentTab: 'priorities' })}>{this.state.prioritiesCount ? <strong>{this.state.prioritiesCount}</strong> : ''}<span>Priorities</span></a>
                  <a className={'tab br-0 ' + this.activeTab('status')}  onClick={() => this.setState({ currentTab: 'status' })}>{this.state.statusCount ? <strong>{this.state.statusCount}</strong> : ''}<span>Status</span></a>
                  <a className={'tab br-0 ' + this.activeTab('label')}  onClick={() => this.setState({ currentTab: 'label' })}>{projectLabels ? <strong>{projectLabels.length}</strong> : ''}<span>Labels</span></a>
                  <span className="dropdown float-right integrations_dropdown">
                    <a className={'tab closed ' + this.activeTab('integrations')} onClick={() => this.setState({ currentTab: 'integrations' })}>
                      <strong>4</strong><span>Integrations</span>
                       </a>
                    <ul className="menu" style={{ display: this.state.currentTab === 'integrations' ? 'block' : 'none' }}>
                      <li className="submenu"><a onClick={() => this.setState({ currentTab: 'sentry', showMenu: true })}><img alt="" src="https://sentry-brand.storage.googleapis.com/sentry-glyph-black.svg" style={{ height: '25px', width: '25px' }} /> Sentry </a></li>
                      <li className="submenu change"><a onClick={() => this.setState({ currentTab: 'gitlab', showMenu: true })}> <i className="fab fa-gitlab"></i> Git Lab</a></li>
                      <li className="submenu change bt-0"><a onClick={() => this.setState({ currentTab: 'github', showMenu: true })}> <i className="fab fa-github"></i> Git Hub</a></li>
                      <li className="submenu"><a onClick={() => this.setState({ currentTab: 'trello', showMenu: true })}><i className="fab fa-trello"></i>Trello</a></li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-12 tab_contents1">
              {this.state.currentTab === 'team' &&
                <div className="row team_content tab-contents team-content active">
                  <div className="col-lg-8 pl-0">
                    <table className="table table-hover ">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Access</th>
                          {projectTeam.map(m => m.access_level == "admin" && (this.context.employee_name === m.user_profile.data.employee_name) &&
                            <th key={m.id} className="text-center">Actions</th>                               
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {projectTeam.length > 0 ? projectTeam.map((member, index) => 
                          <tr key={member.id}>
                            <td>{index + 1}</td>
                            <td>
                              <div className="task_title">
                                <Link to={{ pathname: `${this.context.company}/pm/${this.props.match.params.id}/tasks`, state: {teamMemberDetails: member.user_profile.data, teamMemberIndex: index, teamDetails: projectTeam }}}>
                                  {member.user_profile.data.employee_name}
                                </Link>
                              </div>
                            </td>
                            <td>{member.access_level}</td>
                            { projectTeam.map(m => (m.access_level == "admin" && this.context.employee_name === m.user_profile.data.employee_name) &&
                              <td className="actions" align="center" key={m.id}>
                                <a className="action_edit" onClick={() => {this.editMember(member)}}><i className="fa fa-edit"></i></a>
                                {this.context.id !== member.user_profile.data.id ?
                                  <a className="action_delete" onClick={() => {this.deleteSpecific(member)}}>
                                    <i className="fa fa-trash"></i> 
                                  </a>
                                :
                                  <a className="action_delete"></a>
                                }
                              </td>
                            )}
                          </tr>)
                        : 
                          <tr>
                            <td>{this.state.loading.team && 'Loading ...'}</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-4">
                    {this.state.actionTitle ? <h2 className="heading">Add Member</h2> : <h2 className="heading">Edit Member</h2> }
                    <form>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Select User <div className="error-text">*</div> </label>
                            {addEditTitle}
                            <div className="error-text">
                              {this.state.errors.profile ? this.state.errors.profile : null }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Access Level <div className="error-text">*</div> </label>
                            <select 
                              className="form-control " 
                              id="exampleFormControlSelect1"
                              value = {this.state.accessLevel}
                              onChange={(e) => this.setState({ accessLevel: e.target.value })}
                            >
                              <option value="">select access level</option>
                              {accessLevels.length > 0 ? accessLevels.map((accessLevel) => 
                                <option key={accessLevel[0]} value={accessLevel[0]}>{accessLevel[1]}</option>
                              ) : null}
                            </select>
                            <div className="error-text">
                              {this.state.errors.access_level ? this.state.errors.access_level : null }
                            </div>
                          </div>
                        </div>
                        {this.state.errors.message ? <div className="error-text">{this.state.errors.message}</div> : null }
                        <div className="col-lg-12 buttons text-left mt-5">
                          <button type="button" className="primary_btn" onClick={() => {this.addEditMember()}}>Save</button>
                          <button type="button" className="secondary_btn" onClick={() => {this.clearMember()}}>Cancel</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              }
              {this.state.detailsEdit &&
                <div className="slide_section ">
                  <div className="add_task slide_content">
                    <div className="row justify-content-start slide-section slide_content_row no-gutters">
                      <div className="col-lg-12">
                        <h2 className="heading">Edit Project</h2>
                         <form>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>Project Name<span><sup>*</sup></span></label>
                                <input
                                  type="text" 
                                  className="form-control" 
                                  placeholder="Enter Project Name" 
                                  value={this.state.projectName}
                                  onChange={(e) => this.setState({ projectName: e.target.value })}
                                />
                                <div className="error-text">
                                  {this.state.errors.name ? this.state.errors.name : null }
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>Status<span><sup>*</sup></span></label>
                                <select 
                                  className="form-control "
                                  value={this.state.status}
                                  onChange={(e) => this.setState({ status: e.target.value })}
                                > 
                                  <option value="on-going">On Going</option>
                                  <option value="completed">Completed</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>Owner</label>
                                <input 
                                  type="text" 
                                  className="form-control"
                                  value={projectDetail.created_by_user ? projectDetail.created_by_user.data.employee_name : ''}
                                  onChange={() => {}}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>Type</label>
                                <select 
                                  className="form-control "
                                  value={this.state.billable}
                                  onChange={(e) => this.setState({ billable: e.target.value })}
                                > 
                                  <option value="true">Billable</option>
                                  <option value="false">Non-Billable</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>Dead Line</label>
                                <Datetime 
                                  dateFormat="YYYY-MM-DD" 
                                  timeFormat=""
                                  inputProps={{ placeholder: 'Select Dead Line'}} 
                                  value={this.state.projectDeadline} 
                                  onChange={this.handleChangeDeadline.bind(this)}
                                  closeOnSelect                              
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>Logo</label>
                                {this.state.logo && this.state.files.length === 0 &&
                                  <img 
                                    className="uploaded-logo" 
                                    id="blah"
                                    src={this.state.file ? this.state.logo : this.state.logo} 
                                    alt="projectLogo"
                                    height={100}
                                  />
                                }
                                <DropBox
                                  files={this.state.files}
                                  accept="image/*"
                                  isImage
                                  imageStyle={{ height:100}}
                                  onDrop={(files) => this.setState({ files })}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>Description</label>
                                <QuillEditor 
                                  value={this.state.projectDescription}
                                  onChange={this.ContentChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 buttons text-left mt-5">
                              <button type="button" className="primary_btn" onClick={() => {this.saveProjectDetails()}}>Save</button>
                              <button type="button" className="secondary_btn" onClick={() => {this.setState({ detailsEdit: false, overlay: false }) }}>Cancel</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {this.state.currentTab === 'priorities' &&
                <ProjectPriorities projectId={this.props.match.params.id} updateCount={this.updatePrioritiesStatusCount}/>
              }
              {this.state.currentTab === 'status' &&
                <ProjectStatus projectId={this.props.match.params.id} updateCount={this.updatePrioritiesStatusCount}/>
              }
              {this.state.currentTab === 'label' &&
                <div className="row priority_content tab-content">
                  <div className="col-lg-8 pl-0">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Tasks</th>
                          <th>Color</th>
                          <th>Is Visible</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectLabels.length > 0 ? projectLabels.map((label,index) => 
                          <tr key={label.id}>
                            <td>
                              <Link to={{ pathname: `${this.context.company}/pm/${this.props.match.params.id}/tasks`, state: {teamLabels: label, teamLabelsIndex: index }}}>
                                {label.name}
                              </Link>
                            </td>
                            <td className="tasks"><span className="open" title="open">{label.opened_tasks_count}</span> / <span className="closed" title="closed">{label.closed_tasks_count}</span></td>
                            <td><div className="color_pick" style={{ color: label.color }}><i className="fas fa-square"></i></div></td>
                            <td>{label.is_visible_in_board ? 'True' : 'False' }</td>
                            <td className="actions" align="center">
                              <a className="action_edit" onClick={() => {this.onEditLabel(label)}}><i className="fa fa-edit"></i> </a>
                              <a className="action_delete" onClick={() => this.setState({ displayDeleteModal: true, labelId: label.id})}><i className="fa fa-trash"></i> </a>
                            </td>
                          </tr>
                          ) 
                        :
                          <tr>
                            <td>{this.state.loading.labels && 'Loading ...'}</td>
                          </tr>  
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-4">
                    <h2 className="heading">{this.state.editLabels ? 'Edit Label' : 'Add Label' }</h2>
                     <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Name<sup className="error-text" style={{top: '0px' }}>*</sup></label>
                            <input 
                              type="text" 
                              className="form-control" 
                              ref={this.labelNameRef}
                              onKeyPress={this.handleKeyPress}   
                            />
                            <div className="error-text">
                              {this.state.errors.name ? this.state.errors.name : null }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Color</label>
                            <div className="color_pick">
                              <span
                                onClick={() => this.setState({ showLabelPicker: !this.state.showLabelPicker })}
                                style={{ color: this.state.labelColor }} 
                                onBlur={() => this.setState({ showLabelPicker: false })} 
                              >
                                <i className="fas fa-square"></i>
                              </span>
                            </div>
                          </div>
                          {this.state.showLabelPicker &&
                            <div style={{position: 'absolute', zIndex: '9999999999'}}>
                              <div
                                style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                                onClick={() => this.setState({ showLabelPicker: false })}
                              />
                              <SketchPicker
                                color={ this.state.labelColor }
                                onChangeComplete={ this.handleChangeComplete.bind(this) }
                              />
                            </div>
                          }
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group check_box">
                            <label style={{display:'block'}}>Is Visible On board</label>
                            <input type="checkbox" ref={this.labelsVisibleRef}/>
                          </div>
                        </div>
                        <div className="col-lg-12 buttons text-left mt-5">
                          <button type="button" onClick={() => {this.addEditLabel()}} className="primary_btn">Save</button>
                          <button type="button" onClick={() => {this.clear()}} className="secondary_btn">Cancel</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              }
              {this.state.currentTab === 'sentry' &&
                <Sentry projectId={this.props.match.params.id}/>
              }
              {this.state.currentTab === 'gitlab' &&
                <GitLab projectId={this.props.match.params.id}/>
              }
              {this.state.currentTab === 'github' &&
                <GitHub 
                  projectId={this.props.match.params.id} 
                  githubCode={this.props.location.state ? this.props.location.state.code : null}
                  loginStatus={this.props.location.state ? this.props.location.state.loginStatus : null}
                />
              }
              {this.state.currentTab === 'trello' &&
                <Trello projectId={this.props.match.params.id}/>
              }
            </div>
          </div>
        </div>
        <Modal
          display={this.state.displayDeleteModal}
          bodyText='Are you sure? You want to delete?'
          modalAction={this.onDeleteLabel.bind(this)}
          removeModal={() => this.setState({ displayDeleteModal: false })}
        />
        <Modal
          display={this.state.displayRemoveModal}
          bodyText='Are you sure? You want to delete?'
          modalAction={this.removeMember.bind(this)}
          removeModal={() => this.setState({ displayRemoveModal: false })}
        />
      </div>
    )
  }
}
