import React, { Component } from 'react';
import { Context } from '../../common'

export default class TestExecutionDetails extends Component {
  static contextType = Context;
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      loading: true      
    }
  }
  render() {
    return (
      <div className="secondary_block pb-0">
        <div className="row mar_lr_0 table_height ">
          <div className="col-lg-12 ">
            <h2 className="heading mt-3">Test Details - #PT123 Test Title Comes Here <a className="float-right new_btn" href="/pm/test_executions/"><i className="fas fa-plus"></i> New Test Executions</a><a href="/pm/past_executions/" className="float-right primary_btn no_brdr_btn mr-2"><i className="fas fa-history"></i> Past Test Executions</a> <a className="float-right border_btn mr-2" href="/pm/test_cases/" ><i className="fas fa-tasks"></i> Test Cases</a></h2>
          </div>
        </div>

        <div className="row mar_lr_0 mt-2">
          <div className="col-lg-8 pl-3">
            <div className="test_cases_container">
              <h5 className="secondary_heading mb-3 d-block">Last Execution Details</h5>
              {/* Test types */}
              <div className="test_types">
                <div className="card">
                  <div className="card-body">
                    <a href="/pm/test_execution_details/" className="title">#PT123</a><span className="tester ml-4">Tested By <img src="https://lh3.googleusercontent.com/a-/AOh14GgzRugjANDbpQJ3pYcxAbRX2ySG0o-isUouX6epDg=s40" /> William Smith</span>
                    <div className="float-right d-inline-block status_date">
                      <span className="status">On Going</span>
                      <span className="date">On: 06 Sep, 2021 20:20 PM</span>
                    </div>
                    <div className="options mt-2 ">
                      <span className="option">Total Test Cases: <b>126</b></span>
                      <span className="badge pass">Passed <small>50</small></span>
                      <span className="badge fail">Failed <small>30</small></span>
                      <span className="badge na">N/A <small>10</small></span>
                      <span className="badge hold">On Hold <small>20</small></span>
                      <span className="badge block">Block <small>16</small></span>
                    </div>
                  </div>
                </div>
              </div> {/** end of test types */}

              <h5 className="secondary_heading mb-3 mt-4 d-block">Test cases</h5>

              <div id="accordionExample" className="accordion">
                <div className="card">
                    <div id="headingOne" className="card-header border-0">
                        <h2 className="mb-0">
                            <button type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" className="btn btn-link collapsible-link">Authentication (4)</button>
                        </h2>
                    </div>
                    <div id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample" className="collapse show">
                        <div className="card-body">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" width="100px">#ID</th>
                                <th scope="col">Test Title</th>
                                <th scope="col">Expected Result</th>
                                <th scope="col" width="80px">Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row"><a href="#">#PT12</a></th>
                                <td>New User Sign Up</td>
                                <td>All Fields Blank Accepted</td>
                                <td className="status" data-toggle="modal" data-target="#myModal3"><i className="fas fa-info-circle pass"></i></td>
                              </tr>
                              <tr>
                                 <th scope="row"><a href="#">#PT13</a></th>
                                <td>Login</td>
                                <td>Login Successful</td>
                                <td className="status"><i className="fas fa-info-circle fail"></i></td>
                              </tr>
                               <tr>
                                 <th scope="row"><a href="#">#PT14</a></th>
                                <td>Forgot Password</td>
                                <td>Got to forgot page</td>
                                <td className="status"><i className="fas fa-info-circle blocked"></i></td>
                              </tr>
                              <tr>
                                 <th scope="row"><a href="#">#PT15</a></th>
                                <td>Forgot Email</td>
                                <td>Send Activation link</td>
                                <td className="status"><i className="fas fa-info-circle hold"></i></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div id="headingTwo" className="card-header  border-0">
                        <h2 className="mb-0">
                            <button type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" className="btn btn-link collapsed  collapsible-link">Login (2)</button>
                        </h2>
                    </div>
                    <div id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample" className="collapse">
                        <div className="card-body">
                            <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" width="100px">#ID</th>
                                <th scope="col">Test Title</th>
                                <th scope="col">Expected Result</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row"><a href="#">#PT12</a></th>
                                <td>New User Sign Up</td>
                                <td>All Fields Blank Accepted</td>
                              </tr>
                              <tr>
                                 <th scope="row"><a href="#">#PT13</a></th>
                                <td>Login</td>
                                <td>Login Successful</td>
                              </tr>
                               <tr>
                                 <th scope="row"><a href="#">#PT14</a></th>
                                <td>Forgot Password</td>
                                <td>Got to forgot page</td>
                              </tr>
                              <tr>
                                 <th scope="row"><a href="#">#PT15</a></th>
                                <td>Forgot Email</td>
                                <td>Send Activation link</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div id="headingThree" className="card-header border-0">
                        <h2 className="mb-0">
                            <button type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" className="btn btn-link collapsed collapsible-link">Help Menu (4)</button>
                        </h2>
                    </div>
                    <div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample" className="collapse">
                        <div className="card-body">
                            <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" width="100px">#ID</th>
                                <th scope="col">Test Title</th>
                                <th scope="col">Expected Result</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row"><a href="#">#PT12</a></th>
                                <td>New User Sign Up</td>
                                <td>All Fields Blank Accepted</td>
                              </tr>
                              <tr>
                                 <th scope="row"><a href="#">#PT13</a></th>
                                <td>Login</td>
                                <td>Login Successful</td>
                              </tr>
                               <tr>
                                 <th scope="row"><a href="#">#PT14</a></th>
                                <td>Forgot Password</td>
                                <td>Got to forgot page</td>
                              </tr>
                              <tr>
                                 <th scope="row"><a href="#">#PT15</a></th>
                                <td>Forgot Email</td>
                                <td>Send Activation link</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                    </div>
                </div>
                <div className="modal right side_pop_modal fade" id="myModal3" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                  <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i className="fas fa-times"></i> Close</span></button>
          <h4 className="modal-title" id="myModalLabel2">Test Execution Details</h4>
        </div>
        <div className="modal-body">
          <div className="details_block">
            <div className="item">
              <p className="title">Test Case Title :</p>
              <p>New User Sign Up</p>
            </div>
            <div className="item">
              <p className="title">Steps :</p>
              <ul className="mt-2">
                <li>Militancy unstunned</li>
                <li>Dysetednological Stea</li>
                <li>Lorem Ipsum has been the industrys standard</li>
                <li>Sometimes on purpose (injected humour and the like)</li>
                <li>Where can I get some</li>
              </ul>
            </div>
            <div className="item">
              <p className="title">Expected Result :</p>
              <p>Valid Credentials Accepted</p>
            </div>

            <div className="test_results_block">
              <h2 className="heading">Test Results <span className="float-right pass badge">Passed</span></h2>
              <div className="item">
                <p className="title">Tested On :</p>
                <p>06 Sep, 2021 20:10 PM</p>
              </div>
              <div className="item">
                <p className="title">Actual Result :</p>
                <p>Valid Credentials</p>
              </div>
              <div className="item">
                <p className="title">Notes :</p>
                <p>Software development company that provides a range of end-to-end software product.</p>
              </div>
              <div className="item">
                <p className="title mb-1">Screenshots :</p>
                <span className="screenshot">
                  <img src="https://s3-alpha.figma.com/hub/file/409772554/c9cc119b-01d8-416a-8ec6-3409711df3b2-cover" />
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
                </div>
              </div> {/** end of accordian */}

            </div>
          </div>
        </div>
      </div>
    )
  }
}