import React, { Component } from 'react';
import moment from 'moment';
import { fetch_get, fetch_post, PROJECT_ACTIVITY, notify, Context, Modal } from '../../common';

export default class WorklogComment extends Component {
  static contextType = Context
  constructor(props) {
    super(props);
    this.state = {
      activity: this.props.location.state.activity,
      commentDescription: '',
      errors: {},
      activityComments: []
    };
    this.activityDate = this.activityDate.bind(this);
    this.userName = this.userName.bind(this);
    this.activeTime = this.activeTime.bind(this);
  }

  componentDidMount() {
    this.fetchComments();
  }

  fetchComments() {
    fetch_get(PROJECT_ACTIVITY + this.state.activity.id + '/')
    .then((response) => {
      this.setState({ activityComments: response.activity_comments.data });
    });
  }

  activeTime(seconds) {
    seconds = moment.duration(parseInt(seconds),'seconds')
    var format =  Math.floor(seconds.asHours()) > 0 ? Math.floor(seconds.asHours()) + 'h ' : '';
    format += seconds.minutes() + 'm';
    return format
  }

  activityDate() {
    var date = moment(this.state.activity.c_start_time)
    return date.format("DD MMM YYYY")
  }

  deleteActivity() {
    fetch_get(PROJECT_ACTIVITY + this.state.activity.id + '/delete/', true)
    .then((response) => {
      if(response.status === 204) {
        this.props.history.goBack();
      }
    });
  }

  commentActivity() {
    const data = new FormData();
    data.append('comment', this.state.commentDescription);
    data.append('activity', this.state.activity.id);
    data.append('commented_by', this.context.id);
    fetch_post(PROJECT_ACTIVITY + this.state.activity.id + '/', data)
    .then((response) => {
      if(response.error) {
        this.setState({ errors: response.errors });
      } else {
        this.setState({
          commentDescription: '',
          errors: {}
        }, () => { this.fetchComments() })
      }
    });
  }

  deleteComment() {
    const data = new FormData();
    data.append('comment_id', this.state.commentId);
    fetch_post(PROJECT_ACTIVITY + 'comment/remove/', data)
    .then((response) => {
      if(response.error) {
        this.setState({ errors: response.errors });
      } else {
        this.setState({ displayDeleteModal: false });
        notify(response.response)
        this.fetchComments()
      }
    });
  }

  userName(name, full=false) {
    let nameFormat = (name.split(' ')[0]).charAt(0)
    let fullName = name.split(' ')[0];
    return full ? fullName : nameFormat;
  }

  startEndTime(startTime, endTime, inactiveTime) {
    return " Start Time :" + startTime + " \nEnd time :" + endTime + " \nIn Active time :" + this.activeTime(inactiveTime)
  }

  render() {
    const { activity } = this.state;
    return (  
        <div className="secondary_block">
      <div className="row mar_lr_0">
        <div className="col-lg-12">
          <h2 className="heading">Activity Details <span onClick={() => this.props.history.goBack()} className="primary_btn float-right">Activity List</span></h2>
        </div>
        <div className="col-lg-12 text-center"> <h4 className="sml_heading">{this.activityDate()}</h4><br /></div>
        <div className="col-lg-12">
          <div className="row mar_lr_0">
            <div className="col-lg-12 p-0">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ width:"15%" }}>User</th>
                    <th style={{ width:"20%" }}>Task</th>
                    <th style={{ width:"10%" }}>Active Time</th>
                    <th style={{ width:"45%" }}>Description</th>
                    <th style={{ width:"7%" }}>Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="list_bg_black">
                    <td>
                      <span className="profile_name">
                       {this.userName(activity.profile_details.data.employee_name)}
                      </span>{this.userName(activity.profile_details.data.employee_name, 'full')}
                    </td>
                    <td>{activity.task_details.data.name}</td>
                    <td>
                      <span
                        data-toggle="tooltip" 
                        data-placement="bottom"  
                        data-html="true" 
                        title= {this.startEndTime(activity.formatted_start_time, activity.formatted_end_time, activity.inactive_time)}
                      >
                        <i className="fas fa-clock"></i> {this.activeTime(activity.active_time)}
                      </span>
                    </td>
                    <td>{activity.description}</td>
                    <td>{activity.is_manual_log ? 'Manual' : 'Client'}</td>
                  </tr>
                </tbody>
              </table> 
            </div>
          </div>
        </div>
        <div className="col-lg-12 form-group">
          <div className="comment_box">
            <h4 className="sml_heading">Comment</h4>
            <textarea 
              className="form-control" 
              rows="4"
              value={this.state.commentDescription}
              onChange={(e) => {this.setState({ commentDescription: e.target.value, error: {} })}}
            >
            </textarea>
            <div className="error-text">
              {this.state.errors.comment ? this.state.errors.comment : null}
            </div>
            <button className="float-right primary_btn" type="button" onClick={() => this.commentActivity() }>Submit</button>
            <br clear="all" />
          </div>
        </div>
        {this.state.activityComments.length > 0 && this.state.activityComments.map((comment) =>
          <div className="col-lg-12" key={comment.id}>
            <div className="activity_comments">
              <div className="item">
                <div className="title"><span>{comment.comment}<span className="float-right" onClick={() => this.setState({displayDeleteModal: true, commentId: comment.id})}><i className="fas fa-trash"></i></span></span></div>
                <div className="detail">Commented By <b>{comment.commented_by_user.data.email}</b> on {comment.formatted_commented_on}</div>
              </div>
            </div>
          </div>
        )}
        <Modal
          display={this.state.displayDeleteModal}
          bodyText='Are you sure? You want to delete?'
          modalAction={this.deleteComment.bind(this)}
          removeModal={() => this.setState({displayDeleteModal: false})}
        />
      </div>
      </div>
    )
  }
}
