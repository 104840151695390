import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { APP_NAME, Context } from '../common'

export default class LeftNav extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      selectedChild: window.location.pathname.split('/')[3],
    };
    this.isActiveChild = this.isActiveChild.bind(this);
  }

  componentDidMount() {
    this.setState({ selectedChild: this.state.selectedChild === 'kanban-leads' ? 'leads' : this.state.selectedChild }, this.collapseActions)
    if (!localStorage.getItem('token')) {
      this.props.history.push(APP_NAME);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (prevState.selectedChild !== window.location.pathname.split('/')[3]) ? {
      selectedChild: window.location.pathname.split('/')[3] === 'kanban-leads' ? 'leads' : window.location.pathname.split('/')[3]
    } : null
  }

  collapseActions() {
    let MenuBlock = document.getElementsByClassName("menu_block");
    let  Sidebar = document.getElementsByClassName("sidebar");
    let Stage = document.getElementsByClassName("stage");
    let SideMenu = document.getElementsByClassName("side_menu");
    if(SideMenu[0].style.width !== "25%") {
      MenuBlock[0].style.display = "inline-block";
      Sidebar[0].style.width = "15%";
      Stage[0].style.marginLeft = "15%";
      SideMenu[0].style.width = "25%";
    }
  }

  componentDidUpdate() {
    let SideMenu = document.getElementsByClassName("side_menu");
    if(SideMenu[0].style.width !== "25%") {
      this.collapseActions()
    }
  }

  isActiveChild(child) {
    return (child === this.state.selectedChild) ? "active" : "";
  }

  render() {
    return (
      <div>
        {
          <ul>
            <li onClick={()=> this.setState({ selectedChild: 'dashboard' })}>
              <Link className={this.isActiveChild('dashboard')} to={APP_NAME + this.context.company + '/sales/dashboard'}>
                <span className="fa_icon">
                  <i className="fa fa-tachometer-alt fa-w-20" title="dashboard" /></span>
                <span className="menu_text">Dashboard</span>
              </Link>
            </li>
            <li onClick={()=> this.setState({ selectedChild: 'teams' })}>
              <Link className={this.isActiveChild('teams')} to={APP_NAME + this.context.company + '/sales/teams'}>
                <span className="fa_icon">
                  <i className="fa fa-users fa-w-20" title="Teams" /></span>
                <span className="menu_text">Teams</span>
              </Link>
            </li>
            <li onClick={()=> this.setState({ selectedChild: 'companies' })}>
              <Link className={this.isActiveChild('companies')} to={APP_NAME + this.context.company + '/sales/companies'}>
                <span className="fa_icon">
                  <i className="fa fa-building fa-w-14" title="Companies" />
                </span>
                <span className="menu_text">Companies</span>
              </Link>
            </li>
            <li onClick={()=> this.setState({ selectedChild: 'leads' })}>
              <Link className={this.isActiveChild('leads' )} to={APP_NAME + this.context.company + '/sales/leads'}>
                <span className="fa_icon">
                  <i className="fa fa-flag fa-w-16" title="Leads" /></span>
                <span className="menu_text">Leads</span>
              </Link>
            </li>
            <li onClick={()=> this.setState({ selectedChild: 'contacts' })}>
              <Link className={this.isActiveChild('contacts')} to={APP_NAME + this.context.company + '/sales/contacts'}>
                <span className="fa_icon">
                  <i className="fa fa-address-book fa-w-14" title="Contacts" /></span>
                <span className="menu_text">Contacts</span>
              </Link>
            </li>
            <li onClick={()=> this.setState({ selectedChild: 'accounts' })}>
              <Link className={this.isActiveChild('accounts')} to={APP_NAME + this.context.company + '/sales/accounts'}>
                <span className="fa_icon">
                  <i className="fa fa-id-card fa-w-16" title="Accounts" /></span>
                <span className="menu_text">Accounts</span>
              </Link>
            </li>
            <li onClick={()=> this.setState({ selectedChild: 'opportunities' })}>
              <Link className={this.isActiveChild('opportunities')} to={APP_NAME + this.context.company + '/sales/opportunities'}>
                <span className="fa_icon">
                  <i className="fa fa-id-card fa-w-16" title="Opportunities" /></span>
                <span className="menu_text">Opportunities</span>
              </Link>
            </li>            
            <li onClick={()=> this.setState({ selectedChild: 'events' })}>
              <Link className={this.isActiveChild('events')} to={APP_NAME + this.context.company + '/sales/events'}>
                <span className="fa_icon">
                  <i className="fa fa-calendar-alt fa-w-14" title="Events" /></span>
                <span className="menu_text">Events</span>
              </Link>
            </li>
            <li onClick={()=> this.setState({ selectedChild: 'documents' })}>
              <Link className={this.isActiveChild('documents')} to={APP_NAME + this.context.company + '/sales/documents'}>
                <span className="fa_icon">
                  <i className="fa fa-file-alt fa-w-12" title="Documents" /></span>
                <span className="menu_text">Documents</span>
              </Link>
            </li>
            <li onClick={()=> this.setState({ selectedChild: 'email-templates' })}>
              <Link className={this.isActiveChild('email-templates')} to={APP_NAME + this.context.company + '/sales/email-templates'}>
                <span className="fa_icon">
                  <i className="fa fa-file-alt fa-w-12" title="Email Templates" /></span>
                <span className="menu_text">Email Templates</span>
              </Link>
            </li>
            {
              this.context.role &&
              <li onClick={()=> this.setState({ selectedChild: 'settings' })}>
                <Link className={this.isActiveChild('settings')} to={APP_NAME + this.context.company + '/sales/settings'}>
                  <span className="fa_icon">
                    <i className="fa fa-cogs fa-w-12" title="Settings" /></span>
                  <span className="menu_text">Settings</span>
                </Link>
              </li>
            }
          </ul>
        }  
      </div>
    );
  }
}
