import React, { Component, Fragment } from 'react';
import AddTasks from './AddTasks';
import TaskDetailsPage from './TaskDetailsPage';
import TasksKanban from './TasksKanban';
import Task from './Task';
import { fetch_post, fetch_get, PROJECTS_LIST, Spinner, Context } from '../../common';

let assignedMembersList = [];
let labelsList= [];
let statusesList = [];
let prioritiesList = [];
let tasksData = {};
let tasksResponse = [];
let milestones = [];
let TasksNextPage = { all: false, open: false, closed: false };
let filterCount = 0
export default class TasksList extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      userSearch: '',
      membersMenu: false,
      labelsMenu: false,
      usersMenu: false,
      overlay: false,
      tasksList: [],
      addTask: false,
      addTaskFromDashboard: '',
      taskDetailView: false,
      filterId: '',
      filterName: '',
      filterStatus: [],
      filterPriority: [],
      filterlabels: [],
      filterAssignedTo: [],
      filterIntegrationType: null,
      filter: false,
      filterMenu: false,
      taskDetailId: null,
      taskName: '',
      noResults: '',
      taskStatus: 'open',
      closedTasks: [],
      opendTasks: [],
      loading: false,
      milestoneDetails: { name: 'All', id: null },
      projectMilestones: [],
      nextPageNumber: { all: null, open: null, closed: null },
      tasksCount: { all: 0, open: 0, closed: 0 },
      filterMilestone: { name: '', id: null },
      filterData: '',
      noAccess: null,
      lazyLoading: false,
      updateTaskId: null,
      updateTaskDetails: false,
      milestoneMenu: false,
      isProjectFound: true,
      labelsIds: [],
      labels: [],
      members: [],
      memberIds: [],
      statusIds: [],
      priorityIds: [],
      users: [],
      labelSearch: '',
      assignedMembersSearch: '',
      integrationMenu: false,
      priorityMenu: false,
      statusMenu: false,
      noTasks: false,
      kanbanView: false,
      kanbanStatusId: null,
      boardIds: [],
    };
    this.fetchTasks = this.fetchTasks.bind(this);
    this.taskCreate = this.taskCreate.bind(this);
    this.showTaskDetail = this.showTaskDetail.bind(this);
    this.closeTask = this.closeTask.bind(this);
    this.filterInitialData = this.filterInitialData.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.reopenTask = this.reopenTask.bind(this);
    this.labelUpdate = this.labelUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.updateTaskDetail = this.updateTaskDetail.bind(this);
    this.overlayControl = this.overlayControl.bind(this);
    this.handleMilestone = this.handleMilestone.bind(this);
    this.dropdownMenu = this.dropdownMenu.bind(this);
    this.updateKanbanBoards = this.updateKanbanBoards.bind(this);
  }

  componentDidMount() {
    if(this.props.match.path.split("/")[4] === 'tasks-kanban') {
      this.setState({ kanbanView: true })
    } else {
      this.initialState = this.state;
      document.getElementById('menu_left').addEventListener("scroll", this.handleScroll);
      window.addEventListener("scroll", this.handleScroll);
      this.setState({ loading: true })
      if(this.props.match.params.tid) {
        this.setState({ overlay: true })
        this.showTaskDetail(this.props.match.params.tid)
      }
      if(this.props.location.state && this.props.location.state.addTaskFromDashboard){
        this.setState({ addTaskFromDashboard: this.props.location.state.addTaskFromDashboard, overlay: true })
      }
      if(this.props.location.state && this.props.location.state.milestoneName) {
        this.setState({ 
          milestoneDetails: { name: this.props.location.state.milestoneName, id: this.props.location.state.milestoneId }, 
          filterMilestone: { name: this.props.location.state.milestoneName, id: this.props.location.state.milestoneId } 
          }, 
          () =>  {this.taskFilter('filter'); this.filterInitialData(); }
        )
        if(this.props.location.state.addTask) {
          this.setState({ addTask: true, overlay: true })
        }
      }else if(this.props.location.state && this.props.location.state.teamMemberDetails){
        let teamdetails = this.props.location.state.teamMemberDetails;
        if(this.state.memberIds.includes(teamdetails.id)) {
          this.state.memberIds.splice(this.state.memberIds.indexOf(teamdetails.id), 1 );
          this.state.filterAssignedTo.map((memberDetails, index) => {
            if(memberDetails.id === teamdetails.id) {
              this.state.filterAssignedTo.splice(index, 1)
            }
          })
          this.setState({memberIds: this.state.memberIds, filterAssignedTo: this.state.filterAssignedTo,filter: filterCount === 2 ? false : true},this.taskFilter)
        } else {
          this.setState({ filterAssignedTo: [...this.state.filterAssignedTo, teamdetails], filter: true, memberIds: [...this.state.memberIds, teamdetails.id] }, this.taskFilter)
        }
        this.filterInitialData();
      }else if(this.props.location.state && this.props.location.state.projectStatusDetails){
        let statusdetails = this.props.location.state.projectStatusDetails;
        if(this.state.statusIds.includes(statusdetails.id)) {
          this.state.statusIds.splice(this.state.statusIds.indexOf(statusdetails.id), 1 );
          this.state.filterStatus.map((statusDetails, index) => {
            if(statusDetails.id === statusdetails.id) {
              this.state.filterStatus.splice(index, 1)
            }
          })
          this.setState({ statusIds: this.state.statusIds, filterStatus: this.state.filterStatus, filter: filterCount === 2 ? false : true}, this.taskFilter)
        } else {
          this.setState({ filterStatus: [...this.state.filterStatus, statusdetails], filter: true, statusIds: [...this.state.statusIds, statusdetails.id] }, this.taskFilter)
        }
        this.filterInitialData();
      }else if(this.props.location.state && this.props.location.state.teamLabels){
        let labeldetails = this.props.location.state.teamLabels;
        // let lindex = this.props.location.state.teamLabelsIndex;
        if(this.state.labelsIds.includes(labeldetails.id)) {
          this.state.labelsIds.splice(this.state.labelsIds.indexOf(labeldetails.id), 1 );
          this.state.filterlabels.map((labelDetails, index) => {
            if(labelDetails.id === labeldetails.id) {
              this.state.filterlabels.splice(index, 1)
            }
          })
          this.setState({ labelsIds: this.state.labelsIds, filterlabels: this.state.filterlabels, filter: filterCount === 2 ? false : true}, this.taskFilter)
        } else {
          this.setState({ filterlabels: [...this.state.filterlabels, labeldetails], filter: true, labelsIds: [...this.state.labelsIds, labeldetails.id] }, this.taskFilter)
        }
        this.filterInitialData();
      }else if(this.props.location.state && this.props.location.state.priorityDetails){
        let prioritydetails = this.props.location.state.priorityDetails;
        if(this.state.priorityIds.includes(prioritydetails.id)) {
          this.state.priorityIds.splice(this.state.priorityIds.indexOf(prioritydetails.id), 1 );
          this.state.filterPriority.map((priorityDetails, index) => {
            if(priorityDetails.id === prioritydetails.id) {
              this.state.filterPriority.splice(index, 1)
            }
          })
          this.setState({ priorityIds: this.state.priorityIds, filterPriority: this.state.filterPriority, filter: filterCount === 2 ? false : true}, this.taskFilter)
        } else {
          this.setState({ filterPriority: [...this.state.filterPriority, prioritydetails], filter: true, priorityIds: [...this.state.priorityIds, prioritydetails.id] }, this.taskFilter)
        }
        this.filterInitialData();
      }else{
        this.fetchOpenTasks(this.props.match.params.id, true, 1,false);
        this.fetchTasks(this.props.match.params.id);
        this.fetchClosedTasks(this.props.match.params.id); 
      } 
    }   
  }   

  handleMilestone(milestoneName,milestoneId){
      this.setState({ 
        milestoneDetails: { name: milestoneName, id: milestoneId }, 
        filterMilestone: { name: milestoneName, id: milestoneId },
        filter: true 
        }, 
        () =>  {this.taskFilter('filter'); this.filterInitialData(); }
      )
  }

  componentWillUnmount() {
    if(document.getElementById('menu_left')) {
      document.getElementById('menu_left').removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  setTaskValues(response, statusValue, list) {
    if(statusValue === 'all') {
      TasksNextPage.all = response.tasks.has_next;
      this.setState({ 
        tasksList: list,
        nextPageNumber: {
          ...this.state.nextPageNumber,
          all: response.tasks.next_page_number
        },
        tasksCount: {
          ...this.state.tasksCount,
          all: response.tasks.count
        },
        lazyLoading: false,
        loading: false
      })
    } else if(statusValue === 'open') {
      TasksNextPage.open = response.tasks.has_next
      this.setState({ 
        opendTasks: list,
        nextPageNumber: {
          ...this.state.nextPageNumber,
          open: response.tasks.next_page_number
        },
        tasksCount: {
          ...this.state.tasksCount,
          open: response.tasks.count
        },
        lazyLoading: false,
        loading: false
      })
    } else if(statusValue === 'close'){
      TasksNextPage.closed = response.tasks.has_next
      this.setState({ 
        closedTasks: list,
        nextPageNumber: {
          ...this.state.nextPageNumber,
          closed: response.tasks.next_page_number
        },
        tasksCount: {
          ...this.state.tasksCount,
          closed: response.tasks.count
        },
        lazyLoading: false
      })
    }
  }

  fetchTasks(id, page=1, isLoadMore=false) {
    // console.log(filter)
    this.setState({ lazyLoading: true, loading: true });
    tasksResponse = this.state.tasksList;
    fetch_get(PROJECTS_LIST + id + '/tasks/?page=' + page)
    .then((response) => {
      if(response.tasks) {
        this.setState({ isProjectFound: true })
        tasksResponse = (isLoadMore ? tasksResponse : []).concat(response.tasks.data)
        this.setTaskValues(response, 'all', tasksResponse)
        if(response.tasks.count === 0) {
          this.setState({ noResults: 'No Tasks Found'});
        }
      }
    })
  }

  fetchOpenTasks(id, filter=false, page=1, isLoadMore=false) {
    this.setState({ lazyLoading: true, loading: true });
    if(filter) {
      this.filterInitialData()
    }
    tasksResponse = this.state.opendTasks;
    fetch_get(PROJECTS_LIST + id + '/tasks/?status=opened&page=' + page)
    .then((response) => {
      if(response && response.tasks) {
        tasksResponse = (isLoadMore ? tasksResponse : []).concat(response.tasks.data)
        this.setTaskValues(response, 'open', tasksResponse)
        tasksData = response.tasks.data;
      }
    });
  }

  fetchClosedTasks(id, page=1, isLoadMore=false) {
    // console.log(filter)
    var idIsNum = /^\d+$/.test(id);
    if(idIsNum) {
      this.setState({ lazyLoading: true, loading: true });
      tasksResponse = this.state.closedTasks;
      fetch_get(PROJECTS_LIST + id + '/tasks/?status=closed&page=' + page)
      .then((response) => {
        if(response.message) {
          this.setState({ noAccess: response.message, isProjectFound: false, loading: false });
        } else {
          this.setState({ isProjectFound: true })
          tasksResponse = (isLoadMore ? tasksResponse : []).concat(response.tasks.data)
          this.setTaskValues(response, 'close', tasksResponse)
        }
      })
    } else {
      this.setState({ noAccess: 'Please enter valid Url, Project not found', isProjectFound: false, loading: false });
    }
  }

  taskCreate(value, id) {
    this.setState({ 
      addTask: value,
      taskDetailView: false,
      overlay: true,
      kanbanStatusId: id
    })
  }

  filterInitialData() {
    assignedMembersList = [];
    labelsList= [];
    statusesList = [];
    prioritiesList = [];
    milestones = [];
    // let users = [];
    fetch_get(PROJECTS_LIST + this.props.match.params.id + '/tasks/create/')
    .then((response) => {
      if(response) {
        assignedMembersList.push(response.profiles.data)
        labelsList.push(response.labels.data)
        statusesList.push(response.task_statuses.data)
        prioritiesList.push(response.priorities.data)
        milestones.push(response.milestones)
        // users.push(response.profiles.data)
        //statuses = response.task_statuses.data;
        this.setState({ 
          status: response.task_statuses.data[0].id,
          priorities: (response.priorities && response.priorities.data) ? response.priorities.data[0].id : null,
          projectMilestones: response.milestones,
          labels: response.labels.data,
          members: response.profiles.data,
          users: response.profiles.data
        });
      }
    });
  }

  showTaskDetail(id, statusId=null) {
    this.setState({ 
      taskDetailView: true, 
      taskDetailId: id,
      overlay: true,
      membersMenu: false,
      statusMenu: false,
      priorityMenu: false,
      integrationMenu: false,
      milestoneMenu: false,
      labelsMenu: false,
      kanbanStatusId:statusId
    })
    this.props.history.push(`${this.context.company}/pm/${this.props.match.params.id}/tasks/${id}`)
  }

  onFilterMemberSelect(member) {
    if(this.state.memberIds.includes(member.id)) {
      this.state.memberIds.splice(this.state.memberIds.indexOf(member.id), 1 );
      this.state.filterAssignedTo.map((memberDetails, index) => {
        if(memberDetails.id === member.id) {
          this.state.filterAssignedTo.splice(index, 1)
        }
      })
      this.setState({ memberIds: this.state.memberIds, filterAssignedTo: this.state.filterAssignedTo, filter: filterCount === 2 ? false : true}, this.taskFilter)
    } else {
      this.setState({ filterAssignedTo: [...this.state.filterAssignedTo, member], filter: true, memberIds: [...this.state.memberIds, member.id] }, this.taskFilter)
    }
  }

  labelUpdate(label) {
    if(this.state.labelsIds.includes(label.id)) {
      this.state.labelsIds.splice(this.state.labelsIds.indexOf(label.id), 1 );
      this.state.filterlabels.map((labelDetails, index) => {
        if(labelDetails.id === label.id) {
          this.state.filterlabels.splice(index, 1)
        }
      })
      this.setState({ labelsIds: this.state.labelsIds, filterlabels: this.state.filterlabels, filter: filterCount === 2 ? false : true}, this.taskFilter)
    } else {
      this.setState({ filterlabels: [...this.state.filterlabels, label], filter: true, labelsIds: [...this.state.labelsIds, label.id] }, this.taskFilter)
    }
  }

  onFilterStatusChange(status) {
    if(this.state.statusIds.includes(status.id)) {
      this.state.statusIds.splice(this.state.statusIds.indexOf(status.id), 1 );
      this.state.filterStatus.map((statusDetails, index) => {
        if(statusDetails.id === status.id) {
          this.state.filterStatus.splice(index, 1)
        }
      })
      this.setState({ statusIds: this.state.statusIds, filterStatus: this.state.filterStatus, filter: filterCount === 2 ? false : true}, this.taskFilter)
    } else {
      this.setState({ filterStatus: [...this.state.filterStatus, status], filter: true, statusIds: [...this.state.statusIds, status.id] }, this.taskFilter)
    }
  }

  onFilterPriorityChange(priority) {
    if(this.state.priorityIds.includes(priority.id)) {
      this.state.priorityIds.splice(this.state.priorityIds.indexOf(priority.id), 1 );
      this.state.filterPriority.map((priorityDetails, index) => {
        if(priorityDetails.id === priority.id) {
          this.state.filterPriority.splice(index, 1)
        }
      })
      this.setState({ priorityIds: this.state.priorityIds, filterPriority: this.state.filterPriority, filter: filterCount === 2 ? false : true}, this.taskFilter)
    } else {
      this.setState({ filterPriority: [...this.state.filterPriority, priority], filter: true, priorityIds: [...this.state.priorityIds, priority.id] }, this.taskFilter)
    }
  }

  taskFilter(filterType='filter') {
    this.setState({ loading: true })
    const { 
      filterlabels, filterStatus, filterPriority, filterName, filterAssignedTo, 
      filterId, filterIntegrationType, filterMilestone, userSearch 
    } = this.state;
    const data = new FormData();
    if(filterType === 'clear') {
      tasksData = [];
      this.setState({
        filter: false,
        filterMenu: false,
        filterlabels: [],
        filterAssignedTo: [],
        filterName: '',
        filterId: '',
        filterStatus: [],
        filterPriority: [],
        filterMilestone: {name: '', id: null}, 
        filterIntegrationType: null,
        milestoneDetails: { name: 'All', id: null},
        labelsIds: [],
        memberIds: [],
        statusIds: [],
        priorityIds: [],
      })
      data.append('label', []);
      data.append('assigned_to', []);
      data.append('status', []);
      data.append('priority',[]);
      data.append('pt_id', '');
      data.append('title', '');
      data.append('milestone', '');
      data.append('integration_type', '');
    } else {
      if(filterlabels.length > 0) {
        filterlabels.filter((obj) =>
          data.append('label', obj.id)
        );
      }
      if(filterAssignedTo.length > 0) {
        filterAssignedTo.filter((obj) =>
          data.append('assigned_to', obj.id)
        );
      }

      if(filterStatus.length > 0) {
        filterStatus.filter((obj) =>
          data.append('status', obj.id)
        );
      }
      if(filterPriority.length > 0) {
        filterPriority.filter((obj) =>
          data.append('priority', obj.id)
        );
      }
      data.append('title', filterName);
      data.append('username', userSearch);
      data.append('pt_id', filterId);
      filterMilestone.id && data.append('milestone',  filterMilestone.id);
      if(filterIntegrationType === 'without integration') {
        data.append('project_issues', true); 
      } else {
        data.append('integration_type', filterIntegrationType ? filterIntegrationType : ''); 
      }
    }
    this.filterOpenTasks(data)
    this.filterClosedTasks(data)
    this.filterAllTasks(data)
  }

  filterOpenTasks(data, openPage=1, isLoad=false) {
    this.setState({ lazyLoading: true, loading: true });
    tasksResponse = this.state.opendTasks;
    fetch_post(PROJECTS_LIST + this.props.match.params.id + '/tasks/?status=opened&page=' + openPage, data)
    .then((response) => {
      tasksResponse = (isLoad ? tasksResponse : []).concat(response.tasks.data)
      this.setTaskValues(response, 'open', tasksResponse)
      if(response.tasks.has_next) {
        this.setState({ filterData: data});
      }
    });
  }

  filterAllTasks(data, allPage=1, isLoad=false) {
    this.setState({ lazyLoading: true, loading: true });
    tasksResponse = this.state.tasksList;
    fetch_post(PROJECTS_LIST + this.props.match.params.id + '/tasks/?page=' + allPage, data)
    .then((response) => {
      tasksResponse = (isLoad ? tasksResponse : []).concat(response.tasks.data);
      this.setState({ 
        loading: false,
      })
      this.setTaskValues(response, 'all', tasksResponse)
      if(response.tasks.count === 0) {
        this.setState({ noResults: 'No Results Found' });
      }
      if(response.tasks.has_next) {
        this.setState({ filterData: data});
      }
    });
  }

  filterClosedTasks(data, closedPage=1, isLoad=false) {
    this.setState({ lazyLoading: true, loading: true });
    tasksResponse = this.state.closedTasks;
    fetch_post(PROJECTS_LIST + this.props.match.params.id + '/tasks/?status=closed&page=' + closedPage, data)
    .then((response) => {
      tasksResponse = (isLoad ? tasksResponse : []).concat(response.tasks.data)
      this.setTaskValues(response, 'close', tasksResponse)
      if(response.tasks.has_next) {
        this.setState({ filterData: data});
      }
    });
  }

  activeTab(value) {
    tasksData = null;
    switch (this.state.taskStatus) {
      case 'open':
        tasksData = this.state.opendTasks;
        break;
      case 'closed':
        tasksData = this.state.closedTasks;
        break;
      case 'all':
        tasksData = this.state.tasksList;
        break;
      default :
    }
    return (this.state.taskStatus === value ?  ' active' : '');
  }

  milestoneTab(value) {
    return 'submenu milestone' + (this.state.milestoneDetails.id === value ? ' selected' : '');
  }

  reopenTask(taskId) {
    for (let i=0,len=this.state.closedTasks.length; i<len; i++){
      if(this.state.closedTasks[i].pt_id === taskId) {
        this.setState({ opendTasks: this.state.opendTasks.concat(this.state.closedTasks[i]) })
      }
    }
    this.setState({
      closedTasks: this.state.closedTasks.filter(el => el.pt_id != taskId ),
      taskDetailView: false,
      tasksCount: {
        ...this.state.tasksCount,
        open: this.state.tasksCount.open + 1,
        closed: this.state.tasksCount.closed - 1,
      },
    });
  }

  closeTask(taskId, actionStatus){
    if(actionStatus === 'delete') {
      this.setState({
        opendTasks: this.state.opendTasks.filter(el => el.pt_id != taskId ),
        closedTasks:  this.state.closedTasks.filter(el => el.pt_id != taskId ),
        tasksList:  this.state.tasksList.filter(el => el.pt_id != taskId ),
        taskDetailView: false,
        tasksCount: {
          ...this.state.tasksCount,
          open: this.state.tasksCount.open > 0 ? (this.state.tasksCount.open - 1) : 0,
          closed: this.state.tasksCount.closed > 0 ? (this.state.tasksCount.closed - 1) : 0,
          all: this.state.tasksCount.all - 1
        }
      });
    } else {
      for (let i=0,len=this.state.opendTasks.length; i<len; i++){
        if(this.state.opendTasks[i].pt_id === taskId) {
          this.setState({ closedTasks: this.state.closedTasks.concat(this.state.opendTasks[i]) })
        }
      }
      this.setState({
        opendTasks: this.state.opendTasks.filter(el => el.pt_id != taskId ),
        taskDetailView: false,
        tasksCount: {
          ...this.state.tasksCount,
          open: this.state.tasksCount.open - 1,
          closed: this.state.tasksCount.closed + 1,
        }
      });
    }
  }

  updateKanbanBoards(boardIds) {
    // if(this.state.updateBoards) {
    //   this.setState({ updateBoards: false})
    // } else {
      this.setState({ boardIds })
    // }
  }

  updateTask(id, updateAll=false, status=null, updateTask=true) {
    if(this.state.kanbanView) {
      if(status !== this.state.kanbanStatusId) {
        this.updateKanbanBoards([parseInt(status), parseInt(this.state.kanbanStatusId)])
        this.setState({ kanbanStatusId: status })
      }else {
        this.updateKanbanBoards([this.state.kanbanStatusId])
      }
    }
    if(updateTask) {
      if(updateAll) {
        this.taskFilter('filter')
      } else {
        fetch_get(PROJECTS_LIST + this.props.match.params.id + '/tasks/' + id + '/')
        .then((response) => {
          Object.keys(response.task.data).map((object) => {
            if(object == 'comments_count'){
              let comments = response.task.data[object];
              delete response.task.data[object];
              response.task.data['comment_count'] =  comments
            }
            if(object == 'labels_details'){
              let details = response.task.data[object]
              delete response.task.data[object];
              response.task.data['labels_text'] =  details
            }
            if(object == 'priority_details'){
              let prioritydetails = response.task.data[object].data.name
              delete response.task.data[object];
              response.task.data['priority_text'] =  prioritydetails
            }
            if(object == 'status_details'){
              let statusdetails = response.task.data[object].data.name
              delete response.task.data[object];
              response.task.data['status_text'] =  statusdetails
            }
            if(object == 'attachments_list'){
              let attachmentdetails = response.task.data[object].data.length
              delete response.task.data[object];
              response.task.data['attachment_count'] =  attachmentdetails
            }
          })
          this.state.opendTasks.map((task, index) => {
            if(task.pt_id === id) {
              this.state.opendTasks[index] = response.task.data;
              this.setState({ opendTasks: this.state.opendTasks })
            }
          })
          this.state.tasksList.map((task, index) => {
            if(task.pt_id === id) {
              this.state.tasksList[index] = response.task.data;
              this.setState({ tasksList: this.state.tasksList })
            }
          })
          this.state.closedTasks.map((task, index) => {
            if(task.pt_id === id) {
              this.state.closedTasks[index] = response.task.data;
              this.setState({ closedTasks: this.state.closedTasks })
            }
          })
          this.updateTaskDetail(true, id)
        })
      }
    }
  }

  updateTaskDetail(updateTask, updateTaskId=null) {
    this.setState({ updateTask, updateTaskId })
  }

  handleScroll() {
    if(document.getElementById('menu_left')) {
      var menuLeft = document.getElementById('menu_left');
      var offset = Math.max(menuLeft.scrollTop + menuLeft.clientHeight);
      const windowHeight = Math.max(window.innerHeight);
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
      var windowBottom = Math.max(windowHeight + window.pageYOffset);
      windowBottom = windowBottom + 1;
      if (windowBottom >= docHeight && offset >= menuLeft.scrollHeight) {
        if(!this.state.lazyLoading) {
          if(!this.state.filter && this.state.milestoneDetails.name === 'All' && this.state.userSearch === '') {
            switch (this.state.taskStatus) {
              case 'open':
                if(TasksNextPage.open) {
                  this.fetchOpenTasks(this.props.match.params.id, false, this.state.nextPageNumber.open, true);
                  this.setState({ noTasks: false })
                } else {
                  this.setState({ noTasks: true })
                }
                break;
              case 'closed':
                if(TasksNextPage.closed) {  
                  this.fetchClosedTasks(this.props.match.params.id, this.state.nextPageNumber.closed, true);
                  this.setState({ noTasks: false })
                } else {
                  this.setState({ noTasks: true })
                }
                break;
              case 'all':
                if(TasksNextPage.all) { 
                  this.fetchTasks(this.props.match.params.id, this.state.nextPageNumber.all, true);
                  this.setState({ noTasks: false })
                } else {
                  this.setState({ noTasks: true })
                }
                break;
              default :
            }
          } else {
            switch (this.state.taskStatus) {
              case 'open':
                if(TasksNextPage.open) {
                  this.filterOpenTasks(this.state.filterData, this.state.nextPageNumber.open, true);
                }
                break;
              case 'closed':
                if(TasksNextPage.closed) {  
                  this.filterClosedTasks(this.state.filterData, this.state.nextPageNumber.closed, true);
                }
                break;
              case 'all':
                if(TasksNextPage.all) { 
                  this.filterAllTasks(this.state.filterData, this.state.nextPageNumber.all, true);
                }
                break;
              default :
            }
          }
        }
      }
    }
  }

  overlayControl() {
    this.setState({ overlay: false, addTask: false, taskDetailView: false, addTaskFromDashboard: false, statusMenu: false, priorityMenu: false, integrationMenu: false, milestoneMenu: false, labelsMenu: false, membersMenu: false  })
    this.props.history.push(`${this.context.company}/pm/${this.props.match.params.id}/tasks`)
  }

  onSearch(search, text, e) {
    let value = text !== undefined ? text : e.target.value
    if(search === 'member') {
      this.setState({ membersMenu: true })
      var filteredmembers = assignedMembersList[0].filter(function (member) {
        return (member.employee_name.toLowerCase().includes(value.toLowerCase()));
      });
      this.setState({ members: filteredmembers, assignedMembersSearch: value })
    } else if(search === 'label'){
      this.setState({ labelsMenu: true })
      var filteredLabels = labelsList[0].filter(function (label) {
        return (label.name.toLowerCase().includes(value.toLowerCase()));
      });
      this.setState({ labels: filteredLabels, labelSearch: value })
    } else {
      this.setState({ usersMenu: true })
      var filteredUsers = assignedMembersList[0].filter(function (user) {
        return (user.employee_name.split(" ")[0].toLowerCase().includes(value.toLowerCase()));
      });
      this.setState({ users: filteredUsers,  userSearch: value })
    } 
  }

  onCancel() {
    this.setState({ addTask: false, overlay: false, addTaskFromDashboard: false})
  }

  onFilterNameSearch(e) {
    e.preventDefault();
    if(this.state.users.length > 0 && this.state.usersMenu) {
      if(this.state.userSearch !== '') {
        this.setState({ userSearch: this.state.users[0].employee_name.split(" ")[0]}, () =>{ this.setState({ usersMenu:false }, this.taskFilter)})
        this.onSearch('filtername', this.state.users[0].employee_name.split(" ")[0])
      } else {
        this.onSearch('filtername', this.state.userSearch)
        this.setState({ usersMenu: false }, this.taskFilter)
      }
    }
  }

  filterLabelSearch(e) {
    if(e.key === 'Enter' && this.state.labels.length > 0 && this.state.labelSearch !== ''){
      e.preventDefault();
      this.onSearch('label', '')
      this.labelUpdate(this.state.labels[0], 0, 'add')
      this.setState({ labelsMenu: false, labelSearch: '' })
    }
  }

  filterMemberSearch(e) {
    if(e.key === 'Enter' && this.state.members.length > 0 && this.state.assignedMembersSearch !== ''){
      e.preventDefault();
      this.onSearch('member', '')
      this.onFilterMemberSelect(this.state.members[0], 0)
      this.setState({ membersMenu: false, assignedMembersSearch: '' })
    }
  }

  dropdownMenu(){
    {this.state.priorityMenu && this.setState({ priorityMenu: false}) }
    {this.state.statusMenu && this.setState({ statusMenu: false }) }
    {this.state.integrationMenu && this.setState({ integrationMenu: false }) }
    {this.state.milestoneMenu && this.setState({ milestoneMenu: false }) }
    {this.state.labelsMenu && this.setState({ labelsMenu: false }) }
    { this.state.membersMenu && this.setState({ membersMenu: false }) }
  }

  render() {
    filterCount = document.getElementById('filterView') ? document.getElementById('filterView').childElementCount : 0;
    return(
      <div>
        {this.state.isProjectFound ? 
          <div className="secondary_block bg-white pb-0">
            <div className="overlay" style={{ display: this.state.overlay ? 'block' : 'none'}} onClick={() => this.overlayControl()}></div>
            <div className="row mar_lr_0 table_height ">
              <div className="row tabs_block mar_lr_0 tasks_tab_block mb-0">
                <div className="fixed_top row">
                  {!this.state.kanbanView ?
                    <div className="col-lg-5 filter_row task_filter_row" onClick={() => this.dropdownMenu()}></div>
                  :
                    <div className="col-lg-4"></div>
                  }
                  <div className="col-lg-12 relative" onClick={() => this.dropdownMenu()}>
                    {!this.state.kanbanView &&
                      <div className="tabs">
                        <a className={'tab br-1 all' + this.activeTab('all')} onClick={() => this.setState({ taskStatus: 'all', currentPage: 1 })}>
                          <strong>{tasksData ? this.state.tasksCount.all : ' '}</strong> 
                          <span>All</span>
                        </a>
                        <a className={'tab open' + this.activeTab('open')} onClick={() => this.setState({ taskStatus: 'open', currentPage: 1 })}>
                          <strong>{tasksData ? this.state.tasksCount.open : ' '}</strong> 
                          <span>Open</span>
                        </a>
                        <a className={'tab closed' + this.activeTab('closed')} onClick={() => this.setState({ taskStatus: 'closed', currentPage: 1 })}>
                          <strong>{tasksData ? this.state.tasksCount.closed : ' '}</strong> 
                          <span>Closed</span>
                        </a>
                          <a className="tab edit filtssder_form ml-4" onClick={() => {this.setState({ filterMenu: !this.state.filterMenu }) }}>
                            <span>Filter</span><i className="fas fa-filter"></i>
                          </a>
                        <div className="overlay light" style={{ display: (this.state.filterMenu || this.state.usersMenu) ? 'block' : 'none' }} onClick={() => this.setState({ filterMenu: false, usersMenu: false })}></div>
                        <a className="add_project" 
                            onClick={() => 
                              this.setState(
                                { addTask: true, overlay: true, statusMenu: false, priorityMenu: false, integrationMenu: false, milestoneMenu: false, labelsMenu: false, membersMenu: false })
                            }
                        >
                          <i className="fas fa-plus"></i>
                        </a>
                      </div>
                    }
                    {/*<div className="tabs">
                      <a className={this.state.kanbanView ? 'tab open' : 'tab open active'} onClick={() => this.setState({ kanbanView: false })}><span>View List</span></a>
                      <a className={this.state.kanbanView ? 'tab closed active' : 'tab closed' } onClick={() => this.setState({ kanbanView: true })}><span>View Board</span></a>
                    </div>*/}
                    {this.state.filterMenu && 
                      <div className="filter_section absolute filter_section1">
                        <div className="section">
                          <div className="row section_heading">
                            <div className="col-lg-3 form-group">
                              <div className="heading">Task Id</div>
                              <input 
                                type="number" 
                                className="form-control" 
                                value={this.state.filterId}
                                onChange={(e) => {this.setState({ filterId: e.target.value, filter: true }, this.taskFilter )}}
                              />
                            </div>
                            <div className="col-lg-9 form-group">
                              <div className="heading">Task Name</div>
                              <input 
                                type="text"
                                className="form-control"
                                // placeholder="Enter Task Name" 
                                value={this.state.filterName} 
                                onChange={(e) => {this.setState({ filterName: e.target.value, filter: true }, this.taskFilter )}}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-6 pr-0 section">
                          <div className="heading">Assigned To</div>
                          <div className="row">
                            <div 
                              className="col-lg-12 form-group dropdown people filter_people"
                              onClick={() => this.setState({ membersMenu: !this.state.membersMenu })}
                            >
                              <input 
                                type="text" 
                                // ref="member"
                                className="form-control toggle" 
                                placeholder="Search by User"
                                value={this.state.assignedMembersSearch}
                                onChange={this.onSearch.bind(this, 'member', undefined)} 
                                onKeyPress={this.filterMemberSearch.bind(this)}
                              />
                              <ul className="menu" style={{ display: this.state.membersMenu ? 'block' : 'none', zIndex: 999999 }}>
                                {this.state.members.length > 0 ? this.state.members.map((member, index) => 
                                  <li className="submenu" key={member.id} onClick={() => this.onFilterMemberSelect(member, index)}>
                                    <a>
                                      {member.has_profile_pic ? 
                                        <img alt="" src={member.profile_img} />
                                      :
                                        <span className="profile_name">{member.profile_text && member.profile_text}</span>
                                      }
                                      {member.employee_name}
                                      {this.state.memberIds.includes(member.id) && 
                                        <span className="float-right" style={{ display: 'block'}}><i className="fas fa-check" style={{ fontSize: '1.0rem' }}></i></span>
                                      }
                                    </a>
                                  </li>
                                ): null}
                              </ul>
                              <div className="overlay light" style={{ display: this.state.membersMenu ? 'block' : 'none' }} onClick={() => this.setState({ membersMenu: false }) } />
                            </div>
                          </div>
                          <div className="avatars">
                            {this.state.filterAssignedTo.length > 0 ? this.state.filterAssignedTo.map((member, index) => 
                              <span className="avatar tooltips" key={member.id}>
                                {member.has_profile_pic ? 
                                  <img src={member.profile_img} alt="" title={member.employee_name}/>
                                :
                                  <div className="profile_name" data-toggle="tooltip" data-placement="bottom" title={member.employee_name}>{member.profile_text && member.profile_text}</div>
                                }
                                <small className="remove" onClick={() => { this.onFilterMemberSelect(member, index) }}><i className="fas fa-times"></i></small>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 section">
                          <div className="heading">Label</div>
                          <div className="row justify-content-start add_label_row">
                            <div className="col-lg-12">
                              <div className="form-group mar-bot-5">
                                <span 
                                  className="input-group mb-3 dropdown no-caret task_dropdown"
                                  onClick={() => this.setState({ labelsMenu: !this.state.labelsMenu })}
                                >
                                  <input 
                                    type="text"
                                    style={{width:'10px'}}
                                    className="form-control" 
                                    placeholder="Search By Label" 
                                    aria-label="Select User" 
                                    aria-describedby="button-addon2"
                                    // ref="name"
                                    value={this.state.labelSearch}
                                    onKeyPress={this.filterLabelSearch.bind(this)} 
                                    onChange={this.onSearch.bind(this, 'label', undefined)} 
                                  />
                                  <div className="input-group-append">
                                    <button className="toggle btn btn-outline-secondary" type="button" id="button-addon2"><i className="fas fa-angle-down"></i></button>
                                  </div>
                                  <ul className="menu task_detail_menu label_menu" style={{ display: this.state.labelsMenu ? 'block' : 'none', zIndex: 999999 }}>
                                    {this.state.labels.length > 0 && this.state.labels.map((label, index) => 
                                      <li className="submenu" key={label.id} onClick={() => this.labelUpdate(label, index, 'add')}>
                                        <a style={{ backgroundColor: label.color }}> {label.name} 
                                          {this.state.labelsIds.includes(label.id) && 
                                            <span className="float-right" style={{ display: 'block'}}><i className="fas fa-check"></i></span>
                                          }
                                          {!this.state.labelsIds.includes(label.id) && 
                                            <span className="float-right"><i className="fas fa-check"></i></span>
                                          }
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                  <div className="overlay light" style={{ display: this.state.labelsMenu ? 'block' : 'none' }} onClick={() => this.setState({ labelsMenu: false }) } />
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="tags">
                                {this.state.filterlabels.length > 0 ? this.state.filterlabels.map((label) => 
                                  <a key={label.id} className="tag" style={{ backgroundColor: label.color }}>{label.name}</a>
                                ): null}
                              </div>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div className="section">
                          <div className="heading">Milestone</div>
                          <ul className="filter_list">
                            {milestones.length > 0 ? milestones[0].map((milestone) =>
                              <li key={milestone.data.id}>
                                <input 
                                  type="radio" 
                                  value={milestone.data.id} 
                                  id={milestone.data.id} 
                                  name="filterMilestone" 
                                  checked={this.state.filterMilestone.id === milestone.data.id}
                                  onChange={() => this.setState({ filterMilestone:{name: milestone.data.name, id: milestone.data.id}, milestoneDetails:{name: milestone.data.name, id: milestone.data.id}, filter: true }, this.taskFilter)} 
                                />
                                <label htmlFor={milestone.data.id}>{milestone.data.name}</label>
                              </li>
                            ): null}
                            <li>
                              <input type="radio" value=" " id="noMilestone" name="filterMilestone" onChange={() => this.setState({ filterMilestone: { name: '', id: null }, milestoneDetails: { name: 'All', id: null } }, this.taskFilter)} /> 
                              <label htmlFor="noMilestone">None</label>
                            </li>
                            <br clear="all" />
                          </ul>
                        </div>  
                        <div className="section">
                          <div className="heading">Status</div>
                          <div className="row">
                            <div className="col-lg-12">
                              <ul className="filter_list">
                                {statusesList.length > 0 ? statusesList[0].map((status, index) => 
                                  <li key={status.id} >
                                    <input type="checkbox" checked={this.state.statusIds.includes(status.id)} id={status.id} name={status.name} value={status.id} onChange={() => this.onFilterStatusChange(status, index)} /> 
                                    <label htmlFor={status.id}>{status.name}</label>
                                  </li>
                                ): null}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="section">
                          <div className="heading">Priority</div>
                          <div className="row">
                            <div className="col-lg-12">
                              <ul className="filter_list">
                                {prioritiesList.length > 0 ? prioritiesList[0].map((priority, index) => 
                                  <li key={priority.id} >
                                    <input type="checkbox" checked={this.state.priorityIds.includes(priority.id)} id={priority.id} name={priority.name} value={priority.id} onChange={() => this.onFilterPriorityChange(priority, index)} /> 
                                    <label htmlFor={priority.id}>{priority.name}</label>
                                  </li>
                                ): null}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="section">
                          <div className="heading">Integration Type</div>
                          <div className="row">
                            <div className="col-lg-12">
                              <ul className="filter_list">
                                <li><input type="radio" value="sentry" id="sentry" name="integrationType" checked={this.state.filterIntegrationType === "sentry"} onChange={(e) => this.setState({ filterIntegrationType: e.target.value, filter: true }, this.taskFilter)} /> <label htmlFor="sentry">Sentry</label></li>
                                <li><input type="radio" value="gitlab" id="gitlab" name="integrationType" checked={this.state.filterIntegrationType === "gitlab"} onChange={(e) => this.setState({ filterIntegrationType: e.target.value, filter: true }, this.taskFilter)} /> <label htmlFor="gitlab">GitLab</label></li>
                                <li><input type="radio" value="trello" id="trello" name="integrationType" checked={this.state.filterIntegrationType === "trello"} onChange={(e) => this.setState({ filterIntegrationType: e.target.value, filter: true }, this.taskFilter)} /> <label htmlFor="trello">Trello</label></li>
                                <li><input type="radio" value="without integration" id="without" name="integrationType" checked={this.state.filterIntegrationType === "without integration"} onChange={(e) => this.setState({ filterIntegrationType: e.target.value, filter: true }, this.taskFilter)} /> <label htmlFor="without">Without Integration</label></li>
                                <li><input type="radio" value="" id="none" name="integrationType" checked={this.state.filterIntegrationType === ''} onChange={() => this.setState({ filterIntegrationType: '' }, this.taskFilter)} /> <label htmlFor="none">None</label></li>
                                <br clear="all" />
                              </ul>
                            </div>
                          </div>
                        </div>
                        <br clear="all" />
                        <div className="section">
                          <button className="btn primary_btn" onClick={() => this.taskFilter('clear')}>Clear Filters</button>
                        </div>  
                      </div>
                    }
                  </div>
                </div>
                <div className="col-lg-12" onClick={() => this.dropdownMenu()}>
                  <div className="filter_results">
                    {this.state.filter &&
                      <ul id="filterView">
                        {this.state.filterId !== '' && <li> {this.state.filterId} <span className="float-right" onClick={() => this.setState({ filterId: '', filter: filterCount === 2 ? false : true }, this.taskFilter)}><i className="fas fa-times"></i></span></li>}{' '}
                        {this.state.filterName !== '' && <li> {this.state.filterName} <span className="float-right" onClick={() => this.setState({ filterName: '', filter: filterCount === 2 ? false : true}, this.taskFilter)}><i className="fas fa-times"></i></span></li>}{' '}
                        {this.state.filterStatus.length > 0  && 
                          this.state.filterStatus.map((status, index) =>
                            <li key={status.id}> {status.name} <span className="float-right" onClick={() => this.onFilterStatusChange(status, index)}><i className="fas fa-times"></i></span>
                            </li>
                        )}{' '}
                        {this.state.filterPriority.length > 0  && 
                          this.state.filterPriority.map((priority, index) =>
                            <li key={priority.id}> {priority.name} <span className="float-right" onClick={() => this.onFilterPriorityChange(priority, index)}><i className="fas fa-times"></i></span>
                            </li>
                        )}{' '}
                        {this.state.filterIntegrationType && <li> {this.state.filterIntegrationType} <span className="float-right" onClick={() => this.setState({ filterIntegrationType: null, filter: filterCount === 2 ? false : true}, this.taskFilter)}><i className="fas fa-times"></i></span></li>}{' '}
                        {this.state.filterMilestone.id && <li> {this.state.filterMilestone.name}<span className="float-right" onClick={() => this.setState({ filterMilestone: { name: '', id: null}, filter: filterCount === 2 ? false : true, milestoneDetails: { name: 'All', id: null}}, this.taskFilter)}><i className="fas fa-times"></i></span></li>}{' '}
                        {this.state.filterAssignedTo.length > 0  && 
                          this.state.filterAssignedTo.map((member, index) =>
                            <li key={member.id}> {member.employee_name} <span className="float-right" onClick={() => this.onFilterMemberSelect(member, index)}><i className="fas fa-times"></i></span>
                            </li>
                        )}{' '}
                        {this.state.filterlabels.length > 0  && 
                          this.state.filterlabels.map((label, index) =>
                            <li key={label.id}> {label.name} <span className="float-right" onClick={() => this.labelUpdate(label, index)}><i className="fas fa-times"></i></span>
                            </li>
                        )}{' '}
                        {(document.getElementById('filterView') && document.getElementById('filterView').childElementCount > 1) &&
                          <li className="clear">
                            <span onClick={() => this.taskFilter('clear')}>Clear all filters</span>
                          </li>
                        }
                      </ul>
                    }
                  </div>
                </div>
                <div className="col-lg-12">
                  {!this.state.kanbanView ?
                    <div id="menu_left">
                      {(tasksData && tasksData.length > 0) ?
                        <Fragment> 
                          {tasksData.map((task) => 
                            <Task
                              key={task.pt_id}
                              task={task}
                              id={this.props.match.params.id}
                              showTaskDetail={this.showTaskDetail}
                              labelUpdate={this.labelUpdate}
                              // labelFilter={this.labelFilter}
                              updateTaskDetail={this.updateTaskDetail}
                              updateTaskId={this.state.updateTaskId}
                              updateTaskDetails={this.state.updateTaskDetails}
                              handleMilestone={this.handleMilestone}
                            />
                          )}
                          {this.state.loading ?
                            <Spinner />
                          :
                            (this.state.noTasks && tasksData.length > 10) &&
                              <div style={{ backgroundColor: '#6d6d6d', textAlign: 'center', color: '#fff' }}>End of the list</div>
                          }
                        </Fragment>
                      :
                        <Fragment>
                          {this.state.loading && <Spinner /> }
                          {(!this.state.loading && tasksData.length === 0) && <span>No Tasks Found</span>}
                        </Fragment>
                      }
                    </div>
                  :
                    <div>
                      <TasksKanban 
                        props={this.props} 
                        showTaskDetail={this.showTaskDetail}
                        updateKanbanBoards={this.updateKanbanBoards}
                        boardIds={this.state.boardIds}
                        addTask={this.taskCreate}
                      />
                    </div>
                  }
                  {(this.state.addTask || this.state.addTaskFromDashboard) && 
                    <AddTasks 
                      id={this.props.match.params.id} 
                      taskId={this.state.taskDetailId} 
                      updateTask={this.updateTask}
                      overlayControl={this.overlayControl}
                      selectedMilestone={this.state.milestoneDetails}
                      onCancel={this.onCancel}
                      kanbanStatusId={this.state.kanbanStatusId}
                    />
                  }
                  {this.state.taskDetailView && 
                    <TaskDetailsPage 
                      kanbanStatusId={this.state.kanbanStatusId}
                      taskId={this.state.taskDetailId} 
                      id={this.props.match.params.id} 
                      closeTask={this.closeTask}
                      reopenTask={this.reopenTask}
                      updateTask={this.updateTask}
                      overlayControl={this.overlayControl}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        :
          <div><center><b>{this.state.noAccess}</b></center></div>
        }
      </div>
    )
  }
}