export const getParams = (search) => {
  const search_param = {}
  if (search) {
    search = search.substr(1)
    search.split('&').forEach((obj) => {
      search_param[obj.split('=')[0]] = obj.split('=')[1]
    })
  }
  return search_param
}
