import React, { Component } from 'react'
import { Context, fetch_post } from '../../common'
import { Link } from 'react-router-dom'

export default class TestCases extends Component {
  static contextType = Context;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      testType: '',
      testCases: '',
      testCaseId: '',
      name: '',
      module: '',
      steps: '',
      expectedResult: '',
      notes: '',
      errors: '',
      modal: false
    }
  }

  componentDidMount() {
    this._isMounted = true    
    this.fetchTestDetails()
  }
  
  fetchTestDetails() {
    let data = new FormData();
    data.append('test_type_id', this.props.match.params.id)
    fetch_post('/test/type/details/', data)
      .then(response => {        
        if(this._isMounted) {
          this.setState({ 
            testType: response.test_type_data.data, 
            testCases: response.test_cases_data                      
          })          
        }
      })          
  }
  
  handleFormChange(e) {
    this.setState({ [e.target.id]: e.target.value })
  }

  addTestCase() {
    const data = new FormData()
    data.append('test_case_id', this.state.testCaseId)
    data.append('name', this.state.name)
    data.append('module', this.state.module)
    data.append('steps', this.state.steps)
    data.append('expected_result', this.state.expectedResult)
    data.append('notes', this.state.notes)    
    data.append('test_type_id', this.props.match.params.id) 
    fetch_post('/test/case/create/', data)
      .then(response => {        
        if(response.error) {
          this.setState({ errors: response.errors })          
        } else {
          document.querySelector('.modal-backdrop').remove()          
          document.querySelector('.modal-open').classList.remove('modal-open')
          this.setState({ modal: false })
          this.setState({ errors: '' })
          this.fetchTestDetails()
        }
      })
      .catch(() => {
        this.setState({ errors:  'Something went wrong, Please try again' })
      })
  }
  addNewTestType() {
    this.setState({ 
      testCaseId: '',
      name: '',
      module: '',
      steps: '',
      expectedResult: '',
      notes: '',
      errors: ''
    })
  }

  gotToTestExecutions() {    
    this.props.history.push(`${this.props.match.params.id}/testexecutions`)
  }
  render() {
    return (
      <div className="secondary_block pb-0">
        <div className="row mar_lr_0 table_height">
          <div className="col-lg-12 ">
            <h2 className="heading mt-3">Test Details - {this.state.testType.name}
              <Link className="float-right new_btn" onClick={() => this.gotToTestExecutions()}><i className="fas fa-plus"></i> New Test Executions</Link>
              <a href="/pm/past_executions/" className="float-right primary_btn no_brdr_btn mr-2"><i className="fas fa-history"></i> Past Test Executions</a> 
              <a className="float-right border_btn mr-2" 
                data-toggle="modal" 
                data-target="#myModal3"
                onClick={() => this.setState({ modal : true })}>
                <i className="fas fa-plus"></i> New Test Case
              </a>
            </h2>
          </div>
        </div>

        {
          this.state.modal
          ? <div className="modal right side_pop_modal fade" id="myModal3" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i className="fas fa-times"></i> Close</span></button>
                <h4 className="modal-title" id="myModalLabel2" onClick={() => this.addNewTestType() }>New Test Case</h4>                
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="testTypeId">ID <span className="error">*</span></label>
                    <input  type="text" 
                            className="form-control" 
                            id="testCaseId" 
                            aria-describedby="testTypeId" 
                            placeholder="Enter test type id" 
                            value={this.state.testCaseId}
                            onChange={(e) => this.handleFormChange(e)}/>
                    <small id="emailHelp" className="form-text text-muted">It should be unique based on Test Type.</small>
                    <div className="error-text">
                      {this.state.errors.test_case_id ? this.state.errors.test_case_id[0] : ''}
                    </div>
                  </div>
                  <div className="form-group">
                      <label htmlFor="name">Name <span className="error">*</span></label>
                      <input  type="text" 
                              className="form-control" 
                              id="name" 
                              aria-describedby="name" 
                              placeholder="Enter name"
                              value={this.state.name}
                              onChange={(e) => this.handleFormChange(e)}/>
                      <div className="error-text">
                        {this.state.errors.name ? this.state.errors.name[0] : ''}
                      </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="module">Module <span className="error">*</span></label>
                    <input  type="text" 
                            className="form-control" 
                            id="module" 
                            aria-describedby="module" 
                            placeholder="Enter Module"
                            value={this.state.module}
                            onChange={(e) => this.handleFormChange(e)} /> 
                    <div className="error-text">
                      {this.state.errors.module ? this.state.errors.module[0] : ''}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="expected_result">Expected Results <span className="error">*</span></label>
                    <input  type="text" 
                            className="form-control" 
                            id="expectedResult" 
                            aria-describedby="expected_result" 
                            placeholder="Enter Expected Result"
                            value={this.state.expectedResult}
                            onChange={(e) => this.handleFormChange(e)} />
                    <div className="error-text">
                      {this.state.errors.expected_result ? this.state.errors.expected_result[0] : ''}
                    </div> 
                </div>
                  <div className="form-group">
                      <label htmlFor="steps">Steps <span className="error">*</span></label>
                      <textarea className="form-control" 
                                id="steps" 
                                rows="4"
                                value={this.state.steps}
                                onChange={(e) => this.handleFormChange(e)}></textarea>
                      <small id="emailHelp" className="form-text text-muted">Add break to each step by click on enter.</small>
                      <div className="error-text">
                        {this.state.errors.steps ? this.state.errors.steps[0] : ''}
                      </div> 
                  </div>
                  <div className="form-group">
                  <button type="button" className="border_btn secondary"><i className="fas fa-plus"></i> Add Notes</button>
                </div>
                  <div className="form-group">
                    <label htmlFor="notes">Add Notes </label>
                    <textarea className="form-control" 
                              id="notes" 
                              rows="4"
                              value={this.state.notes}
                              onChange={(e) => this.handleFormChange(e)}></textarea>
                    <small id="emailHelp" className="form-text text-muted">Add break to each step by click on enter.</small>
                    <div className="error-text">
                      {this.state.errors.notes ? this.state.errors.notes[0] : ''}
                    </div> 
                </div>
                  <button type="button" className="btn primary_btn mt-2" onClick={() => this.addTestCase()}>Add Test Case</button>
                </form>
              </div>
            </div>
            </div>
          </div> 
          : ''
        }
          <div className="row mar_lr_0 mt-2">
            <div className="col-lg-8 pl-4">

              <div className="test_cases_container">
                <h5 className="secondary_heading mb-3 d-block">Test cases</h5>
                <div id="accordionExample" className="accordion">
                {
                  this._isMounted && Object.keys(this.state.testCases).map(test => {                    
                      return(
                        <div className="card" key={test}>
                          <div id="headingOne" className="card-header border-0">
                          <h2 className="mb-0">
                            <button type="button" data-toggle="collapse" data-target={`#${test.replace(/ +/g, '')}`}
                              aria-expanded="true" aria-controls={`${test}`}
                              className="btn btn-link collapsible-link">{test} ({this.state.testCases[test].data.length})</button>
                          </h2>
                        </div>
                        <div id={`${test.replace(/ +/g, '')}`} aria-labelledby="headingOne" data-parent="#accordionExample" className="collapse show">
                          <div className="card-body">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col" width="100px">#ID</th>
                                  <th scope="col">Test Title</th>
                                  <th scope="col">Expected Result</th>
                                </tr>
                              </thead>
                              { 
                              this.state.testCases[test].data.map(test => {
                                return(
                                  <tbody key={test.test_case_id}>
                                    <tr>
                                      <th scope="row"><a href="#">{test.test_case_id}</a></th>
                                      <td>{test.name}</td>
                                      <td>{test.expected_result}</td>
                                    </tr>                              
                                  </tbody>
                                )})
                              }
                            </table>
                          </div>
                        </div>
                
                   
                    
                </div>
                
                      )    
                  })
                }
                
                </div>
              </div>
            </div>
        </div>

      </div> 

    )
  }
}
