import React, { Component } from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import UserScreenshots from '../../hr/activity/UserScreenshots';
import { fetch_get, WORK_SUMMARY, PROJECTS_LIST, formatDate, Spinner } from '../../common';

export default class Screenshots extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pmView: window.location.pathname.split('/')[2],
      fromDate: moment(),
      toDate: moment(),
      profile: '',
      projectMembers: [],
      screenShots: [],
      loading: false,
      screenShot: '',
      projectId: this.props.match.params.id
    };
    this.activeTime = this.activeTime.bind(this)
  }

  componentDidMount() {
    this._isMounted = true;
    this.getProjectMembers();
    this.filterScreenShots();
  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.setState({ 
        projectId: this.props.match.params.id,
        profile: '',
        fromDate: moment(),
        toDate: moment()
      }, () => {
        this.getProjectMembers();
        this.filterScreenShots();
      })
    }
  }

  getProjectMembers() {
    fetch_get(PROJECTS_LIST + this.state.projectId + '/get-members/')
    .then((response) => {
      if(this._isMounted) {
        this.setState({ projectMembers: response.members.data });
      }
    });
  }

  filterScreenShots() {
    this.setState({ loading: true });
    var url = '';
    if(this.state.profile) {
      url = 'screens/?from_date=' + this.state.fromDate.format("YYYY-MM-DD") + '&to_date=' + this.state.toDate.format("YYYY-MM-DD")  + '&project=' + this.state.projectId + '&user='+ this.state.profile;
    } else {
      url = 'screens/?from_date=' + this.state.fromDate.format("YYYY-MM-DD") + '&to_date=' + this.state.toDate.format("YYYY-MM-DD") + '&project=' + this.state.projectId;
    }
    fetch_get(WORK_SUMMARY + url)
    .then((response) => {
      if(this._isMounted) {
        this.setState({
          screenShots: response.screenshots_list,
          loading: false
        })
      }
    })
  }

  handleChangeFromDate(fromDate) {
    this.setState({ fromDate });
  }

  handleChangeToDate(toDate) {
    this.setState({ toDate });
  }

  activeTime(seconds) {
    seconds = moment.duration(parseInt(seconds),'seconds')
    var format =  Math.floor(seconds.asHours()) > 0 ? Math.floor(seconds.asHours()) + 'h ' : '';
    format += seconds.minutes() + 'm';
    return format
  }

  clearFilterData() {
    this.setState({
      profile: '',
      fromDate: moment(),
      toDate: moment()
    }, () => { this.filterScreenShots() })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // deleteScreenshots() {
  //   const data = new FormData();
  //   for(let i of document.querySelectorAll('input[type="checkbox"]')) {
  //     if(i.checked) {
  //       data.append('selected_screens[]', i.id);
  //     }
  //   }
  //   fetch_post(WORK_SUMMARY + 'screens/delete/', data, true)
  //   .then((response) => {
  //     if(response.status === 204) {
  //       notify("Succesfully Deleted Screens");
  //       this.filterScreenShots();
  //     }
  //   })
  // }

  // selectAllScreens(index, e) {
  //   this.state.screenShots[index].images.data.map((screen) =>
  //     document.getElementById(screen.id).checked = e.target.checked
  //   );
  // }

  render() {
    return (
      <div className="secondary_block">
        <div className="row mar_lr_0">
          <div className="col-lg-12">
            <h2 className="heading">Screenshots</h2>
            <div className="row filter_row">
              <form className="col-lg-12">
                <div className="row mar_lr_0">
                  <div className="col-lg-3 pl-0 form-group">
                    <Datetime
                      placeholderText="Enter From date" 
                      value={this.state.fromDate}
                      dateFormat="YYYY-MM-DD"
                      timeFormat=""
                      onChange={this.handleChangeFromDate.bind(this)}
                      closeOnSelect
                    />
                  </div>
                  <div className="col-lg-3 form-group">
                    <Datetime
                      placeholderText="Enter To date" 
                      value={this.state.toDate}
                      dateFormat="YYYY-MM-DD"
                      timeFormat=""
                      onChange={this.handleChangeToDate.bind(this)}
                      closeOnSelect
                    />
                  </div>
                  {this.state.projectMembers.length > 1 && 
                    <div className="col-lg-3 form-group">
                      <select 
                        className="form-control " 
                        value={this.state.profile}
                        onChange={(e) => this.setState({ profile: e.target.value })}
                      >
                        <option value="">Select Project member</option>
                        {this.state.projectMembers.map((profile) => 
                          <option key={profile.id} value={profile.id}>{profile.employee_name}</option>
                        )}
                      </select>
                    </div>
                  }
                  <div className="col-lg-3">
                    <button type="button" className="btn primary_btn" onClick={() => this.filterScreenShots()}>Filter</button>
                    <button type="button" className="btn secondary_btn" onClick={() => this.clearFilterData()}>Clear</button>
                  </div>
                </div>
              </form>
           </div>
          </div>
          <div className="col-lg-12">
            {this.state.screenShots.length > 0 && !this.state.loading ? this.state.screenShots.map((screen, id) =>
              <div key={id} className="screens_container">
                <div key={id}>
                  {
                    <UserScreenshots
                      screen={screen}
                      id={id}
                      filteredDate={formatDate(this.state.fromDate, 'YYYY-MM-DD')}
                      toDate={formatDate(this.state.toDate, 'YYYY-MM-DD')}
                    />
                  }
                </div>
              </div>
            ) 
            :
              <div>
                {this.state.loading ? 
                  <Spinner loadingText /> : 
                  <div className="no_result_found"><center>No Screens Found</center></div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
