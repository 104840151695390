import React from 'react'
export class TagsComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      inputTag: '',
      tags: props.tags
    }
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    return (nextProps.tags !== prevState.tags) ? {
      tags: nextProps.tags
    } : null
  }

  removeSelectedTag (tag) {
    const { tags } = this.state
    const index = tags.indexOf(tag)
    tags.splice(index, 1)
    this.setState({ tags })
    this.props.onChange(tags)
  }

  handleKeyDown (e) {
    if (e.keyCode === 8 && this.state.inputTag === '') {
      const { tags } = this.state
      tags.splice(-1, 1)
      this.setState({ tags })
      this.props.onChange(tags)
    }
  }

  handleKeyPress (event) {
    if (event.key === 'Enter') {
      let isDuplicate = false
      this.state.tags.forEach((obj) => {
        if (obj === event.target.value) {
          isDuplicate = true
        }
      })
      if (!isDuplicate && event.target.value !== '') {
        this.setState({
          tags: this.state.tags.concat(event.target.value)
        })
        this.props.onChange(this.state.tags.concat(event.target.value))
      }
      this.setState({ inputTag: '' })
    }
  }

  render () {
    return (
      <div
        className='tags no-margin form-control'
        onClick={() => document.getElementById('tagInput').focus()}
      >
        {
          this.state.tags.map((tag) =>
            <span className='tag' key={tag} style={{ backgroundColor: 'lightgrey' }}>
              {tag}
              <a className='close-select' onClick={() => this.removeSelectedTag(tag)}>
                <i className='fa fa-times' style={{ color: 'black' }} />
              </a>
            </span>
          )
        }
        <input
          type='text'
          id='tagInput'
          value={this.state.inputTag}
          className='border-0 tag-input'
          size={this.state.inputTag.length + 1}
          onKeyDown={this.handleKeyDown.bind(this)}
          onChange={(e) => this.setState({ inputTag: e.target.value })}
          onKeyPress={this.handleKeyPress}
        />
      </div>
    )
  }
}
