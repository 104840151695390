import React, { Component } from 'react';
import { MEETINGS, fetch_get, notify, Context } from '../../common';

export default class MeetingActions extends Component {
  static contextType = Context;
  componentDidMount() {
    fetch_get(`${MEETINGS + this.props.match.params.mid}/${this.props.match.params.status}-invitation`)
    .then((response) => {
      if (response.message) {
        notify(response.message)
        this.props.history.push(this.context.company + '/pm/' + this.props.match.params.id +'/meetings/' + this.props.match.params.mid + '/details')
      }
    }); 
  }
  render() {
    return(
      <div>Processing...</div>
    );
  }
}
